import React, { Component } from "react";
import {
    initialStatisticSeach,
    getStatisticSearch,
    setStatisticSearch,
} from "../../pages/Home/StatisticPage/statistic";
import moment from "moment";
import { Form, DatePicker, Button, Input, Select } from "antd";

import "./StatisticSearch.scss";
import {
    getExecutorsFromServer,
    getStatisticDoctor,
    getStatisticFromServer,
    getChartSummery,
    getChartSummeryByDate,
} from "../../reducers/statisticReducer";
import { connect } from "react-redux";
import { setLoading } from "../../reducers/loadingHomeReducer";

const { Option } = Select;

class StatisticSearch extends Component {
    onFinish = async (values) => {
        this.props.setLoading(true);
        let statisticSearch = getStatisticSearch();
        statisticSearch.fromDate = moment(values.fromDate._d).format("L");
        statisticSearch.toDate = moment(values.toDate._d).format("L");
        statisticSearch.typeSearch = values.typeSearch;
        statisticSearch.existsOnHis = values.existsOnHis;
        statisticSearch.areaId = values.areaId;
        statisticSearch.PageIndex = 1;

        setStatisticSearch(statisticSearch);
        this.props.getChartSummery(getStatisticSearch());
        this.props.getChartSummeryByDate(getStatisticSearch());
        Promise.all([
            this.props.getStatisticDoctor(getStatisticSearch()),
            this.props.getExecutorsFromServer(getStatisticSearch()),
        ]).then((values) => {
            this.props.setLoading(false);
        });
    };
    render() {
        const { listArea } = this.props;
        return (
            <div className="statistic-search-d">
                <Form
                    name="horizontal_login"
                    initialValues={initialStatisticSeach}
                    layout="inline"
                    onFinish={this.onFinish}
                >
                    <Form.Item name="fromDate" label="Từ">
                        <DatePicker format="DD/MM/YYYY" placeholder="" />
                    </Form.Item>
                    <Form.Item name="toDate" label="Đến">
                        <DatePicker format="DD/MM/YYYY" placeholder="" />
                    </Form.Item>
                    <Form.Item name="areaId" valuePropName="value" label="Khu vực">
                        <Select style={{ width: 170 }}>
                            <Option value={""}>Tất cả</Option>
                            {listArea.map((item) => {
                                return (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item name="typeSearch" valuePropName="value" label="Lọc theo">
                        <Select style={{ width: 170 }}>
                            <Option value={1}>Loại thiết bị</Option>
                            <Option value={2}>Thiết bị</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="existsOnHis" valuePropName="value" label="HIS">
                        <Select style={{ width: 170 }}>
                            <Option value="">Tất cả</Option>
                            <Option value={true}>Đồng bộ His</Option>
                            <Option value={false}>Không đồng bộ His</Option>
                        </Select>
                    </Form.Item>
                    <Button style={{ background: "#2a274c", color: "#fff" }} htmlType="submit">
                        Tìm kiếm
                    </Button>
                </Form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        summary: state.statistic.summary,
        doctors: state.statistic.doctors,
        executors: state.statistic.executors,
        chartSummary: state.statistic.chartSummary,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getStatisticFromServer: (search) => dispatch(getStatisticFromServer(search)),
        getStatisticDoctor: (search) => dispatch(getStatisticDoctor(search)),
        getExecutorsFromServer: (search) => dispatch(getExecutorsFromServer(search)),
        getChartSummery: (search) => dispatch(getChartSummery(search)),
        getChartSummeryByDate: (search) => dispatch(getChartSummeryByDate(search)),
        setLoading: (data) => dispatch(setLoading(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticSearch);
