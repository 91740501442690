import Axios from "axios";
import { message } from "antd";
import { store } from "../store/store";
const ACTION_TYPES = {
    SET_DATA_AREA: "SET_DATA_AREA",
    SET_AREA: "SET_AREA",
    SET_AREA_ITEM: "SET_AREA_ITEM",
};

const initialstate = {
    areas: [],
    totalCount: 0,
    areaItem: {},
    listArea: [],
};

const areaReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_DATA_AREA:
            state = action.payload;
            return {...state };
        case ACTION_TYPES.SET_AREA:
            state = {...state };
            state.areas = action.payload.data;
            state.totalCount = action.payload.dataCount;
            return {...state };
        case ACTION_TYPES.SET_AREA_ITEM:
            state.areaItem = action.payload;
            return {...state };
        default:
            return state;
    }
};
const setAreas = (areas) => {
    return {
        type: ACTION_TYPES.SET_AREA,
        payload: areas,
    };
};
const getAreaFromServer = (request) => async(dispatch) => {
    const response = await Axios.get(
        `api/area/paging?Keyword=${request.Keyword}&PageIndex=${request.PageIndex}&PageSize=${request.PageSize}`
    );
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setAreas(response.data));
        }
    } else {
        message.error(response.status);
    }
};

const setAreaItem = (area) => {
    return {
        type: ACTION_TYPES.SET_AREA_ITEM,
        payload: area,
    };
};

const setAreaData = (area) => {
    return {
        type: ACTION_TYPES.SET_DATA_AREA,
        payload: area,
    };
};
const getAreaById = (id) => async(dispatch) => {
    const response = await Axios.get(`api/area/${id}`);
    if (response.data.status === 1) {
        dispatch(setAreaItem(response.data.data));
    }
    
};
const updateAreaToServer = (id, area) => async(dispatch) => {
    await Axios.put(`api/area/${id}`, area)
        .then((response) => {
            if (response.data) {
                if (response.data.status === 1) {
                    message.success(response.data.message);
                }
                
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let messages = "";

                message.error(messages, 5);
            }
        });
};

const addAreaToServer = (request) => async(dispatch) => {
    await Axios.post("api/area", request)
        .then((response) => {
            if (response.status === 200) {
                if (response.data.status === 1) {
                    message.success("Successfully! ");
                }
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let mes = "";
                if (e.Name) {
                    mes += e.Name + "! ";
                }
                for (var key in e) {
                    mes += e[key] + "!\n";
                }
                message.error(mes, 3);
            }
        });
};

const deleteAreaFromServer = (id) => async(dispatch) => {
    await Axios.delete(`api/area/${id}`)
        .then((response) => {
            if (response.data.status === 1) {
                message.success(response.data.message);
            } else {
                message.error(response.data.message);
            }
        })
        .catch((err) => {
            message.error("Error!");
        });
};

//Active area
const activeArea = (id) => async(dispatch) => {
    await Axios.put(`/api/area/${id}/active`).catch((err) => {
        message.error("Error!");
    });
};

const inactiveArea = (id) => async(dispatch) => {
    await Axios.put(`/api/area/${id}/inactive`).catch((err) => {
        message.error("Error!");
    });
};
const fetchDropdownAreas = () => async(dispatch) => {
    const response = await Axios.get(`/api/hospitals/dropdown-area`);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setAreaData({...store.getState().area, listArea: response.data.data }));
        }
    }
};
export {
    addAreaToServer,
    deleteAreaFromServer,
    getAreaFromServer,
    areaReducer,
    getAreaById,
    updateAreaToServer,
    activeArea,
    inactiveArea,
    fetchDropdownAreas,
};