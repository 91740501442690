import { Button } from "antd";
import Axios from "axios";
import React, { Component } from "react";
import { MODALIATY_TYPE } from "../../models/modalityType";
import { getStatisticBaseSearchPrint } from "../../pages/Home/StatisticPage/statistic";

export class TableDoctorsBase extends Component {
    onPrint = (totalAll,totalCol,totalRow) => {
        window.setUpStatisticDoctors(this.props.data,MODALIATY_TYPE,getStatisticBaseSearchPrint());
        //printJS('ris-preview-template', 'html');
        let winPrintDoctos = window.open('', 'print-doctors');
        winPrintDoctos.document.write(document.getElementById('statistic-doctors-print').innerHTML);
        winPrintDoctos.document.close(); // necessary for IE >= 10
        winPrintDoctos.focus(); // necessary for IE >= 10*/

        winPrintDoctos.print();
        setTimeout(function () {
            winPrintDoctos.close();
        }, 250);
    };
    render() {
        const { data } = this.props;
        const totalRow = [];
        const totalCol = [];
        let totalAll = 0;
        // MODALIATY_TYPE.forEach((item, index) => {
        //     let totalRowItem = 0;
        //     {
        //         data.forEach((i) => {
        //             totalRowItem += i.numberOfModalities[item];
        //         });
        //         totalRow.push(totalRowItem);
        //     }
        // });
        // data.forEach((i) => {
        //     let totalColItem = 0;
        //     MODALIATY_TYPE.forEach((item, index) => {
        //         {
        //             if (i.numberOfModalities[item]) {
        //                 totalColItem += i.numberOfModalities[item];
        //                 totalAll += i.numberOfModalities[item];
        //             }
        //         }
        //     });
        //     totalCol.push(totalColItem);
        // });
        return (
            <div id="table-summaryBase" className="table-statistic">
                <div style={{ width: "100%", height: 30, background: "#2a274c", marginTop: 10,position:"relative" }}>
                    <p style={{ lineHeight: "30px", color: "white", textAlign: "center", fontSize: 20 }}>
                        <b>THỐNG KÊ BÁC SĨ</b>
                    </p>
                    <Button style={{position:"absolute",top:0,right:0}} onClick= {()=> this.onPrint()}>
                        In
                    </Button>
                </div>
                <table className="table-admin" style={{ margin: 0 }}>
                    <thead>
                        <tr>
                            <th style={{ width: "300px" }}>Họ và Tên</th>
                            {MODALIATY_TYPE.map((item, index) => {
                                return <th key={item}>{item}</th>;
                            })}
                            <th>Tổng</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => {
                            let totalItem = 0;
                            return (
                                <tr key={index}>
                                    <td>{item.drName}</td>
                                    {MODALIATY_TYPE.map((m, ind) => {
                                        totalItem += Number(item.numberOfModalities[m] ?? 0);
                                        return <td key={ind}>{item.numberOfModalities[m]}</td>;
                                    })}
                                    <td>{totalItem}</td>
                                </tr>
                            );
                        })}

                        {/* <tr>
                            <td>Tổng</td>
                            {totalCol.map((item) => (
                                <td>{item}</td>
                            ))}
                            <td>{totalAll}</td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        );
    }
}
