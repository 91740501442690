import React, { Component } from "react";
import { Form, Input, Button, message, Layout } from "antd";
import { getRoleById, updateRoleToServer, getRoleFromServer } from "../../reducers/roleManagerReducer";
import { connect } from "react-redux";
import moment from "moment";
import { handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const { Header, Content, Footer } = Layout;
class ContentEditRole extends Component {
    constructor(props) {
        super(props);
        this.formRef = null;
    }

    componentDidMount = () => {
        this.props.getRoleById(this.props.roleId).then(() => {
            this.formRef.setFieldsValue({ ...this.props.roleItem });
        });
    };
    componentDidUpdate = (prevProps) => {
        if (prevProps.roleId !== this.props.roleId) {
            this.props.getRoleById(this.props.roleId).then(() => {
                this.formRef.setFieldsValue({ ...this.props.roleItem });//1-1
            });
        }
    };
    onFinish = (values) => {
        const { roleId } = this.props;
        this.props.updateRoleToServer(roleId, values).then(() => {
            this.props.handleCloseModalApp();
            this.props.getRoleFromServer();
        });
    };
    render() {
        const {roleItem} = this.props;
        return (
            <>
                <Layout className="layout-role-edit">
                    <Header>
                        <p className="modalBasic-title">Sửa Quyền</p>
                        <button
                            className="modalBasic-btn-close"
                            onClick={() => this.props.setModalBasic({ visible: false })}
                        >
                            <i className="fas fa-times icon"></i>
                        </button>
                    </Header>
                    <Content>
                        <Form
                            {...formItemLayout}
                            name="addRole"
                            className="form-edit-role"
                            onFinish={this.onFinish}
                            ref={(ref) => (this.formRef = ref)}
                            scrollToFirstError
                        >
                            <Form.Item name="name" label="Tên quyền">
                                <Input disabled = {roleItem.name == "DoctorOnlyRead" ||roleItem.name == "DoctorApproved" || roleItem.name == "Admin" || roleItem.name == "DoctorsConsultation" || roleItem.name == "Technician" || roleItem.name == "SuperAdmin"}  allowClear />
                            </Form.Item>
                            <Form.Item name="description" label="Mô tả">
                                <Input allowClear />
                            </Form.Item>
                            
                            <Button style={{ display: "none" }} id="btn-submit-form-edit-role" htmlType={"submit"}>
                                Lưu lại
                            </Button>
                        </Form>
                    </Content>
                    <Footer>
                        <Button
                            style={{ color: "#fff", background: "#2687d4" }}
                            onClick={() => document.getElementById("btn-submit-form-edit-role").click()}
                        >
                            Lưu lại
                        </Button>
                    </Footer>
                </Layout>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        roleItem: state.role.roleItem,//1-1
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getRoleById: (id) => dispatch(getRoleById(id)),
        updateRoleToServer: (id, role) => dispatch(updateRoleToServer(id, role)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        getRoleFromServer: (search) => dispatch(getRoleFromServer(search)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentEditRole);
