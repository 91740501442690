import moment from "moment";
let patientSeach = {
    Keyword: "",
    Age: "",
    Sex: "",
    PageIndex: 1,
    PageSize: 10,
};

const getPatientSearch = () => {
    return patientSeach;
};

const setPatientSearch = (search) => {
    patientSeach = search;
};

export { getPatientSearch, setPatientSearch };
