import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, message, Checkbox, Select, Layout, InputNumber } from "antd";
import { getAreaById, updateAreaToServer, getAreaFromServer, fetchDropdownAreas } from "../../reducers/areaReducer";
import { connect } from "react-redux";
import moment from "moment";
import { getAreaSearch } from "../../pages/Home/AreaPage/area";
import { handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
const { Option } = Select;

const { Content, Footer, Header } = Layout;

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
class ContentEdit extends Component {
    constructor(props) {
        super(props);
        this.formRef = null;
    }

    componentDidMount = () => {
        this.props.getAreaById(this.props.areaId).then(() => {
            this.formRef.setFieldsValue({ ...this.props.areaItem, doB: moment(this.props.areaItem.doB) });
        });
    };
    componentDidUpdate = (prevProps) => {
        if (prevProps.areaId !== this.props.areaId) {
            this.props.getAreaById(this.props.areaId).then(() => {
                this.formRef.setFieldsValue({ ...this.props.areaItem, doB: moment(this.props.areaItem.doB) });
            });
        }
    };
    onFinish = (values) => {
        const { areaId } = this.props;
        values.areaID = Number(values.areaID);
        this.props.updateAreaToServer(areaId, values).then(() => {
            this.props.setModalBasic({ visible: false });
            const search = getAreaSearch();
            this.props.getAreaFromServer(search);
        });
    };
    render() {
        const { listArea } = this.props;
        return (
            <>
                <Layout className="layout-doctor-edit">
                    <Header>
                        <p className="modalBasic-title">Cập nhật thông tin</p>
                        <button
                            className="modalBasic-btn-close"
                            onClick={() => this.props.setModalBasic({ visible: false })}
                        >
                            <i className="fas fa-times icon"></i>
                        </button>
                    </Header>
                    <Content>
                        <Form
                            className="form-edit-area"
                            {...formItemLayout}
                            name="addArea"
                            onFinish={this.onFinish}
                            ref={(ref) => (this.formRef = ref)}
                            scrollToFirstError
                        >
                            <Form.Item name="name" label="Tên" rules={[{ required: true, message:"vui lòng nhập tên!" }]}>
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="description" label="Mô tả">
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="order" label="Thứ tự" valuePropName="value">
                                <InputNumber min={0} max={1500} defaultValue={1} />
                            </Form.Item>
                            <Form.Item name="areaID" label="Cơ sở" valuePropName="value">
                                <Select allowClear>
                                    {listArea.map((item) => {
                                        return (
                                            <Option key={item.id} value={Number(item.id)}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name="status" label="Status" hidden>
                                <Input />
                            </Form.Item>
                            <Button
                                style={{ color: "#fff", background: "#2687d4", display: "none" }}
                                id="btn-submit-edit-area"
                                htmlType={"submit"}
                            >
                                Lưu lại
                            </Button>
                        </Form>
                    </Content>
                    <Footer>
                        <Button
                            style={{ color: "#fff", background: "#2687d4" }}
                            onClick={() => document.getElementById("btn-submit-edit-area").click()}
                            htmlType={"submit"}
                        >
                            Lưu lại
                        </Button>
                    </Footer>
                </Layout>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        areaItem: state.area.areaItem,
        listArea: state.area.listArea,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAreaById: (id) => dispatch(getAreaById(id)),
        updateAreaToServer: (id, area) => dispatch(updateAreaToServer(id, area)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        fetchDropdownAreas: () => dispatch(fetchDropdownAreas()),
        getAreaFromServer: (search) => dispatch(getAreaFromServer(search)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentEdit);
