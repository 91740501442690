import React, { Component } from "react";
import "./SummaryTable.scss";

class ExecutorsTable extends Component {
    state = {
        top: 0,
    };
    componentDidUpdate = (prevProps) => {
        if (prevProps.executors !== this.props.executors) {
            this.setState({
                top: this.thead.offsetHeight,
            });
        }
    };
    render() {
        const { executors } = this.props;
        return (
            <div className={"summary-table-d"}>
                <div style={{ width: "100%", height: 30, background: "#2a274c" }}>
                    <p style={{ lineHeight: "30px", color: "white", textAlign: "center", fontSize: 20 }}>
                        <b>THỐNG KÊ KỸ THUẬT VIÊN</b>
                    </p>
                </div>
                <div style={{ width: "100%", maxHeight: 600, overflow: "auto" }}>
                    <table
                        border="0"
                        className="table-admin"
                        style={{ position: "relative", maxHeight: 100, overflow: "auto", margin: 0 }}
                    >
                        <thead>
                            <tr>
                                <th rowSpan="2" style={{ position: "sticky", background: "#fff", top: 0 }}>
                                    STT
                                </th>
                                <th rowSpan="2" style={{ position: "sticky", background: "#fff", top: 0 }}>
                                    Họ và tên
                                </th>
                                {executors.listModal
                                    ? executors.listModal.map((modal, index) => {
                                          return (
                                              <th
                                                  className="item-executor"
                                                  ref={(ref) => (this.thead = ref)}
                                                  colSpan="2"
                                                  style={{ position: "sticky", background: "#fff", top: 0 }}
                                                  key={modal.key}
                                              >
                                                  {modal.value}
                                              </th>
                                          );
                                      })
                                    : ""}
                            </tr>
                            <tr>
                                {executors.listModal
                                    ? executors.listModal.map((modal, index) => {
                                          return (
                                              <>
                                                  <th
                                                      style={{
                                                          position: "sticky",
                                                          background: "#fff",
                                                          top: this.state.top,
                                                      }}
                                                      key={index}
                                                  >
                                                      Chính
                                                  </th>
                                                  <th
                                                      style={{
                                                          position: "sticky",
                                                          background: "#fff",
                                                          top: this.state.top,
                                                      }}
                                                      key={index}
                                                  >
                                                      Phụ
                                                  </th>
                                              </>
                                          );
                                      })
                                    : ""}
                            </tr>
                        </thead>
                        <tbody>
                            {executors.doctor
                                ? executors.doctor.map((doctor, index) => {
                                      let rows = [];
                                      for (let key in doctor.mainNumbers) {
                                          rows.push(doctor.mainNumbers[key]);
                                          rows.push(doctor.subNumbers[key]);
                                      }
                                      return (
                                          <tr key={index}>
                                              <td>{index + 1}</td>
                                              <td style={{ whiteSpace: "nowrap" }}>{doctor.executor}</td>
                                              {rows.map((it, id) => {
                                                  return <td key={id}>{it}</td>;
                                              })}
                                          </tr>
                                      );
                                  })
                                : ""}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
export default ExecutorsTable;
