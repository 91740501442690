let doctorroleSeach = {
    Keyword: "",
    RoleName:"",
    PageIndex: 1,
    PageSize: 10,
};

const getDoctorRoleSearch = () => {
    return doctorroleSeach;
};

const setDoctorRoleSearch = (search) => {
    doctorroleSeach = search;
};


export { setDoctorRoleSearch, getDoctorRoleSearch };
