import React, { Component } from "react";
import { connect } from "react-redux";
import { getTypeServiceFromServer, deleteTypeServiceFromServer } from "../../../reducers/typeserviceReducer";
import { getTypeServiceSearch, initialTypeServiceSeach, setTypeServiceSearch } from "./typeservice";
import { handleOpenModalApp, setModalBasic } from "../../../reducers/uiReducer";
import ContentEdit from "../../../components/TypeService/ContentEdit";
import AddFormContent from "../../../components/TypeService/AddFormContent";
import {Form, Pagination, Button, Popconfirm,Input} from "antd"
import FontAwesome from "react-fontawesome";
import "./TypeServicePage.scss"
class TypeServicePage extends Component {
    componentDidMount = () => {
        this.props.getTypeServiceFromServer(getTypeServiceSearch());
    };

    handleUpdateTable = () => {
        this.props.getTypeServiceFromServer(getTypeServiceSearch());
    };
  
    onEdit = (typeserviceId, typeserviceName) => {
        const modalData = {
            visible: true,
            width: 900,
            content: <ContentEdit typeserviceId={typeserviceId} typeserviceName={typeserviceName} />,
            name: "doctor-add",
        };
        this.props.setModalBasic(modalData);
    };

    onAdd = () => {
        const modalData = {
            visible: true,
            width: 900,
            content: <AddFormContent />,
            name: "doctor-add",
        };
        this.props.setModalBasic(modalData);
    };

    onDelete = (typeserviceId, typeserviceName) => {
        this.props.deleteTypeServiceFromServer(typeserviceId).then(() => {
            setTypeServiceSearch({ ...getTypeServiceSearch(), PageIndex: 1 });
            this.props.getTypeServiceFromServer(getTypeServiceSearch());
        });
    };

    onFinish = (values) => {
        let typeservicesSearch = getTypeServiceSearch();
        typeservicesSearch.Keyword = values.Keyword;
        typeservicesSearch.PageIndex = 1;
        setTypeServiceSearch(typeservicesSearch);
        this.props.getTypeServiceFromServer(getTypeServiceSearch());
    };
    onChangePage = (page, pageSize) => {
        let typeservicesSearch = getTypeServiceSearch();
        typeservicesSearch.PageIndex = page;
        typeservicesSearch.PageSize = pageSize;
        setTypeServiceSearch(typeservicesSearch);
        this.props.getTypeServiceFromServer(getTypeServiceSearch());
    };
    render() {
        const { typeservices, totalCount } = this.props;
        return (
            <div className="typeservice-page-d">
                <div className="typeservice-search-d">
                    <Form
                        name="horizontal_login"
                        initialValues={initialTypeServiceSeach}
                        layout="inline"
                        onFinish={this.onFinish}
                    >
                        <Form.Item name="Keyword">
                            <Input placeholder="Từ khóa" />
                        </Form.Item>

                        <Button style={{ background: "#2a274c", color: "#fff" }} htmlType="submit">
                            Tìm kiếm
                        </Button>
                    </Form>
                    <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.onAdd}>
                        Thêm mới
                    </Button>
                </div>
                <div className={"typeservice-table-d"}>
                    <table border="0" className="table-admin">
                        <thead>
                            <tr>
                                <th>Tên</th>
                                <th>Rút gọn</th>
                                <th>Mã</th>
                                <th>Trạng thái</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {typeservices.map((typeservices, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{typeservices.name}</td>
                                        <td>{typeservices.shortName}</td>
                                        <td>{typeservices.code}</td>
                                        <td>
                                            <input type="checkbox" checked={typeservices.active}></input>
                                        </td>
                                        <td>
                                            <div className="tools">
                                                <Button onClick={() => this.onEdit(typeservices.id, typeservices.name)}>
                                                    <FontAwesome name="edit" />
                                                </Button>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={`Xác nhận xóa ${typeservices.name}`}
                                                    onConfirm={() => this.onDelete(typeservices.id, typeservices.name)}
                                                    okText="Ok"
                                                    cancelText="Cancel"
                                                >
                                                    <Button>
                                                        <FontAwesome name="trash-alt" />
                                                    </Button>
                                                </Popconfirm>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="pagination-d">
                        <Pagination
                            defaultCurrent={5}
                            current={getTypeServiceSearch().PageIndex}
                            total={totalCount}
                            showQuickJumper
                            showTitle={false}
                            defaultPageSize={getTypeServiceSearch().PageSize}
                            onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        typeservices: state.typeservice.typeservices,
        totalCount: state.typeservice.totalCount,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getTypeServiceFromServer: (search) => dispatch(getTypeServiceFromServer(search)),
        handleOpenModalApp: (modalData) => dispatch(handleOpenModalApp(modalData)),
        deleteTypeServiceFromServer: (id) => dispatch(deleteTypeServiceFromServer(id)),
        setModalBasic: (data) => dispatch(setModalBasic(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TypeServicePage);
