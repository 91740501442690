import React, { Component } from "react";
import { connect } from "react-redux";
import {
    getDoctorRoleFromServer,
    fetchDoctorRoleById,
    getDoctorNotRoleFromServer,
} from "../../../reducers/doctorroleReducer";
import { getDoctorRoleSearch, setDoctorRoleSearch } from "./doctorrole";
import { handleOpenModalApp, setModalBasic } from "../../../reducers/uiReducer";
import ContentEdit from "../../../components/DoctorRole/ContentEdit";
import { Button, Pagination, Form, Input, Checkbox,Select } from "antd";
import FontAwesome from "react-fontawesome";
import "./DoctorRolePage.scss";
import ContentAdd from "../../../components/DoctorRole/ContentAdd";
import RoleItem from "../../../components/DoctorRole/RoleItem";
import ContentAddViaRole from "../../../components/DoctorRole/ContentAddViaRole";
import { removeVietnameseTones } from "../../../utils";
import { getDropdownRoles } from "../../../reducers/doctorReducer";

const { Option } = Select;
class DoctorRolePage extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount = () => {
        this.props.getDoctorRoleFromServer(getDoctorRoleSearch());
        this.props.getDropdownRoles();
    };
    handleUpdateTable = () => {
        this.props.getDoctorRoleFromServer(getDoctorRoleSearch());
    };

    handleEdit = (doctorrole) => {
        this.props.fetchDoctorRoleById(doctorrole.userId);
        const modalData = {
            visible: true,
            width: window.screen.width -100,
            content: <ContentEdit />,
            name: "doctor-add",
        };
        this.props.setModalBasic(modalData);
    };
    handleAdd = () => {
        const modalData = {
            visible: true,
            width: window.screen.width -100,
            content: <ContentAdd />,
            name: "doctor-add",
        };
        this.props.setModalBasic(modalData);
    };

    handleAddByRolesGroup = () => {
        const modalData = {
            visible: true,
            width: window.screen.width -100,
            content: <ContentAddViaRole />,
            name: "doctor-add",
        };
        this.props.setModalBasic(modalData);
    };

    onChangePage = (page, pageSize) => {
        let doctorRoleSearch = getDoctorRoleSearch();
        doctorRoleSearch.PageIndex = page;
        doctorRoleSearch.PageSize = pageSize;
        setDoctorRoleSearch(doctorRoleSearch);
        this.handleUpdateTable();
    };

    onFinish = (values) => {
        if(!values.Roles)
            values.Roles = "";
        setDoctorRoleSearch({
            ...getDoctorRoleSearch(),
            PageIndex:  1,
            Keyword: values.Keyword,
            RoleName: values.Roles
        })
        this.handleUpdateTable();
    };


    render() {
        const { doctorroles, totalCount,listGroupRoles } = this.props;
        const listRoleTitle = doctorroles.length>1 ?Object.getOwnPropertyNames(doctorroles[0].listServerpacs[0].permissionData):[];
        const title = {
            Clinical: "Chỉ xem",
            Read: "Đọc",
            Approve: "Duyệt",
            Edit: "Chỉnh sửa",
            Delete: "Xóa",
            UpdateFromHis: "Cập nhật từ HIS",
            Share: "Chia sẻ",
            Statistical: "Thống kê",
            ApproveDiscard:"Hủy duyệt",
            Consult:"Hội chẩn",
            ConsultDiscard:"Hủy hội chẩn",
            DeleteSeri:"Xóa seri",
        };
        return (
            <div className="doctorrole-page-d">
                <div className="doctorrole-search-d">
                    <Form
                        name="horizontal_login"
                        initialValues={{ Keyword: "" }}
                        layout="inline"
                        onFinish={this.onFinish}
                    >
                        <Form.Item name="Keyword">
                            <Input placeholder="Email & Họ tên" />
                        </Form.Item>
                        <Form.Item name="Roles" style={{width: 250 }}>
                            <Select
                                defaultValue=""
                                showSearch
                                style={{ width: "100%" }}
                                filterOption={(input, option) =>
                                    {
                                        option.children = removeVietnameseTones(option.children);
                                        input = removeVietnameseTones(input);
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    }
                                >
                                    <Option value="">Tất cả</Option>
                                    {listGroupRoles.map((item) => {
                                        return (
                                            <Option key={item.name} value={item.name}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                        <Button style={{ background: "#2a274c", color: "#fff" }} htmlType="submit">
                            Tìm kiếm
                        </Button>
                        <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.handleAdd}>
                            Thêm mới
                        </Button>
                        <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.handleAddByRolesGroup}>
                            Phân quyền theo nhóm
                        </Button>
                    </Form>
                </div>
                <div className={"doctorrole-table-d "}>
                    <table border="0" className="table-admin">
                        <thead>
                            <tr>
                                <th>Tên</th>
                                <th>Cơ sở</th>
                                <th>Thư mục</th>
                                <th>Máy chụp</th>
                                {doctorroles.length > 0 &&
                                    listRoleTitle.map(
                                        (j, index) => {
                                            return <th key={j}>{title[j]}</th>;
                                        }
                                    )}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {doctorroles.map((doctorrole, index) => {
                                return (
                                        <RoleItem key={`r-${index}`} doctorrole={doctorrole} index={index} numRole={Object.getOwnPropertyNames(title).length} />
                                )
                                })
                            }
                        </tbody>
                    </table>
                    <div className="pagination-d">
                        <Pagination
                            defaultCurrent={3}
                            current={getDoctorRoleSearch().PageIndex}
                            total={totalCount}
                            showQuickJumper
                            hideOnSinglePage
                            showTitle={false}
                            defaultPageSize={getDoctorRoleSearch().PageSize}
                            onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        doctorroles: state.doctorrole.doctorroles,
        totalCount: state.doctorrole.totalCount,
        listGroupRoles: state.doctor.listGroupRoles
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getDoctorRoleFromServer: (search) => dispatch(getDoctorRoleFromServer(search)),
        getDoctorNotRoleFromServer: () => dispatch(getDoctorNotRoleFromServer()),
        getDropdownRoles: () => dispatch(getDropdownRoles()),
        handleOpenModalApp: (modalData) => dispatch(handleOpenModalApp(modalData)),
        fetchDoctorRoleById: (id) => dispatch(fetchDoctorRoleById(id)),
        setModalBasic: (data) => dispatch(setModalBasic(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorRolePage);
