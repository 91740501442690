import React, { Component } from "react";
import { Col, Row, Input, Form, Button, Select, DatePicker } from "antd";
import "./TechnicalServiceSearch.scss";
import {
    getTechnicalServiceSearch,
    setTechnicalServiceSearch,
    technicalserviceSeachDefault,
} from "../../pages/Home/TechnicalServicePage/technicalservice";
import AddFormContent from "./AddFormContent";
import FontAwesome from "react-fontawesome";
import moment from "moment";

class TechnicalServiceSearch extends Component {
    onFinish = (values) => {
        let technicalserviceSearch = getTechnicalServiceSearch();
        technicalserviceSearch.Keyword = values.Keyword;
        technicalserviceSearch.studyID = values.studyID;
        technicalserviceSearch.code = values.code;
        technicalserviceSearch.fromDate = moment(values.fromDate._d).format("L");
        technicalserviceSearch.toDate = moment(values.toDate._d).format("L");
        technicalserviceSearch.PageIndex = 1;
        setTechnicalServiceSearch(technicalserviceSearch);
        this.props.handleUpdateTable(technicalserviceSearch);
    };
    onOpenModal = () => {
        const modalData = {
            title: [<p>Thêm mới bác sĩ</p>],
            visible: true,
            width: 900,
            height: 500,
            content: <AddFormContent handleAdd={this.handleAdd} />,
        };
        this.props.handleOpenModal(modalData);
    };
    render() {
        return (
            <div className="technicalservice-search-d">
                <Form
                    name="horizontal_login"
                    initialValues={technicalserviceSeachDefault}
                    layout="inline"
                    onFinish={this.onFinish}
                >
                    <Form.Item name="fromDate">
                        <DatePicker format="DD/MM/YYYY" placeholder="" />
                    </Form.Item>
                    <Form.Item name="toDate">
                        <DatePicker format="DD/MM/YYYY" placeholder="" />
                    </Form.Item>
                    <Form.Item name="Keyword">
                        <Input placeholder="Tên dịch vụ" />
                    </Form.Item>
                    <Form.Item name="studyID">
                        <Input placeholder="studyID" />
                    </Form.Item>
                    <Form.Item name="code">
                        <Input placeholder="code" />
                    </Form.Item>
                    <Button style={{ background: "#2a274c", color: "#fff" }} htmlType="submit">
                        Tìm kiếm
                    </Button>
                </Form>
                {/* <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.onOpenModal}>
                    Add
                </Button> */}
            </div>
        );
    }
}

export default TechnicalServiceSearch;
