import Axios from "axios";
import { message } from "antd";
const ACTION_TYPES = {
    SET_TECHNICALSERVICE: "SET_TECHNICALSERVICE",
    SET_TECHNICALSERVICE_ITEM: "SET_TECHNICALSERVICE_ITEM",
};

const initialstate = {
    technicalservices: [],
    totalCount: 0,
    technicalserviceItem: {},
};

const technicalserviceReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_TECHNICALSERVICE:
            state = { ...state };
            state.technicalservices = action.payload.data;
            state.totalCount = action.payload.dataCount;
            return { ...state };
        case ACTION_TYPES.SET_TECHNICALSERVICE_ITEM:
            state.technicalserviceItem = action.payload;
            return { ...state };
        default:
            return state;
    }
};
const setTechnicalServices = (technicalservices) => {
    return {
        type: ACTION_TYPES.SET_TECHNICALSERVICE,
        payload: technicalservices,
    };
};
const getTechnicalServiceFromServer = (request) => async (dispatch) => {
    const response = await Axios.get(
        `api/ServiceExs/paging?Keyword=${request.Keyword}&code=${request.code}&studyID=${request.studyID}&fromDate=${request.fromDate}&toDate=${request.toDate}&PageIndex=${request.PageIndex}&PageSize=${request.PageSize}`
    );
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setTechnicalServices(response.data));
        }
        
    } else {
        message.error(response.status);
    }
};

const setTechnicalServiceItem = (technicalservice) => {
    return {
        type: ACTION_TYPES.SET_TECHNICALSERVICE_ITEM,
        payload: technicalservice,
    };
};
const getTechnicalServiceById = (id) => async (dispatch) => {
    const response = await Axios.get(`/api/ServiceExs/${id}`);
    if (response.data.status === 1) {
        dispatch(setTechnicalServiceItem(response.data.data));
    }
    
};

const updateTechnicalServiceToServer = (id, technicalservice) => async (dispatch) => {
    await Axios.put(`api/ServiceExs/${id}`, technicalservice)
        .then((response) => {
            if (response.data) {
                message.success(response.data.message);
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let messages = "";
                if (e.Email) {
                    for (let i of e.Email) {
                        messages += i + "! ";
                    }
                }
                if (e.PhoneNo) {
                    for (let i of e.PhoneNo) {
                        messages += i + "! ";
                    }
                }
                if (e.Name) {
                    for (let i of e.Name) {
                        messages += i + "! ";
                    }
                }
                message.error(messages, 5);
            }
        });
};

const addTechnicalServiceToServer = (request) => async (dispatch) => {
    await Axios.post("api/ServiceExs/register", request)
        .then((response) => {
            if (response.status === 200) {
                if (response.data.status !== 1) {
                    message.error(response.data.message);
                } else {
                    message.success(response.data.message);
                }
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let mes = "";
                if (e.Email) {
                    for (let i of e.Email) {
                        mes += i + ", ";
                    }
                }
                if (e.Password) {
                    for (let i of e.Password) {
                        mes += i + ", \n";
                    }
                }
                if (e.UserName) {
                    for (let i of e.UserName) {
                        mes += i + ", \n";
                    }
                }
                if (e.ConfirmPassword) {
                    for (let i of e.ConfirmPassword) {
                        mes += i + ", \n";
                    }
                }
                message.error(mes, 5);
            }
        });
};

const deleteTechnicalServiceFromServer = (id) => async (dispatch) => {
    await Axios.delete(`api/ServiceExs/${id}`)
        .then((response) => {
            message.success(response.data.message);
        })
        .catch((err) => {
            message.error("Bạn không có quyền xóa Dịch vụ này!");
        });
};
const EditApproTimeTechnicalServiceFromServer = (id, indicate) => async (dispatch) => {
    await Axios.put(`/api/ServiceExs/${id}/edit-appro-time`, indicate)
        .then((response) => {
            message.success(response.data.message);
        })
        .catch((err) => {
            message.error("Bạn không có quyền sửa hoặc chưa có người duyệt service này!");
        });
};
export {
    addTechnicalServiceToServer,
    deleteTechnicalServiceFromServer,
    getTechnicalServiceFromServer,
    technicalserviceReducer,
    getTechnicalServiceById,
    updateTechnicalServiceToServer,
    EditApproTimeTechnicalServiceFromServer,
};
