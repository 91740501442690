import moment from "moment";

let typeserviceSeach = {
    Keyword: "",
    PageIndex: 1,
    PageSize: 10,
};
const initialTypeServiceSeach = {
    Keyword: "",
    PageIndex: 1,
    PageSize: 10,
};
const formTypeService = {
    name: "",
    shortName: "",
    code: "",
    active: true,
    status: 0,
};
const getTypeServiceSearch = () => {
    return typeserviceSeach;
};

const setTypeServiceSearch = (search) => {
    typeserviceSeach = search;
};

export { initialTypeServiceSeach, getTypeServiceSearch, setTypeServiceSearch, formTypeService };
