import { Button, DatePicker, Input, message, Row, Select } from "antd";
import { Option } from "antd/lib/mentions";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getStatisticBaseSearch, getStatisticBaseSearchPrint, setStatisticBaseSearch, setStatisticBaseSearchPrint } from "../../pages/Home/StatisticPage/statistic";
import { fetchDropdownAreas } from "../../reducers/staticStudiesReducer";
import {
    fetchDropdownMultiHospital,
    fetchDropdownMultiServerpacsByIdHos,
    fetchDropdownMultiServices,
    getSummeryBase,
    getDoctorsBase,
    getExecutorsBase,
    getChartByWeeks,
    getChartByMonths,
    fetchDropdownModalities,
    getStudiesBase,
} from "../../reducers/statisticReducer";
import "./StatisticSearch.scss";
class StatisticSearchBase extends Component {
    componentDidMount = () => {
        this.props.fetchDropdownAreas().then(() => {
            const selectedIds = this.props.listArea.map(({ id }) => Number(id));
            this.props.fetchDropdownMultiServerpacsByIdHos({AreaId:selectedIds,modalities:this.state.modalities});
        });
        this.props.fetchDropdownMultiServices([]);
        this.props.fetchDropdownModalities([]);
        this.setState({printSearch:getStatisticBaseSearchPrint()});
    };
    state = {
        fromDate: moment(),
        toDate: moment(),
        areaId: [],
        folder: [],
        serverpacs: [],
        executors: [],
        doctors: [],
        services: [],
        typeOfObject: [],
        modalities:[],
        svStatus: [],
        results: "",
        pName: "",
        pAge: "",
        pGender: "",
        pAddress: "",
        listFilterDay: [
            {
                active: true,
                name: `Hôm nay`,
                fromDate: moment(),
                toDate: moment(),
            },
            {
                active: false,
                name: `Tuần ${moment().get("week")}`,
                fromDate: moment().startOf("week").add(1, "day"),
                toDate: moment(),
            },
            {
                active: false,
                name: `Tháng ${moment().get("month") + 1}`,
                fromDate: moment().startOf("month"),
                toDate: moment(),
            },
            {
                active: false,
                name: `Năm ${moment().get("year")}`,
                fromDate: moment().startOf("year"),
                toDate: moment(),
            },
        ],
        printSearch:{}
    };

    onChange = async (name, value,option) => {
        await this.setState({
            [name]: value,
        });
        var print = this.state.printSearch;
        if(Array.isArray(option)){
            print[name]=option;
        }else{
            print[name] = value;
        }
        if(name=='toDate'|| name=='fromDate'){
            print[name] = value.format("DD/MM/YYYY");
        }
        await this.setState({
            printSearch: print,
        });
        if (name === "areaId") {
            this.setState({ folder: [], serverpacs: [] });
            this.props.fetchDropdownMultiServerpacsByIdHos({AreaId:value,modalities:this.state.modalities});
        }
        if (name === "modalities") {
            this.setState({ services: [],serverpacs: []});
            this.props.fetchDropdownMultiServices(value);
            this.props.fetchDropdownMultiServerpacsByIdHos({AreaId:this.state.areaId,modalities:value});
        }
    };
    onSeach = () => {
        const {
            fromDate,
            toDate,
            areaId,
            folder,
            serverpacs,
            executors,
            doctors,
            services,
            typeOfObject,
            svStatus,
            results,
            pName,
            pAge,
            pGender,
            pAddress,
            listFilterDay,
            modalities,
            printSearch
        } = this.state;
        const dataSearch = {
            fromDate: fromDate.format().substring(0, 10),
            toDate: toDate.format().substring(0, 10),
            areaId,
            folder,
            serverpacs,
            executors,
            doctors,
            services,
            typeOfObject,
            svStatus,
            results,
            pName,
            pAge,
            pGender,
            pAddress,
            listFilterDay,
            modalities,
        };
        setStatisticBaseSearch(dataSearch);
        if(printSearch){
            setStatisticBaseSearchPrint(printSearch);
        }
        message.loading("Loading...", 999);
        Promise.all([
            this.props.getSummeryBase(getStatisticBaseSearch()),
            this.props.getDoctorsBase(getStatisticBaseSearch()),
            this.props.getExecutorsBase(getStatisticBaseSearch()),
            this.props.getChartByWeeks(getStatisticBaseSearch()),
            this.props.getChartByMonths(getStatisticBaseSearch()),
            this.props.getStudiesBase(getStatisticBaseSearch()),
        ]).then((value) => {
            message.destroy();
        });
    };

    
    render() {
        const {
            fromDate,
            toDate,
            areaId,
            folder,
            serverpacs,
            executors,
            doctors,
            services,
            typeOfObject,
            svStatus,
            results,
            pName,
            pAge,
            pGender,
            pAddress,
            listFilterDay,
            modalities,
        } = this.state;
        const {
            listArea,
            listFolder,
            listServerpacs,
            listDoctor,
            listExecutor,
            listServices,
            listSvStatus,
            listModalities,
            listTypeOfObject,
        } = this.props;
        return (
            <div id="statistic-search">
                <div className="search-box">
                    <div>
                        <DatePicker
                            size="middle"
                            value={fromDate}
                            format={"DD/MM/YYYY"}
                            onChange={(value) => this.onChange("fromDate", value)}
                        />
                    </div>
                    <div>
                        <DatePicker
                            size="middle"
                            value={toDate}
                            format={"DD/MM/YYYY"}
                            onChange={(value) => this.onChange("toDate", value)}
                        />
                    </div>
                    {listFilterDay.map((item) => {
                        return (
                            <div>
                                <Button
                                    onClick={async () => {
                                        await this.setState({
                                            fromDate: item.fromDate,
                                            toDate: item.toDate,
                                        });
                                        setStatisticBaseSearchPrint({...getStatisticBaseSearchPrint(),fromDate:item.fromDate.format("DD/MM/YYYY"),toDate: item.toDate.format("DD/MM/YYYY")});
                                        const newListFilterDay = [...listFilterDay];
                                        newListFilterDay.forEach((element) => {
                                            if (element.name === item.name) {
                                                element.active = true;
                                            } else {
                                                element.active = false;
                                            }
                                        });
                                        this.setState({
                                            listFilterDay: newListFilterDay,
                                        });
                                        this.onSeach();
                                    }}
                                    style={item.active ? { background: "#191732", color: "#fff" } : {}}
                                    size="middle"
                                >
                                    {item.name}
                                </Button>
                            </div>
                        );
                    })}
                </div>
                <div className="search-box">
                    <div>
                        <Select
                            size="middle"
                            mode="multiple"
                            value={areaId}
                            allowClear
                            style={{ width: "auto", minWidth: 120 }}
                            placeholder="Cơ sở"
                            onChange={(value,option) => this.onChange("areaId", value,option.map(a => a.children))}
                        >
                            {listArea.map((area) => {
                                return (
                                    <Option key={area.id} value={Number(area.id)}>
                                        {area.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                    <div>
                        <Select
                            size="middle"
                            mode="multiple"
                            allowClear
                            style={{ width: "auto", minWidth: 120 }}
                            placeholder="Loại máy chụp"
                            value={modalities}
                            onChange={(value,option) => this.onChange("modalities", value,option.map(a => a.children))}
                        >
                            {listModalities.map((modality) => {
                                return (
                                    <Option key={modality.id} value={modality.id}>
                                        {modality.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                    <div>
                        <Select
                            mode="multiple"
                            size="middle"
                            allowClear
                            style={{ width: "auto", minWidth: 120 }}
                            placeholder="Thiết bị"
                            value={serverpacs}
                            onChange={(value,option) => this.onChange("serverpacs", value,option.map(a => a.children))}
                        >
                            {listServerpacs.map((serverpacs) => {
                                return (
                                    <Option key={serverpacs.id} value={Number(serverpacs.id)}>
                                        {serverpacs.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
                <div className="search-box">
                    <div>
                        <Select
                            size="middle"
                            mode="multiple"
                            allowClear
                            style={{ width: "auto", minWidth: 120 }}
                            placeholder="Bác sĩ duyệt"
                            value={doctors}
                            onChange={(value,option) => this.onChange("doctors", value,option.map(a => a.children))}
                        >
                            {listDoctor.map((doctor) => {
                                return (
                                    <Option key={doctor.id} value={doctor.id}>
                                        {doctor.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                    <div>
                        <Select
                            mode="multiple"
                            size="middle"
                            allowClear
                            style={{ width: "auto", minWidth: 120 }}
                            placeholder="Kỹ thuật viên"
                            value={executors}
                            onChange={(value,option) => this.onChange("executors", value,option.map(a => a.children))}
                        >
                            {listExecutor.map((doctor) => {
                                return (
                                    <Option key={doctor.id} value={doctor.id}>
                                        {doctor.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
                <div className="search-box">
                    <div>
                        <Select
                            size="middle"
                            mode="multiple"
                            allowClear
                            style={{ width: "auto", minWidth: 120 }}
                            placeholder="Dịch vụ ICD"
                            value={services}
                            onChange={(value,option) => this.onChange("services", value,option.map(a => a.children))}
                        >
                            {listServices.map((service) => {
                                return (
                                    <Option key={service.id} value={service.id}>
                                        {service.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                    <div>
                        <Input
                            size="middle"
                            onChange={(e) => this.onChange("results", e.target.value)}
                            value={results}
                            placeholder="Kết luận"
                        />
                    </div>
                    <div>
                        <Select
                            size="middle"
                            mode="multiple"
                            allowClear
                            style={{ width: "auto", minWidth: 120 }}
                            placeholder="Trạng thái"
                            value={svStatus}
                            onChange={(value,option) => this.onChange("svStatus", value,option.map(a => a.children))}
                        >
                            {listSvStatus.map((svStatus) => {
                                return (
                                    <Option key={svStatus.name} value={svStatus.value}>
                                        {svStatus.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                    <div>
                        <Select
                            mode="multiple"
                            size="middle"
                            allowClear
                            style={{ width: "auto", minWidth: 120 }}
                            placeholder="Loại đối tượng"
                            value={typeOfObject}
                            onChange={(value,option) => this.onChange("typeOfObject", value,option.map(a => a.children))}
                        >
                            {listTypeOfObject.map((typeOfObject) => {
                                return (
                                    <Option key={typeOfObject.id} value={typeOfObject.id}>
                                        {typeOfObject.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>
                </div>
                <div className="search-box">
                    <div>
                        <Input
                            size="middle"
                            onChange={(e) => this.onChange("pName", e.target.value)}
                            value={pName}
                            placeholder="Họ tên"
                        />
                    </div>
                    <div>
                        <Input
                            size="middle"
                            onChange={(e) => this.onChange("pAge", e.target.value)}
                            value={pAge}
                            placeholder="Tuổi"
                        />
                    </div>
                    <div>
                        <Input
                            size="middle"
                            onChange={(e) => this.onChange("pGender", e.target.value)}
                            value={pGender}
                            placeholder="Giới tính"
                        />
                    </div>
                    <div>
                        <Input
                            size="middle"
                            onChange={(e) => this.onChange("pAddress", e.target.value)}
                            value={pAddress}
                            placeholder="Địa chỉ"
                        />
                    </div>
                    <div></div>
                </div>
                <div className="btn-search">
                    <Button style={{ background: "#191732", color: "#fff" }} onClick={this.onSeach}>
                        Tìm kiếm
                    </Button>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        listFolder: state.statistic.listHospital,
        listServerpacs: state.statistic.listServerpacsByHos,
        listArea: state.staticStudies.listArea,
        listServices: state.statistic.listServices,
        listModalities: state.statistic.listModalities,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchDropdownMultiHospital: (areaId) => dispatch(fetchDropdownMultiHospital(areaId)),
        fetchDropdownMultiServerpacsByIdHos: (idHos) => dispatch(fetchDropdownMultiServerpacsByIdHos(idHos)),
        fetchDropdownMultiServices: (modalities) => dispatch(fetchDropdownMultiServices(modalities)),
        fetchDropdownAreas: () => dispatch(fetchDropdownAreas()),
        fetchDropdownModalities: () => dispatch(fetchDropdownModalities()),
        getSummeryBase: (search) => dispatch(getSummeryBase(search)),
        getDoctorsBase: (search) => dispatch(getDoctorsBase(search)),
        getExecutorsBase: (search) => dispatch(getExecutorsBase(search)),
        getStudiesBase: (search) => dispatch(getStudiesBase(search)),
        getChartByWeeks: (search) => dispatch(getChartByWeeks(search)),
        getChartByMonths: (search) => dispatch(getChartByMonths(search)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(StatisticSearchBase);
