import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, message, Checkbox } from "antd";
import { getPatientById, updatePatientToServer, getPatientFromServer } from "../../reducers/patientReducer";
import { connect } from "react-redux";
import moment from "moment";
import { getPatientSearch } from "../../pages/Home/PatientPage/patient";
import { handleCloseModalApp } from "../../reducers/uiReducer";

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
class ContentEdit extends Component {
    constructor(props) {
        super(props);
        this.formRef = null;
    }

    componentDidMount = () => {
        this.props.getPatientById(this.props.patientId).then(() => {
            this.formRef.setFieldsValue({ ...this.props.patientItem, doB: moment(this.props.patientItem.doB) });
        });
    };
    componentDidUpdate = (prevProps) => {
        if (prevProps.patientId !== this.props.patientId) {
            this.props.getPatientById(this.props.patientId).then(() => {
                this.formRef.setFieldsValue({ ...this.props.patientItem, doB: moment(this.props.patientItem.doB) });
            });
        }
    };
    onFinish = (values) => {
        const tmp = ["age"];
        for (let item of tmp) {
            values[item] = Number(values[item]);
        }
        const { patientId } = this.props;
        this.props.updatePatientToServer(patientId, values).then(() => {
            this.props.handleCloseModalApp();
            const search = getPatientSearch();
            this.props.getPatientFromServer(search);
        });
    };
    render() {
        return (
            <Form
                style={{ marginTop: 20 }}
                {...formItemLayout}
                name="addPatient"
                onFinish={this.onFinish}
                ref={(ref) => (this.formRef = ref)}
                scrollToFirstError
            >
                <Form.Item name="code" label="Code">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="name" label="Họ tên">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="sex" label="Giới tính">
                    <Radio.Group>
                        <Radio value="M">Nam</Radio>
                        <Radio value="F">Nữ</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="age" label="Tuổi">
                    <Input allowClear type="number" />
                </Form.Item>
                <Form.Item name="address" label="Địa chỉ">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="doB" label="Ngày sinh">
                    <DatePicker format="DD/MM/YYYY" placeholder="" style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item name="email" label="Email">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="phoneNo" label="Số điện thoại">
                    <Input allowClear type="number" />
                </Form.Item>
                <Form.Item name="typeCode" label="Mã BHYT">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="type" label="BHYT">
                    <Input allowClear />
                </Form.Item>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}></div>
                    <div style={{ flex: 3 }}>
                        <Button style={{ color: "#fff", background: "#2687d4" }} htmlType={"submit"}>
                            Lưu lại
                        </Button>
                    </div>
                </div>
            </Form>
        );
    }
}

function mapStateToProps(state) {
    return {
        patientItem: state.patient.patientItem,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getPatientById: (id) => dispatch(getPatientById(id)),
        updatePatientToServer: (id, patient) => dispatch(updatePatientToServer(id, patient)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        getPatientFromServer: (search) => dispatch(getPatientFromServer(search)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentEdit);
