import moment from "moment";
let technicalserviceSeach = {
    Keyword: "",
    studyID:"",
    code: "",
    fromDate: moment().toISOString().substr(0, 10),//moment("01-01-2015").toISOString().substr(0, 10),
    toDate: moment().toISOString().substr(0, 10),
    PageIndex: 1,
    PageSize: 10,
};
const technicalserviceSeachDefault = {
    Keyword: "",
    studyID:"",
    code: "",
    fromDate: moment(),//moment("01-01-2015"),
    toDate: moment(),
    PageIndex: 1,
    PageSize: 10,
};

const getTechnicalServiceSearch = () => {
    return technicalserviceSeach;
};

const setTechnicalServiceSearch = (search) => {
    technicalserviceSeach = search;
};

export { technicalserviceSeachDefault, getTechnicalServiceSearch, setTechnicalServiceSearch };
