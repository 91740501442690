const ACTION_TYPES = {
    TOGGLE_NAV: "TOGGLE_NAV",
    OPEN_MODAL_APP: "OPEN_MODAL_APP",
    CLOSE_MODAL_APP: "CLOSE_MODAL_APP",
    SET_MODAL_BASIC: "SET_MODAL_BASIC",
    SET_LOADING_WELCOME_PAGE: "SET_LOADING_WELCOME_PAGE",
};

const initialstate = {
    drawer: true,
    modal: {},
    modalBasic: {},
    loadingWelcomePage: false,
};

const uiReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.TOGGLE_NAV:
            state.drawer = !state.drawer;
            return { ...state };
        case ACTION_TYPES.OPEN_MODAL_APP:
            state.modal = action.payload;
            return { ...state };
        case ACTION_TYPES.CLOSE_MODAL_APP:
            state.modal.visible = false;
            return { ...state };
        case ACTION_TYPES.SET_MODAL_BASIC:
            state.modalBasic = action.payload;
            return { ...state };
        case ACTION_TYPES.SET_LOADING_WELCOME_PAGE:
            state.loadingWelcomePage = action.payload;
            return { ...state };
        default:
            return state;
    }
};

const handleToggleNav = () => {
    return {
        type: ACTION_TYPES.TOGGLE_NAV,
    };
};
const handleOpenModalApp = (modalData) => {
    return {
        type: ACTION_TYPES.OPEN_MODAL_APP,
        payload: modalData,
    };
};
const handleCloseModalApp = () => {
    return {
        type: ACTION_TYPES.CLOSE_MODAL_APP,
    };
};
const setLoadingWelcomePage = (value) => {
    return {
        type: ACTION_TYPES.SET_LOADING_WELCOME_PAGE,
        payload: value,
    };
};

const setModalBasic = (data) => {
    return {
        type: ACTION_TYPES.SET_MODAL_BASIC,
        payload: data
    };
}

export { uiReducer, handleToggleNav, handleOpenModalApp, handleCloseModalApp, setModalBasic, setLoadingWelcomePage };
