import { Button } from "antd";
import printJS from 'print-js';
import Axios from "axios";
import React, { Component } from "react";
import { MODALIATY_TYPE } from "../../models/modalityType";
import { getStatisticBaseSearchPrint } from "../../pages/Home/StatisticPage/statistic";

export class TableSumaryBase extends Component {
    onPrint = (totalAll,totalCol,totalRow) => {
        window.setUpStatistic(this.props.data,MODALIATY_TYPE,getStatisticBaseSearchPrint(),totalAll,totalCol,totalRow);
        //printJS('ris-preview-template', 'html');
        let mywindow = window.open('', 'print-summary');
        mywindow.document.write(document.getElementById('statistic-summary-print').innerHTML);
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        setTimeout(function () {
            mywindow.close();
        }, 250);
    };

    render() {
        const { data } = this.props;
        const totalRow = [];
        const totalCol = [];
        let totalAll = 0;
        MODALIATY_TYPE.forEach((item, index) => {
            let totalRowItem = 0;
            {
                data.forEach((i) => {
                    totalRowItem += i.numberOfModalities[item];
                });
                totalRow.push(totalRowItem);
            }
        });
        data.forEach((i) => {
            let totalColItem = 0;
            MODALIATY_TYPE.forEach((item, index) => {
                {
                    if (i.numberOfModalities[item]) {
                        totalColItem += i.numberOfModalities[item];
                        totalAll += i.numberOfModalities[item];
                    }
                }
            });
            totalCol.push(totalColItem);
        });
        return (
            <div id="table-summaryBase" className="table-statistic">
                <div style={{ width: "100%", height: 34, background: "#2a274c", marginTop: 5,position:"relative" }}>
                    <p style={{ lineHeight: "30px", color: "white", textAlign: "center", fontSize: 20 }}>
                        <b>THỐNG KÊ CHUNG</b>
                    </p>
                    <Button style={{position:"absolute",top:0,right:0}} onClick= {()=> this.onPrint(totalAll,totalCol,totalRow)}>
                        In
                    </Button>
                </div>
                <table className="table-admin" style={{ margin: 0 }}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            {data.map((item) => (
                                <th>{item.areaName}</th>
                            ))}
                            <th>Tổng</th>
                        </tr>
                    </thead>
                    <tbody>
                        {MODALIATY_TYPE.map((item, index) => {
                            return (
                                <tr key={item}>
                                    <td>{item}</td>
                                    {data.map((i) => (
                                        <td>{i.numberOfModalities[item]}</td>
                                    ))}
                                    <td>{totalRow[index] || totalRow[index] === 0 ? totalRow[index] : ""}</td>
                                </tr>
                            );
                        })}
                        <tr>
                            <td>Tổng</td>
                            {totalCol.map((item) => (
                                <td>{item}</td>
                            ))}
                            <td>{totalAll}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
