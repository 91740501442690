import moment from "moment";

let statisticSeach = {
    fromDate: moment().format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    typeSearch: 1,
    existsOnHis: "",
    HospitalID: "",
    areaId: "",
    DoctorId: "",
};
let statisticBaseSeach = {
    fromDate: moment().format().substring(0, 10),
    toDate: moment().format().substring(0, 10),
    areaId: [],
    folder: [],
    serverpacs: [],
    executors: [],
    doctors: [],
    services: [],
    typeOfObject: [],
    svStatus: [],
    results: "",
    pName: "",
    pAge: "",
    pGender: "",
    pAddress: "",
    isOrUser: false,
    isOrService: false,
};

let statisticBaseSeachPrint = {
    fromDate: moment().format("DD/MM/YYYY"),
    toDate: moment().format("DD/MM/YYYY"),
    areaId: [],
    folder: [],
    serverpacs: [],
    executors: [],
    doctors: [],
    services: [],
    modalities: [],
    typeOfObject: [],
    svStatus: [],
    results: "",
    pName: "",
    pAge: "",
    pGender: "",
    pAddress: "",
    isOrUser: false,
    isOrService: false,
};

const initialStatisticSeach = {
    fromDate: moment(),
    toDate: moment(),
    typeSearch: 1,
    existsOnHis: "",
    HospitalID: "",
    areaId: "",
    DoctorId: "",
};
const getStatisticSearch = () => {
    return statisticSeach;
};

const setStatisticSearch = (search) => {
    statisticSeach = search;
};

const getStatisticBaseSearch = () => {
    return statisticBaseSeach;
};
const getStatisticBaseSearchPrint = () => {
    return {...statisticBaseSeachPrint };
};
const setStatisticBaseSearch = (search) => {
    statisticBaseSeach = search;
};
const setStatisticBaseSearchPrint = (search) => {
    statisticBaseSeachPrint = search;
};

export {
    initialStatisticSeach,
    getStatisticSearch,
    setStatisticSearch,
    getStatisticBaseSearch,
    setStatisticBaseSearch,
    getStatisticBaseSearchPrint,
    setStatisticBaseSearchPrint
};