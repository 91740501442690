import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, message, Checkbox, Select, Layout, InputNumber } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
import { getMediEquipmentById, getMediEquipmentFromServer, updateMediEquipmentToServer } from "../../reducers/mediEquipmentReducer";
import { getMediEquipmentSearch, getTypeEquipMedi } from "../../pages/Home/MediEquipmentPage/MediEquipment";
const { Option } = Select;

const { Content, Footer, Header } = Layout;

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
class ContentEditMedi extends Component {
    constructor(props) {
        super(props);
        this.formRef = null;
    }

    componentDidMount = () => {
        this.props.getMediEquipmentById(this.props.mediEquipmentId).then(() => {
            this.formRef.setFieldsValue({ ...this.props.mediEquipmentItem});
        });
    };
    componentDidUpdate = (prevProps) => {
        if (prevProps.mediEquipmentId !== this.props.mediEquipmentId) {
            this.props.getMediEquipmentById(this.props.mediEquipmentId).then(() => {
                this.formRef.setFieldsValue({ ...this.props.mediEquipmentItem});
            });
        }
    };
    onFinish = (values) => {
        const { mediEquipmentId } = this.props;
        let obj = {
            name: values.name,
            typeEquip: values.typeEquip,
            price: values.price,
            status: (values.status === true || values.status === 1 ? 1 : 0),
        };
        values.mediEquipmentId = Number(values.mediEquipmentId);
        this.props.updateMediEquipmentToServer(mediEquipmentId, obj).then(() => {
            this.props.setModalBasic({ visible: false });
            const search = getMediEquipmentSearch();
            this.props.getMediEquipmentFromServer(search);
        });
    };
    render() {
        const TypeEquipMedi = getTypeEquipMedi();
        return (
            <>
                <Layout className="layout-doctor-edit">
                    <Header>
                        <p className="modalBasic-title">Cập nhật thông tin</p>
                        <button
                            className="modalBasic-btn-close"
                            onClick={() => this.props.setModalBasic({ visible: false })}
                        >
                            <i className="fas fa-times icon"></i>
                        </button>
                    </Header>
                    <Content>
                        <Form
                            className="form-edit-mediEquipment"
                            {...formItemLayout}
                            name="addMediEquipment"
                            onFinish={this.onFinish}
                            ref={(ref) => (this.formRef = ref)}
                            scrollToFirstError
                        >
                            <Form.Item name="name" label="Tên">
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="typeEquip" label="Loại">
                                <Select>
                                    {TypeEquipMedi.map((item) => {
                                        return (
                                            <Option key={item.id} value={Number(item.id)}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name="price" label="Giá" >
                                <InputNumber />
                            </Form.Item>
                            <Form.Item name="status" label="Trạng thái" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                            <Button
                                style={{ color: "#fff", background: "#2687d4", display: "none" }}
                                id="btn-submit-edit-mediEquipment"
                                htmlType={"submit"}
                            >
                                Lưu lại
                            </Button>
                        </Form>
                    </Content>
                    <Footer>
                        <Button
                            style={{ color: "#fff", background: "#2687d4" }}
                            onClick={() => document.getElementById("btn-submit-edit-mediEquipment").click()}
                            htmlType={"submit"}
                        >
                            Lưu lại
                        </Button>
                    </Footer>
                </Layout>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        mediEquipmentItem: state.mediEquipment.mediEquipmentItem,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getMediEquipmentById: (id) => dispatch(getMediEquipmentById(id)),
        updateMediEquipmentToServer: (id, mediEquipment) => dispatch(updateMediEquipmentToServer(id, mediEquipment)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        getMediEquipmentFromServer: (search) => dispatch(getMediEquipmentFromServer(search)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentEditMedi);
