import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Input, Form, Button, DatePicker, Checkbox, Select, Layout, Pagination } from "antd";
import "./../../pages/Home/StaticStudiesPage/StaticStudiesPage.scss";
import "./SummaryTable.scss";
const { Header, Footer, Sider, Content } = Layout;
class StaticStudiesBase extends Component {
    state = {
        top: 0,
    };
    componentDidMount = () => {
        this.setState({
            top: this.thead.offsetHeight - 1,
        });
    };
    convertGender = (gender) => {
        if (gender == "M" || gender == "1" || gender == "Nam") return "Nam";
        return "Nữ";
    };

    appendLeadingZeroes = (n) => {
        if (n <= 9) {
            return "0" + n;
        }
        return n;
    };

    formatDatetime = (time) => {
        var date = new Date(time);
        var month = this.appendLeadingZeroes(date.getMonth() + 1); //months from 1-12
        var day = this.appendLeadingZeroes(date.getDate());
        var year = date.getFullYear();
        var hours = date.getHours();
        var minutes = date.getMinutes(); // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = day + "/" + month + "/" + year + " " + hours + ":" + minutes;
        return strTime;
    };
    render() {
        const { data } = this.props;
        return (
            <div className={"summary-table-d"}>
                <div style={{ fontWeight: "bold", textAlign: "center" }}>
                    <span>Tổng số ca: {data.length}</span>
                </div>
                <div style={{ width: "100%", maxHeight: "100vh", overflow: "auto" }}>
                    <table
                        border="0"
                        className="table-admin"
                        style={{ position: "relative", maxHeight: 100, overflow: "auto", margin: 0 }}
                    >
                        <thead>
                            <tr>
                                <th style={{ position: "sticky", background: "#fff", top: 0 }} rowSpan="2">
                                    STT
                                </th>
                                <th style={{ position: "sticky", background: "#fff", top: 0 }} rowSpan="2">
                                    Mã bệnh nhân
                                </th>
                                <th
                                    style={{ position: "sticky", background: "#fff", top: 0, whiteSpace: "nowrap" }}
                                    rowSpan="2"
                                    width="8%"
                                >
                                    Họ tên bệnh nhân
                                </th>
                                <th
                                    style={{ position: "sticky", background: "#fff", top: 0 }}
                                    colSpan="2"
                                    ref={(ref) => (this.thead = ref)}
                                >
                                    Tuổi
                                </th>
                                <th style={{ position: "sticky", background: "#fff", top: 0 }} rowSpan="2" width="20%">
                                    Địa chỉ
                                </th>
                                <th style={{ position: "sticky", background: "#fff", top: 0 }} rowSpan="2" width="6%">
                                    Ngày thực hiện
                                </th>
                                <th style={{ position: "sticky", background: "#fff", top: 0 }} rowSpan="2">
                                    BHYT
                                </th>
                                <th style={{ position: "sticky", background: "#fff", top: 0 }} rowSpan="2" width="8%">
                                    Chẩn đoán
                                </th>
                                <th style={{ position: "sticky", background: "#fff", top: 0 }} rowSpan="2">
                                    Nơi gửi
                                </th>
                                <th style={{ position: "sticky", background: "#fff", top: 0 }} rowSpan="2" width="15%">
                                    Yêu cầu
                                </th>
                                <th style={{ position: "sticky", background: "#fff", top: 0 }} rowSpan="2" width="25%">
                                    Kết quả
                                </th>
                                <th style={{ position: "sticky", background: "#fff", top: 0 }} rowSpan="2" width="10%">
                                    Người đọc
                                </th>
                            </tr>
                            <tr>
                                <th style={{ position: "sticky", background: "#fff", top: this.state.top }}>Nam</th>
                                <th style={{ position: "sticky", background: "#fff", top: this.state.top }}>Nữ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => {
                                let stt = index + 1;
                                return (
                                    <tr key={index}>
                                        <td>{stt}</td>
                                        <td>{item.pCode}</td>
                                        <td>{item.pName}</td>
                                        <td>{this.convertGender(item.sex) == "Nam" ? item.pAge : ""}</td>
                                        <td>{this.convertGender(item.sex) == "Nữ" ? item.pAge : ""}</td>
                                        <td>{item.pAddress}</td>
                                        <td>{this.formatDatetime(item.timeEx)}</td>
                                        <td>{item.pType}</td>
                                        <td>{item.comment}</td>
                                        <td>{item.assDepartment}</td>
                                        <td>{item.serviceName}</td>
                                        <td>{item.result}</td>
                                        <td>{item.doctorApproved}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticStudiesBase);
