import React, { Component } from "react";
import { connect } from "react-redux";
import "./ModalBasic.scss";

class ModalApp extends Component {
    closeModal = (e) => {
        this.props.handleCloseModalApp();
    };

    render() {
        const { visible, width, content, height, name } = this.props;
        return (
            <div className={`modalBasic ${visible ? "modalBasic--visible" : "modalBasic--hidden"}`}>
                <div className="modalBasic__overlay" ></div>
                <div className={`modalBasic__main ${name}`} style={{width, height}}>{content}</div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        visible: state.ui.modalBasic.visible,
        content: state.ui.modalBasic.content,
        name: state.ui.modalBasic.name,
        width: state.ui.modalBasic.width,
        height: state.ui.modalBasic.height,
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalApp);
