import React, { Component } from "react";
import { Col, Row, Input, Form, Button, DatePicker, Checkbox, Select } from "antd";
import "./IndicateSearch.scss";
import { getIndicateSearch, setIndicateSearch, initialIndicateSeach } from "../../pages/Home/IndicatePage/indicate";
import AddFormContent from "./AddFormContent";
import FontAwesome from "react-fontawesome";
import moment from "moment";
import { removeVietnameseTones } from "../../utils";

const { Option } = Select;

class IndicateSearch extends Component {
    onFinish = (values) => {
        let indicateSearch = getIndicateSearch();
        indicateSearch.Keyword = values.Keyword;
        indicateSearch.fromDate = moment(values.fromDate._d).format("L");
        indicateSearch.toDate = moment(values.toDate._d).format("L");
        indicateSearch.id = values.id;
        indicateSearch.serverID = values.serverID;
        indicateSearch.pCode = values.pCode;
        indicateSearch.Modality = values.Modality;
        indicateSearch.orgCode = values.orgCode;
        indicateSearch.ExistsOnHis = values.ExistsOnHis;
        indicateSearch.State = values.State;
        indicateSearch.PageIndex = 1;
        setIndicateSearch(indicateSearch);
        this.props.handleUpdateTable(indicateSearch);
    };
    // onOpenModal = () => {
    //     const modalData = {
    //         title: [<p>Thêm mới bác sĩ</p>],
    //         visible: true,
    //         width: 800,
    //         height: 500,
    //         content: <AddFormContent handleAdd={this.handleAdd} />,
    //     };
    //     this.props.handleOpenModal(modalData);
    // };
    onOpenModalChangeServerPacs = () => {
        this.props.handleChangeServerPacs();
    };
    onOpenModalChangePath = () => {
        this.props.handleChangePath();
    };
    // onOpenModalChangePath = () => {
    //     const modalData = {
    //         title: [<p>Đổi đường dẫn</p>],
    //         visible: true,
    //         width: 800,
    //         height: 500,
    //         content: <AddFormContent handleAdd={this.handleAdd} />,
    //     };
    //     this.props.handleOpenModal(modalData);
    // };
    render() {
        const { listServerPacs } = this.props;
        return (
            <div>
                <div className="indicate-search-d">
                    <Form
                        name="horizontal_login"
                        initialValues={initialIndicateSeach}
                        layout="inline"
                        onFinish={this.onFinish}
                    >
                        <Form.Item name="fromDate" style={{ marginTop: 5 }}>
                            <DatePicker format="DD/MM/YYYY" placeholder="" />
                        </Form.Item>
                        <Form.Item name="toDate" style={{ marginTop: 5 }}>
                            <DatePicker format="DD/MM/YYYY" placeholder="" />
                        </Form.Item>
                        {/* <Form.Item name="Keyword">
                        <Input placeholder="Keyword" />
                    </Form.Item> */}
                        <Form.Item name="id" style={{ marginTop: 5 }}>
                            <Input placeholder="id" />
                        </Form.Item>
                        <Form.Item name="serverID" style={{marginTop: 5, width: 250 }}>
                            <Select
                                defaultValue=""
                                showSearch
                                style={{ width: "100%" }}
                                filterOption={(input, option) =>
                                    {
                                        option.children = removeVietnameseTones(option.children);
                                        input = removeVietnameseTones(input);
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    }
                                >
                                    <Option value="">Tất cả</Option>
                                    {listServerPacs.map((item) => {
                                        return (
                                            <Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                        <Form.Item name="pCode" style={{ marginTop: 5 }}>
                            <Input placeholder="Mã bệnh nhân" />
                        </Form.Item>
                        <Form.Item name="Modality" style={{ marginTop: 5 }}>
                            <Input placeholder="Modality" />
                        </Form.Item>
                        <Form.Item name="orgCode" style={{ marginTop: 5 }}>
                            <Input placeholder="orgCode" />
                        </Form.Item>
                        <Form.Item name="ExistsOnHis" style={{ marginTop: 5 ,width:150}}>
                            <Select>
                                <Option value="">Tất cả</Option>
                                <Option value={true}>Đồng bộ HIS</Option>
                                <Option value={false}>Chưa đồng bộ HIS</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="State" style={{ marginTop: 5 ,width:180}}>
                            <Select>
                                <Option value="">Tất cả</Option>
                                <Option value={0}>Chưa có kết quả</Option>
                                <Option value={1}>Đã có kết quả</Option>
                                <Option value={3}>Đã xóa</Option>
                            </Select>
                        </Form.Item>
                        <div style={{ marginTop: 5 }}>
                            <Button style={{ background: "#2a274c", color: "#fff" }} htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.onOpenModalChangeServerPacs}>
                                Đổi máy chụp
                            </Button>
                            <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.onOpenModalChangePath}>
                                Đổi đường dẫn
                            </Button>
                        </div>

                    </Form>
                    {/* <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.onOpenModal}>
                    Add
                </Button> */}

                </div>
                {/* <div className="indicate-search-d">
                    
                </div> */}

            </div>
        );
    }
}

export default IndicateSearch;
