import moment from "moment";

let indicateSeach = {
    fromDate: new Date().toISOString().substr(0, 10),//new Date("01-01-2015").toISOString().substr(0, 10),
    toDate: new Date().toISOString().substr(0, 10),
    Keyword: "",
    id: "",
    serverID: "",
    Code: "",
    pCode: "",
    orgCode: "",
    Modality: "",
    ExistsOnHis: '',
    State:"",
    PageIndex: 1,
    PageSize: 10,
};

let serviceExEquip = {
    fromDate: new Date().toISOString().substr(0, 10),//new Date("01-01-2015").toISOString().substr(0, 10),
    toDate: new Date().toISOString().substr(0, 10),
    HospitalID: null,
    serverId:null,
    modality: "",
    pCode: "",
    pName: "",
    pType:"",
    PageIndex: 1,
    PageSize: 30,
}
const initialIndicateSeach = {
    fromDate: moment(),//moment("01-01-2015"),
    toDate: moment(),
    Keyword: "",
    State:"",
    id: "",
    serverID: "",
    Code: "",
    pCode: "",
    orgCode: "",
    Modality: "",
    ExistsOnHis: '',
    PageIndex: 1,
    PageSize: 10,
};

const initialServiceExSearch = {
    fromDate: moment(),//moment("01-01-2015"),
    toDate: moment(),
    HospitalID: "",
    serverId:"",
    modality: "",
    pCode: "",
    pName: "",
    pType:"",
    PageIndex: 1,
    PageSize: 10,
};

const getIndicateSearch = () => {
    return indicateSeach;
};

const setIndicateSearch = (search) => {
    indicateSeach = search;
};

const setServiceExSearch = (search) => {
    serviceExEquip = search;
};

const getServiceExSearch = ()=>{
    return serviceExEquip;
}
export { initialIndicateSeach, getIndicateSearch, setIndicateSearch,initialServiceExSearch,setServiceExSearch,getServiceExSearch };
