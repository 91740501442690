import React, { Component } from "react";
import { connect } from "react-redux";
import { getLogById, getLog, getLogViewer } from "../../../reducers/logReducer";
import { handleOpenModalApp, setModalBasic } from "../../../reducers/uiReducer";
import { Form, Button, DatePicker } from "antd";
import FontAwesome from "react-fontawesome";
import ContentInfo from "./ContentInfo";
import ContentInfoViewer from "./ContentInfoViewer";
import { getLogSearch, initialLogSeach, setLogSearch } from "./log";
import moment from "moment";
import { setLoading } from "../../../reducers/loadingHomeReducer";

class LogPage extends Component {
    state = {
        flag : 1
    };
    componentDidMount = () => {
        this.props.getLog();
    };
    handleOpenModal = (modalData) => {
        this.props.handleOpenModalApp(modalData);
    };
    handleUpdateTable = async () => {
        await this.props.getLog();
    };
    handleUpdateTableViewer = async() => {
        await this.props.getLogViewer();
    };
    onChange = () => {
        const {flag} = this.state;
        if(flag === 0){
            this.setState({flag : 1});
        }
        else if(flag === 1){
            this.setState({flag : 0});
        }
    };
    onFinish = async (values) => {
        this.props.setLoading(true);
        const {flag} = this.state;
        let logSearch = getLogSearch();
        logSearch.fromDate = moment(values.fromDate).format("YYYY-MM-DD");
        logSearch.toDate = moment(values.toDate).format("YYYY-MM-DD");
        // logSearch.fromDate = values.fromDate.format("YYYY-MM-DD");
        // logSearch.toDate = values.toDate.format("YYYY-MM-DD");
        
        setLogSearch(logSearch);
        if(flag === 1){
            await this.handleUpdateTable();
        }
        else if(flag === 0){
            await this.handleUpdateTableViewer();
        }
        this.props.setLoading(false);
    };
    handleShowInfo = (id, name) => {
        const modalData = {
            title: [<p>Thông tin nhật ký</p>],
            visible: true,
            width:  window.innerWidth,
            height:  window.innerHeight,
            content: <ContentInfo id={id} name={name} />,
        };
        this.props.setModalBasic(modalData);
    };
    handleShowInfoViewer = (id, name) => {
        const modalData = {
            title: [<p>Thông tin nhật ký</p>],
            visible: true,
            width:  window.innerWidth,
            height:  window.innerHeight,
            content: <ContentInfoViewer id={id} name={name} />,
        };
        this.props.setModalBasic(modalData);
    };
    render() {
        const { logs } = this.props;
        return (
            <div className="doctor-page-d">
                <div className="doctor-search-d">
                <Form
                        name="horizontal_login"
                        initialValues={initialLogSeach}
                        layout="inline"
                        onFinish={this.onFinish}
                    >
                        <Form.Item name="fromDate" style={{ marginTop: 5 }}>
                            <DatePicker format="DD/MM/YYYY" placeholder="" />
                        </Form.Item>
                        <Form.Item name="toDate" style={{ marginTop: 5 }}>
                            <DatePicker format="DD/MM/YYYY" placeholder="" />
                        </Form.Item>
                        <Button style={{ background: "#2a274c", color: "#fff",marginTop: 5 }} htmlType="submit">
                            Tìm kiếm
                        </Button>
                        <Button style={{ background: "#2a274c", color: "#fff",marginTop: 5 }}  htmlType="submit" onClick={this.onChange}>
                            {this.state.flag === 1 ? "LogViewer" : "LogAdmin"}
                        </Button>
                    </Form>
                </div>
                <div className={"doctor-table-d"}>
                    <table border="0" className="table-admin">
                        <thead>
                            <tr>
                                <th>Tên log ({this.state.flag === 1 ? "LogAdmin" : "LogViewer"})</th>
                                <th>ngày tạo</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {logs?.map((log, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{log.name}</td>
                                        <td>{moment(log.date).format("DD/MM/YYYY")}</td>
                                        <td>
                                        <div className="tools">
                                                {this.state.flag === 1 ?
                                                <Button onClick={() => this.handleShowInfo(log.id, log.name)}>
                                                    <FontAwesome name="info" />
                                                </Button>
                                                :
                                                <Button onClick={() => this.handleShowInfoViewer(log.id, log.name)}>
                                                    <FontAwesome name="info" />
                                                </Button>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        logs: state.log.logs,//2-2
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getLog: () => dispatch(getLog()),
        getLogViewer: () => dispatch(getLogViewer()),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        handleOpenModalApp: (modalData) =>dispatch(handleOpenModalApp (modalData)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        setLoading: (data) => dispatch(setLoading(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogPage);
