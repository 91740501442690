import React, { Component } from "react";
import { connect } from "react-redux";
import { handleOpenModalApp, setModalBasic } from "../../../reducers/uiReducer";
import ContentEdit from "../../../components/ServerPacs/ContentEdit";
import "./ServerPacsPage.scss";
import { Form, Input, Button, Popconfirm, Checkbox, Pagination } from "antd";
import FontAwesome from "react-fontawesome";
import AddFormContent from "../../../components/ServerPacs/AddFormContent";
import { fetchServerpacsTemp, getServerpacsDropdown, getTemplateDropdown } from "../../../reducers/serverpacsTemplateReducer";
import { getServerpacsTempSearch,setServerpacsTempSearch } from "../../../models/serverpacsModel";
import AddOrEditTemplate from "../../../components/ServerpacsTemplate/AddOrEditTemplate";
import { Link } from "react-router-dom";
class ServerpacsTemplateManager extends Component {
    componentDidMount = () => {
        this.props.fetchServerpacsTemp();
    };

    handleUpdateTable = () => {
        this.props.fetchServerpacsTemp();
    };

    onAddAndEdit = async (id) => {
        if (id) {
            await this.props.getServerpacsDropdown(id);
            await this.props.getTemplateDropdown(id);
        }else{
            await this.props.getServerpacsDropdown(-1);
            await this.props.getTemplateDropdown(-1);
        }
        const modalData = {
            visible: true,
            width: 900,
            content: <AddOrEditTemplate idSeverpacs={id} />,
            name: "modal-icd-add"
        };
        this.props.setModalBasic(modalData);
    };

    onFinish = (values) => {
        setServerpacsTempSearch({ ...getServerpacsTempSearch(), Keyword: values.Keyword, PageIndex: 1});
        this.handleUpdateTable();
    };


    onChangePage = (page, pageSize) => {
        //Change ServerPacsSearch
        let serverpacsSearch = getServerpacsTempSearch();
        serverpacsSearch.PageIndex = page;
        serverpacsSearch.PageSize = pageSize;
        setServerpacsTempSearch(serverpacsSearch);
        this.handleUpdateTable();
    };

    render() {
        const { dataSvTemp, totalCount } = this.props;
        return (
            <div className="serverpacs-page-d">
                <div className="serverpacs-search-d">
                    <Link to="/server-pacs">
                        <Button
                        style={{ background: "#2a274c", color: "#fff",marginRight:10 }}
                        onClick={() => localStorage.setItem("router", "server-pacs")}
                        icon = {<FontAwesome name="arrow-left" className="icon-fontawesome" />}
                        >
                        </Button>
                    </Link>
                    <Form
                        name="horizontal_login"
                        layout="inline"
                        onFinish={this.onFinish}
                    >
                        <Form.Item name="Keyword">
                            <Input placeholder="Từ khóa" />
                        </Form.Item>

                        <Button style={{ background: "#2a274c", color: "#fff" }} htmlType="submit">
                            Tìm kiếm
                        </Button>
                        <Button style={{ background: "#2a274c", color: "#fff" }} onClick={()=>this.onAddAndEdit()}>
                            Phân mẫu mới
                        </Button>
                    </Form>
                </div>
                <div className={"serverpacs-table-d"}>
                    <table border="0" className="table-admin">
                        <thead>
                            <tr>
                                <th>Tên</th>
                                <th>Tên đầy đủ</th>
                                <th>Mẫu</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {dataSvTemp.map((svp, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{svp.name}</td>
                                        <td>{svp.fullName}</td>
                                        <td>
                                            {svp.serviceTemps.map((temp, i) => {
                                                return (
                                                    <span key={i}> {temp.name}, </span>
                                                )
                                            })}
                                        </td>
                                        <td>
                                            <div className="tools">
                                                <Button onClick={() => this.onAddAndEdit(svp.id)}>
                                                    <FontAwesome name="edit" />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="pagination-d">
                        <Pagination
                            defaultCurrent={5}
                            current={getServerpacsTempSearch().PageIndex}
                            total={totalCount}
                            showQuickJumper
                            showTitle={false}
                            defaultPageSize={getServerpacsTempSearch().PageSize}
                            onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        dataSvTemp: state.serverpacsTempManager.data,
        totalCount: state.serverpacsTempManager.total,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchServerpacsTemp: () => dispatch(fetchServerpacsTemp()),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        getServerpacsDropdown: (id)=> dispatch(getServerpacsDropdown(id)),
        getTemplateDropdown: (id) => dispatch(getTemplateDropdown(id))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerpacsTemplateManager);
