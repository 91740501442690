import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, message, Select, Layout } from "antd";
import { getServerPacsById, updateServerPacsToServer, getServerPacsFromServer, getDropdownModalityTypeFromServer } from "../../reducers/serverpacsReducer";
import { connect } from "react-redux";
import moment from "moment";
import { getServerPacsSearch } from "../../pages/Home/ServerPacsPage/serverpacs";
import { handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
import { removeVietnameseTones } from "../../utils";
const { Option } = Select;

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const { Content, Footer, Header } = Layout;

class ContentEdit extends Component {
    constructor(props) {
        super(props);
        this.formRef = null;
    }

    componentDidMount = async () => {
        this.props.getServerPacsById(this.props.serverpacsId).then(() => {
            this.formRef.setFieldsValue({ ...this.props.serverpacsItem, doB: moment(this.props.serverpacsItem.doB) });
        });
        this.props.getDropdownModalityTypeFromServer();
    };
    componentDidUpdate = (prevProps) => {
        if (prevProps.serverpacsId !== this.props.serverpacsId) {
            this.props.getServerPacsById(this.props.serverpacsId).then(() => {
                this.formRef.setFieldsValue({
                    ...this.props.serverpacsItem,
                    doB: moment(this.props.serverpacsItem.doB),
                });
            });
        }
    };

    onFinish = (values) => {
        let aplyfor = parseInt(values.applyFor);
        if(aplyfor >= 3){
            aplyfor = 3;
        }
        if(aplyfor <= 0){
            aplyfor = 0;
        }
        let data = {
            ...this.props.serverpacsItem,
            name: values.name,
            hospitalID: values.hospitalID,
            requestFromIP: values.requestFromIP,
            applyFor: aplyfor,
            headerID: values.headerID,
            path: values.path,
            loginName:values.loginName,
            modality:values.modality,
            modalityType:values.modalityType,
            mapModality:values.mapModality,
            machineCode:values.machineCode,
        };
        if(!data.status){
            data.status = 0;
        }
        const { serverpacsId } = this.props;
        this.props.updateServerPacsToServer(serverpacsId, data).then(() => {
            const search = getServerPacsSearch();
            this.props.getServerPacsFromServer(search);
            this.props.setModalBasic({ visible: false })
        });
    };
    render() {
        const { dropdownHospital, dropdownPrintHeader,dropdownModalityType } = this.props;
        return (
            <Layout className="layout-doctor-edit">
                <Header>
                    <p className="modalBasic-title">Sửa thông tin ServerPacs</p>
                    <button
                        className="modalBasic-btn-close"
                        onClick={() => this.props.setModalBasic({ visible: false })}
                    >
                        <i className="fas fa-times icon"></i>
                    </button>
                </Header>
                <Content>
                    <Form
                        style={{ marginTop: 20 }}
                        {...formItemLayout}
                        name="addServerPacs"
                        onFinish={this.onFinish}
                        ref={(ref) => (this.formRef = ref)}
                        scrollToFirstError
                    >
                        <Form.Item name="name" label="Tên" rules={[{ required: true, message:"vui lòng nhập tên!" }]}>
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="room" label="Phòng">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="loginName" label="LoginName">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="requestFromIP" label="requestFromIP">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="requestFor" label="RequestFor">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="applyFor" label="applyFor">
                            <Input allowClear type="number" />
                        </Form.Item>
                        <Form.Item name="path" label="Chọn thư mục lưu" rules={[{ required: true, message:"vui lòng chọn thư mục lưu!" }]}>
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="modality" label="Modality" rules={[{ required: true, message:"vui lòng nhập modality!" }]}>
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="modalityType" label="ModalityType" rules={[{ required: true , message:"vui lòng nhập modalityType!"}]}>
                            <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    filterOption={(input, option) =>
                                        {
                                            option.children = removeVietnameseTones(option.children);
                                            input = removeVietnameseTones(input);
                                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    }
                                >
                                    {dropdownModalityType.map((item) => (
                                    <Option value={item} key={item}>{item}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="mapModality" label="Map Modality">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="machineCode" label="MachineCode">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="hospitalID" label="Chọn Bệnh viện" valuePropName="value">
                            <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    filterOption={(input, option) =>
                                        {
                                            option.children = removeVietnameseTones(option.children);
                                            input = removeVietnameseTones(input);
                                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    }
                                >
                                    {dropdownHospital.map((item) => (
                                    <Option value={item.id} key={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="headerID" label="Chọn Mẫu in" valuePropName="value">
                            <Select>
                                {dropdownPrintHeader.map((item) => (
                                    <Option value={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Button
                            style={{ color: "#fff", background: "#2687d4", display: "none" }}
                            id="btn-submit-edit-serverpacs"
                            htmlType={"submit"}
                        >
                            Lưu lại
                        </Button>
                    </Form>
                </Content>
                <Footer>
                    <Button
                        style={{ color: "#fff", background: "#2687d4" }}
                        onClick={() => document.getElementById("btn-submit-edit-serverpacs").click()}
                        htmlType={"submit"}
                    >
                        Lưu lại
                    </Button>
                </Footer>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        serverpacsItem: state.serverpacs.serverpacsItem,
        dropdownHospital: state.serverpacs.dropdownHospital,
        dropdownPrintHeader: state.serverpacs.dropdownPrintHeader,
        dropdownModalityType:state.serverpacs.dropdownModalityType,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getServerPacsById: (id) => dispatch(getServerPacsById(id)),
        updateServerPacsToServer: (id, serverpacs) => dispatch(updateServerPacsToServer(id, serverpacs)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        getServerPacsFromServer: (search) => dispatch(getServerPacsFromServer(search)),
        getDropdownModalityTypeFromServer: () => dispatch(getDropdownModalityTypeFromServer()),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentEdit);
