import { message } from "antd";
import Axios from "axios";
import { getIcdSearch } from "../models/icdModel";
import { store } from "../store/store";

const ACTION_TYPES = {
    SET_ICD_TEMP_DATA: "SET_ICD_TEMP_DATA",
};

const initialstate = {
    data: [],
    total: 0,
    dropdownIcd: [],
    dropdownIcdNew: [],
    dropdownDiagnose: [],
    listIcdTemp: []
};

const icdTempManagerReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_ICD_TEMP_DATA:
            state = action.payload;
            return {...state };
        default:
            return state;
    }
};

const getListIcd = (id, serviceID) => async(dispatch) => {
    await Axios.get(`/api/Indications/dropdown-icd?id=${id}&serviceID=${serviceID}`).then((response) => {
        if (response.data.status === 1) {
            dispatch(setDataIcdTemp({...store.getState().icdTemp, dropdownIcd: response.data.data }));
        }
        
    });
};

const getListIcdNew = (serviceID) => async(dispatch) => {
    await Axios.get(`/api/Indications/dropdown-icd-new?serviceID=${serviceID}`).then((response) => {
        if (response.data.status === 1) {
            dispatch(setDataIcdTemp({...store.getState().icdTemp, dropdownIcdNew: response.data.data }));
        }
        
    });
};

const getListDiagnose = (serviceID) => async(dispatch) => {
    await Axios.get(`/api/Indications/dropdown-diagnose?serviceID=${serviceID}`).then((response) => {
        if (response.data.status === 1) {
            dispatch(setDataIcdTemp({...store.getState().icdTemp, dropdownDiagnose: response.data.data }));
        }
        
    });
};

const getListIcdTemp = (id) => async(dispatch) => {
    await Axios.get(`/api/Indications/get-indication-temp-by-ididc?Ididc=${id}`).then((response) => {
        if (response.data.status === 1) {
            dispatch(setDataIcdTemp({...store.getState().icdTemp, listIcdTemp: response.data.data }));
        }
        
    });
};

const postIcdTemp = (data) => async(dispatch) => {
    await Axios.post(`/api/Indications/add-icd-temp`, data).then((response) => {
        if (response.data.status === 1) {
            message.success(response.data.message);
        } else {
            message.error(response.data.message);
        }
    });
};
const deleteIcdTemp = (id) => async(dispatch) => {
    await Axios.delete(`/api/Indications/delete-indication-temp/${id}`).then((response) => {
        if (response.data.status === 1) {
            message.success(response.data.message);
        } else {
            message.error(response.data.message);
        }
    });
};
const fetchIcdTemp = () => async dispatch => {
    const params = getIcdSearch();
    const response = await Axios.get(`/api/Indications/indication-temp?Keyword=${params.Keyword}&code=${params.code}&serviceID=${params.serviceId}&PageIndex=${params.PageIndex}&PageSize=${params.PageSize}`)
    if (response.data.status === 1) {
        dispatch(setDataIcdTemp({ ...store.getState().icdTemp, data: response.data.data, total: response.data.dataCount }));
    }
}

const setDataIcdTemp = (data) => {
    return {
        type: ACTION_TYPES.SET_ICD_TEMP_DATA,
        payload: data,
    };
};

export { icdTempManagerReducer, fetchIcdTemp, getListIcd, getListDiagnose, setDataIcdTemp, getListIcdTemp, postIcdTemp, deleteIcdTemp, getListIcdNew }