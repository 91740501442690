import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, message, Layout, Select } from "antd";
import { EditTimeExIndicateFromServer, getIndicateFromServer, getIndicateById } from "../../reducers/indicateReducer";
import { connect } from "react-redux";
import moment from "moment";
import { handleOpenModalApp, handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
import { getIndicateSearch } from "../../pages/Home/IndicatePage/indicate";
const { Option } = Select;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const { Header, Content, Footer } = Layout;
class ContentEditTime extends Component {
    constructor(props) {
        super(props);
        this.formRef = null;
    }

    componentDidMount = () => {
        this.props.getIndicateById(this.props.indicateId).then(() => {
            this.formRef.setFieldsValue({ ...this.props.indicateItem, timeEx: moment(this.props.indicateItem.timeEx)});
        });
    };
    componentDidUpdate = (prevProps) => {
        if (prevProps.indicateId !== this.props.indicateId) {
        this.props.getIndicateById(this.props.indicateId).then(() => {
                this.formRef.setFieldsValue({ ...this.props.indicateItem, timeEx: moment(this.props.indicateItem.timeEx) });
            });
        }
    };
    onFinish = (values) => {
        const { indicateId } = this.props;
        const obj = {
            timeEx: values.timeEx.format(),
            serverID: parseInt(values.serverID),
            drive: values.drive.toUpperCase(),
        }
        this.props.EditTimeExIndicateFromServer(indicateId, obj).then(() => {
            this.props.handleCloseModalApp();
            const search = getIndicateSearch();
            this.props.getIndicateFromServer(search);
        });
    };
    render() {
        const { listServerPacs } = this.props;
        return (
            <>
                <Layout className="layout-indicate-edit">
                    <Header>
                        <p className="modalBasic-title">Sửa thời gian chụp</p>
                        <button
                            className="modalBasic-btn-close"
                            onClick={() => this.props.setModalBasic({ visible: false })}
                        >
                            <i className="fas fa-times icon"></i>
                        </button>
                    </Header>
                    <Content>
                        <Form
                            //style={{ marginTop: 20 }}
                            {...formItemLayout}
                            name="EditTimeEx"
                            className="form-edit-indicate"
                            onFinish={this.onFinish}
                            ref={(ref) => (this.formRef = ref)}
                            scrollToFirstError
                        >
                            <Form.Item name="timeEx" label="Thời gian chụp">
                                <DatePicker showTime format="DD/MM/YYYY HH:mm:ss" placeholder="" style={{ width: "100%" }} />
                            </Form.Item>
                            <Form.Item name="serverID" label="Server mới">
                                <Select>
                                    {listServerPacs.map((item) => {
                                        return (
                                            <Option key={item.id} value={parseInt(item.id)}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name="drive" label="Drive mới">
                                <Input allowClear />
                            </Form.Item>
                            {/* <div style={{ display: "flex" }}>
                                <div style={{ flex: 1 }}></div>
                                <div style={{ flex: 3 }}>
                                    <Button style={{ display: "none" }} id="btn-submit-edit-indication" htmlType={"submit"}>
                                        Lưu lại
                                    </Button>
                                </div>
                            </div> */}
                            <Button style={{ display: "none" }} id="btn-submit-edit-indicate-time" htmlType={"submit"}>
                                Lưu lại
                            </Button>
                        </Form>
                    </Content>
                    <Footer>
                        <Button
                            style={{ color: "#fff", background: "#2687d4" }}
                            onClick={() => document.getElementById("btn-submit-edit-indicate-time").click()}
                        >
                            Lưu lại
                        </Button>
                    </Footer>
                </Layout>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        indicateItem: state.indicate.indicateItem,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getIndicateById: (id) => dispatch(getIndicateById(id)),
        EditTimeExIndicateFromServer: (id, indicate) => dispatch(EditTimeExIndicateFromServer(id, indicate)),
        handleOpenModalApp: (modalData) => dispatch(handleOpenModalApp(modalData)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        getIndicateFromServer: (search) => dispatch(getIndicateFromServer(search)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentEditTime);
