import React, { Component } from "react";
import { Form, Input, Button, message, AutoComplete, Layout, Row, Col, Select, Checkbox } from "antd";
import { connect } from "react-redux";
import { setModalBasic } from "../../reducers/uiReducer";
import {
    assign,
    fetchServerpacsTemp,
    getServerpacsDropdown,
    getTemplateDropdown,
} from "../../reducers/serverpacsTemplateReducer";
const { Content, Header } = Layout;

const { Option } = Select;
class AddOrEditTemplateIcd extends Component {
    componentDidMount = () => {};
    idSeverpacs = this.props.idSeverpacs;

    onSelectSvp = async (id) => {
        this.idSeverpacs = id;
        await this.props.getTemplateDropdown(id);
    };
    assign = async (e, id) => {
        if (this.idSeverpacs) {
            let data = {
                serverpacsId: this.idSeverpacs,
                serviceId: id,
                selected: e.target.checked,
            };
            await this.props.assign(data);
            await this.props.getTemplateDropdown(this.idSeverpacs);
            await this.props.fetchServerpacsTemp();
        } else {
            message.warning("Vui lòng chọn máy chụp!");
        }
    };
    render() {
        const { severpacsDropdown, templateDropdown, idSeverpacs } = this.props;
        return (
            <Layout className="icd-add-form-content">
                <Header>
                    <p className="modalBasic-title">Phân mẫu Template</p>
                    <button
                        className="modalBasic-btn-close"
                        onClick={() => this.props.setModalBasic({ visible: false })}
                    >
                        <i className="fas fa-times icon"></i>
                    </button>
                </Header>
                <Content>
                    <Row>
                        <Col span={11}>
                            <div style={{ paddingLeft: 5 }}>
                                <Select
                                    showSearch
                                    placeholder="Chọn một máy"
                                    defaultValue={idSeverpacs}
                                    onSelect={(value) => this.onSelectSvp(value)}
                                    style={{ width: "100%" }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {severpacsDropdown.map((item) => (
                                        <Option
                                            key={item.id}
                                            value={Number(item.id)}
                                        >{`${item.name} -- ${item.fullName}`}</Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={11}>
                            {templateDropdown.map((item) => (
                                <Checkbox
                                    onClick={(e) => this.assign(e, item.id)}
                                    style={{ marginLeft: 0 }}
                                    key={item.id}
                                    checked={item.selected}
                                >
                                    {item.shortName} -- {item.name}{" "}
                                </Checkbox>
                            ))}
                        </Col>
                    </Row>
                </Content>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        severpacsDropdown: state.serverpacsTempManager.severpacsDropdown,
        templateDropdown: state.serverpacsTempManager.templateDropdown,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        getServerpacsDropdown: (id) => dispatch(getServerpacsDropdown(id)),
        getTemplateDropdown: (id) => dispatch(getTemplateDropdown(id)),
        fetchServerpacsTemp: () => dispatch(fetchServerpacsTemp()),
        assign: (data) => dispatch(assign(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditTemplateIcd);
