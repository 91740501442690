import Axios from "axios";
import React, { Component } from "react";

export default class PreviewPrint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            htmlSummary: null,
            htmlDoctors: null,
            htmlExecutors: null,
        };
    }

    componentDidMount = async () => {
        const htmlTemplate = await Axios.create({ baseURL: null }).get(`./templates/statisticSummaryBase.html?v=${Math.random()}`);
        const htmlDr = await Axios.create({ baseURL: null }).get(`./templates/statisticDoctorsBase.html?v=${Math.random()}`);
        const htmlExc = await Axios.create({ baseURL: null }).get(`./templates/statisticExecutorsBase.html?v=${Math.random()}`);
        this.setState({
            htmlSummary: htmlTemplate.data,
            htmlDoctors: htmlDr.data,
            htmlExecutors: htmlExc.data,
        });
    };

    render() {
        const { htmlSummary,htmlDoctors,htmlExecutors} = this.state;
        return (
            <div style={{display:"none"}}>
            <div id="statistic-summary-print">
                <div
                    key={Date.now()}
                    id="statistic-html-template"
                    className="statistic-html-template"
                    dangerouslySetInnerHTML={{ __html: htmlSummary }}
                />
            </div>
            <div id="statistic-doctors-print">
                <div
                    key={Date.now()}
                    id="statistic-doctors-template"
                    className="statistic-html-template"
                    dangerouslySetInnerHTML={{ __html: htmlDoctors }}
                />
            </div>
            <div id="statistic-executors-print">
                <div
                    key={Date.now()}
                    id="statistic-executors-template"
                    className="statistic-html-template"
                    dangerouslySetInnerHTML={{ __html: htmlExecutors }}
                />
            </div>
            </div>
        );
    }
}
