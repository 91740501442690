import Axios from "axios";
import { queryString } from "../utils/queryString";
import { message } from "antd";
import { store } from "../store/store";
const ACTION_TYPES = {
    SET_DATA_SERVERPACS: "SET_DATA_SERVERPACS",
    SET_INDICATES: "SET_INDICATES",
    SET_ROLES: "SET_ROLES",
    SET_INDICATE_ITEM: "SET_INDICATE_ITEM",
    SET_SERVICE_EQUIP:"SET_SERVICE_EQUIP",
};

const initialstate = {
    indicates: [],
    totalCount: 0,
    roles: [],
    indicateItem: {},
    listServerPacs:[],
    listServiceEquip:[],
    totalCountEquip:0
};

const indicateReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_DATA_SERVERPACS:
            state = { ...state };
            state.listServerPacs = action.payload.listServerPacs
            return {...state };
        case ACTION_TYPES.SET_INDICATES:
            state = { ...state };
            state.indicates = [...action.payload.data];
            state.totalCount = action.payload.dataCount;
            return { ...state };
        case ACTION_TYPES.SET_ROLES:
            state.roles = action.payload;
            return { ...state };
        case ACTION_TYPES.SET_INDICATE_ITEM:
            state.indicateItem = action.payload;
            return { ...state };
        case ACTION_TYPES.SET_DROPDOWN_SERVICES:
            state.dropdownServices = action.payload;
            return { ...state };
        case ACTION_TYPES.SET_SERVICE_EQUIP:
            state = { ...state };
            state.listServiceEquip = [...action.payload.data];
            state.totalCountEquip = action.payload.dataCount;
            return { ...state };
        default:
            return state;
    }
};
const setIndicates = (indicates) => {
    return {
        type: ACTION_TYPES.SET_INDICATES,
        payload: indicates,
    };
};

const setServiceEquip = (serviceEquip) => {
    return {
        type: ACTION_TYPES.SET_SERVICE_EQUIP,
        payload: serviceEquip,
    };
};

const getIndicateFromServer = (request) => async (dispatch) => {
    const { fromDate, toDate,id,serverID, Keyword, Code, pCode, orgCode, Modality, ExistsOnHis,State, PageIndex, PageSize } = request;
    let url = "api/Studies/paging?";
    url += `fromDate=${fromDate}&`;
    url += `toDate=${toDate}&`;
    url += `Keyword=${Keyword}&`;
    url += `id=${id}&`;
    url += `serverID=${serverID}&`;
    url += `Code=${Code}&`;
    url += `pCode=${pCode}&`;
    url += `orgCode=${orgCode}&`;
    url += `Modality=${Modality}&`;
    url += `ExistsOnHis=${ExistsOnHis}&`;
    url += `State=${State}&`;
    url += `PageIndex=${PageIndex}&`;
    url += `PageSize=${PageSize}`;
    const response = await Axios.get(url);
    if (response.status === 200) {
        // response.data.data.forEach((item, index) => {
        //     item.stt = index + 1;
        // });
        if (response.data.status === 1) {
            dispatch(setIndicates(response.data));
        }
        
    } else {
        message.error(response.status);
    }
};

const setRoles = (roles) => {
    return {
        type: ACTION_TYPES.SET_ROLES,
        payload: roles,
    };
};
const getListRoleFromServer = (request) => async (dispatch) => {
    const response = await Axios.get(`api/Users/${request}/roles`);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setRoles(response.data.data.listRoles));
        }
        
    }
};

const updateRoleToServer = (indicateId, roles) => async (dispatch) => {
    const response = await Axios.put(`api/Users/${indicateId}/roles`, { roles: roles });
    if (response.status === 200) {
        message.success("Update Thành công!");
    } else {
        message.error("Update Thất bại!", response.status);
    }
};
const setIndicateItem = (indicate) => {
    return {
        type: ACTION_TYPES.SET_INDICATE_ITEM,
        payload: indicate,
    };
};
const setServerPacsData = (serverPacs) => {
    return {
        type: ACTION_TYPES.SET_DATA_SERVERPACS,
        payload: serverPacs,
    };
};
const getIndicateById = (id) => async (dispatch) => {
    const response = await Axios.get(`api/Studies/${id}`);
    if (response.data.status === 1) {
        dispatch(setIndicateItem(response.data.data));
    }
    
};
const ChangeServerPacsToServer = (indicate) => async (dispatch) => {
     await Axios.put(`api/Studies/change-serverpacs`, indicate )
    .then((response) => {
        if (response.data) {
            message.success(response.data.message);
        }
    })
    ;

};
const ChangePathToServer = (indicate) => async (dispatch) => {
    await Axios.put(`api/Studies/change-path`, indicate )
   .then((response) => {
        if (response.status === 200) {
            if (response.data.status !== 1) {
                message.error(response.data.message);
            } else {
                message.success(response.data.message);
            }
    }
   })
   ;

};

const updateIndicateToServer = (id, indicate) => async (dispatch) => {
    await Axios.put(`api/Studies/${id}`, indicate)
        .then((response) => {
            if (response.data) {
                message.success(response.data.message);
            }
        })
        .catch((err) => {
            if (err.response) {
                let messages = "";
                const e = err.response.data.errors;
                if (e.Email) {
                    for (let i of e.Email) {
                        messages += i + "\n";
                    }
                }
                message.error(messages, 3);
            }
        });
};

const addIndicateToServer = (request) => async (dispatch) => {
    await Axios.post("api/Studies/register", request)
        .then((response) => {
            if (response.status === 200) {
                if (response.data.status !== 1) {
                    message.error(response.data.message);
                } else {
                    message.success(response.data.message);
                }
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let mes = "";
                if (e.Email) {
                    for (let i of e.Email) {
                        mes += i + ", ";
                    }
                }
                if (e.Password) {
                    for (let i of e.Password) {
                        mes += i + ", \n";
                    }
                }
                if (e.UserName) {
                    for (let i of e.UserName) {
                        mes += i + ", \n";
                    }
                }
                if (e.ConfirmPassword) {
                    for (let i of e.ConfirmPassword) {
                        mes += i + ", \n";
                    }
                }
                message.error(mes, 3);
            }
        });
};

const deleteIndicateFromServer = (id) => async (dispatch) => {
    await Axios.delete(`api/Studies/${id}`)
        .then((response) => {
            message.success(response.data.message);
        })
        .catch((err) => {
            message.error("Bạn không có quyền xóa study này!");
        });
};

const handleRecoveryIndicate = (id) => async (dispatch) => {
    await Axios.put(`api/Studies/${id}/active`)
        .then((response) => {
            if(response.data.status == 1){
                message.success(response.data.message);
            }
            else
                message.error("Có lỗi xảy ra, vui long thử lại sau!");
        })
        .catch((err) => {
            message.error("Bạn không có quyền xóa study này!");
        });
};
const EditTimeExIndicateFromServer = (id, indicate) => async (dispatch) => {
    await Axios.put(`/api/Studies/${id}/edit-time-ex`, indicate)
        .then((response) => {
            message.success(response.data.message);
        })
        .catch((err) => {
            message.error("Bạn không có quyền sửa study này!");
        });
};
const fetchDropdownServerPacs = () => async(dispatch) => {
    const response = await Axios.get(`/api/Studies/dropdown-serverpacs`);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setServerPacsData({...store.getState().serverPacs, listServerPacs: response.data.data }));
        }
    }
};

const getServiceEquipFromServer = (request) => async (dispatch) => {
    const response = await Axios.post(`/api/ServiceExs/service-equipDD`,request);
    if (response.status === 200) {
        // response.data.data.forEach((item, index) => {
        //     item.stt = index + 1;
        // });
        if (response.data.status === 1) {
            dispatch(setServiceEquip(response.data));
        }
        
    } else {
        message.error(response.status);
    }
};
// đông anh
// const getServiceEquipFromServer = (request) => async (dispatch) => {
//     const response = await Axios.post(`/api/ServiceExs/service-equip`,request);
//     if (response.status === 200) {
//         // response.data.data.forEach((item, index) => {
//         //     item.stt = index + 1;
//         // });
//         if (response.data.status === 1) {
//             dispatch(setServiceEquip(response.data));
//         }
        
//     } else {
//         message.error(response.status);
//     }
// };

const fetchDropdownSvpByHosId= (hosId) => async(dispatch) => {
    if (hosId) {
        const response = await Axios.get(`/api/Statics/dropdown-Severpacs-By-Id-Hos/${hosId}`);
        if (response.status === 200) {
            if (response.data.status === 1) {
                dispatch(setServerPacsData({...store.getState().serverPacs, listServerPacs: response.data.data }));
            }
        }
    } else {
        const response = await Axios.get(`/api/Statics/dropdown-Severpacs`);
        if (response.status === 200) {
            if (response.data.status === 1) {
                dispatch(setServerPacsData({...store.getState().serverPacs, listServerPacs: response.data.data }));
            }
        }
    }
};
const PostServiceEquipFromServer = (request) => async (dispatch) => {
    return Axios.post(`/api/ServiceExs/post-service-equipDD`,request);
};

// const PostServiceEquipFromServer = (request) => async (dispatch) => {
//     return Axios.post(`/api/ServiceExs/post-service-equip`,request);
// };

export {
    addIndicateToServer,
    deleteIndicateFromServer,
    getIndicateFromServer,
    indicateReducer,
    getListRoleFromServer,
    setRoles,
    updateRoleToServer,
    getIndicateById,
    updateIndicateToServer,
    EditTimeExIndicateFromServer,
    ChangeServerPacsToServer,
    ChangePathToServer,
    setServerPacsData,
    fetchDropdownServerPacs,
    handleRecoveryIndicate,
    getServiceEquipFromServer,
    fetchDropdownSvpByHosId,
    PostServiceEquipFromServer
};
