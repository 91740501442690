import Axios from "axios";
import { message, notification } from "antd";
import { saveToken, saveTokenRefresh, clearAll, setUserID, setUsername } from "../utils/authenticate";

export const ACTION_TYPES = {
    AUTHENTICATE: "AUTHENTICATE",
};

const initializeAuthenticate = false;

const authReducer = (state = initializeAuthenticate, action) => {
    switch (action.type) {
        case ACTION_TYPES.AUTHENTICATE:
            state = action.payload;
            return state;

        default:
            return state;
    }
};

const loginApi = (user) => async (dispatch) => {
    const response = await Axios.post("api/Users/authenticate", user);
    const data = response.data;
    if (data.status === 1) {
        saveToken(data.data.jwtToken);
        saveTokenRefresh(data.data.refreshToken);
        await Axios.get("api/Users/info")
            .then((response) => {
                if (response.status === 200) {
                    setUserID(response.data.data.id);
                    setUsername(response.data.data.name);
                    return dispatch(setAuth(true));
                }
            })
            .catch((error) => {
                if (error.response.status === 403) {
                    clearAll();
                    notification["error"]({
                        message: "Login fail!, Not permission",
                        placement: "topRight",
                    });
                }
                return dispatch(setAuth(false));
            });
    } else {
        notification["error"]({
            message: "Login fail!",
            description: data.message,
            placement: "topRight",
        });
        return dispatch(setAuth(false));
    }
};

const setAuth = (data) => {
    return {
        type: ACTION_TYPES.AUTHENTICATE,
        payload: data,
    };
};

export { authReducer, loginApi, setAuth };
