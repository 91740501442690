import React, { Component } from "react";
import {
  Form,
  Input,
  Radio,
  DatePicker,
  Button,
  message,
  Layout,
  Upload,
  InputNumber,
  Select,
  Checkbox,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  getConfigurationById,
  updateConfigurationToServer,
  getConfigurationFromServer,
  setConfigurationItem,
} from "../../reducers/configurationReducer";
import { connect } from "react-redux";
import moment from "moment";
import { getConfigurationSearch } from "../../pages/Home/ConfigurationPage/configuration";
import { handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
import "./AddFormContent.scss";
import ContentConfigTools from "./ContentConfigTools";
import { store } from "../../store/store";

const { TextArea } = Input;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};
const { Content, Footer, Header } = Layout;
const { Option } = Select;

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/x-icon";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG/ICO file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

class ContentEdit extends Component {
  constructor(props) {
    super(props);
    this.formRef = null;
  }
  state = {
    loading: false,
    type: "",
    ServerRisUrl: window.API_SERVER_RIS_URL,
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };

  componentDidMount = async () => {
    let ServerRisUrl =
      window.location.protocol == "https:"
        ? window.HTTPS_API_SERVER_RIS_URL ?? window.API_SERVER_RIS_URL
        : window.API_SERVER_RIS_URL;
    this.setState({ ServerRisUrl: ServerRisUrl });
    await this.props
      .getConfigurationById(this.props.configurationId)
      .then(() => {
        this.formRef.setFieldsValue({
          ...this.props.configurationItem,
          doB: moment(this.props.configurationItem.doB),
        });
        this.setState({
          imageUrl: `${ServerRisUrl}/${this.props.configurationItem.configName}`,
          loading: false,
        });
      });
    const { configurationItem } = this.props;
    if (
      configurationItem?.configName === "true" ||
      configurationItem?.configName === "false"
    ) {
      this.setState({
        type: "boolean",
      });
    } else {
      this.setState({
        type: "text",
      });
    }
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.configurationId !== this.props.configurationId) {
      this.props.getConfigurationById(this.props.configurationId).then(() => {
        this.formRef.setFieldsValue({
          ...this.props.configurationItem,
          doB: moment(this.props.configurationItem.doB),
        });
        this.setState({
          imageUrl: `${this.state.ServerRisUrl}/${this.props.configurationItem.configName}`,
          loading: false,
        });
      });
    }
  };
  onFinish = (values) => {
    if (typeof values.configName === "object") {
      let version = `?v=${Math.random()}`;
      values.configName = values.configName.file.response.data + version;
    }
    const { configurationId } = this.props;
    this.props
      .updateConfigurationToServer(configurationId, {
        ...this.props.configurationItem,
        ...values,
      })
      .then(() => {
        this.props.setModalBasic({ visible: false });
        const search = getConfigurationSearch();
        this.props.getConfigurationFromServer(search);
      });
  };
  onSubmit = () => {
    const { configurationId } = this.props;
    this.props
      .updateConfigurationToServer(configurationId, {
        ...this.props.configurationItem,
      })
      .then(() => {
        this.props.setModalBasic({ visible: false });
        const search = getConfigurationSearch();
        this.props.getConfigurationFromServer(search);
      });
  };
  onChangeType = (e) => {
    this.setState({
      type: e.target.value,
    });
  };
  onFormatJson = (e) => {
    try {
      var ugly = document.getElementById("myTextArea").value;
      var obj = JSON.parse(ugly);
      var pretty = JSON.stringify(obj, undefined, 4);
      document.getElementById("myTextArea").value = pretty;
    } catch (error) {
      console.log(error);
    }
  };
  onRemoveWhiteSpace = (e) => {
    try {
      var ugly = document.getElementById("myTextArea").value;
      var pretty = ugly.replace(/\s+/g, " ").trim();
      document.getElementById("myTextArea").value = pretty;
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { loading, imageUrl } = this.state;
    let { type } = this.state;
    const isLogo =
      this.props.configurationItem.configCode === "Logo" ||
      this.props.configurationItem.configCode === "Ico"
        ? true
        : false;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <Layout className="layout-doctor-edit">
        <Header>
          <p className="modalBasic-title" style={{ display: "inline" }}>
            Sửa thông tin cấu hình -{" "}
            <p style={{ textTransform: "none", display: "inline" }}>
              {this.props.configurationItem.configCode}
            </p>
          </p>
          <button
            className="modalBasic-btn-close"
            onClick={() => this.props.setModalBasic({ visible: false })}
          >
            <i className="fas fa-times icon"></i>
          </button>
        </Header>
        <Content>
          <Form
            className="form-edit-configuration"
            style={
              isLogo ? { display: "flex", justifyContent: "center" } : {}
            }
            {...formItemLayout}
            name="addConfiguration"
            onFinish={this.onFinish}
            ref={(ref) => (this.formRef = ref)}
            scrollToFirstError
          >
            {!isLogo ? (
              <div>
                <Form.Item className="contentNoMargin" label="Kiểu">
                  <Radio.Group onChange={this.onChangeType} value={type}>
                    <Radio value="boolean">boolean</Radio>
                    <Radio value="text">text</Radio>
                  </Radio.Group>
                </Form.Item>
                {type == "text" ? (
                  <Form.Item className="contentNone" label=" ">
                    <div display="flex">
                      <Button
                        style={{ marginRight: 10 }}
                        type="primary"
                        shape="round"
                        size="normal"
                        onClick={() => this.onFormatJson()}
                      >
                        format
                      </Button>
                      <Button
                        type="primary"
                        shape="round"
                        size="normal"
                        onClick={() => this.onRemoveWhiteSpace()}
                      >
                        Remove white space
                      </Button>
                    </div>
                  </Form.Item>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            {isLogo ? (
              <Form.Item name="configName">
                <Upload
                  name="file"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action={`${this.state.ServerRisUrl}/api/Files/upload-config-logo`}
                  beforeUpload={beforeUpload}
                  onChange={this.handleChange}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            ) : (
              <Form.Item name="configName" label="Nội dung" rules={[{ required: true, message:"vui lòng nhập nội dung!" }]}>
                {type == "text" ? (
                  <TextArea id="myTextArea" rows={8} />
                ) : (
                  <Select placeholder="">
                    <Option value="true">true</Option>
                    <Option value="false">false</Option>
                  </Select>
                )}
              </Form.Item>
            )}
            <Form.Item name="configValue" label="Giá trị" rules={[{ required: true, message:"vui lòng nhập giá trị!" }]}>
              <InputNumber allowClear />
            </Form.Item>
            <Button
              style={{
                color: "#fff",
                background: "#2687d4",
                display: "none",
              }}
              id="btn-submit-adđ-config"
              htmlType={"submit"}
            >
              Lưu lại
            </Button>
            {}
          </Form>
        </Content>
        <Footer>
          <Button
            style={{ color: "#fff", background: "#2687d4" }}
            onClick={() =>
              document.getElementById("btn-submit-adđ-config").click()
            }
          >
            Lưu lại
          </Button>
        </Footer>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    configurationItem: state.configuration.configurationItem,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getConfigurationById: (id) => dispatch(getConfigurationById(id)),
    updateConfigurationToServer: (id, configuration) =>
      dispatch(updateConfigurationToServer(id, configuration)),
    handleCloseModalApp: () => dispatch(handleCloseModalApp()),
    getConfigurationFromServer: (search) =>
      dispatch(getConfigurationFromServer(search)),
    setModalBasic: (data) => dispatch(setModalBasic(data)),
    setConfigurationItem: (id) => dispatch(setConfigurationItem(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentEdit);
