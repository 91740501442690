import React, { Component } from "react";
import { connect } from "react-redux";
import {
    getHospitalFromServer,
    deleteHospitalFromServer,
    activeHospital,
    inactiveHospital,
    fetchDropdownAreas,
} from "../../../reducers/hospitalReducer";
import { getHospitalSearch, setHospitalSearch } from "./hospital";
import { handleOpenModalApp, setModalBasic } from "../../../reducers/uiReducer";
import ContentEdit from "../../../components/Hospital/ContentEdit";
import AddFormContent from "../../../components/Hospital/AddFormContent";
import { Form, Input, Select, Popconfirm, Pagination, Button, Checkbox } from "antd";
import FontAwesome from "react-fontawesome";

import "./HospitalPage.scss";
const { Option } = Select;

class HospitalPage extends Component {
    componentDidMount = () => {
        this.props.getHospitalFromServer(getHospitalSearch());
        this.props.fetchDropdownAreas();
    };

    handleUpdateTable = () => {
        this.props.getHospitalFromServer(getHospitalSearch());
    };

    onEdit = (hospitalId, hospitalName) => {
        const modalData = {
            visible: true,
            width: 900,
            content: <ContentEdit hospitalId={hospitalId} hospitalName={hospitalName} />,
            name: "doctor-edit",
        };
        this.props.setModalBasic(modalData);
    };

    onAdd = () => {
        const modalData = {
            visible: true,
            width: 900,
            content: <AddFormContent />,
            name: "doctor-edit",
        };
        this.props.setModalBasic(modalData);
    };

    onDelete = (id) => {
        this.props.deleteHospitalFromServer(id).then(() => {
            this.props.getHospitalFromServer(getHospitalSearch());
        });
    };

    onInactive = (id) => {
        this.props.inactiveHospital(id).then(() => {
            this.props.getHospitalFromServer(getHospitalSearch());
        });
    };

    onActive = (id) => {
        this.props.activeHospital(id).then(() => {
            this.props.getHospitalFromServer(getHospitalSearch());
        });
    };

    onChangePage = (page, pageSize) => {
        let hospitalSearch = getHospitalSearch();
        hospitalSearch.PageIndex = page;
        hospitalSearch.PageSize = pageSize;
        setHospitalSearch(hospitalSearch);
        this.handleUpdateTable();
    };

    onFinish = (values) => {
        let hospitalSearch = getHospitalSearch();
        hospitalSearch.Keyword = values.Keyword;
        hospitalSearch.HosType = values.HosType;
        hospitalSearch.AreaId = values.AreaId;
        hospitalSearch.PageIndex = 1;
        setHospitalSearch(hospitalSearch);
        this.handleUpdateTable(hospitalSearch);
    };

    render() {
        const { hospitals, totalCount, listArea } = this.props;
        return (
            <div className="hospital-page-d">
                <div className="hospital-search-d">
                    <Form
                        name="horizontal_login"
                        initialValues={{ Keyword: "", HosType: "", AreaId: "" }}
                        layout="inline"
                        onFinish={this.onFinish}
                    >
                        <Form.Item name="Keyword">
                            <Input placeholder="Keyword" />
                        </Form.Item>
                        <Form.Item name="HosType">
                            <Select>
                                <Option value="">Tất cả</Option>
                                <Option value={0}>Normal</Option>
                                <Option value={1}>Favorite</Option>
                                <Option value={2}>Share</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="AreaId" style={{ width: 170 }}>
                            <Select>
                                <Option value="">Tất cả</Option>
                                {listArea.map((item) => {
                                    return (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Button style={{ background: "#2a274c", color: "#fff" }} htmlType="submit">
                            Tìm kiếm
                        </Button>
                    </Form>
                    <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.onAdd}>
                        Thêm mới
                    </Button>
                </div>
                <div className={"hospital-table-d"}>
                    <table border="0" className="table-admin">
                        <thead>
                            <tr>
                                <th>Tên thư mục</th>
                                <th>Địa chỉ</th>
                                <th>Loại thư mục</th>
                                <th>Cơ sở</th>
                                <th>Thứ tự</th>
                                <th>Trạng thái</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {hospitals.map((hospital, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{hospital.name}</td>
                                        <td>{hospital.locate}</td>
                                        <td>
                                            {hospital.hospitalType === 0 ? (
                                                <span>Bình thường</span>
                                            ) : hospital.hospitalType === 1 ? (
                                                <span>Favorite</span>
                                            ) : (
                                                <span>Share</span>
                                            )}
                                        </td>
                                        <td>{hospital.areaName}</td>
                                        <td>{hospital.order}</td>
                                        <td>
                                            {hospital.status === 1 ? (
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={`Xác nhận vô hiệu hóa ${hospital.name}`}
                                                    onConfirm={() => this.onInactive(hospital.id)}
                                                    okText="Ok"
                                                    cancelText="Cancel"
                                                >
                                                    <Checkbox checked={true}></Checkbox>
                                                </Popconfirm>
                                            ) : (
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={`Xác nhận kích hoạt ${hospital.name}`}
                                                    onConfirm={() => this.onActive(hospital.id)}
                                                    okText="Ok"
                                                    cancelText="Cancel"
                                                >
                                                    <Checkbox checked={false}></Checkbox>
                                                </Popconfirm>
                                            )}
                                        </td>
                                        <td>
                                            {" "}
                                            <div className="tools">
                                                <Button onClick={() => this.onEdit(hospital.id, hospital.name)}>
                                                    <FontAwesome name="edit" />
                                                </Button>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={`Xác nhận xóa ${hospital.name}`}
                                                    onConfirm={() => this.onDelete(hospital.id)}
                                                    okText="Ok"
                                                    cancelText="Cancel"
                                                >
                                                    <Button>
                                                        <FontAwesome name="trash-alt" />
                                                    </Button>
                                                </Popconfirm>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="pagination-d">
                        <Pagination
                            defaultCurrent={3}
                            current={getHospitalSearch().PageIndex}
                            total={totalCount}
                            showQuickJumper
                            showTitle={false}
                            defaultPageSize={getHospitalSearch().PageSize}
                            onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        hospitals: state.hospital.hospitals,
        totalCount: state.hospital.totalCount,
        listArea: state.hospital.listArea,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getHospitalFromServer: (search) => dispatch(getHospitalFromServer(search)),
        handleOpenModalApp: (modalData) => dispatch(handleOpenModalApp(modalData)),
        deleteHospitalFromServer: (id) => dispatch(deleteHospitalFromServer(id)),
        fetchDropdownAreas: () => dispatch(fetchDropdownAreas()),
        activeHospital: (id) => dispatch(activeHospital(id)),
        inactiveHospital: (id) => dispatch(inactiveHospital(id)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HospitalPage);
