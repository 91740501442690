import Axios from "axios";
import { queryString } from "../utils/queryString";
import { message } from "antd";
const ACTION_TYPES = {
    SET_ROLEADMIN: "SET_ROLEADMIN",
    SET_ROLEADMIN_ITEM: "SET_ROLEADMIN_ITEM",
};

const initialstate = {
    roleadmins: [],
    roleadminitem: [],
    totalCount: 0,
};

const roleAdminReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_ROLEADMIN:
            state = { ...state };
            state.roleadmins = [...action.payload.data];
            state.totalCount = action.payload.dataCount;
            return { ...state };
        case ACTION_TYPES.SET_ROLEADMIN_ITEM:
            state.roleadminitem = action.payload;
            return { ...state };
        default:
            return state;
    }
};
const setRoleAdmin = (roleadmins) => {
    return {
        type: ACTION_TYPES.SET_ROLEADMIN,
        payload: roleadmins,
    };
};
const getRoleAdminFromServer = (request) => async (dispatch) => {
    const { userName, PageIndex, PageSize } = request;
    let url = "api/RoleAdmin/paging?";
    url += `userName=${userName}&`;
    url += `PageIndex=${PageIndex}&`;
    url += `PageSize=${PageSize}`;
    const response = await Axios.get(url);
    if (response.status === 200) {
        // response.data.data.forEach((item, index) => {
        //     item.stt = index + 1;
        // });
        if (response.data.status === 1) {
            dispatch(setRoleAdmin(response.data));
        }
        
    } else {
        message.error(response.status);
    }
};

const setRoleAdminItem = (roleadminitem) => {
    return {
        type: ACTION_TYPES.SET_ROLEADMIN_ITEM,
        payload: roleadminitem,
    };
};
const getRoleAdminById = (id) => async (dispatch) => {
    const response = await Axios.get(`api/RoleAdmin/${id}`);
    if (response.data.status === 1) {
        dispatch(setRoleAdminItem(response.data.data));
    }
    
};

const updateRoleAdminToServer = (request) => async (dispatch) => {
    await Axios.post(`api/RoleAdmin/create`, request)
        .then((response) => {
            if (response.data) {
                message.success(response.data.message);
            }
        })
        .catch((err) => {
            if (err.response) {
                
                message.error("có lỗi xảy ra");
            }
        });
};
export {
    roleAdminReducer,
    getRoleAdminById,
    updateRoleAdminToServer,
    getRoleAdminFromServer,
    setRoleAdminItem
};
