import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, message, Checkbox, Layout } from "antd";
import {
    getTypeServiceById,
    updateTypeServiceToServer,
    getTypeServiceFromServer,
    setTypeServiceItem,
} from "../../reducers/typeserviceReducer";
import { connect } from "react-redux";
import moment from "moment";
import { getTypeServiceSearch } from "../../pages/Home/TypeServicePage/typeservice";
import { handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const { Content, Footer, Header } = Layout;

class ContentEdit extends Component {
    constructor(props) {
        super(props);
        this.formRef = null;
    }

    componentDidMount = () => {
        this.props.getTypeServiceById(this.props.typeserviceId).then(() => {
            this.formRef.setFieldsValue({ ...this.props.typeserviceItem });
        });
    };
    componentDidUpdate = (prevProps) => {
        if (prevProps.typeserviceId !== this.props.typeserviceId) {
            this.props.getTypeServiceById(this.props.typeserviceId).then(() => {
                this.formRef.setFieldsValue({
                    ...this.props.typeserviceItem,
                    doB: moment(this.props.typeserviceItem.doB),
                });
            });
        }
    };

    onFinish = (values) => {
        const { typeserviceId } = this.props;
        this.props.updateTypeServiceToServer(typeserviceId, values).then(() => {
            const search = getTypeServiceSearch();
            this.props.getTypeServiceFromServer(search);
            this.props.setModalBasic({ visible: false });
        });
    };
    onChacngeActive = (e) => {
        const { typeserviceItem } = this.props;
        typeserviceItem.active = !e.target.value;
        this.props.setTypeServiceItem(typeserviceItem);
    };
    render() {
        return (
            <>
                <Layout className="layout-typpe-service-edit">
                    <Header>
                        <p className="modalBasic-title">Sửa thông tin loại dịch vụ</p>
                        <button
                            className="modalBasic-btn-close"
                            onClick={() => this.props.setModalBasic({ visible: false })}
                        >
                            <i className="fas fa-times icon"></i>
                        </button>
                    </Header>
                    <Content>
                        <Form
                            className="form-edit-typeService"
                            {...formItemLayout}
                            name="addTypeservice"
                            onFinish={this.onFinish}
                            ref={(ref) => (this.formRef = ref)}
                            scrollToFirstError
                        >
                            <Form.Item name="name" label="Tên" rules={[{ required: true, message:"vui lòng nhập tên!" }]}>
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="shortName" label="Rút gọn" rules={[{ required: true, message:"vui lòng nhập tên rút gọn!" }]}>
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="code" label="Mã">
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="active" label="Trạng thái" valuePropName="checked">
                                <Checkbox />
                            </Form.Item>
                            <Button style={{ display: "none" }} id="btn-submit-typeservice-edit" htmlType={"submit"}>
                                Lưu lại
                            </Button>
                        </Form>
                    </Content>
                    <Footer>
                        <Button
                            style={{ color: "#fff", background: "#2687d4" }}
                            onClick={() => document.getElementById("btn-submit-typeservice-edit").click()}
                            htmlType={"submit"}
                        >
                            Lưu lại
                        </Button>
                    </Footer>
                </Layout>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        typeserviceItem: state.typeservice.typeserviceItem,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getTypeServiceById: (id) => dispatch(getTypeServiceById(id)),
        updateTypeServiceToServer: (id, typeservice) => dispatch(updateTypeServiceToServer(id, typeservice)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        getTypeServiceFromServer: (search) => dispatch(getTypeServiceFromServer(search)),
        setTypeServiceItem: (typeservice) => dispatch(setTypeServiceItem(typeservice)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentEdit);
