import React, { Component } from "react";
import "./SummaryTable.scss";

class DoctorsTable extends Component {
    render() {
        const { doctors } = this.props;
        //         name: "MR"
        // readNum: 0
        // readNumHis: 0
        // unReadNum: 0
        // unReadNumHis: 0
        return (
            <div className={"summary-table-d"}>
                <div style={{ width: "100%", height: 30, background: "#2a274c" }}>
                    <p style={{ lineHeight: "30px", color: "white", textAlign: "center", fontSize: 20 }}>
                        <b>THỐNG KÊ BÁC SĨ</b>
                    </p>
                </div>
                <div style={{ width: "100%", maxHeight: 600, overflow: "auto" }}>
                    <table
                        border="0"
                        className="table-admin"
                        style={{ position: "relative", maxHeight: 100, overflow: "auto", margin:0 }}
                    >
                        <thead>
                            <tr>
                                <th style={{ position: "sticky",background: '#fff', top: 0 }}>STT</th>
                                <th style={{ position: "sticky",background: '#fff', top: 0 , whiteSpace: 'nowrap'}}>Họ và tên</th>
                                {doctors.listModal
                                    ? doctors.listModal.map((modal, index) => {
                                          return (
                                              <th style={{ position: "sticky",background: '#fff', top: 0 }} key={modal.key}>
                                                  {modal.value}
                                              </th>
                                          );
                                      })
                                    : ""}
                            </tr>
                        </thead>
                        <tbody>
                            {doctors.doctor
                                ? doctors.doctor.map((doctor, index) => {
                                      let rows = [];
                                      for (let key in doctor.numberOfModality) {
                                          rows.push(doctor.numberOfModality[key]);
                                      }
                                      return (
                                          <tr key={index}>
                                              <td>{index + 1}</td>
                                              <td>{doctor.doctorName}</td>
                                              {rows.map((it, id) => {
                                                  return <td key={id}>{it}</td>;
                                              })}
                                          </tr>
                                      );
                                  })
                                : ""}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
export default DoctorsTable;
