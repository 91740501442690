import React, { Component } from "react";
import { getTemplateSearch, setTemplateSearch, templateSeachDefault } from "./templateModel";
import { Form, Input, Button, Popconfirm, Pagination, Select, Checkbox } from "antd";
import {
    deleteTemplateFromServer,
    getDropdownServices,
    getTemplateFromServer,
    activeTemplate,
    inactiveTemplate,
} from "../../../reducers/templateReducer";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import { setModalBasic } from "../../../reducers/uiReducer";
import "./TemplatePage.scss";
import AddTemplate from "../../../components/TemplateComponent/AddTemplate";
import EditTemplate from "../../../components/TemplateComponent/EditTemplate";

const { Option } = Select;
class TemplatePage extends Component {
    componentDidMount = () => {
        this.props.getTemplateFromServer(getTemplateSearch());
        this.props.getDropdownServices();
    };

    handleUpdateTable = () => {
        this.props.getTemplateFromServer(getTemplateSearch());
    };

    onFinish = (values) => {
        setTemplateSearch({
            ...getTemplateSearch(),
            Keyword: values.Keyword,
            ServiceID: values.ServiceID,
            PageIndex: 1,
        });
        this.props.getTemplateFromServer(getTemplateSearch());
    };

    onAdd = () => {
        const modalData = {
            visible: true,
            width: 900,
            content: <AddTemplate />,
            name: "template-add",
        };
        this.props.setModalBasic(modalData);
    };

    onEdit = (id) => {
        const modalData = {
            visible: true,
            width: 900,
            content: <EditTemplate id={id} />,
            name: "template-add",
        };
        this.props.setModalBasic(modalData);
    };

    onDelete = (id) => {
        this.props.deleteTemplateFromServer(id).then(() => {
            setTemplateSearch({ ...getTemplateSearch(), PageIndex: 1 });
            this.props.getTemplateFromServer(getTemplateSearch());
        });
    };

    onChangePage = (page, pageSize) => {
        //Change ServerPacsSearch
        let templateSearch = getTemplateSearch();
        templateSearch.PageIndex = page;
        templateSearch.PageSize = pageSize;
        setTemplateSearch(templateSearch);
        this.handleUpdateTable();
    };

    onInactive = (id) => {
        this.props.inactiveTemplate(id).then(() => {
            this.props.getTemplateFromServer(getTemplateSearch());
        });
    };

    onActive = (id) => {
        this.props.activeTemplate(id).then(() => {
            this.props.getTemplateFromServer(getTemplateSearch());
        });
    };

    render() {
        const { templates, totalCount, dropdownServices } = this.props;
        return (
            <div className="template-page-d">
                <div className="template-search-d">
                    <Form
                        name="horizontal_login"
                        initialValues={templateSeachDefault}
                        layout="inline"
                        onFinish={this.onFinish}
                    >
                        {/* <Button style={{ background: "#2a274c", color: "#fff", marginRight: 20 }} onClick={this.goBack}>
                        Back
                    </Button> */}
                        <Form.Item name="Keyword">
                            <Input placeholder="Từ khóa" />
                        </Form.Item>

                        <Form.Item style={{ width: "300px" }} name="ServiceID">
                            <Select
                                showSearch
                                placeholder="Chọn dịch vụ"
                                allowClear
                                style={{ width: "100%" }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {dropdownServices.map((item) => (
                                    <Option key={item.id} value={Number(item.id)}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Button style={{ background: "#2a274c", color: "#fff" }} htmlType="submit">
                            Tìm kiếm
                        </Button>
                        <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.onAdd}>
                            Thêm mới
                        </Button>
                    </Form>
                </div>
                <div className="template-table-d">
                    <table border="0" className="table-admin">
                        <thead>
                            <tr>
                                <th>Tên</th>
                                <th>Tên dịch vụ</th>
                                <th>Kỹ thuật</th>
                                <th>Kết quả</th>
                                <th>Trạng thái</th>
                                <th style={{ minWidth: window.innerWidth / 3 }}>Mô tả</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {templates.map((item, i) => (
                                <tr>
                                    <td>{item.name}</td>
                                    <td>{item.svName}</td>
                                    <td>{item.tech}</td>
                                    <td>
                                        <div dangerouslySetInnerHTML={{ __html: item.result }} />
                                    </td>
                                    <td>
                                        {item.status === 1 ? (
                                            <Popconfirm
                                                placement="topRight"
                                                title={`Xác nhận vô hiệu hóa ${item.name}`}
                                                onConfirm={() => this.onInactive(item.id)}
                                                okText="Ok"
                                                cancelText="Cancel"
                                            >
                                                <Checkbox checked={true}></Checkbox>
                                            </Popconfirm>
                                        ) : (
                                            <Popconfirm
                                                placement="topRight"
                                                title={`Xác nhận kích hoạt ${item.name}`}
                                                onConfirm={() => this.onActive(item.id)}
                                                okText="Ok"
                                                cancelText="Cancel"
                                            >
                                                <Checkbox checked={false}></Checkbox>
                                            </Popconfirm>
                                        )}
                                    </td>
                                    <td>
                                        <div dangerouslySetInnerHTML={{ __html: item.text }} />
                                    </td>
                                    <td>
                                        <div className="tools">
                                            <Button onClick={() => this.onEdit(item.id)}>
                                                <FontAwesome name="edit" />
                                            </Button>
                                            <Popconfirm
                                                placement="topRight"
                                                title={`Xác nhận xóa ${item.name}`}
                                                onConfirm={() => this.onDelete(item.id)}
                                                okText="Ok"
                                                cancelText="Cancel"
                                            >
                                                <Button>
                                                    <FontAwesome name="trash-alt" />
                                                </Button>
                                            </Popconfirm>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination-d">
                        <Pagination
                            defaultCurrent={3}
                            current={getTemplateSearch().PageIndex}
                            total={totalCount}
                            showQuickJumper
                            showTitle={false}
                            defaultPageSize={getTemplateSearch().PageSize}
                            pageSizeOptions={[3, 10, 20, 50, 100]}
                            onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        templates: state.template.templates,
        totalCount: state.template.totalCount,
        dropdownServices: state.template.dropdownServices,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        getTemplateFromServer: (data) => dispatch(getTemplateFromServer(data)),
        getDropdownServices: () => dispatch(getDropdownServices()),
        deleteTemplateFromServer: (id) => dispatch(deleteTemplateFromServer(id)),
        activeTemplate: (id) => dispatch(activeTemplate(id)),
        inactiveTemplate: (id) => dispatch(inactiveTemplate(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePage);
