import React, { Component } from "react";
import { Form, Input, Radio, Layout, Button, Select, InputNumber } from "antd";
import { deaultHospitalForm, getHospitalSearch } from "../../pages/Home/HospitalPage/hospital";
import { addHospitalToServer, fetchDropdownAreas, getHospitalFromServer } from "../../reducers/hospitalReducer";
import { connect } from "react-redux";
import { handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
const { Option } = Select;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const { Content, Footer, Header } = Layout;

class AddFormContent extends Component {
    onFinish = (values) => {
        values.AreaId = Number(values.AreaId);
        this.props.addHospitalToServer(values).then(() => {
            this.props.setModalBasic({ visible: false });
            this.props.getHospitalFromServer(getHospitalSearch());
        });
    };

    render() {
        const { listArea } = this.props;
        return (
            <Layout className="doctor-add-form-content">
                <Header>
                    <p className="modalBasic-title">Thêm mới thư mục</p>
                    <button
                        className="modalBasic-btn-close"
                        onClick={() => this.props.setModalBasic({ visible: false })}
                    >
                        <i className="fas fa-times icon"></i>
                    </button>
                </Header>
                <Content>
                    <Form
                        style={{ marginTop: 20 }}
                        {...formItemLayout}
                        name="addHospital"
                        onFinish={this.onFinish}
                        initialValues={{
                            hospitalType: 0,
                            name: "",
                            locate: "",
                            AreaId: listArea[0]?.id ?? "",
                            order: 0,
                        }}
                        scrollToFirstError
                    >
                        <Form.Item name="name" label="Tên" rules={[{ required: true, message:"vui lòng nhập tên!" }]}>
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="locate" label="Vị trí">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="hospitalType" label="Loại thư mục" valuePropName="value">
                            <Select>
                                <Option value={0}>Normal</Option>
                                <Option value={1}>Favorite</Option>
                                <Option value={2}>Share</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="order" label="Thứ tự" valuePropName="value">
                            <InputNumber min={0} max={1500} />
                        </Form.Item>
                        <Form.Item name="AreaId" label="Cơ sở" valuePropName="value">
                            <Select>
                                {listArea.map((item) => {
                                    return (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Button
                            style={{ color: "#fff", background: "#2687d4", display: "none" }}
                            id="btn-submit-add-hospital"
                            htmlType={"submit"}
                        >
                            Thêm mới
                        </Button>
                    </Form>
                </Content>
                <Footer>
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            document.getElementById("btn-submit-add-hospital").click();
                        }}
                    >
                        Thêm mới
                    </Button>
                </Footer>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        listArea: state.hospital.listArea,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addHospitalToServer: (hospital) => dispatch(addHospitalToServer(hospital)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        fetchDropdownAreas: () => dispatch(fetchDropdownAreas()),
        getHospitalFromServer: (search) => dispatch(getHospitalFromServer(search)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFormContent);
