const ACTION_TYPES = {
    SET_LOADING_HOME_PAGE: "SET_LOADING_HOME_PAGE",
};

const initialstate = false;

const loadingReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_LOADING_HOME_PAGE:
            state = action.payload;
            return state;
        default:
            return state;
    }
};
const setLoading = (data) => {
    return {
        type: ACTION_TYPES.SET_LOADING_HOME_PAGE,
        payload: data,
    };
};

export { setLoading, loadingReducer };