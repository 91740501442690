import moment from "moment";
let configurationSeach = {
    Keyword: "",
    PageIndex: 1,
    PageSize: 10,
};

const getConfigurationSearch = () => {
    return configurationSeach;
};

const setConfigurationSearch = (search) => {
    configurationSeach = search;
};

export { getConfigurationSearch, setConfigurationSearch };
