import React, { Component } from "react";
import { connect } from "react-redux";
import {
    getServerPacsFromServer,
    deleteServerPacsFromServer,
    getListHospitalFromServer,
    getListPrintFromServer,
} from "../../../reducers/serverpacsReducer";
import { getServerPacsSearch, initialServerPacsSeach, setServerPacsSearch } from "./serverpacs";
import { handleOpenModalApp, setModalBasic } from "../../../reducers/uiReducer";
import ContentEdit from "../../../components/ServerPacs/ContentEdit";
import "./ServerPacsPage.scss";
import { Form, Input, Button, Popconfirm, Checkbox, Pagination,Select } from "antd";
import FontAwesome from "react-fontawesome";
import AddFormContent from "../../../components/ServerPacs/AddFormContent";
import { Link } from "react-router-dom";

const { Option } = Select;
class ServerPacsPage extends Component {
    componentDidMount = () => {
        this.props.getServerPacsFromServer(getServerPacsSearch());
        this.props.getListHospitalFromServer();
        this.props.getListPrintFromServer();
    };

    handleUpdateTable = () => {
        this.props.getServerPacsFromServer(getServerPacsSearch());
    };


    onAdd = ()=>{
        const modalData = {
            visible: true,
            width: 900,
            content: <AddFormContent />,
            name: "serverpacs-add",
        };
        this.props.setModalBasic(modalData);
        
    }

    onFinish = (values) => {
        if(!values.RequestFor){
            values.RequestFor = "";
        }
        if(!values.RequestFromIp){
            values.RequestFromIp ="";
        }
        setServerPacsSearch({ ...getServerPacsSearch(), Keyword: values.Keyword, PageIndex: 1,RequestFor:values.RequestFor,RequestFromIp:values.RequestFromIp});
        this.handleUpdateTable();
    };

    onEdit = (serverpacsId, serverpacsName) => {
        const modalData = {
            visible: true,
            width: 900,
            content: <ContentEdit serverpacsId={serverpacsId} serverpacsName={serverpacsName} />,
            name: "serverpacs-edit",
        };
        this.props.setModalBasic(modalData);
    };
    
    onDelete = (id) => {
        this.props.deleteServerPacsFromServer(id).then(() => {
            this.props.getServerPacsFromServer(getServerPacsSearch());
        });
    };

    onChangePage = (page, pageSize) => {
        //Change ServerPacsSearch
        let serverpacsSearch = getServerPacsSearch();
        serverpacsSearch.PageIndex = page;
        serverpacsSearch.PageSize = pageSize;
        setServerPacsSearch(serverpacsSearch);
        this.handleUpdateTable();
    };

    render() {
        const { serverpacss, totalCount } = this.props;
        return (
            <div className="serverpacs-page-d">
                <div className="serverpacs-search-d">
                    <Form
                        name="horizontal_login"
                        initialValues={initialServerPacsSeach}
                        layout="inline"
                        onFinish={this.onFinish}
                    >
                        <Form.Item name="Keyword">
                            <Input placeholder="Từ khóa" />
                        </Form.Item>
                        <Form.Item name="RequestFor" label="Dịch vụ">
                            <Select
                                    showSearch
                                    defaultValue={null}
                                    style={{ width: "100%" }}
                                >
                                    <Option value={null}>Tất cả</Option>
                                    <Option value={1}>SCP</Option>
                                    <Option value={2}>WorkList</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="RequestFromIp" label="Loại">
                            <Select
                                    showSearch
                                    defaultValue={null}
                                    style={{ width: "100%" }}
                                >
                                    <Option value={null}>Tất cả</Option>
                                    <Option value={"Share"}>Share</Option>
                                    <Option value={"Favorite"}>Favorite</Option>
                            </Select>
                        </Form.Item>
                        <Button style={{ background: "#2a274c", color: "#fff" }} htmlType="submit">
                            Tìm kiếm
                        </Button>
                        <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.onAdd}>
                            Thêm mới
                        </Button>
                        <Link to="/server-template-manager">
                            <Button style={{ background: "#2a274c", color: "#fff" }} onClick={() => localStorage.setItem("router", 'server-template-manager')}>
                                Phân mẫu cho máy chủ
                            </Button>
                        </Link>
                        {/* <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.onOpenModal}>
                        Add
                    </Button> */}
                    </Form>
                </div>
                <div className={"serverpacs-table-d"}>
                    <table border="0" className="table-admin">
                        <thead>
                            <tr>
                                <th style={{minWidth:120}}>Tên</th>
                                <th>Tên đầy đủ</th>
                                <th>Phân mẫu</th>
                                <th>Thư mục lưu trữ</th>
                                <th>StartMode</th>
                                <th>State</th>
                                <th>RequestFromIP</th>
                                <th>RequestFor</th>
                                <th>ApplyFor</th>
                                <th>IsStore</th>
                                <th>WLResultAll</th>
                                <th style={{minWidth:120}}>Tên thư mục</th>
                                <th style={{minWidth:150}}>Tên mẫu</th>
                                <th>LoginName</th>
                                <th>Modality</th>
                                <th>ModalityType</th>
                                <th>Map Modality</th>
                                <th>MachineCode</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {serverpacss.map((svp, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{svp.name}</td>
                                        <td>{svp.fullName}</td>
                                        {svp.isAsignTemp == true ? <td></td> : <td style={{color:"red",fontWeight:"bold"}}>Chưa phân</td>}
                                        <td>{svp.path}</td>
                                        <td>{svp.startMode}</td>
                                        <td>{svp.state}</td>
                                        <td>{svp.requestFromIP}</td>
                                        <td>{svp.requestFor}</td>
                                        <td>{svp.applyFor}</td>
                                        <td>{svp.isStore}</td>
                                        <td>
                                            <Checkbox checked={svp.wlResultAll}></Checkbox>
                                        </td>
                                        <td>{svp.hospitalName}</td>
                                        <td>{svp.headerName}</td>
                                        <td>{svp.loginName}</td>
                                        <td>{svp.modality}</td>
                                        <td>{svp.modalityType}</td>
                                        <td>{svp.mapModality}</td>
                                        <td>{svp.machineCode}</td>
                                        <td>
                                            <div className="tools">
                                                <Button onClick={() => this.onEdit(svp.id, svp.name)}>
                                                    <FontAwesome name="edit" />
                                                </Button>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={`Xác nhận xóa ${svp.name}`}
                                                    onConfirm={() => this.onDelete(svp.id, svp.name)}
                                                    okText="Ok"
                                                    cancelText="Cancel"
                                                >
                                                    <Button>
                                                        <FontAwesome name="trash-alt" />
                                                    </Button>
                                                </Popconfirm>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={`Đang hoàn thiện`}
                                                    okText="Ok"
                                                    cancelText="Cancel"
                                                >
                                                    <Button>
                                                        <FontAwesome name="share-square" />
                                                    </Button>
                                                </Popconfirm>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="pagination-d">
                        <Pagination
                            defaultCurrent={5}
                            current={getServerPacsSearch().PageIndex}
                            total={totalCount}
                            showQuickJumper
                            showTitle={false}
                            defaultPageSize={getServerPacsSearch().PageSize}
                            onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        serverpacss: state.serverpacs.serverpacss,
        totalCount: state.serverpacs.totalCount,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getServerPacsFromServer: (search) => dispatch(getServerPacsFromServer(search)),
        handleOpenModalApp: (modalData) => dispatch(handleOpenModalApp(modalData)),
        deleteServerPacsFromServer: (id) => dispatch(deleteServerPacsFromServer(id)),
        getListHospitalFromServer: () => dispatch(getListHospitalFromServer()),
        getListPrintFromServer: () => dispatch(getListPrintFromServer()),
        setModalBasic: (data) => dispatch(setModalBasic(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerPacsPage);
