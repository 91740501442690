import Axios from "axios";
import { queryString } from "../utils/queryString";
import { message } from "antd";
import { store } from "../store/store";
const ACTION_TYPES = {
    SET_DOCTORROLES_DEFAULT:"SET_DOCTORROLES_DEFAULT",
    SET_DOCTORROLES: "SET_DOCTORROLES",
    SET_ROLES: "SET_ROLES",
    SET_DOCTORROLE_ITEM: "SET_DOCTORROLE_ITEM",
    SET_LIST_DOCTOR_NOT_ROLE: "SET_LIST_DOCTOR_NOT_ROLE",
    SET_DOCTORROLE_GROUP:"SET_DOCTORROLE_GROUP",
    SET_DROPDOWN_AREA:"SET_DROPDOWN_AREA",
    SET_DROPDOWN_HOSPITAL:"SET_DROPDOWN_HOSPITAL",
    SET_DROPDOWN_SERVERPACS:"SET_DROPDOWN_SERVERPACS"
};

const initialstate = {
    doctorroles: [],
    doctorNotRoles: [],
    totalCount: 0,
    roles: [],
    doctorroleItem: {
        listHospitals: [],
    },
    groupRoles:[],
    groupRoleItem: {
        listHospitals: [],
    },
    listDropdownArea:[],
    listDropdownHospital:[],
    listDropdownServerPacs:[]
};

const doctorroleReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_DOCTORROLES:
            state = { ...state };
            state.doctorroles = action.payload.data;
            state.totalCount = action.payload.dataCount;
            return { ...state };
        case ACTION_TYPES.SET_ROLES:
            state.roles = action.payload;
            return { ...state };
        case ACTION_TYPES.SET_DOCTORROLE_ITEM:
            state.doctorroleItem = action.payload;
            return { ...state };
        case ACTION_TYPES.SET_DOCTORROLE_GROUP:
            state.groupRoleItem = action.payload;
            return { ...state };
        case ACTION_TYPES.SET_LIST_DOCTOR_NOT_ROLE:
            state.doctorNotRoles = action.payload;
            return { ...state };
        case ACTION_TYPES.SET_DOCTORROLES_DEFAULT:
            state = action.payload;
            return {...state }; 
        case ACTION_TYPES.SET_DROPDOWN_AREA:
            state.listDropdownArea = action.payload;
            return {...state };  
        case ACTION_TYPES.SET_DROPDOWN_HOSPITAL:
            state.listDropdownHospital = action.payload;
            return {...state };  
        case ACTION_TYPES.SET_DROPDOWN_SERVERPACS:
            state.listDropdownServerPacs = action.payload;
            return {...state };       
        default:
            return state;
    }
};

const setDefaultRoles = (data) => {
    return {
        type: ACTION_TYPES.SET_DOCTORROLES_DEFAULT,
        payload: data,
    };
};

const setDoctorRoles = (doctorroles) => {
    return {
        type: ACTION_TYPES.SET_DOCTORROLES,
        payload: doctorroles,
    };
};
const permissionsDefault = {
    Clinical: false,
    Read: false,
    Approve: false,
    Consult:false,
    ConsultDiscard:false,
    Edit: false,
    Delete: false,
    UpdateFromHis: false,
    Share: false,
    Statistical: false,
    ApproveDiscard:false,
    DeleteSeri:false,
};

const getDoctorRoleFromServer = (request) => async (dispatch) => {
    const response = await Axios.get(
        `api/Users/hospitals?Keyword=${request.Keyword}&RoleName=${request.RoleName}&PageIndex=${request.PageIndex}&PageSize=${request.PageSize}`
    );
    if (response.data.status === 1) {
        response.data.data.forEach((element) => {
            let listHospital = "";
            element.listServerpacs.forEach((item) => {
                listHospital += item.name + ", ";
                const data = item.permissions?.split(",");
                let permissions = { ...permissionsDefault };
                if (data) {
                    data.forEach((item) => {
                        permissions[item] = true;
                    });
                }
                item.permissionData = permissions;
            });
            element.listHospital = listHospital.substr(0, listHospital.length - 2);
        });
        dispatch(setDoctorRoles(response.data));
    } else {
        message.error(response.data.message);
    }
};

const conversePermissionStringToObject = (permissionsString) => {
    const data = permissionsString?.split(",");
    let permissions = { ...permissionsDefault };
    if (data) {
        data.forEach((item) => {
            if (item) {
                permissions[item] = true;
            }
        });
    }
    return permissions;
};

const conversePermissionObjectToString = (permissionsObject) => {
    let permissionsString = "";
    const permissionsPropertyNames = Object.getOwnPropertyNames(permissionsObject);
    permissionsPropertyNames.forEach((item) => {
        if (permissionsObject[item] === true) {
            permissionsString += item + ",";
        }
    });
    return permissionsString.substr(0, permissionsString.length - 1);
};

const fetchDoctorRoleById = (id) => async (dispatch) => {
    const response = await Axios.get(`/api/Users/${id}/hospitals`);
    if (response.data.status === 1) {
        response.data.data.listServerpacs.forEach((svp) => {
            svp.permissionData = conversePermissionStringToObject(svp.permissions);
        });
        dispatch(setDoctorRoleItem(response.data.data));
    }
};

const setDoctorRoleItem = (doctorrole) => {
    return {
        type: ACTION_TYPES.SET_DOCTORROLE_ITEM,
        payload: doctorrole,
    };
};


const setDoctorRoleGroup = (doctorrole) => {
    return {
        type: ACTION_TYPES.SET_DOCTORROLE_GROUP,
        payload: doctorrole,
    };
};


const updateDoctorRoleToServer = (id, listServerpacs) => async (dispatch) => {
    await Axios.put(`api/Users/${id}/hospitals`, { mserverpacs: listServerpacs })
        .then((response) => {
            if (response.data.status === 1) {
                message.success(response.data.message);
            }
        })
        .catch((err) => {
            let messages = "Error!";
            message.error(messages, 3);
        });
};

const setListDoctorNotRole = (data) => {
    return {
        type: ACTION_TYPES.SET_LIST_DOCTOR_NOT_ROLE,
        payload: data,
    };
};

const getDoctorNotRoleFromServer = () => async (dispatch) => {
    await Axios.get(`api/Users/hospitals/users`).then((response) => {
        dispatch(setListDoctorNotRole(response.data.data));
    });
};

const getGroupRoles = () => async (dispatch) => {
    await Axios.get(`api/roles/group-roles`).then((response) => {
        if(response.status==200){
            if(response.data.status == 1){
                dispatch(setDefaultRoles({...store.getState().doctorrole, groupRoles: response.data.data }));
            }else
            dispatch(setDefaultRoles({...store.getState().doctorrole, groupRoles: [] }));
        }else
        dispatch(setDefaultRoles({...store.getState().doctorrole, groupRoles: [] }));
    });
};

const getGroupRolesViaId = (id) => async (dispatch) => {
    await Axios.get(`api/roles/${id}/group-roles`).then((response) => {
        if(response.status==200){
            if(response.data.status == 1){
                response.data.data.listServerpacs.forEach((svp) => {
                    svp.permissionData = conversePermissionStringToObject(svp.permissions);
                });
                dispatch(setDefaultRoles({...store.getState().doctorrole, groupRoleItem: response.data.data }));
            }else{
                dispatch(setDefaultRoles({...store.getState().doctorrole, groupRoleItem: {listHospitals: []} }));
            }
        }else
            dispatch(setDefaultRoles({...store.getState().doctorrole, groupRoleItem: {listHospitals: []} }));
    });
};



const updateDoctorRoleByGroupRoles = (id, listServerpacs) => async (dispatch) => {
    await Axios.put(`api/roles/${id}/group-roles`, { mserverpacs: listServerpacs })
        .then((response) => {
            if (response.data.status === 1) {
                message.success(response.data.message);
            }
        })
        .catch((err) => {
            let messages = "Error!";
            message.error(messages, 3);
        });
};
const getDropdownAreaFromServer = () => async(dispatch) => {
    await Axios.get(`/api/Users/dropdown-area`).then((response) => {
        if (response.data.status === 1) {
            dispatch(setDropdownArea(response.data.data));
        }
        
    });
};

const setDropdownArea = (data) => {
    return {
        type: ACTION_TYPES.SET_DROPDOWN_AREA,
        payload: data,
    };
};

const getDropdownHospitalFromServer = (areaID) => async(dispatch) => {
    await Axios.get(`/api/Users/dropdown-hospital?areaID=${areaID}`).then((response) => {
        if (response.data.status === 1) {
            dispatch(setDropdownHospital(response.data.data));
        }
        
    });
};

const setDropdownHospital = (data) => {
    return {
        type: ACTION_TYPES.SET_DROPDOWN_HOSPITAL,
        payload: data,
    };
};

const getDropdownServerpacsFromServer = (areaID, hospitalID) => async(dispatch) => {
    await Axios.get(`/api/Users/dropdown-serverpacs?areaID=${areaID}&hospitalID=${hospitalID}`).then((response) => {
        if (response.data.status === 1) {
            dispatch(setDropdownServerPacs(response.data.data));
        }
        
    });
};

const setDropdownServerPacs = (data) => {
    return {
        type: ACTION_TYPES.SET_DROPDOWN_SERVERPACS,
        payload: data,
    };
};

export {
    getDoctorRoleFromServer,
    doctorroleReducer,
    fetchDoctorRoleById,
    updateDoctorRoleToServer,
    getDoctorNotRoleFromServer,
    setDoctorRoleItem,
    conversePermissionObjectToString,
    getGroupRoles,
    getGroupRolesViaId,
    updateDoctorRoleByGroupRoles,
    setDoctorRoleGroup,
    getDropdownAreaFromServer,
    getDropdownHospitalFromServer,
    getDropdownServerpacsFromServer
};
