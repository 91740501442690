import Axios from "axios";
import { message } from "antd";
import { store } from "../store/store";
const ACTION_TYPES = {
    SET_DATA: "SET_DATA",
    SET_HOSPITAL: "SET_HOSPITAL",
    SET_HOSPITAL_ITEM: "SET_HOSPITAL_ITEM",
};

const initialstate = {
    hospitals: [],
    totalCount: 0,
    hospitalItem: {},
    listArea: [],
};

const hospitalReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_DATA:
            state = action.payload;
            return {...state };
        case ACTION_TYPES.SET_HOSPITAL:
            state = {...state };
            state.hospitals = action.payload.data;
            state.totalCount = action.payload.dataCount;
            return {...state };
        case ACTION_TYPES.SET_HOSPITAL_ITEM:
            state.hospitalItem = action.payload;
            return {...state };
        default:
            return state;
    }
};
const setHospitals = (hospitals) => {
    return {
        type: ACTION_TYPES.SET_HOSPITAL,
        payload: hospitals,
    };
};
const getHospitalFromServer = (request) => async(dispatch) => {
    const response = await Axios.get(
        `api/Hospitals/paging?Keyword=${request.Keyword}&HosType=${request.HosType}&AreaId=${request.AreaId}&PageIndex=${request.PageIndex}&PageSize=${request.PageSize}`
    );
    if (response.status === 200) {
        dispatch(setHospitals(response.data));
    } else {
        message.error(response.status);
    }
};

const setHospitalItem = (hospital) => {
    return {
        type: ACTION_TYPES.SET_HOSPITAL_ITEM,
        payload: hospital,
    };
};

const setHospital = (hospital) => {
    return {
        type: ACTION_TYPES.SET_DATA,
        payload: hospital,
    };
};
const getHospitalById = (id) => async(dispatch) => {
    const response = await Axios.get(`api/Hospitals/${id}`);
    dispatch(setHospitalItem(response.data.data));
};
const updateHospitalToServer = (id, hospital) => async(dispatch) => {
    await Axios.put(`api/Hospitals/${id}`, hospital)
        .then((response) => {
            if (response.data) {
                message.success(response.data.message);
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let messages = "";

                message.error(messages, 5);
            }
        });
};

const addHospitalToServer = (request) => async(dispatch) => {
    await Axios.post("api/Hospitals", request)
        .then((response) => {
            if (response.status === 200) {
                message.success("Successfully! ");
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let mes = "";
                if (e.Name) {
                    mes += e.Name + "! ";
                }
                for (var key in e) {
                    mes += e[key] + "!\n";
                }
                message.error(mes, 3);
            }
        });
};

const deleteHospitalFromServer = (id) => async(dispatch) => {
    await Axios.delete(`api/Hospitals/${id}`)
        .then((response) => {
            if (response.data.status === 1) {
                message.success(response.data.message);
            } else {
                message.error(response.data.message);
            }
        })
        .catch((err) => {
            message.error("Error!");
        });
};

//Active hospital
const activeHospital = (id) => async(dispatch) => {
    await Axios.put(`/api/Hospitals/${id}/active`).catch((err) => {
        message.error("Error!");
    });
};

const inactiveHospital = (id) => async(dispatch) => {
    await Axios.put(`/api/Hospitals/${id}/inactive`).catch((err) => {
        message.error("Error!");
    });
};
const fetchDropdownAreas = () => async(dispatch) => {
    const response = await Axios.get(`/api/Hospitals/dropdown-area`);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setHospital({...store.getState().hospital, listArea: response.data.data }));
        }
    }
};
export {
    addHospitalToServer,
    deleteHospitalFromServer,
    getHospitalFromServer,
    hospitalReducer,
    getHospitalById,
    updateHospitalToServer,
    activeHospital,
    inactiveHospital,
    fetchDropdownAreas,
};