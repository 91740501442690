import React, { Component } from "react";

import { Modal } from "antd";
import { connect } from "react-redux";
import { handleCloseModalApp } from "../reducers/uiReducer";
import "./ModalApp.scss";
import { Button } from "antd";
import FontAwesome from "react-fontawesome";

class ModalApp extends Component {
    closeModal = (e) => {
        this.props.handleCloseModalApp();
    };

    render() {
        const { visible, header, content, footer, name } = this.props;
        return (
            <div className={`modal ${visible ? "modal--visible" : "modal--hidden"}`}>
                <div className="modal__overlay" onClick={this.closeModal}></div>
                <div className={`modal__body ${name ? name : ""}`}>
                    {header !== null && header !== undefined ? (
                        <header className="modal__header">
                            <div className="modal__header-content">{header}</div>
                            <button className="modal__header-close-btn" onClick={this.closeModal}>
                                <i className="fas fa-times"></i>
                            </button>
                        </header>
                    ) : null}
                    {content}
                    {footer && <footer className="modal__footer">{footer}</footer>}
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        visible: state.ui.modal.visible,
        header: state.ui.modal.header,
        content: state.ui.modal.content,
        footer: state.ui.modal.footer,
        name: state.ui.modal.name,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalApp);
