import React, { Component } from "react";
import { getTemplateSearch, templateResultDefault } from "../../pages/Home/TemplatePage/templateModel";
import { Form, Input, Radio, DatePicker, Button, message, Select, Layout, InputNumber } from "antd";
import { connect } from "react-redux";
import { setModalBasic } from "../../reducers/uiReducer";
import { addTemplateToServer, getTemplateFromServer, getDropdownServices } from "../../reducers/templateReducer";
import { setModalBasic2 } from "../../reducers/modalBasic2Reducer";
import { getListDiagnose, getListIcdTemp } from "../../reducers/icdTempManagerReducer";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const toolbarConfig = {
    fontSize: {
        options: [9, 11, 13, "default", 17, 19, 21],
    },
    toolbar: {
        items: [
            "heading",
            "|",
            "bold",
            "italic",
            "underline",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "alignment",
            "|",
            "insertTable",
            "imageUpload",
            "blockQuote",
            "mediaEmbed",
            "undo",
            "redo",
            "code",
            "codeBlock",
            "fontBackgroundColor",
            "fontColor",
            "fontSize",
            "fontFamily",
            "highlight",
            "horizontalLine",
            "htmlEmbed",
            "imageInsert",
            "MathType",
            "ChemType",
            "pageBreak",
        ],
    },
    language: "en",
    image: {
        toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side", "linkImage"],
    },
    table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableCellProperties", "tableProperties"],
    },
    licenseKey: "",
};

const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20 },
};
const { Content, Footer, Header } = Layout;
const { Option } = Select;

class AddTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
            result: "",
            proposal: "",
        };
    }

    componentDidMount = () => {
        if (this.props.modal == 2) {
            this.props.getDropdownServices();
        }
    };

    onFinish = (values) => {
        values.text = this.state.text ?? "";
        values.result = this.state.result ?? "";
        values.proposal = this.state.proposal ?? "";
        const { serviceId, idIdc, modal } = this.props;
        if (modal == 2) {
            if (!values.serviceID) values.serviceID = serviceId;
        }
        this.props.addTemplateToServer(values).then(() => {
            if (modal == 2) {
                this.props.setModalBasic2({ visible: false });
                if (serviceId) {
                    this.props.getListDiagnose(serviceId);
                }
                if (idIdc) {
                    this.props.getListIcdTemp(idIdc);
                }
            } else {
                this.props.getTemplateFromServer(getTemplateSearch());
                this.props.setModalBasic({ visible: false });
            }
        });
    };

    onChange = (name, value) => {
        if (name === "text") {
            this.setState({ text: value });
        }
        if (name === "result") {
            this.setState({ result: value });
        }
        if (name === "proposal") {
            this.setState({ proposal: value });
        }
    };

    render() {
        const { dropdownServices, modal, serviceId } = this.props;
        return (
            <Layout className="doctor-add-form-content">
                <Header>
                    <p className="modalBasic-title">Thêm mới mẫu kết quả</p>
                    <button
                        className="modalBasic-btn-close"
                        onClick={() => {
                            if (modal === 2) {
                                this.props.setModalBasic2({ visible: false });
                            } else {
                                this.props.setModalBasic({ visible: false });
                            }
                        }}
                    >
                        <i className="fas fa-times icon"></i>
                    </button>
                </Header>
                <Content>
                    <Form
                        style={{ marginTop: 20 }}
                        {...formItemLayout}
                        name="addTemplateResult"
                        onFinish={this.onFinish}
                        initialValues={{ serviceID: serviceId ?? dropdownServices[0]?.id ?? 1 }}
                        scrollToFirstError
                    >
                        <Form.Item name="name" label="Tên" rules={[{ required: true, message:"vui lòng nhập tên!" }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="serviceID" label="Dich vụ" valuePropName="value" rules={[{ required: true, message:"vui lòng nhập dịch vụ!" }]}>
                            <Select placeholder="Chọn dịch vụ">
                                {dropdownServices.map((item) => (
                                    <Option value={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="tech" label="Kỹ thuật">
                            <Input />
                        </Form.Item>
                        <Form.Item name="text" label="Mô tả" className="ck-style-custom">
                            <CKEditor
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.onChange("text", data);
                                }}
                                config={toolbarConfig}
                                editor={window.ClassicEditor}
                                data={this.state.text}
                            />
                        </Form.Item>
                        <Form.Item name="result" label="Kết quả">
                            <CKEditor
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.onChange("result", data);
                                }}
                                // config={{ toolbar: [] }}
                                config={toolbarConfig}
                                editor={window.ClassicEditor}
                                data={this.state.result}
                            />
                        </Form.Item>
                        <Form.Item name="proposal" label="Khuyến nghị">
                            <CKEditor
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.onChange("proposal", data);
                                }}
                                // config={{ toolbar: [] }}
                                config={toolbarConfig}
                                editor={window.ClassicEditor}
                                data={this.state.proposal}
                            />
                        </Form.Item>
                        <Form.Item name="price" label="Giá" valuePropName="value">
                            <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>
                        <div style={{ display: "flex" }}>
                            <div style={{ flex: 1 }}></div>
                            <div style={{ flex: 7 }}>
                                <Button style={{ display: "none" }} id="btn-submit-add-template" htmlType={"submit"}>
                                    Thêm mới
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Content>
                <Footer>
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            document.getElementById("btn-submit-add-template").click();
                        }}
                    >
                        Thêm mới
                    </Button>
                </Footer>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        dropdownServices: state.template.dropdownServices,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        setModalBasic2: (data) => dispatch(setModalBasic2(data)),
        addTemplateToServer: (data) => dispatch(addTemplateToServer(data)),
        getTemplateFromServer: (data) => dispatch(getTemplateFromServer(data)),
        getDropdownServices: () => dispatch(getDropdownServices()),
        getListDiagnose: (id) => dispatch(getListDiagnose(id)),
        getListIcdTemp: (id) => dispatch(getListIcdTemp(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTemplate);
