import React, { Component } from "react";
import { Button, Select, Checkbox, Layout, message, notification, Popconfirm, Tooltip, } from "antd";
import { connect } from "react-redux";
import { removeVietnameseTones } from "../../utils";
import { setLoadingWelcomePage } from "../../reducers/uiReducer";
import { delService, fetchDropdownRoom, getListOders, getWorklist, updateExecutor, updateRoom } from "../../reducers/welcomeReducer";
import { fetchDropdownExecutor } from "../../reducers/staticStudiesReducer";
import { getWorklistSearch } from "./Model/worklistModel";
import moment from "moment";
import "./WelcomePage.scss";
import FontAwesome from "react-fontawesome";
import { setupAxiosInterceptors } from "../../utils/setupAxiosInterceptors";
import printJS from "print-js";
import Axios from "axios";
const { Option } = Select;
class OrderItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            room: this.props.orderItem.room,
            executor: this.props.orderItem.executor,
            worklistSearch: this.props.worklistSearch,
        };
    }
    onDelete = async (id, key) => {
        this.props.setLoadingWelcomePage(true);
        const result = await this.props.delService(id);
        if (result) {
            if (key == 1) {
                await this.props.getWorklist(this.codeRequest);
            } else {
                await this.props.getListOders(getWorklistSearch());
            }
            notification["success"]({
                message: 'Thành công!',
                description: "Hủy chỉ định thành công!"
            })
        }
        this.props.setLoadingWelcomePage(false);
    };
    componentDidUpdate(previousProps, previousState) {
        if (previousProps.orderItem !== this.props.orderItem) {
            this.setState(
                {
                    room: this.props.orderItem.room,
                    executor: this.props.orderItem.executor,
                    worklistSearch: this.props.worklistSearch,
                }
            )
        }
    }
    onChangeRoom = (room, wlid) => {
        if (!localStorage.getItem("roomToExe")) {
            this.props.updateRoom({ room, wlid });
        } else {
            let mang = [];
            try {
                mang = JSON.parse(localStorage.getItem("roomToExe"));
            } catch {
                console.log("loi")
            }
            if (mang.length > 0) {
                let giatri = mang.find(v => v.room == room);
                if (giatri) {
                    let checkExe = this.props.listExecutor.find(x => x.id == giatri.executor);
                    if (checkExe) {
                        this.props.updateExecutor({ executor: checkExe.id, wlid });
                        this.setState({ executor: checkExe.id });
                    }
                    this.props.updateRoom({ room, wlid });
                } else
                    this.props.updateRoom({ room, wlid });
            }
        }
        this.setState({ room: room });
    };
    onChangeExecutor = (executor, wlid) => {
        if (this.state.room) {
            if (!localStorage.getItem("roomToExe")) {
                let mang = [];
                mang.push({
                    room: this.state.room,
                    executor: executor
                });
                localStorage.setItem("roomToExe", JSON.stringify(mang));
                this.props.updateRoom({ room: this.state.room, wlid });
                this.props.updateExecutor({ executor, wlid });
            } else {
                try {
                    let mang = JSON.parse(localStorage.getItem("roomToExe"));
                    let checkRoom = mang.findIndex(v => v.room == this.state.room);
                    if (checkRoom != -1) {
                        mang[checkRoom] = {
                            executor,
                            room: this.state.room
                        }
                    } else {
                        mang.push({
                            room: this.state.room,
                            executor: executor
                        });
                    }
                    localStorage.setItem("roomToExe", JSON.stringify(mang));
                    this.props.updateExecutor({ executor, wlid });
                } catch {
                    this.props.updateExecutor({ executor, wlid });
                }
            }
        } else {
            let data = {
                executor: executor,
                wlid: wlid,
            }
            this.props.updateExecutor(data);
        }
        this.setState({ executor: executor });
    };
    onPrintCouponPatient = async () => {
        try {
            let path;
            if (window.location.protocol === "https:") {
                path = window.HTTPS_API_SERVER_RIS_URL;
            } else {
                path = window.API_SERVER_RIS_URL;

            }
            // console.log(path);
            const htmlTemplate = await Axios.create({ baseURL: undefined }).get(
                `${path}/Content/template/MauPhieuBenhNhan.html?v=${Math.random()}`,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );

            // const htmlTemplate = await Axios.create({ baseURL: null }).get("/template/coupon/MauPhieuBenhNhan.html"); dev env
            // console.log("htmlTemplate", htmlTemplate);
            await this.props.setTemplate(htmlTemplate.data);
            // console.log({
            //     printable: document.querySelector("#coupon_print")?.outerHTML,
            //     style: document.querySelector("#view-html-tempalte style")?.innerHTML,
            // });
            const data = this.props.orderItem;
            // console.log(data);
            window.printCoupon(data);
            await printJS({
                printable: document.querySelector("#coupon_print")?.outerHTML,
                type: "raw-html",
                style: document.querySelector("#wrapper-preview-template style")
                    ?.innerHTML,
            });
        } catch (error) {
            if (error.response?.status === 404) {
                notification.error({
                    message: "Có lỗi xảy ra",
                    description: "Không tìm thấy file mẫu phiếu",
                    placement: "bottomRight",
                    duration: 1.5,
                    style: {
                        width: "300px",
                    },
                })
            } else {

                notification.error({
                    message: "Có lỗi xảy ra",
                    placement: "bottomRight",
                    duration: 1.5,
                    style: {
                        width: "300px",
                    },
                })
            }
        }
    };

    render() {
        const { index, orderItem } = this.props;
        const { listExecutor, listRoom } = this.props;
        const { room, executor, worklistSearch } = this.state;
        let id = (worklistSearch.PageIndex - 1) * worklistSearch.PageSize + index + 1;
        return (
            <tr className="tr" key={index}>
                <td>{id}</td>
                <td>{orderItem.maLanKham}</td>
                <td>{orderItem.pName}</td>
                <td>{orderItem.pCode}</td>
                <td>{moment.utc(orderItem.createTime).format("DD/MM/YYYY HH:mm:ss")}</td>
                <td>{orderItem.serviceName}</td>
                <td >
                    <Select onChange={(e) => this.onChangeRoom(e, orderItem.wlid)}
                        showSearch
                        value={room ? room : "Chọn Phòng"}
                        style={{ width: 100 }}
                        filterOption={(input, option) => {
                            option.children = removeVietnameseTones(option.children);
                            input = removeVietnameseTones(input);
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        }
                    >
                        {listRoom.map((it) => (
                            <Option key={it} >{it}</Option>
                        ))}
                    </Select>
                </td>
                <td >
                    <Select onSelect={(e) => this.onChangeExecutor(e, orderItem.wlid)}
                        showSearch
                        style={{ width: 200 }}
                        value={executor ? executor : "Chọn KTV"}
                        filterOption={(input, option) => {
                            option.children = removeVietnameseTones(option.children);
                            input = removeVietnameseTones(input);
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        }
                    >
                        {listExecutor.map((item) => (
                            <Option key={item.id} value={item.id}>
                                {item.name}
                            </Option>
                        ))}
                    </Select>
                </td>
                <td style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                }}>
                    <Popconfirm
                        placement="topRight"
                        title={`Xác nhận hủy chỉ định ${orderItem.serviceName}`}
                        onConfirm={() => this.onDelete(orderItem.sCode, 2)}
                        okText="Ok"
                        cancelText="Cancel"
                    >
                        <Button >
                            <FontAwesome name="undo-alt" style={{ color: "red" }} />
                        </Button>
                    </Popconfirm>

                    <Tooltip title={"in phiếu bệnh nhân"}>
                        <Button
                            onClick={this.onPrintCouponPatient}

                        >
                            <i className="fas fa-print"></i>
                        </Button>
                    </Tooltip>
                </td>
            </tr>
        );
    }
}
function mapStateToProps(state) {
    return {
        listExecutor: state.worklist.listExecutor,
        listRoom: state.worklist.listRoom,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        setLoadingWelcomePage: val => dispatch(setLoadingWelcomePage(val)),
        getWorklist: (code) => dispatch(getWorklist(code)),
        getListOders: (code) => dispatch(getListOders(code)),
        delService: (code) => dispatch(delService(code)),
        fetchDropdownExecutor: () => dispatch(fetchDropdownExecutor()),
        fetchDropdownRoom: () => dispatch(fetchDropdownRoom()),
        updateExecutor: (data) => dispatch(updateExecutor(data)),
        updateRoom: (data) => dispatch(updateRoom(data)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderItem);
