import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Input, Pagination, Button, Select, message, Popconfirm } from "antd";

import { addRoleToServer,
    deleteRoleFromServer,
    getRoleFromServer,
    roleManagerReducer, } from "../../../reducers/roleManagerReducer";
import FontAwesome from "react-fontawesome";
import "./DoctorPage.scss";
import { setModalBasic } from "../../../reducers/uiReducer";
// import AddRole from "../../../components/IcdTemplate/AddRole";
import { LoadingOutlined } from "@ant-design/icons";
import ContentEditRole from "../../../components/Doctor/ContentEditRole";
import AddRoleFormContent from "../../../components/Doctor/AddRoleFormContent";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const { Option } = Select;
class RoleManager extends Component {
    componentDidMount = () => {
        this.props.getRoleFromServer();
    };


    onDelete = (id) => {
        this.props.deleteRoleFromServer(id).then(() => {
            this.props.getRoleFromServer();
        });
    };
    onAdd = () => {
        const modalData = {
            visible: true,
            width: 900,
            content: <AddRoleFormContent />,
            name: "role-add",
        };
        this.props.setModalBasic(modalData);
    };

    onChangePage = () => {
        this.props.getRoleFromServer();
    };
    onEdit = (roleId, roleName) => {
        const modalData = {
            visible: true,
            width: 900,
            content: <ContentEditRole   roleId={roleId} roleName={roleName} />,
            name: "modal--role-edit",
        };
        this.props.setModalBasic(modalData);
    };
    // onFinish = (values) => {
    //     this.props.getRoleFromServer();
    // };

    render() {
        const { data } = this.props;
        return (
            <div className="doctor-page-d">
                <div className="doctor-search-d">
                    <Link to="/doctor">
                        <Button
                        style={{ background: "#2a274c", color: "#fff",marginRight:10 }}
                        onClick={() => localStorage.setItem("router", "doctor")}
                        icon = {<FontAwesome name="arrow-left" className="icon-fontawesome" />}
                        >
                        </Button>
                    </Link>
                    <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.onAdd}>
                        Thêm mới
                    </Button>
                </div>
                <div className={"doctor-table-d"}>
                    <table border="0" className="table-admin">
                        <thead>
                            <tr>
                                <th>Tên quyền</th>
                                <th>Mô tả</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Quyền hệ thống</th>
                                <th></th>
                                <th></th>
                            </tr>
                            {data.map((role, index) => {
                                if(role.name == "DoctorOnlyRead" ||role.name == "DoctorApproved" || role.name == "Admin" || role.name == "DoctorsConsultation" || role.name == "Technician" || role.name == "SuperAdmin"){
                                    
                                    return (
                                        <tr key={index}>
                                            <td>{role.name}</td>
                                            <td>{role.description}</td>
                                            <td>
                                            <div className="tools">
                                                    <Button onClick={() => this.onEdit(role.id, role.name)}>
                                                        <FontAwesome name="edit" />
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                }  
                            })}
                            <tr>
                                <th>Quyền tùy chỉnh</th>
                                <th></th>
                                <th></th>
                            </tr>
                            {data.map((role, index) => {
                                if(role.name != "DoctorOnlyRead" && role.name != "DoctorApproved" && role.name != "Admin" && role.name != "DoctorsConsultation" && role.name != "Technician" && role.name != "SuperAdmin"){
                                    
                                    return (
                                        <tr key={index}>
                                            <td>{role.name}</td>
                                            <td>{role.description}</td>
                                            <td>
                                            <div className="tools">
                                                    <Button onClick={() => this.onEdit(role.id, role.name)}>
                                                        <FontAwesome name="edit" />
                                                    </Button>
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title={`Xác nhận xóa ${role.name}`}
                                                        onConfirm={() => this.onDelete(role.id)}
                                                        okText="Ok"
                                                        cancelText="Cancel"
                                                    >
                                                        <Button>
                                                            <FontAwesome name="trash-alt" />
                                                        </Button>
                                                    </Popconfirm>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                }  
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        data: state.role.roles,//2-2
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getRoleFromServer: () => dispatch(getRoleFromServer()),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        deleteRoleFromServer: (id) => dispatch(deleteRoleFromServer(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleManager);
