import Axios from "axios";
import { message } from "antd";
const ACTION_TYPES = {
    SET_CONFIGURATION: "SET_CONFIGURATION",
    SET_CONFIGURATION_ITEM: "SET_CONFIGURATION_ITEM",
};

const initialstate = {
    configurations: [],
    totalCount: 0,
    configurationItem: {},
};

const configurationReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_CONFIGURATION:
            state = {...state };
            state.configurations = action.payload.data;
            state.totalCount = action.payload.dataCount;
            return {...state };
        case ACTION_TYPES.SET_CONFIGURATION_ITEM:
            state.configurationItem = {...action.payload};
            return {...state };
        default:
            return state;
    }
};
const setConfigurations = (configurations) => {
    return {
        type: ACTION_TYPES.SET_CONFIGURATION,
        payload: configurations,
    };
};
const getConfigurationFromServer = (request) => async(dispatch) => {
    const response = await Axios.get(
        `api/Configs/paging?Keyword=${request.Keyword}&PageIndex=${request.PageIndex}&PageSize=${request.PageSize}`
    );
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setConfigurations(response.data));
        }
    } else {
        message.error(response.status);
    }
};

const setConfigurationItem = (configuration) => {
    return {
        type: ACTION_TYPES.SET_CONFIGURATION_ITEM,
        payload: configuration,
    };
};
const getConfigurationById = (id) => async(dispatch) => {
    const response = await Axios.get(`api/Configs/${id}`);
    if (response.data.status === 1) {
        dispatch(setConfigurationItem(response.data.data));
    }
};

const updateConfigurationToServer = (id, configuration) => async(dispatch) => {
    await Axios.put(`api/Configs/${id}`, configuration)
        .then((response) => {
            if (response.data) {
                message.success(response.data.message);
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let messages = "";
                if (e.Email) {
                    for (let i of e.Email) {
                        messages += i + "! ";
                    }
                }
                if (e.PhoneNo) {
                    for (let i of e.PhoneNo) {
                        messages += i + "! ";
                    }
                }
                if (e.Name) {
                    for (let i of e.Name) {
                        messages += i + "! ";
                    }
                }
                message.error(messages, 5);
            }
        });
};

const addConfigurationToServer = (request) => async(dispatch) => {
    await Axios.post("api/Configs", request)
        .then((response) => {
            if (response.status === 200) {
                if (response.data.status === 1)
                    message.success("Successfully!");
                else
                    message.error(response.data.message);
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let mes = "";
                if (e.configName) {
                    for (let i of e.configName) {
                        mes += i + "! ";
                    }
                }
                if (e.configCode) {
                    for (let i of e.configName) {
                        mes += i + "! ";
                    }
                }
                message.error(mes, 3);
            }
        });
};

const deleteConfigurationFromServer = (id) => async(dispatch) => {
    await Axios.delete(`api/Configs/${id}`)
        .then((response) => {
            message.success(response.data.message);
        })
        .catch((err) => {
            message.error("Bạn không có quyền xóa!");
        });
};
export {
    addConfigurationToServer,
    deleteConfigurationFromServer,
    getConfigurationFromServer,
    configurationReducer,
    getConfigurationById,
    updateConfigurationToServer,
    setConfigurationItem,
};