import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, message, Checkbox , Layout, Select, Tooltip} from "antd";
import {
    getDoctorRoleFromServer,
    setDoctorRoleItem,
    conversePermissionObjectToString,
    updateDoctorRoleToServer,
    fetchDoctorRoleById,
    getDropdownAreaFromServer,
    getDropdownHospitalFromServer,
    getDropdownServerpacsFromServer,
} from "../../reducers/doctorroleReducer";
import { connect } from "react-redux";
import moment from "moment";
import { handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
import { removeVietnameseTones } from "../../utils";
import { deaultDoctorRoleForm, getDoctorRoleSearch } from "../../pages/Home/DoctorRolePage/doctorrole";
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const { Content, Footer, Header } = Layout;
const { Option } = Select;
class ContentEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //listHospitals: [],
            stateDoctorroleItem:[],
            areaID:-1,
            hospitalID:-1,
            serverpacsID:-1,
        };
    }

    componentDidMount = async () => {
        await this.props.fetchDoctorRoleById(this.props.userId);
        await this.props.getDropdownAreaFromServer();
        await this.props.getDropdownHospitalFromServer(-1);
        await this.props.getDropdownServerpacsFromServer(-1,-1);
        this.setState({
            stateDoctorroleItem: this.props.doctorroleItem,
        });

        // const { doctorroleItem } = this.props;
        // if(doctorroleItem.listServerpacs)
        // {
        //     let listAreaFull = [];
        //     let listHospitalFull = [];
        //     let listServerPacsFull = [];
        //     doctorroleItem.listServerpacs.forEach((element) => {
        //         listAreaFull.push(element.areaName);
        //         listHospitalFull.push(element.hosName);
        //         listServerPacsFull.push(element.name);
        //     });
        //     this.setState({
        //         listHospital : listHospitalFull.filter(this.onlyUnique),
        //         listArea : listAreaFull.filter(this.onlyUnique),
        //         listServerPacs : listServerPacsFull.filter(this.onlyUnique)
        //     });
            
        // }
    }
    onChangePermisson = (id, name) => {
        const { doctorroleItem } = this.props;
        const newItem = { ...doctorroleItem };
        newItem.listServerpacs.forEach((element) => {
            if (element.id === id) {
                element.permissionData[name] = !element.permissionData[name];
            }
        });
        
        //change state doctorRole hiển thị
        // const { stateDoctorroleItem } = this.state;
        // let newStateDoctorroleItem = { ...stateDoctorroleItem };
        // newStateDoctorroleItem.listServerpacs.forEach((element) => {
        //     if (element.id === id) {
        //         console.log(element.permissionData[name]);
        //         element.permissionData[name] = !element.permissionData[name];
        //         console.log(element.permissionData[name]); 
        //     }
        // });
        // this.setState({
        //     stateDoctorroleItem:newStateDoctorroleItem
        // });
        this.props.setDoctorRoleItem(newItem);
    };

    onSave = async () => {
        const { doctorroleItem } = this.props;
        const newItem = { ...doctorroleItem };
        newItem.listServerpacs.forEach((element) => {
            const permissionsString = conversePermissionObjectToString(element.permissionData);
            element.permissions = permissionsString;
            permissionsString.length > 0 ? (element.selected = true) : (element.selected = false);
        });
        await this.props.updateDoctorRoleToServer(newItem.userId, newItem.listServerpacs);
        this.props.getDoctorRoleFromServer(getDoctorRoleSearch());
        this.props.setModalBasic({ visible: false })
    };
    onActiveAllArea = (areaID) => {
        const { doctorroleItem } = this.props;
        const newDoctorRoleItem = {...doctorroleItem};
        let checkedAllRow = true;
        const {serverpacsID, hospitalID} = this.state;
        if(serverpacsID != -1){
            newDoctorRoleItem.listServerpacs.forEach((item) => {
                if(item.areaId === areaID && item.id == serverpacsID){
                    const listRole = Object.getOwnPropertyNames(doctorroleItem?.listServerpacs[0].permissionData);
                    listRole.forEach((i) => {
                        if(item.permissionData[i] === false){
                            checkedAllRow = false;
                        }
                    })
                }
            })
            newDoctorRoleItem.listServerpacs.forEach((item) => {
                if(item.areaId === areaID && item.id == serverpacsID){
                    const listRole = Object.getOwnPropertyNames(doctorroleItem?.listServerpacs[0].permissionData);
                    if(checkedAllRow){
                        listRole.forEach((i) => {
                            item.permissionData[i] = false;
                        })
                    }else{
                        listRole.forEach((i) => {
                            item.permissionData[i] = true;
                        })
                    }
                }
            })
        }else if(hospitalID != -1){
            newDoctorRoleItem.listServerpacs.forEach((item) => {
                if(item.areaId === areaID && item.hosId == hospitalID){
                    const listRole = Object.getOwnPropertyNames(doctorroleItem?.listServerpacs[0].permissionData);
                    listRole.forEach((i) => {
                        if(item.permissionData[i] === false){
                            checkedAllRow = false;
                        }
                    })
                }
            })
            newDoctorRoleItem.listServerpacs.forEach((item) => {
                if(item.areaId === areaID && item.hosId == hospitalID){
                    const listRole = Object.getOwnPropertyNames(doctorroleItem?.listServerpacs[0].permissionData);
                    if(checkedAllRow){
                        listRole.forEach((i) => {
                            item.permissionData[i] = false;
                        })
                    }else{
                        listRole.forEach((i) => {
                            item.permissionData[i] = true;
                        })
                    }
                }
            })
        }else{
            newDoctorRoleItem.listServerpacs.forEach((item) => {
                if(item.areaId === areaID){
                    const listRole = Object.getOwnPropertyNames(doctorroleItem?.listServerpacs[0].permissionData);
                    listRole.forEach((i) => {
                        if(item.permissionData[i] === false){
                            checkedAllRow = false;
                        }
                    })
                }
            })
            newDoctorRoleItem.listServerpacs.forEach((item) => {
                if(item.areaId === areaID){
                    const listRole = Object.getOwnPropertyNames(doctorroleItem?.listServerpacs[0].permissionData);
                    if(checkedAllRow){
                        listRole.forEach((i) => {
                            item.permissionData[i] = false;
                        })
                    }else{
                        listRole.forEach((i) => {
                            item.permissionData[i] = true;
                        })
                    }
                }
            })
        }
        
        this.props.setDoctorRoleItem(newDoctorRoleItem);
    };
    onActiveAllHospital = (hospitalID) => {
        const { doctorroleItem } = this.props;
        const {serverpacsID} = this.state;
        const newDoctorRoleItem = {...doctorroleItem};
        let checkedAllRow = true;
        if(serverpacsID != -1){
            newDoctorRoleItem.listServerpacs.forEach((item) => {
                if(item.hosId === hospitalID && item.id == serverpacsID){
                    const listRole = Object.getOwnPropertyNames(doctorroleItem?.listServerpacs[0].permissionData);
                    listRole.forEach((i) => {
                        if(item.permissionData[i] === false){
                            checkedAllRow = false;
                        }
                    })
                }
            })
            newDoctorRoleItem.listServerpacs.forEach((item) => {
                if(item.hosId === hospitalID && item.id == serverpacsID){
                    const listRole = Object.getOwnPropertyNames(doctorroleItem?.listServerpacs[0].permissionData);
                    if(checkedAllRow){
                        listRole.forEach((i) => {
                            item.permissionData[i] = false;
                        })
                    }else{
                        listRole.forEach((i) => {
                            item.permissionData[i] = true;
                        })
                    }
                }
            })
        }else {
            newDoctorRoleItem.listServerpacs.forEach((item) => {
                if(item.hosId === hospitalID){
                    const listRole = Object.getOwnPropertyNames(doctorroleItem?.listServerpacs[0].permissionData);
                    listRole.forEach((i) => {
                        if(item.permissionData[i] === false){
                            checkedAllRow = false;
                        }
                    })
                }
            })
            newDoctorRoleItem.listServerpacs.forEach((item) => {
                if(item.hosId === hospitalID){
                    const listRole = Object.getOwnPropertyNames(doctorroleItem?.listServerpacs[0].permissionData);
                    if(checkedAllRow){
                        listRole.forEach((i) => {
                            item.permissionData[i] = false;
                        })
                    }else{
                        listRole.forEach((i) => {
                            item.permissionData[i] = true;
                        })
                    }
                }
            })
        }
        
        this.props.setDoctorRoleItem(newDoctorRoleItem);
    };
    onActiveAllMachine = (id) => {
        const { doctorroleItem } = this.props;
        const newDoctorRoleItem = {...doctorroleItem};
        newDoctorRoleItem.listServerpacs.forEach((item) => {
            if(item.id === id){
                const listRole = Object.getOwnPropertyNames(doctorroleItem?.listServerpacs[0].permissionData);
                let checkedAllRow = true;
                listRole.forEach((i) => {
                    if(item.permissionData[i] === false){
                        checkedAllRow = false;
                    }
                })

                if(checkedAllRow){
                    listRole.forEach((i) => {
                        item.permissionData[i] = false;
                    })
                }else{
                    listRole.forEach((i) => {
                        item.permissionData[i] = true;
                    })
                }
            }
        })
        this.props.setDoctorRoleItem(newDoctorRoleItem);
        //change state doctorRole hiển thị
        // const { stateDoctorroleItem } = this.state;
        // const newStateDoctorroleItem= {...stateDoctorroleItem};
        // newStateDoctorroleItem.listServerpacs.forEach((item) => {
        //     if(item.id === id){
        //         const listRole = Object.getOwnPropertyNames(stateDoctorroleItem?.listServerpacs[0].permissionData);
        //         let checkedAllRow = true;
        //         listRole.forEach((i) => {
        //             if(item.permissionData[i] === false){
        //                 checkedAllRow = false;
        //             }
        //         })

        //         if(checkedAllRow){
        //             listRole.forEach((i) => {
        //                 item.permissionData[i] = false;
        //             })
        //         }else{
        //             listRole.forEach((i) => {
        //                 item.permissionData[i] = true;
        //             })
        //         }
        //     }
        // })
        // this.setState({
        //     stateDoctorroleItem:newStateDoctorroleItem
        // });
    };

    onActiveAllRole = (role) => {
        const { doctorroleItem } = this.props;
        const newDoctorRoleItem = { ...doctorroleItem };
        const {areaID, hospitalID,serverpacsID} = this.state;
        let checkAllColumn = true;
        if(serverpacsID != -1){
            newDoctorRoleItem.listServerpacs.forEach((svp) => {
                if(svp.id == serverpacsID ){
                    if (svp.permissionData[role] === false) {
                        checkAllColumn = false;
                    }
                }
            });
    
            if (checkAllColumn) {
                newDoctorRoleItem.listServerpacs.forEach((svp) => {
                    if(svp.id == serverpacsID ){
                        svp.permissionData[role] = false;
                    }
                });
            } else {
                newDoctorRoleItem.listServerpacs.forEach((svp) => {
                    if(svp.id == serverpacsID){
                        svp.permissionData[role] = true;
                    }
                });
            }
        } else if(hospitalID != -1){
            newDoctorRoleItem.listServerpacs.forEach((svp) => {
                if(svp.hosId == hospitalID ){
                    if (svp.permissionData[role] === false) {
                        checkAllColumn = false;
                    }
                }
            });
    
            if (checkAllColumn) {
                newDoctorRoleItem.listServerpacs.forEach((svp) => {
                    if(svp.hosId == hospitalID ){
                        svp.permissionData[role] = false;
                    }
                });
            } else {
                newDoctorRoleItem.listServerpacs.forEach((svp) => {
                    if(svp.hosId == hospitalID){
                        svp.permissionData[role] = true;
                    }
                });
            }
        }
        else if (areaID != -1){
            newDoctorRoleItem.listServerpacs.forEach((svp) => {
                if(svp.areaId == areaID ){
                    if (svp.permissionData[role] === false) {
                        checkAllColumn = false;
                    }
                }
            });
    
            if (checkAllColumn) {
                newDoctorRoleItem.listServerpacs.forEach((svp) => {
                    if(svp.areaId == areaID ){
                        svp.permissionData[role] = false;
                    }
                });
            } else {
                newDoctorRoleItem.listServerpacs.forEach((svp) => {
                    if(svp.areaId == areaID){
                        svp.permissionData[role] = true;
                    }
                });
            }
        }else{
            newDoctorRoleItem.listServerpacs.forEach((svp) => {
                if (svp.permissionData[role] === false) {
                    checkAllColumn = false;
                }
            });
    
            if (checkAllColumn) {
                newDoctorRoleItem.listServerpacs.forEach((svp) => {
                    svp.permissionData[role] = false;
                });
            } else {
                newDoctorRoleItem.listServerpacs.forEach((svp) => {
                    svp.permissionData[role] = true;
                });
            }
        }
        this.props.setDoctorRoleItem(newDoctorRoleItem);
        
        //change state doctorRole hiển thị
        // const { stateDoctorroleItem } = this.state;
        // const newStateDoctorroleItem = { ...stateDoctorroleItem };
        // newStateDoctorroleItem.listServerpacs.forEach((svp) => {
        //     if (svp.permissionData[role] === false) {
        //         checkAllColumn = false;
        //     }
        // });

        // if (checkAllColumn) {
        //     newStateDoctorroleItem.listServerpacs.forEach((svp) => {
        //         svp.permissionData[role] = false;
        //     });
        // } else {
        //     newStateDoctorroleItem.listServerpacs.forEach((svp) => {
        //         svp.permissionData[role] = true;
        //     });
        // }
        // this.setState({
        //     stateDoctorroleItem:newStateDoctorroleItem
        // })
    };
    onChangeArea = async (value) => {
        if(value == undefined){
            value = -1;
        }
        const { doctorroleItem } = this.props;
        this.setState({
            areaID:value,
            hospitalID:-1,
            serverpacsID:-1,
        })
        if(value == -1){
            await this.props.getDropdownHospitalFromServer(-1);
            await this.props.getDropdownServerpacsFromServer(-1, -1);
            const newStateDoctorroleItem = { ... doctorroleItem };
            this.setState({stateDoctorroleItem:newStateDoctorroleItem});
        }
        else
        {
            await this.props.getDropdownHospitalFromServer(value);
            await this.props.getDropdownServerpacsFromServer(value, -1);
            const newStateDoctorroleItem = { ... doctorroleItem };
            const newSVP = [];
            doctorroleItem.listServerpacs.forEach((svp) => {
                if (svp.areaId == value) {
                    newSVP.push(svp);
                }
            });
            newStateDoctorroleItem.listServerpacs = newSVP;
            this.setState({
                stateDoctorroleItem:newStateDoctorroleItem,
            });
        }
    }
    onChangeHospital = async (value) => {
        if(value == undefined){
            value = -1;
        }
        const { doctorroleItem } = this.props;
        const {areaID} = this.state;
        this.setState({
            hospitalID:value,
            serverpacsID:-1,
        })
        if(value == -1){
            if(areaID == -1){
                await this.props.getDropdownServerpacsFromServer(-1, -1);
                const newStateDoctorroleItem = { ... doctorroleItem };
                this.setState({stateDoctorroleItem:newStateDoctorroleItem});
            }
            else {
                await this.props.getDropdownServerpacsFromServer(areaID, -1);
                const newStateDoctorroleItem = { ... doctorroleItem };
                const newSVP = [];
                doctorroleItem.listServerpacs.forEach((svp) => {
                    if (svp.areaId == areaID) {
                        newSVP.push(svp);
                    }
                });
                newStateDoctorroleItem.listServerpacs = newSVP;
                this.setState({stateDoctorroleItem:newStateDoctorroleItem});
            }
        }
        else
        {
            await this.props.getDropdownServerpacsFromServer(areaID, value);
            const newStateDoctorroleItem = { ... doctorroleItem };
            const newSVP = [];
            doctorroleItem.listServerpacs.forEach((svp) => {
                if (svp.hosId == value) {
                    newSVP.push(svp);
                }
            });
            newStateDoctorroleItem.listServerpacs = newSVP;
            this.setState({
                stateDoctorroleItem:newStateDoctorroleItem,
            });
        }
    }
    onChangeServerPacs = async(value) => {
        if(value == undefined){
            value = -1;
        }
        const { doctorroleItem } = this.props;
        const {areaID,hospitalID} = this.state;
        await this.props.getDropdownServerpacsFromServer(areaID, hospitalID);
        this.setState({
            serverpacsID:value,
        })
        if(value == -1){
            if(areaID == -1 && hospitalID == -1){
                const newStateDoctorroleItem = { ... doctorroleItem };
                const newSVP = [];
                doctorroleItem.listServerpacs.forEach((svp) => {
                    newSVP.push(svp);
                });
                newStateDoctorroleItem.listServerpacs = newSVP;
                this.setState({stateDoctorroleItem:newStateDoctorroleItem});
            }
            else if(hospitalID == -1){//co area
                const newStateDoctorroleItem = { ... doctorroleItem };
                const newSVP = [];
                doctorroleItem.listServerpacs.forEach((svp) => {
                    if (svp.areaId == areaID) {
                        newSVP.push(svp);
                    }
                });
                newStateDoctorroleItem.listServerpacs = newSVP;
                this.setState({stateDoctorroleItem:newStateDoctorroleItem});
            }
            else {
                const newStateDoctorroleItem = { ... doctorroleItem };
                const newSVP = [];
                doctorroleItem.listServerpacs.forEach((svp) => {
                    if (svp.hosId == hospitalID) {
                        newSVP.push(svp);
                    }
                });
                newStateDoctorroleItem.listServerpacs = newSVP;
                this.setState({stateDoctorroleItem:newStateDoctorroleItem});
            }
        }
        else
        {
            const newStateDoctorroleItem = { ... doctorroleItem };
            const newSVP = [];
            doctorroleItem.listServerpacs.forEach((svp) => {
                if (svp.id == value) {
                    newSVP.push(svp);
                }
            });
            newStateDoctorroleItem.listServerpacs = newSVP;
            this.setState({stateDoctorroleItem:newStateDoctorroleItem});
        }
    }
    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    render() {
        const { doctorroleItem } = this.props;
        const {stateDoctorroleItem, areaID, hospitalID, serverpacsID} = this.state;
        let curArea = -1;
        const {listArea, listHospital, listServerpacs} = this.props;
        const title = {
            Clinical: "Chỉ xem",
            Read: "Đọc",
            Approve: "Duyệt",
            Edit: "Chỉnh sửa",
            Delete: "Xóa",
            UpdateFromHis: "Cập nhật từ HIS",
            Share: "Chia sẻ",
            Statistical: "Thống kê",
            ApproveDiscard:"Hủy duyệt",
            Consult:"Hội chẩn",
            ConsultDiscard:"Hủy hội chẩn",
            DeleteSeri:"Xóa seri",
        };
        return (
            <>
                <Layout className="layout-doctor-edit">
                    <Header>
                        <p className="modalBasic-title">Sửa quyền bác sĩ</p>
                        <button
                            className="modalBasic-btn-close"
                            onClick={() => this.props.setModalBasic({ visible: false })}
                        >
                            <i className="fas fa-times icon"></i>
                        </button>
                    </Header>
                    <Content style={{padding:10}}>
                        <div className="content-edit-doctor-role form-edit-doctorRole">
                            <h3>{doctorroleItem.name}</h3>
                            <table border="0" className="table-admin">
                                <thead>
                                    <tr>
                                        <th minwidth="150px">Cơ sở</th>
                                        <th>Thư mục</th>
                                        <th>Tên</th>
                                        {doctorroleItem.listServerpacs &&
                                            Object.getOwnPropertyNames(
                                                doctorroleItem?.listServerpacs[0].permissionData
                                            ).map((j, index) => {
                                                return <th style={{cursor: 'pointer'}} onClick={() => this.onActiveAllRole(j)}>{title[j]}</th>;
                                            })}
                                    </tr>
                                    <tr>
                                        <th>
                                        <Select
                                            defaultValue={-1}
                                            value={areaID} 
                                            allowClear
                                            onChange={(value) =>
                                                this.onChangeArea(
                                                    value
                                                )
                                            }
                                            showSearch
                                            style={{ width: "100%" }}
                                            filterOption={(input, option) =>
                                                {
                                                    option.children = removeVietnameseTones(option.children);
                                                    input = removeVietnameseTones(input);
                                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                }
                                            >
                                                <Option value={-1}>Tất cả</Option>
                                                {listArea && listArea.map((item) => { 
                                                    return (
                                                        <Option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </Option>
                                                    );
                                                })}
                                        </Select>
                                        </th>
                                        <th>
                                        <Select
                                            defaultValue={-1}
                                            value={hospitalID}
                                            allowClear
                                            onChange={(value) =>
                                                this.onChangeHospital(
                                                    value
                                                )
                                            }
                                            showSearch
                                            style={{ width: "100%" }}
                                            filterOption={(input, option) =>
                                                {
                                                    option.children = removeVietnameseTones(option.children);
                                                    input = removeVietnameseTones(input);
                                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                }
                                            >
                                                <Option value={-1}>Tất cả</Option>
                                                {listHospital && listHospital.map((item) => { 
                                                    return (
                                                        <Option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </Option>
                                                    );
                                                })}
                                        </Select>
                                        </th>
                                        <th>
                                        <Select
                                            defaultValue={-1}
                                            allowClear
                                            value={serverpacsID}
                                            onChange={(value) =>
                                                this.onChangeServerPacs(
                                                    value
                                                )
                                            }
                                            showSearch
                                            style={{ width: "100%" }}
                                            filterOption={(input, option) =>
                                                {
                                                    option.children = removeVietnameseTones(option.children);
                                                    input = removeVietnameseTones(input);
                                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                }
                                            >
                                                <Option value={-1}>Tất cả</Option>
                                                {listServerpacs && listServerpacs.map((item) => { 
                                                    return (
                                                        <Option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </Option>
                                                    );
                                                })}
                                        </Select>
                                        </th>
                                        <th colspan={title.length}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stateDoctorroleItem.listServerpacs &&
                                        stateDoctorroleItem.listServerpacs.map((i) => {
                                            let div = "";
                                            if(curArea != i.areaId){
                                                div = <Tooltip title="phân quyền/bỏ phân quyền cơ sở">
                                                        <span onClick={() => this.onActiveAllArea(i.areaId)}><i className="fas fa-sync-alt icon" ></i></span>
                                                    </Tooltip>
                                                curArea = i.areaId;
                                            }
                                            return (
                                                <tr>
                                                    <td >
                                                        <div style={{display:"flex", justifyContent:"space-between",alignItems: "center"}}>
                                                            {i.areaName}
                                                            {div}
                                                        </div>
                                                    </td>
                                                    <td onClick={() => this.onActiveAllHospital(i.hosId)}>{i.hosName}</td>
                                                    <td onClick={() => this.onActiveAllMachine(i.id)}>{i.name}</td>
                                                    {Object.getOwnPropertyNames(i.permissionData).map((j, index) => {
                                                        return (
                                                            <td>
                                                                <Checkbox
                                                                    key={index}
                                                                    checked={i.permissionData[j]}
                                                                    onChange={() => this.onChangePermisson(i.id, j)}
                                                                ></Checkbox>
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </Content>
                    <Footer>
                        <Button style={{ color: "#fff", background: "#2687d4" }} onClick={this.onSave}>
                            Save
                        </Button>
                    </Footer>
                </Layout>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        doctorroleItem: state.doctorrole.doctorroleItem,
        listArea: state.doctorrole.listDropdownArea,
        listHospital: state.doctorrole.listDropdownHospital,
        listServerpacs: state.doctorrole.listDropdownServerPacs,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateDoctorRoleToServer: (id, doctorrole) => dispatch(updateDoctorRoleToServer(id, doctorrole)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        getDoctorRoleFromServer: (search) => dispatch(getDoctorRoleFromServer(search)),
        setDoctorRoleItem: (value) => dispatch(setDoctorRoleItem(value)),
        updateDoctorRoleToServer: (uId, listServerpacs) => dispatch(updateDoctorRoleToServer(uId, listServerpacs)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        fetchDoctorRoleById: (id) => dispatch(fetchDoctorRoleById(id)),
        getDropdownAreaFromServer: () => dispatch(getDropdownAreaFromServer()),
        getDropdownHospitalFromServer: (areaID) => dispatch(getDropdownHospitalFromServer(areaID)),
        getDropdownServerpacsFromServer: (areaID,hospitalID) => dispatch(getDropdownServerpacsFromServer(areaID,hospitalID)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentEdit);
