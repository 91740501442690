import React, { Component } from "react";
import { connect } from "react-redux";
import { getPrintResultFromServer, deletePrintResultFromServer } from "../../../reducers/printresultReducer";
import { getPrintResultSearch, printresultSeachDefault, setPrintResultSearch } from "./printresult";
import { handleOpenModalApp, setModalBasic } from "../../../reducers/uiReducer";
import AddFormContent from "../../../components/PrintResult/AddFormContent";
import ContentEdit from "../../../components/PrintResult/ContentEdit";
import { Form, Input, Popconfirm, Pagination, Button,Image } from "antd";
import FontAwesome from "react-fontawesome";
import "./PrintResultPage.scss"
class PrintResultPage extends Component {
    componentDidMount = () => {
        this.props.getPrintResultFromServer(getPrintResultSearch());
    };

    handleUpdateTable = () => {
        this.props.getPrintResultFromServer(getPrintResultSearch());
    };

    onEdit = (printresultId, printresultName) => {
        const modalData = {
            visible: true,
            width: 900,
            content:  <ContentEdit printresultId={printresultId} printresultName={printresultName} />,
            name: "doctor-add",
        };
        this.props.setModalBasic(modalData);
    };
    onAdd = () => {
        const modalData = {
            visible: true,
            width: 900,
            content: <AddFormContent />,
            name: "doctor-add",
        };
        this.props.setModalBasic(modalData);
    };

    onDelete = (id) => {
        this.props.deletePrintResultFromServer(id).then(() => {
            this.props.getPrintResultFromServer(getPrintResultSearch());
        });
    };

    onFinish = (values) => {
        let printresultSearch = getPrintResultSearch();
        printresultSearch.Keyword = values.Keyword;
        printresultSearch.PageIndex = 1;
        setPrintResultSearch(printresultSearch);
        this.handleUpdateTable();
    };

    onChangePage = (page, pageSize) => {
        let printresultSearch = getPrintResultSearch();
        printresultSearch.PageIndex = page;
        printresultSearch.PageSize = pageSize;
        setPrintResultSearch(printresultSearch);
        this.handleUpdateTable();
    };

    render() {
        const { printresults, totalCount } = this.props;
        return (
            <div className="printresult-page-d">
                <div className="printresult-search-d">
                    <Form
                        name="horizontal_login"
                        initialValues={printresultSeachDefault}
                        layout="inline"
                        onFinish={this.onFinish}
                    >
                        <Form.Item name="Keyword">
                            <Input placeholder="Từ khóa" />
                        </Form.Item>
                        <Button style={{ background: "#2a274c", color: "#fff" }} htmlType="submit">
                            Tìm kiếm
                        </Button>
                        <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.onAdd}>
                            Thêm mới
                        </Button>
                    </Form>
                </div>
                <div className={"printresult-table-d"}>
                    <table border="0" className="table-admin">
                        <thead>
                            <tr>
                                <th>Tên mẫu</th>
                                <th>Tiêu đề</th>
                                <th>Bệnh viện</th>
                                <th>Khoa/Phòng</th>
                                <th>Địa chỉ</th>
                                <th>Điện Thoại</th>
                                <th>Ghi chú</th>
                                <th>Mẫu Layout</th>
                                <th>Logo</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {printresults.map((printresults, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{printresults.name}</td>
                                        <td>{printresults.pTitle}</td>
                                        <td>{printresults.pHospital}</td>
                                        <td>{printresults.pDepartment}</td>
                                        <td>{printresults.pAddress}</td>
                                        <td>{printresults.pPhone}</td>
                                        <td>
                                            <div dangerouslySetInnerHTML={{ __html: printresults.pNote }} />
                                        </td>
                                        <td>{printresults.template}</td>
                                        <td>{printresults.pLogo!==""?
                                        <Image
                                        width={50}
                                        src={(window.location.protocol == "https:" ? (window.HTTPS_API_SERVER_RIS_URL ?? window.API_SERVER_RIS_URL) : window.API_SERVER_RIS_URL)+printresults.pLogo} />:
                                        printresults.pLogo
                                    }</td>
                                        <td>
                                            {" "}
                                            <div className="tools">
                                                <Button onClick={() => this.onEdit(printresults.id, printresults.name)}>
                                                    <FontAwesome name="edit" />
                                                </Button>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={`Xác nhận xóa ${printresults.name}`}
                                                    onConfirm={() => this.onDelete(printresults.id, printresults.name)}
                                                    okText="Ok"
                                                    cancelText="Cancel"
                                                >
                                                    <Button>
                                                        <FontAwesome name="trash-alt" />
                                                    </Button>
                                                </Popconfirm>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="pagination-d">
                        <Pagination
                            defaultCurrent={3}
                            current={getPrintResultSearch().PageIndex}
                            total={totalCount}
                            showQuickJumper
                            showTitle={false}
                            defaultPageSize={getPrintResultSearch().PageSize}
                            onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        printresults: state.printresult.printresults,
        totalCount: state.printresult.totalCount,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getPrintResultFromServer: (search) => dispatch(getPrintResultFromServer(search)),
        handleOpenModalApp: (modalData) => dispatch(handleOpenModalApp(modalData)),
        deletePrintResultFromServer: (id) => dispatch(deletePrintResultFromServer(id)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintResultPage);
