import React from "react";
import "antd/dist/antd.css";
import "./App.scss";
import { Provider, connect } from "react-redux";
import { store } from "./store/store";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import RouterPage from "./routes/RouterPage";
import {
  setupAxiosInterceptors,
  refreshToken,
} from "./utils/setupAxiosInterceptors";
import ModalApp from "./components/ModalApp";
import ModalBasic from "./components/ModalBasic/ModalBasic";
import ModalBasic2 from "./components/ModalBasic/ModalBasic2";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    setupAxiosInterceptors(() => refreshToken());
    this.initTemplateSource();
  }
  initTemplateSource = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    const randVersion = `${dd}${mm}${yyyy}`;
    //Load filejs for print coupon logic
    const scriptSetupCouponPrint = document.createElement("script");
    if (window.location.protocol === "https:") {
      scriptSetupCouponPrint.src =
        window.HTTPS_API_SERVER_RIS_URL +
        "/Content/Template/printCoupon.js?v=" +
        randVersion;
    } else {
      scriptSetupCouponPrint.src =
        window.API_SERVER_RIS_URL+
        "/Content/Template/printCoupon.js?v=" +
        randVersion;
    }
    //dev env
    // scriptSetupCouponPrint.src = "./../template/coupon/printCoupon.js?v="+randVersion;
    scriptSetupCouponPrint.async = true;
    document.body.appendChild(scriptSetupCouponPrint);
  };

  render() {
    return (
      <Provider store={store}>
        <ErrorBoundary>
          <RouterPage />
          <ModalApp />
          <ModalBasic />
          <ModalBasic2 />
        </ErrorBoundary>{" "}
      </Provider>
    );
  }
}
