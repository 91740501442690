import Axios from "axios";
import { getSearchStaticStudies } from "../models/staticStudies";
import { store } from "../store/store";
const ACTION_TYPES = {
    SET_STATIC_STUDIES: "SET_STATIC_STUDIES",
};

const initialstate = {
    data: [],
    dataCount: 0,
    listServerpacs: [],
    listInsurance: [],
    listAssDeparttment: [],
    listExecutor: [],
    listDoctors: [],
    listHospital: [],
    listArea: [],
    listServerpacsByHos: [],
    headerEquips: [],
    listFilterTypeMachine: [],
};

const staticStudiesReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_STATIC_STUDIES:
            state = action.payload;
            return {...state };
        default:
            return state;
    }
};

const fetchStaticStudies = () => async(dispatch) => {
    const response = await Axios.post(`/api/Statics/Studies`, getSearchStaticStudies());
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(
                setStaticStudies({
                    ...store.getState().staticStudies,
                    data: response.data.data.staticStudies,
                    headerEquips: response.data.data.headerEquips,
                })
            );
        }
    }
};

const fetchStaticStudiesCount = () => async(dispatch) => {
    const response = await Axios.post(`/api/Statics/studies-count`, getSearchStaticStudies());
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setStaticStudies({...store.getState().staticStudies, dataCount: response.data.dataCount }));
        }
    }
};

const fetchDropdownSeverpacs = () => async(dispatch) => {
    const response = await Axios.get(`/api/Statics/dropdown-Severpacs`);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setStaticStudies({...store.getState().staticStudies, listServerpacs: response.data.data }));
        }
    }
};

const fetchDropdownInsurance = () => async(dispatch) => {
    const response = await Axios.get(`/api/Statics/dropdown-insurance`);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setStaticStudies({...store.getState().staticStudies, listInsurance: response.data.data }));
        }
    }
};

const fetchDropdownModalities = () => async(dispatch) => {
    const response = await Axios.get(`/api/Statics/dropdown-modalities`);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(
                setStaticStudies({...store.getState().staticStudies, listFilterTypeMachine: response.data.data })
            );
        }
    }
};

const fetchDropdownAssDepartment = () => async(dispatch) => {
    const response = await Axios.get(`/api/Statics/dropdown-AssDepartment`);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setStaticStudies({...store.getState().staticStudies, listAssDeparttment: response.data.data }));
        }
    }
};

const fetchDropdownExecutor = () => async(dispatch) => {
    const response = await Axios.get(`/api/Statics/dropdown-Executor`);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setStaticStudies({...store.getState().staticStudies, listExecutor: response.data.data }));
        }
    }
};

const fetchDropdownDoctors = () => async(dispatch) => {
    const response = await Axios.get(`/api/Statics/dropdown-doctors`);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setStaticStudies({...store.getState().staticStudies, listDoctors: response.data.data }));
        }
    }
};

const fetchDropdownAreas = () => async(dispatch) => {
    const response = await Axios.get(`/api/Statics/dropdown-area`);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setStaticStudies({...store.getState().staticStudies, listArea: response.data.data }));
        }
    }
};

const fetchDropdownHospital = (areaId) => async(dispatch) => {
    const response = await Axios.get(`/api/Statics/dropdown-hospital?areaId=${areaId}`);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setStaticStudies({...store.getState().staticStudies, listHospital: response.data.data }));
        }
    }
};

const fetchDropdownServerpacsByIdHos = (hosId) => async(dispatch) => {
    if (hosId) {
        const response = await Axios.get(`/api/Statics/dropdown-Severpacs-By-Id-Hos/${hosId}`);
        if (response.status === 200) {
            if (response.data.status === 1) {
                dispatch(
                    setStaticStudies({...store.getState().staticStudies, listServerpacsByHos: response.data.data })
                );
            }
        }
    } else {
        dispatch(setStaticStudies({...store.getState().staticStudies, listServerpacsByHos: [] }));
    }
};

const setStaticStudies = (data) => {
    return {
        type: ACTION_TYPES.SET_STATIC_STUDIES,
        payload: data,
    };
};

export {
    staticStudiesReducer,
    setStaticStudies,
    fetchStaticStudies,
    fetchDropdownSeverpacs,
    fetchDropdownInsurance,
    fetchDropdownAssDepartment,
    fetchDropdownExecutor,
    fetchDropdownHospital,
    fetchDropdownServerpacsByIdHos,
    fetchStaticStudiesCount,
    fetchDropdownModalities,
    fetchDropdownDoctors,
    fetchDropdownAreas,
};