import Axios from "axios";
import { message } from "antd";
const ACTION_TYPES = {
    SET_PRINTRESULT: "SET_PRINTRESULT",
    SET_PRINTRESULT_ITEM: "SET_PRINTRESULT_ITEM",
};

const initialstate = {
    printresults: [],
    totalCount: 0,
    printresultItem: {},
};

const printresultReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_PRINTRESULT:
            state = {...state };
            state.printresults = action.payload.data;
            state.totalCount = action.payload.dataCount;
            return {...state };
        case ACTION_TYPES.SET_PRINTRESULT_ITEM:
            state.printresultItem = action.payload;
            return {...state };
        default:
            return state;
    }
};
const setPrintResults = (printresults) => {
    return {
        type: ACTION_TYPES.SET_PRINTRESULT,
        payload: printresults,
    };
};
const getPrintResultFromServer = (request) => async(dispatch) => {
    const response = await Axios.get(
        `api/PrintHeaders/paging?Keyword=${request.Keyword}&PageIndex=${request.PageIndex}&PageSize=${request.PageSize}`
    );
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setPrintResults(response.data));
        }
        
    } else {
        message.error(response.status);
    }
};

const setPrintResultItem = (printresult) => {
    return {
        type: ACTION_TYPES.SET_PRINTRESULT_ITEM,
        payload: printresult,
    };
};
const getPrintResultById = (id) => async(dispatch) => {
    const response = await Axios.get(`api/PrintHeaders/${id}`);
    if (response.data.status === 1) {
        dispatch(setPrintResultItem(response.data.data));
    }
    
};

const updatePrintResultToServer = (id, printresult) => async(dispatch) => {
    await Axios.put(`api/PrintHeaders/${id}`, printresult)
        .then((response) => {
            if (response.data) {
                message.success(response.data.message);
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let messages = "";
                if (e.Name) {
                    for (let i of e.Name) {
                        messages += i + "! ";
                    }
                }
                message.error(messages, 3);
            }
        });
};

const addPrintResultToServer = (request) => async(dispatch) => {
    await Axios.post("api/PrintHeaders", request, { "Content-Type": "multipart/form-data" })
        .then((response) => {
            if (response.status === 200) {
                message.success("Successfully! ");
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let mes = "";
                if (e.Name) {
                    for (let i of e.Name) {
                        mes += i + ", ";
                    }
                }
                message.error(mes, 3);
            }
        });
};

const deletePrintResultFromServer = (id) => async(dispatch) => {
    await Axios.delete(`api/PrintHeaders/${id}`)
        .then((response) => {
            message.success(response.data.message);
        })
        .catch((err) => {
            message.error("Bạn không có quyền xóa Bác sĩ này!");
        });
};
export {
    addPrintResultToServer,
    deletePrintResultFromServer,
    getPrintResultFromServer,
    printresultReducer,
    getPrintResultById,
    updatePrintResultToServer,
};