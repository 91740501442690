import React, { Component } from "react";
import { Form, Input, Radio, Layout, Button, Select, InputNumber, Tabs } from "antd";
import { connect } from "react-redux";
import { handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
import { getDropdownPosition, getDropdownPositionTittle, WritePositionMed, WritePositionTittleMed } from "../../reducers/doctorReducer";
import { removeVietnameseTones } from "../../utils";
const { Option } = Select;
const { TabPane } = Tabs;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const { Content, Footer, Header } = Layout;
const { Search } = Input;

class EditPosition extends Component {
    state = {
        dropdownPosition: [],
        dropdownPositionTittle:[],
        dropdownPositionState: [],
        dropdownPositionTittleState:[],
    };
    componentDidMount = async () => {
        if(this.props.isvermed == true){
            await this.props.getDropdownPositionTittle();
            await this.props.getDropdownPosition();
            this.setState ({
                dropdownPosition : this.props.dropdownPosition,
                dropdownPositionTittle : this.props.dropdownPositionTittle,
                dropdownPositionState : this.props.dropdownPosition,
                dropdownPositionTittleState : this.props.dropdownPositionTittle,
            }) ;
        }
    };
    onFinish = (values) => {
        const {dropdownPosition, dropdownPositionTittle} = this.state;
        this.props.WritePositionMed(dropdownPosition).then(() => {
            this.props.WritePositionTittleMed(dropdownPositionTittle);
            this.props.setModalBasic({ visible: false });
        });
    };
    editField = (value, index) => {
        // Clone dropdownPosition data before mutation
        const dropdownPosition = this.state.dropdownPosition.map(item => ({ ...item }))
        const dropdownPositionState = this.state.dropdownPositionState.map(item => ({ ...item }))
  
        // Update field by index of current dropdownPosition
        dropdownPosition.find(x => x.id === index.toString()).name = value;
        dropdownPositionState.find(x => x.id === index.toString()).name = value;
  
        // Trigger re-render
        this.setState({ dropdownPosition:dropdownPosition,
                        dropdownPositionState:dropdownPositionState
         })
    }   
    onAddPosition = () => {
        //Clone dropdownPosition data before mutation
        const dropdownPosition = this.state.dropdownPosition.map(item => ({ ...item }))
        const dropdownPositionState = this.state.dropdownPositionState.map(item => ({ ...item }))
        let index = dropdownPosition.length.toString();
        // Update field by index of current dropdownPosition
        let object = {
            id : index,
            name : document.getElementById("Add-Position-med").value,
            selected: true
        }
        dropdownPosition.push(object);
        dropdownPositionState.push(object);
  
        // Trigger re-render
        this.setState({ dropdownPosition:dropdownPosition, dropdownPositionState:dropdownPositionState })
    }  

    editFieldPositionTittle = (value, index) => {
        // Clone dropdownPositionTittle data before mutation
        const dropdownPositionTittle = this.state.dropdownPositionTittle.map(item => ({ ...item }))
        const dropdownPositionTittleState = this.state.dropdownPositionTittleState.map(item => ({ ...item }))
        // Update field by index of current dropdownPositionTittle
        dropdownPositionTittle.find(x => x.id === index.toString()).name = value;
        dropdownPositionTittleState.find(x => x.id === index.toString()).name = value;

        // Trigger re-render
        this.setState({ dropdownPositionTittle:dropdownPositionTittle,dropdownPositionTittleState:dropdownPositionTittleState })
    }   
    onAddPositionTittle = () => {
        //Clone dropdownPositionTittle data before mutation
        const dropdownPositionTittle = this.state.dropdownPositionTittle.map(item => ({ ...item }))
        const dropdownPositionTittleState = this.state.dropdownPositionTittleState.map(item => ({ ...item }))
        let index = dropdownPositionTittle.length.toString();
        // Update field by index of current dropdownPositionTittle
        let object = {
            id : index,
            name : document.getElementById("Add-PositionTittle-med").value,
            selected: true
        }
        dropdownPositionTittle.push(object);
        dropdownPositionTittleState.push(object);
  
        // Trigger re-render
        this.setState({ dropdownPositionTittle:dropdownPositionTittle, dropdownPositionTittleState: dropdownPositionTittleState })
    }  

    onSearchPosition = (value) => {
        const dropdownPositionState = this.state.dropdownPosition.map(item => ({ ...item }))
        let val = value.toLowerCase();
        let matches = dropdownPositionState.filter(v => removeVietnameseTones(v.name.toLowerCase()).includes(val));
        this.setState({ dropdownPositionState:matches })
    }
    onSearchPositionTittle = (value) => {
        const dropdownPositionTittleState = this.state.dropdownPositionTittle.map(item => ({ ...item }))
        let val = value.toLowerCase();
        let matches = dropdownPositionTittleState.filter(v => removeVietnameseTones(v.name.toLowerCase()).includes(val));
        this.setState({ dropdownPositionTittleState:matches })
    }
    render() {
        const {dropdownPositionState, dropdownPositionTittleState} = this.state;
        return (
            <Layout className="doctor-add-form-content">
                <Header>
                    <p className="modalBasic-title">Sửa DMục vị trí</p>
                    <button
                        className="modalBasic-btn-close"
                        onClick={() => this.props.setModalBasic({ visible: false })}
                    >
                        <i className="fas fa-times icon"></i>
                    </button>
                </Header>
                <Content>
                    <Tabs defaultActiveKey="1" style={{ background: "#fff", padding: 10 }}>
                            <TabPane tab="Đơn vị công tác" key="1">
                                <tr style={{display:"flex", alignItems:"center",  marginBottom:5}}>
                                    <td><Input id="Add-Position-med" placeholder="nhập tên vị trí" style={{width:500}} /></td>
                                    <Button style={{ background: "#1890ff", color: "#fff" ,marginLeft:10 }} onClick={this.onAddPosition}>
                                        thêm mới
                                    </Button>
                                </tr>
                                <tr style={{display:"flex", alignItems:"center", justifyContent:"center", marginBottom:5}}>
                                    <Search style={{width:500}} placeholder="tìm kiếm" onSearch={e => this.onSearchPosition(e)} enterButton />
                                </tr>
                                {dropdownPositionState?.map((item, index) => {
                                    return (
                                        <tr key={item.id} style={{display:"flex", alignItems:"center", justifyContent:"center", marginBottom:5}}>
                                            <td  style={{width:20}}>{item.id}</td>
                                            <td><Input allowClear  style={{width:500}} value={item.name} onChange={ e => this.editField(e.target.value, item.id) } /></td>
                                        </tr>
                                    );
                                })}
                            </TabPane>
                            <TabPane tab="Vị trí" key="2">
                                <tr style={{display:"flex", alignItems:"center", marginBottom:5}}>
                                    <td><Input id="Add-PositionTittle-med" placeholder="nhập tên vị trí" style={{width:500}} /></td>
                                    <Button style={{ background: "#1890ff", color: "#fff" ,marginLeft:10}} onClick={this.onAddPositionTittle}>
                                        thêm mới
                                    </Button>
                                </tr>
                                <tr style={{display:"flex", alignItems:"center", justifyContent:"center", marginBottom:5}}>
                                    <Search style={{width:500}} placeholder="tìm kiếm" onSearch={e => this.onSearchPositionTittle(e)} enterButton />
                                </tr>
                                {dropdownPositionTittleState?.map((item, index) => {
                                    return (
                                        <tr key={item.id} style={{display:"flex", alignItems:"center", justifyContent:"center", marginBottom:5}}>
                                            <td  style={{width:20}}>{item.id}</td>
                                            <td><Input allowClear style={{width:500}} value={item.name} onChange={ e => this.editFieldPositionTittle(e.target.value, item.id) } /></td>
                                        </tr>
                                    );
                                })}
                            </TabPane>
                        </Tabs>
                </Content>
                <Footer>
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            this.onFinish()
                        }}
                    >
                        Thay đổi
                    </Button>
                </Footer>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        dropdownPosition: state.doctor.dropdownPosition,
        dropdownPositionTittle: state.doctor.dropdownPositionTittle,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        getDropdownPositionTittle: () => dispatch(getDropdownPositionTittle()),
        getDropdownPosition: () => dispatch(getDropdownPosition()),
        WritePositionMed: (data) => dispatch(WritePositionMed(data)),
        WritePositionTittleMed: (data) => dispatch(WritePositionTittleMed(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPosition);
