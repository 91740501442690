import Axios from "axios";
import { message } from "antd";
import { store } from "../store/store";
import { getLogSearch } from "../pages/Home/LogPage/log";
const ACTION_TYPES = {
    SET_LOG: "SET_LOG",
    SET_LOG_ITEM: "SET_LOG_ITEM",
};

const initialstate = {
    logs: [],
    logItem: {},
};

const logReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_LOG:
            state = { ...state };
            state.logs = action.payload.data;
            return { ...state };
        case ACTION_TYPES.SET_LOG_ITEM:
            state.logItem = action.payload;
            return { ...state };
        default:
            return state;
    }
};
const setLogs = (logs) => {
    return {
        type: ACTION_TYPES.SET_LOG,
        payload: logs,
    };
};
const setLogItem = (log) => {
    return {
        type: ACTION_TYPES.SET_LOG_ITEM,
        payload: log,
    };
};



const getLog = () => async(dispatch) => {
    try{
        const data = getLogSearch();
        const response = await Axios.post(`api/Log/all`, data);
        if (response.status === 200) {
            if (response.data.status === 1) {
                dispatch(setLogs(response.data));
            }
        } else {
            message.error(response.status);
        }
    }
    catch (err)  {
        if (err.response) {
            console.log(err.response);
        }
        message.error("có lỗi xảy ra, vui lòng thử lại sau");
        dispatch(setLogs([]));
    }
};
const getLogViewer = () => async(dispatch) => {
    try{
        const data = getLogSearch();
        const response = await Axios.post(`api/Log/all-viewer`, data);
        if (response.status === 200) {
            if (response.data.status === 1) {
                dispatch(setLogs(response.data));
            }
        } else {
            message.error(response.status);
        }
    }
    catch (err)  {
        if (err.response) {
            console.log(err.response);
        }
        message.error("có lỗi xảy ra, vui lòng thử lại sau");
        dispatch(setLogs([]));
    }
};
const getLogById = (id) => async(dispatch) => {
    const response = await Axios.get(`api/Log/${id}`);
    dispatch(setLogItem(response.data.data));
};
const getLogViewerById = (id) => async(dispatch) => {
    const response = await Axios.get(`api/Log/${id}/viewer`);
    dispatch(setLogItem(response.data.data));
};

export {
    getLog,
    getLogById,
    logReducer,
    getLogViewer,
    getLogViewerById
};
