import React, { Component } from 'react'
import { connect } from 'react-redux'



export class PreviewPrintTemplate extends Component {
    state = {}
    componentDidMount() {
        // console.log("jfjh",this.props.dataHtml);
    }
    render() {
        const { dataHtml } = this.props;
        return (
            <div id="ris-preview-template" style={{width: '19cm', maxWidth: '19cm',  minWidth: '19cm'}}>
                <div
                    key={Date.now()}
                    id="view-html-tempalte"
                    className="view-html-tempalte"
                    dangerouslySetInnerHTML={{ __html: dataHtml }}
                />
            </div>
        )
    }
}