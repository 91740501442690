import React, { Component } from "react";
import { connect } from "react-redux";
import {
    getStatisticFromServer,
    getExecutorsFromServer,
    getStatisticDoctor,
    getChartSummery,
    getChartSummeryByDate,
    getSummeryBase,
    getDoctorsBase,
    getExecutorsBase,
    fetchDropdownMultiHospital,
    fetchDropdownMultiServerpacsByIdHos,
    fetchDropdownTypeObject,
    getChartByWeeks,
    getChartByMonths,
    getStudiesBase,
} from "../../../reducers/statisticReducer";
import { getStatisticSearch, initialStatisticSeach, getStatisticBaseSearch } from "./statistic";
import StatisticSearch from "../../../components/Statistic/StatisticSearch";
import SummaryTable from "../../../components/Statistic/SummaryTable";
import DoctorsTable from "../../../components/Statistic/DoctorsTable";
import ExecutorsTable from "../../../components/Statistic/ExecutorsTable";
import ChartColumn from "../../../components/Statistic/ChartStatistic";
import {
    fetchDropdownAreas,
    fetchDropdownDoctors,
    fetchDropdownExecutor,
} from "../../../reducers/staticStudiesReducer";
import { Layout, Tabs } from "antd";
import LineChart from "../../../components/Statistic/LineChartStatistic";
import { TableSumaryBase } from "../../../components/Statistic/SummaryBaseTable";
import { TableDoctorsBase } from "../../../components/Statistic/DoctorsBaseTable";
import { TableExecutorsBase } from "../../../components/Statistic/ExecutorsBaseTable";
import StatisticSearchBase from "../../../components/Statistic/StatisticSearchBase";
import ChartsByWeek from "../../../components/Statistic/ChartsByWeek";
import ChartsByYear from "../../../components/Statistic/ChartsByYear";
import StatisticStudiesBase from "../../../components/Statistic/StatisticStudiesBase";
import PreviewPrint from "../../../components/Statistic/PreviewPrint";
const { Header, Footer, Sider, Content } = Layout;
const { TabPane } = Tabs;
class StatisticPage extends Component {
    contentPrint = null;
    componentDidMount = () => {
        // this.props.getStatisticFromServer(getStatisticSearch());
        // this.props.getStatisticDoctor(getStatisticSearch());
        // this.props.getExecutorsFromServer(getStatisticSearch());
        // this.props.getChartSummery(getStatisticSearch());
        // this.props.getChartSummeryByDate(getStatisticSearch());
        this.props.getSummeryBase(getStatisticBaseSearch());
        this.props.getDoctorsBase(getStatisticBaseSearch());
        this.props.getExecutorsBase(getStatisticBaseSearch());
        this.props.getChartByWeeks(getStatisticBaseSearch());
        this.props.getChartByMonths(getStatisticBaseSearch());
        this.props.getStudiesBase(getStatisticBaseSearch());
        this.props.fetchDropdownAreas();
        this.props.fetchDropdownMultiHospital([]);
        this.props.fetchDropdownMultiServerpacsByIdHos({AreaId:[],modalities:[]});
        this.props.fetchDropdownDoctors();
        this.props.fetchDropdownExecutor();
        this.props.fetchDropdownTypeObject();
    };

    render() {
        const {
            listDoctors,
            listExecutor,
            chartSummary,
            chartSummaryByDate,
            summaryBase,
            doctorsBase,
            executorsBase,
            listTypeObject,
            chartByWeeks,
            chartByYears,
            listStudies,
        } = this.props;
        return (
            <>
                <PreviewPrint/>
                <Layout style={{ height: "100%", overflow: "auto", maxHeight: "100vh" }}>
                    <Header style={{ background: "#fff", height: "auto", lineHeight: 0 }}>
                        {/* <StatisticSearch
                        handleUpdateTable={this.handleUpdateTable}
                        handleOpenModal={this.handleOpenModal}
                        handleAdd={this.handleAdd}
                        listArea={listArea}
                    /> */}
                        <StatisticSearchBase
                            listDoctor={listDoctors}
                            contentPrint={this.contentPrint}
                            listExecutor={listExecutor}
                            listSvStatus={[
                                { value: 0, name: "Chưa đọc" },
                                { value: 1, name: "Đang đọc" },
                                { value: 2, name: "Đã đọc" },
                            ]}
                            listTypeOfObject={listTypeObject}
                        />
                    </Header>
                    <Content id="content-print">
                        <Tabs defaultActiveKey="1" style={{ background: "#fff", padding: 10 }}>
                            <TabPane tab="Thống kê theo bảng" key="1">
                                <div style={{ display: "flex", flexWrap: "wrap", padding: "0 10px" }}>
                                    <div style={{ width: "100%", height: "100%" }}>
                                        <TableSumaryBase data={summaryBase} />
                                    </div>
                                    <div style={{ width: "100%", height: "100%" }}>
                                        <TableDoctorsBase data={doctorsBase} />
                                    </div>
                                    <div style={{ width: "100%", height: "100%" }}>
                                        <TableExecutorsBase data={executorsBase} />
                                    </div>
                                    {/* <div style={{ width: "100%", height: "100%" }}>
                                <SummaryTable summary={summary} />
                            </div>
                            <div style={{ width: "100%", height: "100%" }}>
                                <DoctorsTable doctors={doctors} />
                            </div>
                            <div style={{ width: "100%", height: "100%" }}>
                                <ExecutorsTable executors={executors} />
                            </div>
                            <div style={{ width: "100%", height: "100%" }}>
                                <LineChart dataChart={chartSummaryByDate}></LineChart>
                                <h2 style={{ textAlign: "center" }}>Biểu đồ số lượng ca chụp theo thời gian</h2>
                            </div>
                            <div style={{ width: "100%", height: "100%" }}>
                                <ChartColumn dataChart={chartSummary} />
                                <h2 style={{ textAlign: "center" }}>Biểu đồ số lượng ca chụp theo trạng thái</h2>
                            </div> */}
                                </div>
                            </TabPane>
                            <TabPane tab="Thống kê theo biểu đồ" key="2">
                                <div>
                                    {chartByWeeks.length > 0 && (
                                        <div>
                                            <ChartsByWeek data={chartByWeeks} />
                                        </div>
                                    )}

                                    {chartByYears.length > 0 && (
                                        <div>
                                            <ChartsByYear data={chartByYears} />
                                        </div>
                                    )}
                                </div>
                            </TabPane>
                            <TabPane tab="Thống kê chi tiết" key="3">
                                <div>
                                    {listStudies.length > 0 && (
                                        <div>
                                            <StatisticStudiesBase data={listStudies} />
                                        </div>
                                    )}
                                </div>
                            </TabPane>
                        </Tabs>
                    </Content>
                </Layout>
            </>
        );
    }
}
function mapStateToProps(state) {
    return {
        summary: state.statistic.summary,
        doctorsBase: state.statistic.doctorsBase,
        executorsBase: state.statistic.executorsBase,
        summaryBase: state.statistic.summaryBase,
        doctors: state.statistic.doctors,
        executors: state.statistic.executors,
        chartSummary: state.statistic.chartSummary,
        chartSummaryByDate: state.statistic.chartSummaryByDate,
        listDoctors: state.staticStudies.listDoctors,
        listExecutor: state.staticStudies.listExecutor,
        listArea: state.staticStudies.listArea,
        listHospital: state.statistic.listHospital,
        listServerpacsByHos: state.statistic.listServerpacsByHos,
        listTypeObject: state.statistic.listTypeObject,
        chartByWeeks: state.statistic.chartByWeeks,
        chartByYears: state.statistic.chartByYears,
        listStudies: state.statistic.listStudies,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getStatisticFromServer: (search) => dispatch(getStatisticFromServer(search)),
        getStatisticDoctor: (search) => dispatch(getStatisticDoctor(search)),
        getExecutorsFromServer: (search) => dispatch(getExecutorsFromServer(search)),
        getChartSummery: (search) => dispatch(getChartSummery(search)),
        getChartSummeryByDate: (search) => dispatch(getChartSummeryByDate(search)),
        getSummeryBase: (search) => dispatch(getSummeryBase(search)),
        getDoctorsBase: (search) => dispatch(getDoctorsBase(search)),
        getExecutorsBase: (search) => dispatch(getExecutorsBase(search)),
        getStudiesBase: (search) => dispatch(getStudiesBase(search)),
        fetchDropdownMultiHospital: (areaId) => dispatch(fetchDropdownMultiHospital(areaId)),
        fetchDropdownMultiServerpacsByIdHos: (idHos) => dispatch(fetchDropdownMultiServerpacsByIdHos(idHos)),
        fetchDropdownDoctors: () => dispatch(fetchDropdownDoctors()),
        fetchDropdownExecutor: () => dispatch(fetchDropdownExecutor()),
        fetchDropdownAreas: () => dispatch(fetchDropdownAreas()),
        fetchDropdownTypeObject: () => dispatch(fetchDropdownTypeObject()),
        getChartByWeeks: (data) => dispatch(getChartByWeeks(data)),
        getChartByMonths: (data) => dispatch(getChartByMonths(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticPage);
