import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, message, Layout,Select } from "antd";
import { getIndicateSearch } from "../../pages/Home/IndicatePage/indicate";
import {
    ChangePathToServer, getIndicateFromServer
} from "../../reducers/indicateReducer";
import { connect } from "react-redux";
import { handleOpenModalApp, handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
import moment from "moment";
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const { Option } = Select;
const { Header, Content, Footer } = Layout;
class ChangePath extends Component {
    onFinish = (values) => {
        
        let indicateSearch = getIndicateSearch();
        const obj = {
            Code: indicateSearch.Code,
            ExistsOnHis: indicateSearch.ExistsOnHis === "" ? null : indicateSearch.ExistsOnHis,
            Keyword: indicateSearch.Keyword,
            Modality: indicateSearch.Modality,
            fromDate: moment(indicateSearch.fromDate).format(),
            id: parseInt(indicateSearch.id),
            orgCode: indicateSearch.orgCode,
            pCode: indicateSearch.pCode,
            serverID: parseInt(indicateSearch.serverID),
            toDate: moment(indicateSearch.toDate).format(),
            drive: values.drive.toUpperCase(),
        }
        this.props.ChangePathToServer(obj).then(() => {
            this.props.handleCloseModalApp();
            this.props.getIndicateFromServer(getIndicateSearch());
        });
    };

    render() {
        const { listServerPacs } = this.props;
        return (
            <>
                <Layout className="layout-indicate-change">
                    <Header>
                        <p className="modalBasic-title">Đổi máy chụp</p>
                        <button
                            className="modalBasic-btn-close"
                            onClick={() => this.props.setModalBasic({ visible: false })}
                        >
                            <i className="fas fa-times icon"></i>
                        </button>
                    </Header>
                    <Content>
                        <Form
                            //style={{ marginTop: 20 }}
                            {...formItemLayout}
                            name="ChangeServerPacs"
                            onFinish={this.onFinish}
                            ref={(ref) => (this.formRef = ref)}
                            scrollToFirstError
                        >
                            <Form.Item name="drive" label="Drive mới">
                                <Input allowClear  />
                            </Form.Item>
                            <Button style={{ display: "none" }} id="btn-submit-change-path" htmlType={"submit"}>
                                Thay đổi
                            </Button>
                            {/* <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}></div>
                    <div style={{ flex: 3 }}>
                        <Button style={{ color: "#fff", background: "#2687d4" }} htmlType={"submit"}>
                            Thay Đổi
                        </Button>
                    </div>
                </div> */}
                        </Form>
                    </Content>
                    <Footer>
                        <Button
                            style={{ color: "#fff", background: "#2687d4" }}
                            onClick={() => document.getElementById("btn-submit-change-path").click()}
                        >
                            Thay đổi
                        </Button>
                    </Footer>
                </Layout>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        ChangePathToServer: (indicate) => dispatch(ChangePathToServer(indicate)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        handleOpenModalApp: (modalData) => dispatch(handleOpenModalApp(modalData)),
        getIndicateFromServer: (search) => dispatch(getIndicateFromServer(search)),
        setModalBasic: (data) => dispatch(setModalBasic(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePath);
