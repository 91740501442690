import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button } from "antd";
import { getDoctorById } from "../../reducers/doctorReducer";
import { connect } from "react-redux";
import "./ContentInfo.scss";
import { formatDate } from "../../utils";
class ContentInfo extends Component {
    componentDidMount = () => {
        this.props.getDoctorById(this.props.doctorId);
    };
    componentDidUpdate = (prevProps) => {
        if (prevProps.doctorId !== this.props.doctorId) {
            this.props.getDoctorById(this.props.doctorId);
        }
    };
    render() {
        const { doctorItem } = this.props;
        return (
            <div className="content-info-doctor-d">
                <div className="left-d">
                    <div>
                        <p>Name: </p>
                    </div>
                    <div>
                        <p>Giới tính: </p>
                    </div>
                    <div>
                        <p>Địa chỉ: </p>
                    </div>
                    <div>
                        <p>Ngày sinh: </p>
                    </div>
                    <div>
                        <p>Email: </p>
                    </div>
                    <div>
                        <p>Số điện thoại: </p>
                    </div>
                    <div>
                        <p>CT: </p>
                    </div>
                    <div>
                        <p>DX: </p>
                    </div>
                    <div>
                        <p>EO: </p>
                    </div>
                    <div>
                        <p>MAMO: </p>
                    </div>
                    <div>
                        <p>MRI: </p>
                    </div>
                    <div>
                        <p>PET: </p>
                    </div>
                    <div>
                        <p>US: </p>
                    </div>
                </div>
                <div className="right-d">
                    {/* <div>
                        <Input value={doctorItem.name} disabled />
                    </div>
                    <div>
                        <Input value={doctorItem.gender} disabled />
                    </div>
                    <div>
                        <Input value={doctorItem.address} disabled />
                    </div>
                    <div>
                        <Input value={doctorItem.doB} disabled />
                    </div>
                    <div>
                        <Input value={doctorItem.email} disabled />
                    </div>
                    <div>
                        <Input value={doctorItem.phoneNumber} disabled />
                    </div>
                    <div>
                        <Input value={doctorItem.ct} disabled />
                    </div>
                    <div>
                        <Input value={doctorItem.dx} disabled />
                    </div>
                    <div>
                        <Input value={doctorItem.eo} disabled />
                    </div>
                    <div>
                        <Input value={doctorItem.mamo} disabled />
                    </div>
                    <div>
                        <Input value={doctorItem.mri} disabled />
                    </div>
                    <div>
                        <Input value={doctorItem.pet} disabled />
                    </div>
                    <div>
                        <Input value={doctorItem.us} disabled />
                    </div> */}
                    <div>
                        <b>{doctorItem.name}</b>
                    </div>
                    <div>
                        <p>{doctorItem.gender}</p>
                    </div>
                    <div>
                        <p>{doctorItem.address}</p>
                    </div>
                    <div>
                        <p>{doctorItem.doB}</p>
                    </div>
                    <div>
                        <p>{doctorItem.email}</p>
                    </div>
                    <div>
                        <p>{doctorItem.phoneNumber}</p>
                    </div>
                    <div>
                        <p>{doctorItem.ct}</p>
                    </div>
                    <div>
                        <p>{doctorItem.dx}</p>
                    </div>
                    <div>
                        <p>{doctorItem.eo}</p>
                    </div>
                    <div>
                        <p>{doctorItem.mamo}</p>
                    </div>
                    <div>
                        <p>{doctorItem.mri}</p>
                    </div>
                    <div>
                        <p>{doctorItem.pet}</p>
                    </div>
                    <div>
                        <p>{doctorItem.us}</p>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        doctorItem: state.doctor.doctorItem,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getDoctorById: (id) => dispatch(getDoctorById(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentInfo);
