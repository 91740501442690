import Axios from "axios";
import { message } from "antd";
import { store } from "../store/store";
const ACTION_TYPES = {
    SET_STATISTIC: "SET_STATISTIC",
    SET_SUMMARY: "SET_SUMMARY",
    SET_DOCTORS_STATISTIC: "SET_DOCTORS_STATISTIC",
    SET_EXECUTORS: "SET_EXECUTORS",
    SET_CHART_SUMMARY: "SET_CHART_SUMMARY",
};

const initialstate = {
    summary: [],
    doctors: {},
    executors: {},
    chartSummary: [],
    chartSummaryByDate: [],
    summaryBase: [],
    doctorsBase: [],
    executorsBase: [],
    listHospital: [],
    listModalities: [],
    listServerpacsByHos: [],
    listServices: [],
    listTypeObject: [],
    chartByWeeks: [],
    chartByYears: [],
    listStudies: [],
};

const statisticReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_STATISTIC:
            state = action.payload;
            return {...state };
        case ACTION_TYPES.SET_SUMMARY:
            state = {...state };
            state.summary = action.payload.data;
            return {...state };
        case ACTION_TYPES.SET_DOCTORS_STATISTIC:
            state = {...state };
            state.doctors = action.payload.data;
            return {...state };
        case ACTION_TYPES.SET_EXECUTORS:
            state = {...state };
            state.executors = action.payload.data;
            return {...state };
        case ACTION_TYPES.SET_CHART_SUMMARY:
            state = {...state };
            state.chartSummary = action.payload.data;
            return {...state };
        default:
            return state;
    }
};
const setStatistic = (statistics) => {
    return {
        type: ACTION_TYPES.SET_STATISTIC,
        payload: statistics,
    };
};
const setSummary = (statistics) => {
    return {
        type: ACTION_TYPES.SET_SUMMARY,
        payload: statistics,
    };
};
const setChartSummary = (statistics) => {
    return {
        type: ACTION_TYPES.SET_CHART_SUMMARY,
        payload: statistics,
    };
};
const getStatisticFromServer = (request) => async(dispatch) => {
    const response = await Axios.get(
        `api/Statics/Summary?fromDate=${request.fromDate}&toDate=${request.toDate}&typeSearch=${request.typeSearch}&existsOnHis=${request.existsOnHis}&areaId=${request.areaId}`
    );
    if (response.status === 200) {
        if (response.data.status === 1) {
            response.data.data.forEach((item, index) => {
                item.stt = index + 1;
            });
            dispatch(setSummary(response.data));
        } else {
            message.error(response.message);
        }
    } else {
        message.error(response.status);
    }
};
const setStatisticDoctors = (doctors) => {
    return {
        type: ACTION_TYPES.SET_DOCTORS_STATISTIC,
        payload: doctors,
    };
};
const getStatisticDoctor = (request) => async(dispatch) => {
    await Axios.get(
        `api/Statics/Doctors?fromDate=${request.fromDate}&toDate=${request.toDate}&typeSearch=${request.typeSearch}&existsOnHis=${request.existsOnHis}&areaId=${request.areaId}`
    ).then((response) => {
        if (response.status === 200) {
            if (response.data.status === 1) {
                dispatch(setStatisticDoctors(response.data));
            } else {
                message.error(response.data.message);
            }
        }
    });
};

const setExecutors = (executors) => {
    return {
        type: ACTION_TYPES.SET_EXECUTORS,
        payload: executors,
    };
};
const getExecutorsFromServer = (request) => async(dispatch) => {
    await Axios.get(
        `api/Statics/Executors?fromDate=${request.fromDate}&toDate=${request.toDate}&typeSearch=${request.typeSearch}&existsOnHis=${request.existsOnHis}&areaId=${request.areaId}`
    ).then((response) => {
        // response.data.doctor.forEach((item, index) => {
        //     item.stt = index + 1;
        // });
        if (response.status === 200) {
            if (response.data.status === 1) {
                dispatch(setExecutors(response.data));
            } else {
                message.error(response.data.message);
            }
        }
    });
};

const getChartSummery = (request) => async(dispatch) => {
    await Axios.get(
        `api/Statics/chart-summary?fromDate=${request.fromDate}&toDate=${request.toDate}&typeSearch=${request.typeSearch}&existsOnHis=${request.existsOnHis}&areaId=${request.areaId}`
    ).then((response) => {
        if (response.status === 200) {
            if (response.data.status === 1) {
                dispatch(setChartSummary(response.data));
            } else {
                message.error(response.data.message);
            }
        }
    });
};

const getChartSummeryByDate = (request) => async(dispatch) => {
    await Axios.get(
        `api/Statics/chart-summary-by-date?fromDate=${request.fromDate}&toDate=${request.toDate}&typeSearch=${request.typeSearch}&existsOnHis=${request.existsOnHis}&areaId=${request.areaId}`
    ).then((response) => {
        if (response.status === 200) {
            if (response.data.status === 1) {
                dispatch(setStatistic({...store.getState().statistic, chartSummaryByDate: response.data.data }));
            } else {
                message.error(response.data.message);
            }
        }
    });
};

const getSummeryBase = (request) => async(dispatch) => {
    await Axios.post(`api/Statics/summary-base`, request).then((response) => {
        if (response.status === 200) {
            if (response.data.status === 1) {
                dispatch(setStatistic({...store.getState().statistic, summaryBase: response.data.data }));
            } else {
                message.error(response.data.message);
            }
        }
    });
};

const getDoctorsBase = (request) => async(dispatch) => {
    await Axios.post(`api/Statics/doctors-base`, request).then((response) => {
        if (response.status === 200) {
            if (response.data.status === 1) {
                dispatch(setStatistic({...store.getState().statistic, doctorsBase: response.data.data }));
            } else {
                message.error(response.data.message);
            }
        }
    });
};

const getExecutorsBase = (request) => async(dispatch) => {
    await Axios.post(`api/Statics/executors-base`, request).then((response) => {
        if (response.status === 200) {
            if (response.data.status === 1) {
                dispatch(setStatistic({...store.getState().statistic, executorsBase: response.data.data }));
            } else {
                message.error(response.data.message);
            }
        }
    });
};

const fetchDropdownMultiHospital = (areaId) => async(dispatch) => {
    const response = await Axios.post(`/api/Statics/dropdown-multi-hospital`, areaId);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setStatistic({...store.getState().statistic, listHospital: response.data.data }));
        }
    }
};

const fetchDropdownMultiServerpacsByIdHos = (hosId) => async(dispatch) => {
    const response = await Axios.post(`/api/Statics/dropdown-multi-severpacs-by-id-hos`, hosId);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setStatistic({...store.getState().statistic, listServerpacsByHos: response.data.data }));
        }
    }
};

const fetchDropdownMultiServices = (modalities) => async(dispatch) => {
    const response = await Axios.post(`/api/Statics/dropdown-multi-services`, modalities);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setStatistic({...store.getState().statistic, listServices: response.data.data }));
        }
    }
};
const fetchDropdownTypeObject = () => async(dispatch) => {
    const response = await Axios.get(`/api/Statics/dropdown-type-object`);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setStatistic({...store.getState().statistic, listTypeObject: response.data.data }));
        }
    }
};

const fetchDropdownModalities = () => async(dispatch) => {
    const response = await Axios.get(`/api/Statics/dropdown-modalities`);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setStatistic({...store.getState().statistic, listModalities: response.data.data }));
        }
    }
};

const getChartByWeeks = (dataSearch) => async(dispatch) => {
    const response = await Axios.post(`/api/Statics/chart-by-weeks`, dataSearch);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setStatistic({...store.getState().statistic, chartByWeeks: response.data.data }));
        }
    }
};

const getChartByMonths = (dataSearch) => async(dispatch) => {
    const response = await Axios.post(`/api/Statics/chart-by-month`, dataSearch);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setStatistic({...store.getState().statistic, chartByYears: response.data.data }));
        }
    }
};

const getStudiesBase = (dataSearch) => async(dispatch) => {
    const response = await Axios.post(`/api/Statics/studies-base`, dataSearch);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setStatistic({...store.getState().statistic, listStudies: response.data.data }));
        }
    }
};

export {
    getStatisticFromServer,
    statisticReducer,
    getStatisticDoctor,
    getExecutorsFromServer,
    getChartSummery,
    getChartSummeryByDate,
    getSummeryBase,
    getDoctorsBase,
    getExecutorsBase,
    fetchDropdownMultiHospital,
    fetchDropdownMultiServerpacsByIdHos,
    fetchDropdownMultiServices,
    fetchDropdownTypeObject,
    getChartByWeeks,
    getChartByMonths,
    fetchDropdownModalities,
    getStudiesBase,
};