import Axios from "axios";
import { queryString } from "../utils/queryString";
import { message } from "antd";
const ACTION_TYPES = {
    SET_SERVERPACSS: "SET_SERVERPACSS",
    SET_ROLES: "SET_ROLES",
    SET_SERVERPACS_ITEM: "SET_SERVERPACS_ITEM",
    SET_DROPDOWN_HOSPITAL: "SET_DROPDOWN_HOSPITAL",
    SET_DROPDOWN_PRINT: "SET_DROPDOWN_PRINT",
    SET_DROPDOWN_MODALITYTYPE:"SET_DROPDOWN_MODALITYTYPE"
};

const initialstate = {
    serverpacss: [],
    totalCount: 0,
    roles: [],
    serverpacsItem: {},
    dropdownPrintHeader: [],
    dropdownHospital: [],
    dropdownModalityType: [],
};

const serverpacsReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_SERVERPACSS:
            state = { ...state };
            state.serverpacss = [...action.payload.data];
            state.totalCount = action.payload.dataCount;
            return { ...state };
        case ACTION_TYPES.SET_ROLES:
            state.roles = action.payload;
            return { ...state };
        case ACTION_TYPES.SET_SERVERPACS_ITEM:
            state.serverpacsItem = action.payload;
            return { ...state };
        case ACTION_TYPES.SET_DROPDOWN_HOSPITAL:
            state.dropdownHospital = action.payload;
            return { ...state };
        case ACTION_TYPES.SET_DROPDOWN_PRINT:
            state.dropdownPrintHeader = action.payload;
            return { ...state };
            case ACTION_TYPES.SET_DROPDOWN_MODALITYTYPE:
            state.dropdownModalityType = action.payload;
            return { ...state };
        default:
            return state;
    }
};

const setServerPacss = (serverpacss) => {
    return {
        type: ACTION_TYPES.SET_SERVERPACSS,
        payload: serverpacss,
    };
};

const getServerPacsFromServer = (request) => async (dispatch) => {
    const { Keyword, PageIndex, PageSize,RequestFor,RequestFromIp } = request;
    let url = "api/ServerPacs/paging?";
    url += `Keyword=${Keyword}&`;
    url += `RequestFor=${RequestFor}&`;
    url += `RequestFromIp=${RequestFromIp}&`;
    url += `PageIndex=${PageIndex}&`;
    url += `PageSize=${PageSize}`;
    const response = await Axios.get(url);
    if (response.status === 200) {
        // response.data.data.forEach((item, index) => {
        //     item.stt = index + 1;
        // });
        if (response.data.status === 1) {
            dispatch(setServerPacss(response.data));
        }
        
    } else {
        message.error(response.status);
    }
};

const setServerPacsItem = (serverpacs) => {
    return {
        type: ACTION_TYPES.SET_SERVERPACS_ITEM,
        payload: serverpacs,
    };
};
const getServerPacsById = (id) => async (dispatch) => {
    await Axios.get(`api/ServerPacs/${id}`)
        .then((response) => {
            if (response.data.status === 1) {
                dispatch(setServerPacsItem(response.data.data));
            }
            
        })
        .catch((error) => {});
};

const updateServerPacsToServer = (id, serverpacs) => async (dispatch) => {
    await Axios.put(`api/ServerPacs/${id}`, serverpacs)
        .then((response) => {
            if (response.data) {
                message.success(response.data.message);
            }
        })
        .catch((err) => {
            let messages = "";
            message.error(messages, 3);
        });
};

const addServerPacsToServer = (request) => async (dispatch) => {
    await Axios.post("api/ServerPacs", request)
        .then((response) => {
            if (response.status === 200) {
                message.success("Successfully!");
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let mes = "";
                message.error(mes, 3);
            }
        });
};

const deleteServerPacsFromServer = (id) => async (dispatch) => {
    await Axios.delete(`api/ServerPacs/${id}`)
        .then((response) => {
            message.success(response.data.message);
        })
        .catch((err) => {
            message.error("Bạn không có quyền xóa Bác sĩ này!");
        });
};

const setListHospital = (hospitals) => {
    return {
        type: ACTION_TYPES.SET_DROPDOWN_HOSPITAL,
        payload: hospitals,
    };
};

const getListHospitalFromServer = () => async (dispatch) => {
    await Axios.get(`api/Hospitals/GetHospitals`).then((response) => {
        if (response.data.status === 1) {
            dispatch(setListHospital(response.data.data));
        }
        
    });
};

const setListPrint = (listPrint) => {
    return {
        type: ACTION_TYPES.SET_DROPDOWN_PRINT,
        payload: listPrint,
    };
};

const getListPrintFromServer = () => async (dispatch) => {
    await Axios.get(`api/PrintHeaders/GetHeaders`).then((response) => {
        if (response.data.status === 1) {
            dispatch(setListPrint(response.data.data));
        }
        
    });
};
const getDropdownModalityTypeFromServer = () => async (dispatch) => {
    const response = await Axios.get(
        `api/ServerPacs/dropdown-modality-type`
    );
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setDropdownModalityType(response.data.data));
        }
    } else {
        console.log(response.status);
    }
};
const setDropdownModalityType = (dropdownModalityType) => {
    return {
        type: ACTION_TYPES.SET_DROPDOWN_MODALITYTYPE,
        payload: dropdownModalityType,
    };
};

export {
    addServerPacsToServer,
    deleteServerPacsFromServer,
    getListPrintFromServer,
    getServerPacsFromServer,
    serverpacsReducer,
    getListHospitalFromServer,
    getServerPacsById,
    updateServerPacsToServer,
    getDropdownModalityTypeFromServer,
};
