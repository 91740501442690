import React, { Component } from "react";
import { Form, Input, Button, message, AutoComplete, Layout, Row, Col, Select } from "antd";
import { connect } from "react-redux";
import { setModalBasic } from "../../reducers/uiReducer";
import Axios from "axios";
import {
    deleteIcdTemp,
    fetchIcdTemp,
    getListIcdTemp,
    postIcdTemp,
    getListIcd,
    getListDiagnose,
    getListIcdNew,
} from "../../reducers/icdTempManagerReducer";
import Item from "antd/lib/list/Item";
import { getDropdownServices } from "../../reducers/icdReducer";
const { Content, Footer, Header } = Layout;

const { Option } = Select;
class AddTemplateIcd extends Component {
    componentDidMount = () => {
        this.props.getDropdownServices();
    };
    idIdcChange = this.props.idIdc;

    onFinish = async (values) => {
        const response = await Axios.post("/api/Indications", values);
        if (response.data.status === 1) {
            message.success(response.data.message);
            this.props.fetchIndications();
            this.props.setModalBasic({ visible: false });
        } else {
            message.error(response.data.message);
        }
    };
    onSelectIcdTemp = async (id) => {
        await this.props.getListIcdTemp(id);
        this.idIdcChange = id;
    };
    onSelectService = async (id) => {
        if (!id) {
            id = "";
        }
        await this.props.getListIcdNew(id);
        await this.props.getListDiagnose(id);
    };
    onDeleteIcdTemp = async (id) => {
        await this.props.deleteIcdTemp(id);
        await this.props.getListIcdTemp(this.idIdcChange);
        await this.props.fetchIcdTemp();
    };
    onSelectDiagnose = async (id) => {
        if (this.idIdcChange) {
            let data = {
                idIdc: this.idIdcChange,
                idDiag: id,
            };
            await this.props.postIcdTemp(data);
            await this.props.getListIcdTemp(this.idIdcChange);
            await this.props.fetchIcdTemp();
        } else {
            message.warning("Vui lòng chọn dịch vụ!");
        }
    };
    render() {
        const { dropdownIcdNew, dropdownDiagnose, listIcdTemp, dropdownServices } = this.props;
        return (
            <Layout className="icd-add-form-content">
                <Header>
                    <p className="modalBasic-title">Phân mẫu Icd</p>
                    <button
                        className="modalBasic-btn-close"
                        onClick={() => this.props.setModalBasic({ visible: false })}
                    >
                        <i className="fas fa-times icon"></i>
                    </button>
                </Header>
                <Content>
                    <Row>
                        <Form.Item style={{ width: "400px", paddingLeft: 5 }} name="serviceId">
                            <Select
                                showSearch
                                placeholder="Chọn dịch vụ"
                                allowClear
                                style={{ width: "100%" }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) => this.onSelectService(value)}
                            >
                                {dropdownServices.map((item) => (
                                    <Option key={item.id} value={Number(item.id)}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Col span={11}>
                            <div style={{ paddingLeft: 5 }}>
                                <Select
                                    showSearch
                                    placeholder="Chọn một ICD"
                                    onSelect={(value) => this.onSelectIcdTemp(value)}
                                    style={{ width: "100%" }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {dropdownIcdNew.map((item) => (
                                        <Option key={item.id} value={Number(item.id)}>{`${item.name}`}</Option>
                                    ))}
                                </Select>
                            </div>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={11}>
                            <Row style={{ paddingRight: 5 }}>
                                <Select
                                    showSearch
                                    placeholder="Chọn mẫu"
                                    onSelect={(value) => this.onSelectDiagnose(value)}
                                    style={{ width: "100%" }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {dropdownDiagnose.map((item) => (
                                        <Option key={item.id} value={Number(item.id)}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Row>
                            <Row>
                                {listIcdTemp.map((item) => (
                                    <Col key={item.idIdcTemp} span={24} style={{ margin: "5px", fontSize: 20 }}>
                                        <span style={{ color: "#000" }}>{item.nameDiag}</span>
                                        <span
                                            onClick={() => this.onDeleteIcdTemp(item.idIdcTemp)}
                                            style={{ color: "#f5222d", cursor: "pointer", marginLeft: 10 }}
                                        >
                                            <i className="fas fa-times"></i>
                                        </span>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        dropdownIcdNew: state.icdTemp.dropdownIcdNew,
        dropdownDiagnose: state.icdTemp.dropdownDiagnose,
        listIcdTemp: state.icdTemp.listIcdTemp,
        dropdownServices: state.icd.dropdownServices,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        fetchIcdTemp: () => dispatch(fetchIcdTemp()),
        getListIcdTemp: (id) => dispatch(getListIcdTemp(id)),
        getListIcdNew: (serviceId) => dispatch(getListIcdNew(serviceId)),
        getListDiagnose: (serviceId) => dispatch(getListDiagnose(serviceId)),
        postIcdTemp: (data) => dispatch(postIcdTemp(data)),
        deleteIcdTemp: (id) => dispatch(deleteIcdTemp(id)),
        getDropdownServices: () => dispatch(getDropdownServices()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTemplateIcd);
