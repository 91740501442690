import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, message } from "antd";
import { deaultDoctorForm, getDoctorSearch } from "../../pages/Home/DoctorPage/doctor";
import { addDoctorToServer, getDoctorFromServer } from "../../reducers/doctorReducer";
import { connect } from "react-redux";
import { handleCloseModalApp } from "../../reducers/uiReducer";

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
class AddFormContent extends Component {
    onFinish = (values) => {
        this.props.addDoctorToServer(values).then(() => {
            this.props.handleCloseModalApp();
            this.props.getDoctorFromServer(getDoctorSearch());
        });
    };

    render() {
        return (
            <Form
                style={{ marginTop: 20 }}
                {...formItemLayout}
                name="addDoctor"
                onFinish={this.onFinish}
                initialValues={deaultDoctorForm}
                scrollToFirstError
            >
                <Form.Item name="name" label="Họ tên">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="gender" label="Giới tính">
                    <Radio.Group>
                        <Radio value="Nam">Nam</Radio>
                        <Radio value="Nữ">Nữ</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="address" label="Địa chỉ">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="doB" label="Ngày sinh">
                    <DatePicker format="DD/MM/YYYY" placeholder="" style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item name="email" label="Email">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="phoneNumber" label="Số điện thoại">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="userName" label="Tên đăng nhập">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="password" label="Mật khẩu">
                    <Input allowClear type="password" />
                </Form.Item>
                <Form.Item name="confirmPassword" label="Nhập lại mật khẩu">
                    <Input allowClear type="password" />
                </Form.Item>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}></div>
                    <div style={{ flex: 3 }}>
                        <Button style={{ color: "#fff", background: "#2687d4" }} htmlType={"submit"}>
                            Thêm mới
                        </Button>
                    </div>
                </div>
            </Form>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        addDoctorToServer: (doctor) => dispatch(addDoctorToServer(doctor)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        getDoctorFromServer: (search) => dispatch(getDoctorFromServer(search)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFormContent);
