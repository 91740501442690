import Axios from "axios";
import { message, notification } from "antd";
import { store } from "../store/store";
const ACTION_TYPES = {
    SET_WELCOME: "SET_WELCOME",
};

const initialstate = {
    listServices: [],
	listOders:[],
	listExecutor:[],
	listRoom:[],
	totalCount:0,
};

const welcomeReducer = (state = initialstate, action) => {
    switch (action.type) {
		case ACTION_TYPES.SET_WELCOME:
            state = action.payload;
            return {...state };
        default:
            return state;
    }
};

const getWorklist = (code) => async (dispatch) => {
    
	try{
		var response = await Axios.post('api/worklist', { code }, {
			headers: {
				'Content-Type': 'application/json',
				'X-Header': 'VRPACS'
			}
		});
		if (response.status === 200) {
			if (response.data.status == "1") {
				notification["success"]({
					message: 'Thành công!',
					description: response.data.message
				});
				dispatch(setWorkList({...store.getState().worklist, listOders: response.data.data }));
				return true;
			} else if (response.data.status == "2") {
				notification["warning"]({
					message: 'Cảnh báo!',
					description: response.data.message
				});
				return false;
			} else {
				notification["error"]({
					message: 'Thất bại!',
					description: response.data.message
				});
				return false;
			}
		}
	}catch{
		notification["error"]({
			message: 'Thất bại!',
			description: "Có lỗi xảy ra, vui lòng thử lại sau!"
		});
		return false;
	}
};

const getListOders = (request) => async (dispatch) => {
    await Axios.post('api/worklist/get-oders',request, {
		headers: {
			'Content-Type': 'application/json',
			'X-Header': 'VRPACS'
		}
	}
	).then((response) => {
		if (response.status === 200) {
			if (response.data.status == "1") {
				dispatch(setWorkList({...store.getState().worklist, listOders: response.data.data, totalCount: response.data.dataCount}));
			} else{
				dispatch(setWorkList({...store.getState().worklist, listOders:[]}));
				notification["error"]({
					message: 'Thất bại!',
					description: "Có lỗi xảy ra, vui lòng thử lại sau!"
				});
			}
		}
	})
		.catch((err) => {
			if (err.response) {
				notification["error"]({
					message: 'Thất bại!',
					description: "Có lỗi xảy ra, vui lòng thử lại sau!"
				});
			}
		});
};
const fetchDropdownExecutor = () => async(dispatch) => {
    const response = await Axios.get(`/api/worklist/dropdown-executor`);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setWorkList({...store.getState().worklist, listExecutor: response.data.data }));
        }
    }
};
const fetchDropdownRoom = () => async(dispatch) => {
    const response = await Axios.get(`/api/worklist/dropdown-room`);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setWorkList({...store.getState().worklist, listRoom: response.data.data }));
        }
    }
};

const updateExecutor = (data) => async (dispatch) => {
	try{
		var result = await Axios.post(`api/worklist/update-executor`,data, {
			headers: {
				'Content-Type': 'application/json',
				'X-Header': 'VRPACS'
			}
		});
		if(result.status==200){
			if(result.data.status == "1"){
				notification["success"]({
					message: 'Thành công!',
					description: result.data.message
				})
				return true;
			}else if (result.data.status == "2") {
				notification["warning"]({
					message: 'Cảnh báo!',
					description: result.data.message
				})
				return false;
			} else {
				notification["error"]({
					message: 'Thất bại!',
					description: result.data.message
				})
				return false;
			}
		}
	}catch{
		notification["error"]({
			message: 'Thất bại!',
			description: "Có lỗi xảy ra, vui lòng thử lại sau!"
		});
		return false;
	}
}
const updateRoom = (data) => async (dispatch) => {
	try{
		var result = await Axios.post(`api/worklist/update-room`,data, {
			headers: {
				'Content-Type': 'application/json',
				'X-Header': 'VRPACS'
			}
		});
		if(result.status==200){
			if(result.data.status == "1"){
				notification["success"]({
					message: 'Thành công!',
					description: result.data.message
				})
				return true;
			}else if (result.data.status == "2") {
				notification["warning"]({
					message: 'Cảnh báo!',
					description: result.data.message
				})
				return false;
			} else {
				notification["error"]({
					message: 'Thất bại!',
					description: result.data.message
				})
				return false;
			}
		}
	}catch{
		notification["error"]({
			message: 'Thất bại!',
			description: "Có lỗi xảy ra, vui lòng thử lại sau!"
		});
		return false;
	}
}
const delService = (code) => async (dispatch) => {
	if (!code) {
		notification["warning"]({
			message: 'Cảnh báo!',
			description: "Mã không xác định! Vui lòng thử lại."
		})
		return false;
	}
	try{
		var result = await Axios.delete(`api/worklist/${code}/cancel`, {
			headers: {
				'Content-Type': 'application/json',
				'X-Header': 'VRPACS'
			}
		});
		if(result.status==200){
			if(result.data.status == "1"){
				return true;
			}else if (result.data.status == "2") {
				notification["warning"]({
					message: 'Cảnh báo!',
					description: result.data.message
				})
				return false;
			} else {
				notification["error"]({
					message: 'Thất bại!',
					description: result.data.message
				})
				return false;
			}
		}
	}catch{
		notification["error"]({
			message: 'Thất bại!',
			description: "Có lỗi xảy ra, vui lòng thử lại sau!"
		});
		return false;
	}
}

const setWorkList = (services) => {
    return {
        type: ACTION_TYPES.SET_WELCOME,
        payload: services,
    };
};

export {
    welcomeReducer,
	delService,
	getWorklist,
	getListOders,
	fetchDropdownExecutor,
	fetchDropdownRoom,
	updateExecutor,
	updateRoom
};
