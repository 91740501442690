import React, { Component } from "react";
import { Form, Input, Layout, DatePicker, Button, message, Checkbox } from "antd";
import { deaultTypeServiceForm, getTypeServiceSearch } from "../../pages/Home/TypeServicePage/typeservice";
import { addTypeServiceToServer, getTypeServiceFromServer } from "../../reducers/typeserviceReducer";
import { connect } from "react-redux";
import { handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
import { formTypeService } from "../../pages/Home/TypeServicePage/typeservice";
const { Content, Footer, Header } = Layout;

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
class AddFormContent extends Component {

    onFinish = (values) => {
        this.props.addTypeServiceToServer(values).then(() => {
            this.props.getTypeServiceFromServer(getTypeServiceSearch());
            this.props.setModalBasic({ visible: false })
        });
    };

    render() {
        return (
            <>
                <Layout className="doctor-add-form-content">
                    <Header>
                        <p className="modalBasic-title">Thêm loại dịch vụ</p>
                        <button
                            className="modalBasic-btn-close"
                            onClick={() => this.props.setModalBasic({ visible: false })}
                        >
                            <i className="fas fa-times icon"></i>
                        </button>
                    </Header>
                    <Content>
                        <Form
                            ref={(ref) => (this.refForm = ref)}
                            style={{ marginTop: 20 }}
                            {...formItemLayout}
                            name="addDoctor"
                            onFinish={this.onFinish}
                            scrollToFirstError
                        >
                            <Form
                                style={{ marginTop: 20 }}
                                {...formItemLayout}
                                name="addTypeService"
                                onFinish={this.onFinish}
                                initialValues={formTypeService}
                                scrollToFirstError
                            >
                                <Form.Item name="name" label="Tên" rules={[{ required: true, message:"vui lòng nhập tên!" }]}>
                                    <Input allowClear />
                                </Form.Item>
                                <Form.Item name="shortName" label="Rút gọn" rules={[{ required: true, message:"vui lòng nhập tên rút gọn!" }]}>
                                    <Input allowClear />
                                </Form.Item>
                                <Form.Item name="code" label="Mã" rules={[{ required: true, message:"vui lòng nhập mã!" }]}>
                                    <Input allowClear />
                                </Form.Item>
                                <Form.Item name="active" label="Trạng thái" valuePropName="checked">
                                    <Checkbox />
                                </Form.Item>
                                <Button
                                    style={{ display: "none" }}
                                    id="btn-submit-add-typeservices"
                                    htmlType={"submit"}
                                >
                                    Thêm mới
                                </Button>
                            </Form>
                        </Form>
                    </Content>
                    <Footer>
                        <Button
                            style={{ color: "#fff", background: "#2687d4" }}
                            onClick={() => document.getElementById("btn-submit-add-typeservices").click()}
                        >
                            Thêm mới
                        </Button>
                    </Footer>
                </Layout>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        addTypeServiceToServer: (typeservice) => dispatch(addTypeServiceToServer(typeservice)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        getTypeServiceFromServer: (search) => dispatch(getTypeServiceFromServer(search)),
        setModalBasic: (data) => dispatch(setModalBasic(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFormContent);
