import React, { Component } from "react";
import { connect } from "react-redux";
import {deleteIndicateFromServer,fetchDropdownServerPacs, handleRecoveryIndicate, getServiceEquipFromServer, fetchDropdownSvpByHosId, PostServiceEquipFromServer } from "../../../reducers/indicateReducer";
import { getServiceExSearch, initialServiceExSearch, setServiceExSearch } from "./indicate";
import { handleOpenModalApp, setModalBasic } from "../../../reducers/uiReducer";
import ContentInfo from "../../../components/Indicate/ContentInfo";
import ContentEdit from "../../../components/Indicate/ContentEdit";
import IndicateSearch from "../../../components/Indicate/IndicateSearch";
import IndicateTable from "../../../components/Indicate/IndicateTable";
import ContentEditTime from "../../../components/Indicate/ContentEditTime";
import ChangeServerPacs from "../../../components/Indicate/ChangeServerPacs";
import ChangePath from "../../../components/Indicate/ChangePath";
import { Form ,Input, Button, DatePicker, Select,InputNumber, Pagination, message} from "antd";
import { removeVietnameseTones } from "../../../utils";
import moment from "moment";
import { fetchDropdownHospital, fetchDropdownInsurance } from "../../../reducers/staticStudiesReducer";
import { fetchDropdownModalities } from "../../../reducers/statisticReducer";
import { setLoading } from "../../../reducers/loadingHomeReducer";
import { getConfigurationFromServer } from "../../../reducers/configurationReducer";
import { getConfigurationSearch } from "../ConfigurationPage/configuration";

const { Option } = Select;

class ServiceEquipPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataPost: [],
            dataListServiceEquip:[],
        };
        this.formSeach = null;
        this.formData = null;
    }

    componentDidMount = async () => {
        this.props.setLoading(true);
        let configurationSearch = getConfigurationSearch();
        configurationSearch.PageSize = 1000;
        this.props.getConfigurationFromServer(configurationSearch);
        configurationSearch.PageSize = 10;
        this.props.fetchDropdownHospital(); // load danh sách thư mục
        this.props.fetchDropdownSvpByHosId();//load danh sách maý
        this.props.fetchDropdownInsurance();//load danh sách bảo hiểm (trong bảng bệnh nhân)
        this.props.fetchDropdownModalities();//load dữ liệu
        await this.props.getServiceEquipFromServer(getServiceExSearch());
        this.setState({dataListServiceEquip:this.props.listServiceEquip});
        this.props.setLoading(false);
    };
    
    //Update dữ liệu cho bảng
    handleUpdateTable = async () => {
        this.props.setLoading(true);
        await this.props.getServiceEquipFromServer(getServiceExSearch());
        this.setState({dataListServiceEquip:this.props.listServiceEquip,dataPost:[]});
        this.props.setLoading(false);
    };
    handleOpenModal = (modalData) => {
        this.props.handleOpenModalApp(modalData);
    };
    handleShowInfo = (indicateId, indicateName) => {
        const modalData = {
            title: [<p>Thông tin bác sĩ</p>],
            visible: true,
            width: 900,
            height: 500,
            content: <ContentInfo indicateId={indicateId} indicateName={indicateName} />,
        };
        this.props.handleOpenModalApp(modalData);
    };
    handleEdit = (indicateId, indicateName) => {
        const modalData = {
            title: [<p key="Thay đổi thông tin bác sĩ">Thay đổi thông tin bác sĩ</p>],
            visible: true,
            width: 900,
            height: 500,
            content: <ContentEdit indicateId={indicateId} indicateName={indicateName} />,
        };
        this.props.handleOpenModalApp(modalData);
    };
    handleEditTime = (indicateId, indicateName) => {
        const { listServerPacs } = this.props;
        const modalData = {
            title: [<p key="Thay đổi Thời gian thực hiện">Thay đổi Thời gian thực hiện</p>],
            visible: true,
            width: 900,
            height: 400,
            content: <ContentEditTime indicateId={indicateId} indicateName={indicateName} listServerPacs={listServerPacs}/>,
            name: "modal--indication-add",
        };
        this.props.setModalBasic(modalData);
    };
    handleAdd = (values) => {
    };
    handleChangeServerPacs = () => {
        const { listServerPacs } = this.props;
        const modalData = {
            title: [<p key="Đổi máy chụp">Đổi máy chụp</p>],
            visible: true,
            width: 900,
            height: 300,
            content: <ChangeServerPacs listServerPacs={listServerPacs}/>,
            name: "modal--indication-change-serverPacs",
        };
        this.props.setModalBasic(modalData);
    };
    handleChangePath = () => {
        const { listServerPacs } = this.props;
        const modalData = {
            title: [<p key="Đổi đường dẫn">Đổi đường dẫn</p>],
            visible: true,
            width: 900,
            height: 300,
            content: <ChangePath listServerPacs={listServerPacs}/>,
            name: "modal--indication-change-path",
        };
        this.props.setModalBasic(modalData);
    };
    handleDeleteIndicate = (id) => {
        this.props.deleteIndicateFromServer(id).then(() => {
            this.props.getServiceEquipFromServer(getServiceExSearch());
        });
    };
    handleRecoveryIndicate = (id) => {
        this.props.handleRecoveryIndicate(id).then(() => {
            this.props.getServiceEquipFromServer(getServiceExSearch());
        });
    };

    onFinish = (values) => {
        //Tìm kiếm
        if(this.state.dataPost.length >0){
            if (window.confirm("Có giá trị thay đổi chưa được lưu lại, vẫn muốn tiếp tục!") == true) {
                let indicateSearch = getServiceExSearch();
                indicateSearch.fromDate = moment(values.fromDate._d).format().substring(0, 10);
                indicateSearch.toDate = moment(values.toDate._d).format().substring(0, 10);
                if(values.HospitalID) //Kiểm tra id, nếu không có giá trị, set null
                    indicateSearch.HospitalID = Number(values.HospitalID);
                else
                    indicateSearch.HospitalID = null;
                if(values.serverId) //Kiểm tra id, nếu không có giá trị, set null
                    indicateSearch.serverId = Number(values.serverId);
                else
                    indicateSearch.serverId = null;
                indicateSearch.pCode = values.pCode;
                indicateSearch.pName = values.pName;
                indicateSearch.modality = values.modality;
                indicateSearch.pType = values.pType;
                indicateSearch.PageIndex =1;
                setServiceExSearch(indicateSearch);
                this.handleUpdateTable(); //load dữ liệu
            }
        }else{
            let indicateSearch = getServiceExSearch();
            indicateSearch.fromDate = moment(values.fromDate._d).format().substring(0, 10);
            indicateSearch.toDate = moment(values.toDate._d).format().substring(0, 10);
            if(values.HospitalID) //Kiểm tra id, nếu không có giá trị, set null
                indicateSearch.HospitalID = Number(values.HospitalID);
            else
                indicateSearch.HospitalID = null;
            if(values.serverId) //Kiểm tra id, nếu không có giá trị, set null
                indicateSearch.serverId = Number(values.serverId);
            else
                indicateSearch.serverId = null;
            indicateSearch.pCode = values.pCode;
            indicateSearch.pName = values.pName;
            indicateSearch.modality = values.modality;
            indicateSearch.pType = values.pType;
            indicateSearch.PageIndex =1;
            setServiceExSearch(indicateSearch);
            this.handleUpdateTable(); //load dữ liệu
        }
    };

    onChangeHospital = (ev) =>{
        this.props.fetchDropdownSvpByHosId(ev);
        this.formSeach.setFieldsValue({ serverID: "" });
    }

    convertGender = (gender) => {
        if (gender == "M" || gender == "1" || gender == "Nam") return "Nam";
        return "Nữ";
    };

    appendLeadingZeroes = (n) => {
        if (n <= 9) {
            return "0" + n;
        }
        return n;
    };

    formatDatetime = (time) => {
        var date = new Date(time);
        var month = this.appendLeadingZeroes(date.getMonth() + 1); //months from 1-12
        var day = this.appendLeadingZeroes(date.getDate());
        var year = date.getFullYear();
        var hours = date.getHours();
        var minutes = date.getMinutes(); // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = day + "/" + month + "/" + year + " " + hours + ":" + minutes;
        return strTime;
    };

    //Thay đổi giá trị input
    editField = (value,index,equipProps ) => {
      //Nếu không phải số, return
      if(isNaN(value))
         return;
      //Set lại value cho state
      let {dataListServiceEquip} = this.state;
      dataListServiceEquip[index][equipProps] = value;
      //Clone students data before mutation
      const listSvEx = this.props.listServiceEquip.map(item => ({ ...item }));
      let {dataPost} = {...this.state}; //Lấy ra danh sách data save
      const item = listSvEx[index]; //Lấy ra phần tử edit trong mảng
      let itemPost = dataPost.find(elememt => elememt.svid == item.id);//Tìm kiếm phần tử trong ds data save
      if(itemPost){//nếu có, thay đổi giá trị
        itemPost[equipProps] = isNaN(value)?item[equipProps]:Number(value);
      }else{//nếu không gán giá trị cho phần tử và thêm vào mảng data save
        itemPost =  {
            sid : item.studyID,
            svid: item.id,
            f10x12:item.f10x12,
            f8x10:item.f8x10,
            f3x4:item.f3x4,
            barit :item.barit,
            xenetic:item.xenetic,
            bomtiem:item.bomtiem,
            kim:item.kim,
            fct :item.fct,
            svPrice:item.svPrice
        }
        // đông anh
        // itemPost =  {
        //     sid : item.studyID,
        //     svid: item.id,
        //     f10x12:item.f10x12,
        //     f8x10:item.f8x10,
        //     fRang:item.fRang,
        //     fct :item.fct,
        //     svPrice:item.svPrice
        // }
        itemPost[equipProps] = isNaN(value)?item[equipProps]:Number(value);
        dataPost.push(itemPost);
      }
      this.setState({dataPost:dataPost});//Set ds data save vào state
    };

    //Lưu tất cả
    onSave = async ()=>{
        //Kiểm tra data
        const {dataPost} =this.state;
        if(dataPost.length<1)
        {
            message.warning("Dữ liệu không thay đổi!")
            return
        }
        const response = await this.props.PostServiceEquipFromServer(dataPost);
        if (response.status === 200) {
            if (response.data.status === 1) {
                message.success("Lưu thành công!");
                this.handleUpdateTable();
            }else{
                message.error(`Có lỗi xảy ra! Vui lòng thử lại sau! ${response.data.message}`);
            }
        } else {
            message.error(response.status);
        }
    };

    //Chuyển trang
    onChangePage = (page, pageSize) => {
        //Kiểm tra xem đã lưu dữ liệu chưa.
        if(this.state.dataPost.length >0){//Nếu chưa, hỏi xem có muốn tiếp tục tìm kiếm?
            if (window.confirm("Có giá trị thay đổi chưa được lưu lại, vẫn muốn tiếp tục!") == true) {
                setServiceExSearch({
                    ...getServiceExSearch(),
                    PageIndex: page,
                    PageSize: pageSize,
                });
                this.handleUpdateTable();
            }
        }else{
            setServiceExSearch({
                ...getServiceExSearch(),
                PageIndex: page,
                PageSize: pageSize,
            });
            this.handleUpdateTable();
        }
    };

    render() {
        const { listHospital, listServerPacs,listInsurance ,listModalities,totalCountEquip, configurations} = this.props;
        
        if(configurations.length == 0){
            return null;
        }
        let listServiceEquipString = "";
        if(configurations.length > 0)
        {
            listServiceEquipString = configurations.find(({ configCode }) => configCode === 'ListServiceEquip')?.configName;
        }
        let listServiceEquip;
        if(listServiceEquipString && listServiceEquipString != ""){
            try{
                listServiceEquip = JSON.parse(listServiceEquipString??"");
            }catch {
                console.log("CONFIG KHÔNG ĐÚNG ĐỊNH DẠNG, " + listServiceEquipString + " KHÔNG THỂ CONVERT")
            }
        }
        //console.log(Object.keys(listServiceEquip).length);
        const {dataListServiceEquip} = this.state;
        const indexPage = getServiceExSearch().PageSize*(getServiceExSearch().PageIndex-1);
        return (
            <div className="indicate-page-d" style={{height:"100vh"}}>
                <div>
                <div className="indicate-search-d">
                <Form
                        name="horizontal_login"
                        ref={(ref) => (this.formSeach = ref)}
                        initialValues={initialServiceExSearch}
                        layout="inline"
                        onFinish={this.onFinish}
                    >
                        <Form.Item name="fromDate" style={{ marginTop: 5 }}>
                            <DatePicker format="DD/MM/YYYY" placeholder="" />
                        </Form.Item>
                        <Form.Item name="toDate" style={{ marginTop: 5 }}>
                            <DatePicker format="DD/MM/YYYY" placeholder="" />
                        </Form.Item>
                        <Form.Item name="modality" label="Nhóm dịch vụ" style={{ marginTop: 5 }}>
                        <Select
                                defaultValue=""
                                showSearch
                                style={{ width: "200px" }}
                                filterOption={(input, option) =>
                                    {
                                        option.children = removeVietnameseTones(option.children);
                                        input = removeVietnameseTones(input);
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    }
                                >
                                    <Option value="">Tất cả</Option>
                                    {
                                        listModalities.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>
                                                    {item.name}
                                                </Option>
                                            );
                                        })
                                    }
                            </Select>
                        </Form.Item>
                        <Form.Item name="HospitalID" label="Thư mục" style={{marginTop: 5, width: 250 }}>
                            <Select
                                defaultValue=""
                                showSearch
                                style={{ width: "100%" }}
                                onChange = {this.onChangeHospital}
                                filterOption={(input, option) =>
                                    {
                                        option.children = removeVietnameseTones(option.children);
                                        input = removeVietnameseTones(input);
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    }
                                >
                                    <Option value="">Tất cả</Option>
                                    {
                                        listHospital.map((item) => {
                                            return (
                                                <Option key={item.id} value={item.id}>
                                                    {item.name}
                                                </Option>
                                            );
                                        })
                                    }
                            </Select>
                        </Form.Item>
                        <Form.Item name="serverID" label="Vị trí thực hiện" style={{marginTop: 5, width: 350 }}>
                            <Select
                                defaultValue=""
                                showSearch
                                style={{ width: "100%" }}
                                filterOption={(input, option) =>
                                    {
                                        option.children = removeVietnameseTones(option.children);
                                        input = removeVietnameseTones(input);
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    }
                                >
                                    <Option value="">Tất cả</Option>
                                    {listServerPacs.map((item) => {
                                        return (
                                            <Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                        <Form.Item name="pCode" allowClear label="Mã bệnh nhân" style={{ marginTop: 5 }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="pName" allowClear label="Tên bệnh nhân" style={{ marginTop: 5 }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="pType" label="BHYT" style={{ marginTop: 5 }}>
                        <Select
                                defaultValue=""
                                showSearch
                                style={{ width: "100%" }}
                                filterOption={(input, option) =>
                                    {
                                        option.children = removeVietnameseTones(option.children);
                                        input = removeVietnameseTones(input);
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    }
                                >
                                    <Option value="">Tất cả</Option>
                                    {listInsurance.map((item) => {
                                        return (
                                            <Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                        <div style={{ marginTop: 5 }}>
                            <Button style={{ background: "#2a274c", color: "#fff" }} htmlType="submit">
                                Tìm kiếm
                            </Button>
                            <Button style={{ background: "#1890ff", color: "#fff" ,marginLeft:10}} onClick={this.onSave}>
                                Lưu
                            </Button>
                        </div>
                </Form>
                </div>
            </div>
            <div className={"service-equip-table-d"}>
            <div style={{ fontWeight: "bold", textAlign: "center" }}>
                    <span>Tổng số ca: {totalCountEquip}</span>
                </div>
                <table border="0" className="table-admin" style={{width:"100%"}}>
                    <thead>
                        <tr>
                            <th rowSpan={2}>STT</th>
                            <th rowSpan={2}>Mã BN</th>
                            <th rowSpan={2} style = {{minWidth:"100px"}}>Họ tên</th>
                            <th colSpan={2} style = {{textAlign:"center"}}>Tuổi</th>
                            <th rowSpan={2} style = {{minWidth:"150px",textAlign:"center"}}>Địa chỉ</th>
                            <th rowSpan={2}>BHYT</th>
                            <th rowSpan={2} style = {{minWidth:"50px",maxWidth:300,textAlign:"center"}}>Chẩn đoán</th>
                            <th rowSpan={2} style = {{minWidth:"50px",maxWidth:250,textAlign:"center"}}>Yêu cầu</th>
                            <th rowSpan={2} style = {{minWidth:"140px"}}>Thời gian thực hiện</th>
                            {/* đông anh*/}
                            {/* <th colSpan={4} style = {{textAlign:"center"}}>Cỡ phim</th> */}
                            {/* đông anh*/}
                            {/* đống đa*/}
                            <th colSpan={listServiceEquip ? (Object.keys(listServiceEquip)?.length) : 4} style = {{textAlign:"center"}}>Cỡ phim</th>
                            {/* đống đa*/}
                            <th rowSpan={2}>Giá</th>
                        </tr>
                        {/* đống đa*/}
                        <tr>
                            <th>Nam</th>
                            <th>Nữ</th>
                            {listServiceEquip ?
                                Object.keys(listServiceEquip)?.map(function(key, index){
                                    return(
                                        <th key={listServiceEquip[key]}>{listServiceEquip[key]}</th>
                                    );
                                })
                                : 
                                <>
                                    <th>F8x10</th>
                                    <th>F10x12</th>
                                    <th>FRang</th>
                                    <th>FCT/MR</th>
                                </>
                            }
                            
                        </tr>
                        {/* đống đa*/}
                        {/* đông anh
                        <tr>
                            <th>Nam</th>
                            <th>Nữ</th>
                            <th>F8x10</th>
                            <th>F10x12</th>
                            <th>FRang</th>
                            <th>FCT/MR</th>
                        </tr> */}
                    </thead>
                    <tbody>
                        
                        {dataListServiceEquip.map((serviceEquip, index) => {
                            return (
                                <tr key={index}>
                                    <td>{indexPage+index+1}</td>
                                    <td>{serviceEquip.pCode}</td>
                                    <td>{serviceEquip.pName}</td>
                                    <td>{this.convertGender(serviceEquip.sex) == "Nam" ? serviceEquip.pAge : ""}</td>
                                    <td>{this.convertGender(serviceEquip.sex) == "Nữ" ? serviceEquip.pAge : ""}</td>
                                    <td>{serviceEquip.pAddress}</td>
                                    <td>{serviceEquip.pType}</td>
                                    <td>{serviceEquip.clinicalDiagnosis}</td>
                                    <td>{serviceEquip.serviceName}</td>
                                    <td>{this.formatDatetime(serviceEquip.timeEx)}</td>
                                    {/* đông anh
                                    <td><Input style={{width:"50px"}} value = {serviceEquip.f8x10} onChange={e => this.editField(e.target.value, index,"f8x10")} /></td>
                                    <td><Input style={{width:"50px"}} value = {serviceEquip.f10x12} onChange={e => this.editField(e.target.value, index,"f10x12")} /></td>
                                    <td><Input style={{width:"50px"}} value = {serviceEquip.fRang} onChange={e => this.editField(e.target.value, index,"fRang")} /></td>
                                    <td><Input style={{width:"50px"}} value = {serviceEquip.fct} onChange={e => this.editField(e.target.value, index,"fct")} /></td> 
                                    */}
                                    {/* Đống đa*/}
                                    {listServiceEquip ?
                                        Object.keys(listServiceEquip)?.map((key, i) => {
                                            return(
                                                <td key={i}><Input style={{width:"50px"}} value = {serviceEquip[key]??0} onChange={e => this.editField(e.target.value, index, key)} /></td>
                                            );
                                        })
                                        :
                                        <>
                                            <td><Input style={{width:"50px"}} value = {serviceEquip.f8x10} onChange={e => this.editField(e.target.value, index,"f8x10")} /></td>
                                            <td><Input style={{width:"50px"}} value = {serviceEquip.f10x12} onChange={e => this.editField(e.target.value, index,"f10x12")} /></td>
                                            <td><Input style={{width:"50px"}} value = {serviceEquip.fRang} onChange={e => this.editField(e.target.value, index,"fRang")} /></td>
                                            <td><Input style={{width:"50px"}} value = {serviceEquip.fct} onChange={e => this.editField(e.target.value, index,"fct")} /></td> 
                                        </>
                                    }
                                    {/* Đống đa*/}
                                    <td><Input style={{width:"120px"}} value = {serviceEquip.svPrice??0} onChange={e => this.editField(e.target.value, index,"svPrice")} /></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div style={{ padding: "0 50px", background: "inherit"}}>
                    <div className="pagination-d" style={{ marginTop: 5 }}>
                        <Pagination
                            current={getServiceExSearch().PageIndex}
                            total={totalCountEquip}
                            showQuickJumper
                            showTitle={false}
                            defaultPageSize={getServiceExSearch().PageSize}
                            onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                            defaultCurrent={3}
                        />
                    </div>
                </div>                 
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        listServiceEquip: state.indicate.listServiceEquip,
        totalCountEquip: state.indicate.totalCountEquip,
        listServerPacs: state.indicate.listServerPacs,
        listHospital: state.staticStudies.listHospital,
        listInsurance: state.staticStudies.listInsurance,
        listModalities: state.statistic.listModalities,
        configurations: state.configuration.configurations,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getServiceEquipFromServer: (search) => dispatch(getServiceEquipFromServer(search)),
        PostServiceEquipFromServer: (request) => dispatch(PostServiceEquipFromServer(request)),
        handleOpenModalApp: (modalData) => dispatch(handleOpenModalApp(modalData)),
        deleteIndicateFromServer: (id) => dispatch(deleteIndicateFromServer(id)),
        handleRecoveryIndicate: (id) => dispatch(handleRecoveryIndicate(id)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        fetchDropdownSvpByHosId: (id) => dispatch(fetchDropdownSvpByHosId(id)),
        fetchDropdownHospital:()=>dispatch(fetchDropdownHospital("")),
        fetchDropdownInsurance: ()=> dispatch(fetchDropdownInsurance()),
        fetchDropdownModalities: ()=> dispatch(fetchDropdownModalities()),
        setLoading: (data) => dispatch(setLoading(data)),
        getConfigurationFromServer: (search) => dispatch(getConfigurationFromServer(search)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceEquipPage);
