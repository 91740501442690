import Axios from "axios";
import { getIcdSearch } from "../models/icdModel";
import { store } from "../store/store";
import { message } from "antd";

const ACTION_TYPES = {
    SET_ICD_DATA: "SET_ICD_DATA",
};

const initialstate = {
    data: [],
    total: 0,
    dropdownServices: [],
};

const icdReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_ICD_DATA:
            state = action.payload;
            return {...state };
        default:
            return state;
    }
};

const fetchIndications = () => async dispatch => {
    const params = getIcdSearch();
    const response = await Axios.get(`/api/Indications/paging?Keyword=${params.Keyword}&code=${params.code}&ServiceID=${params.serviceId}&PageIndex=${params.PageIndex}&PageSize=${params.PageSize}`)
    if (response.data.status === 1) {
        dispatch(setDataICD({...store.getState().icd, data: response.data.data, total: response.data.dataCount }))
    }
}

const setDropdownServices = (services) => {
    return {
        type: ACTION_TYPES.SET_DROPDOWN_SERVICES,
        payload: services,
    };
};
const getDropdownServices = () => async(dispatch) => {
    await Axios.get(`api/templates/dropservices`)
        .then((response) => {
            if (response.data.status === 1) {
                dispatch(setDataICD({...store.getState().icd, dropdownServices: response.data.data }))
            }
            
        })
        .catch((error) => {});
};
// const fetchIndications = () => async dispatch => {
//     const params = getIcdSearch();
//     const response = await Axios.get(`/api/Indications/paging?Keyword=${params.Keyword}&code=${params.code}&PageIndex=${params.PageIndex}&PageSize=${params.PageSize}`)
//     if (response.data.status === 1) {
//         dispatch(setDataICD({...store.getState().icd, dataTemplateManager: response.data.data }))
//     }
// }

const setDataICD = (data) => {
    return {
        type: ACTION_TYPES.SET_ICD_DATA,
        payload: data,
    };
};

//Active hospital
const activeIcd = (id) => async(dispatch) => {
    await Axios.put(`/api/indications/${id}/active`)
        .catch((err) => {
            message.error("Error!");
        });
}

const inactiveIcd = (id) => async(dispatch) => {
    await Axios.put(`/api/indications/${id}/inactive`)
        .catch((err) => {
            message.error("Error!");
        });
}

export { icdReducer, fetchIndications, setDataICD, getDropdownServices, activeIcd, inactiveIcd }