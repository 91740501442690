import Axios from "axios";
import { message } from "antd";
const ACTION_TYPES = {
    SET_TEMPLATES: "SET_TEMPLATES",
    SET_TEMPLATE_ITEM: "SET_TEMPLATE_ITEM",
    SET_DROPDOWN_SERVICES: "SET_DROPDOWN_SERVICES",
};

const initialstate = {
    templates: [],
    totalCount: 0,
    templateItem: {},
    dropdownServices: [],
};

const templateReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_TEMPLATES:
            state = { ...state };
            state.templates = action.payload.data;
            state.totalCount = action.payload.dataCount;
            return { ...state };
        case ACTION_TYPES.SET_TEMPLATE_ITEM:
            state.templateItem = action.payload;
            return { ...state };
        case ACTION_TYPES.SET_DROPDOWN_SERVICES:
            state.dropdownServices = action.payload;
            return { ...state };
        default:
            return state;
    }
};

const setTemplates = (Templates) => {
    return {
        type: ACTION_TYPES.SET_TEMPLATES,
        payload: Templates,
    };
};
const getTemplateFromServer = (request) => async (dispatch) => {
    await Axios.get(
        `api/Templates/paging?Keyword=${request.Keyword}&ServiceID=${request.ServiceID}&PageIndex=${request.PageIndex}&PageSize=${request.PageSize}`
    ).then((response) => {
        if (response.status === 200) {
            if (response.data.status === 1) {
                dispatch(setTemplates(response.data));
            }
        }
    });
};
const setDropdownServices = (services) => {
    return {
        type: ACTION_TYPES.SET_DROPDOWN_SERVICES,
        payload: services,
    };
};
const getDropdownServices = () => async (dispatch) => {
    await Axios.get(`api/Templates/dropservices`)
        .then((response) => {
            if (response.data.status === 1) {
                dispatch(setDropdownServices(response.data.data));
            }
            
        })
        .catch((error) => { });
};
const setTemplateItem = (Template) => {
    return {
        type: ACTION_TYPES.SET_TEMPLATE_ITEM,
        payload: Template,
    };
};
const getTemplateById = (id) => async (dispatch) => {
    const response = await Axios.get(`api/Templates/${id}`);
    if (response.data.status === 1) {
        dispatch(setTemplateItem(response.data.data));
    }
    
};

const updateTemplateToServer = (id, Template) => async (
    dispatch
) => {
    await Axios.put(`api/Templates/${id}`, Template)
        .then((response) => {
            if (response.data) {
                message.success(response.data.message);
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let messages = "";
                if (e.Email) {
                    for (let i of e.Email) {
                        messages += i + "! ";
                    }
                }
                if (e.PhoneNo) {
                    for (let i of e.PhoneNo) {
                        messages += i + "! ";
                    }
                }
                if (e.Name) {
                    for (let i of e.Name) {
                        messages += i + "! ";
                    }
                }
                message.error(messages, 5);
            }
        });
};

const addTemplateToServer = (request) => async (dispatch) => {
    await Axios.post("api/Templates", request)
        .then((response) => {
            if (response.status === 200) {
                message.success("Successfuly!");
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let mes = "";
                if (e.Name) {
                    for (let i of e.Name) {
                        mes += i + "! ";
                    }
                }
                message.error(mes, 3);
            }
        });
};

const deleteTemplateFromServer = (id) => async (dispatch) => {
    await Axios.delete(`api/Templates/${id}`)
        .then((response) => {
            message.success(response.data.message);
        })
        .catch((err) => {
            message.error("Bạn không có quyền xóa Bác sĩ này!");
        });
};

//Active hospital
const activeTemplate = (id) => async (dispatch) => {
    await Axios.put(`/api/templates/${id}/active`)
        .catch((err) => {
            message.error("Error!");
        });
}

const inactiveTemplate = (id) => async (dispatch) => {
    await Axios.put(`/api/templates/${id}/inactive`)
        .catch((err) => {
            message.error("Error!");
        });
}

export {
    addTemplateToServer,
    deleteTemplateFromServer,
    getTemplateFromServer,
    templateReducer,
    getTemplateById,
    updateTemplateToServer,
    getDropdownServices,
    activeTemplate,
    inactiveTemplate
};
