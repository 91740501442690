import React, { Component } from "react";
import { Form, Input, Radio, Layout, Button, Select, InputNumber } from "antd";
import { addRoleToServer, getRoleFromServer } from "../../reducers/roleManagerReducer";
import { connect } from "react-redux";
import { handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
const { Option } = Select;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const { Content, Footer, Header } = Layout;

class AddRoleFormContent extends Component {
    onFinish = (values) => {
        this.props.addRoleToServer(values).then(() => {
            this.props.setModalBasic({ visible: false });
            this.props.getRoleFromServer();
        });
    };

    render() {
        return (
            <Layout className="doctor-add-form-content">
                <Header>
                    <p className="modalBasic-title">Thêm mới Quyền</p>
                    <button
                        className="modalBasic-btn-close"
                        onClick={() => this.props.setModalBasic({ visible: false })}
                    >
                        <i className="fas fa-times icon"></i>
                    </button>
                </Header>
                <Content>
                    <Form
                        style={{ marginTop: 20 }}
                        {...formItemLayout}
                        name="addRole"
                        onFinish={this.onFinish}
                        initialValues={{ name: "", order: 1 }}
                        scrollToFirstError
                    >
                        <Form.Item name="name" label="Tên Quyền" rules={[{ required: true, message:"vui lòng nhập tên quyền!" }]}>
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="description" label="Mô tả" rules={[{ required: true, message:"vui lòng nhập mô tả!" }]}>
                            <Input allowClear />
                        </Form.Item>
                        <Button
                            style={{ color: "#fff", background: "#2687d4", display: "none" }}
                            id="btn-submit-add-Role"
                            htmlType={"submit"}
                        >
                            Thêm mới
                        </Button>
                    </Form>
                </Content>
                <Footer>
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            document.getElementById("btn-submit-add-Role").click();
                        }}
                    >
                        Thêm mới
                    </Button>
                </Footer>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        data: state.role.roles,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addRoleToServer: (role) => dispatch(addRoleToServer(role)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        getRoleFromServer: (search) => dispatch(getRoleFromServer(search)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRoleFormContent);
