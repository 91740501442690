import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, message, Checkbox, Select, Layout, InputNumber } from "antd";
import {
    getHospitalById,
    updateHospitalToServer,
    getHospitalFromServer,
    fetchDropdownAreas,
} from "../../reducers/hospitalReducer";
import { connect } from "react-redux";
import moment from "moment";
import { getHospitalSearch } from "../../pages/Home/HospitalPage/hospital";
import { handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
const { Option } = Select;

const { Content, Footer, Header } = Layout;

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
class ContentEdit extends Component {
    constructor(props) {
        super(props);
        this.formRef = null;
    }

    componentDidMount = () => {
        this.props.getHospitalById(this.props.hospitalId).then(() => {
            this.formRef.setFieldsValue({ ...this.props.hospitalItem, doB: moment(this.props.hospitalItem.doB) });
        });
    };
    componentDidUpdate = (prevProps) => {
        if (prevProps.hospitalId !== this.props.hospitalId) {
            this.props.getHospitalById(this.props.hospitalId).then(() => {
                this.formRef.setFieldsValue({ ...this.props.hospitalItem, doB: moment(this.props.hospitalItem.doB) });
            });
        }
    };
    onFinish = (values) => {
        const { hospitalId } = this.props;
        this.props.updateHospitalToServer(hospitalId, values).then(() => {
            this.props.setModalBasic({ visible: false });
            const search = getHospitalSearch();
            this.props.getHospitalFromServer(search);
        });
    };
    render() {
        const { listArea } = this.props;
        return (
            <>
                <Layout className="layout-doctor-edit">
                    <Header>
                        <p className="modalBasic-title">Cập nhật thông tin thư mục</p>
                        <button
                            className="modalBasic-btn-close"
                            onClick={() => this.props.setModalBasic({ visible: false })}
                        >
                            <i className="fas fa-times icon"></i>
                        </button>
                    </Header>
                    <Content>
                        <Form
                            className="form-edit-hospital"
                            {...formItemLayout}
                            name="addHospital"
                            onFinish={this.onFinish}
                            ref={(ref) => (this.formRef = ref)}
                            scrollToFirstError
                        >
                            <Form.Item name="name" label="Tên" rules={[{ required: true, message:"vui lòng nhập tên!" }]}>
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="locate" label="Vị trí">
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="hospitalType" label="Loại thư mục" valuePropName="value">
                                <Select>
                                    <Option value={0}>Normal</Option>
                                    <Option value={1}>Favorite</Option>
                                    <Option value={2}>Share</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="areaId" label="Cơ sở" valuePropName="value">
                                <Select>
                                    {listArea.map((item) => {
                                        return (
                                            <Option key={item.id} value={Number(item.id)}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name="order" label="Thứ tự" valuePropName="value">
                                <InputNumber min={0} max={1500} />
                            </Form.Item>
                            <Form.Item name="status" label="Status" hidden>
                                <Input />
                            </Form.Item>
                            <Button
                                style={{ color: "#fff", background: "#2687d4", display: "none" }}
                                id="btn-submit-edit-hospital"
                                htmlType={"submit"}
                            >
                                Lưu lại
                            </Button>
                        </Form>
                    </Content>
                    <Footer>
                        <Button
                            style={{ color: "#fff", background: "#2687d4" }}
                            onClick={() => document.getElementById("btn-submit-edit-hospital").click()}
                            htmlType={"submit"}
                        >
                            Lưu lại
                        </Button>
                    </Footer>
                </Layout>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        hospitalItem: state.hospital.hospitalItem,
        listArea: state.hospital.listArea,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getHospitalById: (id) => dispatch(getHospitalById(id)),
        updateHospitalToServer: (id, hospital) => dispatch(updateHospitalToServer(id, hospital)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        fetchDropdownAreas: () => dispatch(fetchDropdownAreas()),
        getHospitalFromServer: (search) => dispatch(getHospitalFromServer(search)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentEdit);
