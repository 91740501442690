import Axios from "axios";
import { message } from "antd";
const ACTION_TYPES = {
    SET_SAMPLERESULT: "SET_SAMPLERESULT",
    SET_SAMPLERESULT_ITEM: "SET_SAMPLERESULT_ITEM",
    SET_DROPDOWN_SERVICES: "SET_DROPDOWN_SERVICES",
};

const initialstate = {
    sampleresults: [],
    totalCount: 0,
    sampleresultItem: {},
    dropdownServices: [],
};

const sampleresultReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_SAMPLERESULT:
            state = { ...state };
            state.sampleresults = action.payload.data;
            state.totalCount = action.payload.dataCount;
            return { ...state };
        case ACTION_TYPES.SET_SAMPLERESULT_ITEM:
            state.sampleresultItem = action.payload;
            return { ...state };
        case ACTION_TYPES.SET_DROPDOWN_SERVICES:
            state.dropdownServices = action.payload;
            return { ...state };
        default:
            return state;
    }
};
const setSampleResults = (sampleresults) => {
    return {
        type: ACTION_TYPES.SET_SAMPLERESULT,
        payload: sampleresults,
    };
};
const getSampleResultFromServer = (request) => async (dispatch) => {
    const response = await Axios.get(
        `api/Templates/paging?Keyword=${request.Keyword}&ServiceID=${request.ServiceID}&PageIndex=${request.PageIndex}&PageSize=${request.PageSize}`
    );
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setSampleResults(response.data));
        }
        
    } else {
        message.error(response.status);
    }
};
const setDropdownServices = (services) => {
    return {
        type: ACTION_TYPES.SET_DROPDOWN_SERVICES,
        payload: services,
    };
};
const getDropdownServices = () => async (dispatch) => {
    await Axios.get(`api/Templates/dropservices`)
        .then((response) => {
            if (response.data.status === 1) {
                dispatch(setDropdownServices(response.data.data));
            }
            
        })
        .catch((error) => {});
};
const setSampleResultItem = (sampleresult) => {
    return {
        type: ACTION_TYPES.SET_SAMPLERESULT_ITEM,
        payload: sampleresult,
    };
};
const getSampleResultById = (id) => async (dispatch) => {
    const response = await Axios.get(`api/Templates/${id}`);
    if (response.data.status === 1) {
        dispatch(setSampleResultItem(response.data.data));
    }
    
};

const updateSampleResultToServer = (id, sampleresult) => async (dispatch) => {
    await Axios.put(`api/Templates/${id}`, sampleresult)
        .then((response) => {
            if (response.data) {
                message.success(response.data.message);
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let messages = "";
                if (e.Email) {
                    for (let i of e.Email) {
                        messages += i + "! ";
                    }
                }
                if (e.PhoneNo) {
                    for (let i of e.PhoneNo) {
                        messages += i + "! ";
                    }
                }
                if (e.Name) {
                    for (let i of e.Name) {
                        messages += i + "! ";
                    }
                }
                message.error(messages, 5);
            }
        });
};

const addSampleResultToServer = (request) => async (dispatch) => {
    await Axios.post("api/Templates", request)
        .then((response) => {
            if (response.status === 200) {
                message.success("Successfuly!");
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let mes = "";
                if (e.Name) {
                    for (let i of e.Name) {
                        mes += i + "! ";
                    }
                }
                message.error(mes, 3);
            }
        });
};

const deleteSampleResultFromServer = (id) => async (dispatch) => {
    await Axios.delete(`api/Templates/${id}`)
        .then((response) => {
            message.success(response.data.message);
        })
        .catch((err) => {
            message.error("Bạn không có quyền xóa Bác sĩ này!");
        });
};
export {
    addSampleResultToServer,
    deleteSampleResultFromServer,
    getSampleResultFromServer,
    sampleresultReducer,
    getSampleResultById,
    updateSampleResultToServer,
    getDropdownServices,
};
