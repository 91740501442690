import React, { Component } from "react";
import "./IndicateTable.scss";
import { Pagination, Switch, Button, Tooltip, Popconfirm, Table, Checkbox } from "antd";
import { setIndicateSearch, getIndicateSearch } from "../../pages/Home/IndicatePage/indicate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

class IndicateTable extends Component {
    onChangePage = (page, pageSize) => {
        //Change IndicateSearch
        let indicateSearch = getIndicateSearch();
        indicateSearch.PageIndex = page;
        indicateSearch.PageSize = pageSize;
        setIndicateSearch(indicateSearch);
        this.props.handleUpdateTable();
    };
    // onChangeRole = (indicateId, indicateName) => {
    //     this.props.handleChangeRole(indicateId, indicateName);
    // };
    // onShowInfo = (indicateId, indicateName) => {
    //     this.props.handleShowInfo(indicateId, indicateName);
    // };
    // onEdit = (indicateId, indicateName) => {
    //     this.props.handleEdit(indicateId, indicateName);
    // };
    onEditTime = (indicateId, indicateName) => {
        this.props.handleEditTime(indicateId, indicateName);
    };
    onDelete = (id, name) => {
        this.props.handleDeleteIndicate(id, name);
    };
    onRecovery = (id) => {
        this.props.handleRecoveryIndicate(id);
    };
    render() {
        const { indicates, totalCount } = this.props;
        return (
            <div className={"indicate-table-d"}>
                <table border="0" className="table-admin">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Đường dẫn lưu trữ</th>
                            <th>ServerName</th>
                            <th>Mã Bệnh Nhân</th>
                            <th>Thời Gian Chụp</th>
                            <th>Mã chỉ định</th>
                            <th>Thời Gian Chỉ Định</th>
                            <th>Modality</th>
                            <th>Trạng thái</th>
                            <th>Sync Status</th>
                            <th>Tồn tại bên HIS</th>
                            <th>Thời Gian Tạo</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {indicates.map((indicate, index) => {
                            return (
                                <tr key={index}>
                                    <td>{indicate.id}</td>
                                    <td>{indicate.uid}</td>
                                    <td>{indicate.serverName}</td>
                                    <td>{indicate.pCode}</td>
                                    <td>{moment(indicate.timeEx).format("DD/MM/YYYY HH:mm:ss")}</td>
                                    <td>{indicate.orgCode}</td>
                                    <td>{moment(indicate.timeAss).format("DD/MM/YYYY HH:mm:ss")}</td>
                                    <td>{indicate.modality}</td>
                                    <td>{indicate.state == 0? "Chưa có kết quả" :
                                         indicate.state == 1? "Có kết quả":
                                         indicate.state == 3? "Đã xóa":
                                         indicate.state                                    
                                    }</td>
                                    <td>
                                        <input type="checkbox" checked={indicate.syncStatus}></input>
                                    </td>
                                    <td>
                                        <input type="checkbox" checked={indicate.existOnHis}></input>
                                    </td>
                                    <td>{moment(indicate.createTime).format("DD/MM/YYYY HH:mm:ss")}</td>
                                    <td>
                                        <div className="tools">
                                            <Button onClick={() => this.onEditTime(indicate.id, indicate.name)}>
                                                <FontAwesomeIcon icon="edit" />
                                            </Button>
                                            <Popconfirm
                                                placement="topRight"
                                                title={`Xác nhận xóa ${indicate.id}`}
                                                onConfirm={() => this.onDelete(indicate.id, indicate.name)}
                                                okText="Ok"
                                                cancelText="Cancel"
                                            >
                                                <Button>
                                                    <FontAwesomeIcon icon="trash-alt" />
                                                </Button>
                                            </Popconfirm>
                                            {indicate.state == 3?
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={`Khôi phục ca chụp ${indicate.id}`}
                                                    onConfirm={() => this.onRecovery(indicate.id)}
                                                    okText="Ok"
                                                    cancelText="Cancel"
                                                >
                                                    <Button>
                                                        <FontAwesomeIcon icon="redo-alt" />
                                                    </Button>
                                                </Popconfirm>:
                                                ""
                                            }
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="pagination-d">
                    <Pagination
                        defaultCurrent={5}
                        current={getIndicateSearch().PageIndex}
                        total={totalCount}
                        showQuickJumper
                        showTitle={false}
                        defaultPageSize={getIndicateSearch().PageSize}
                        onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                    />
                </div>
            </div>
        );
    }
}
export default IndicateTable;
