import moment from "moment";

let doctorSeach = {
    Keyword: "",
    RoleName:"",
    PageIndex: 1,
    PageSize: 10,
};

const getDoctorSearch = () => {
    return doctorSeach;
};

const setDoctorSearch = (search) => {
    doctorSeach = search;
};

const deaultDoctorForm = {
    name: "",
    gender: "Nam",
    address: "",
    doB: moment(),
    email: "",
    phoneNumber: "",
    userName: "",
};

let doctorForm = {
    name: "",
    gender: "Nam",
    address: "",
    doB: moment(),
    email: "",
    phoneNumber: "",
    userName: ""
};

const getDoctorForm = () => {
    return doctorForm;
};

const setDoctorForm = (doctor) => {
    doctorForm = doctor;
};

let statusAdd = false;
const getStatusAdd = () => {
    return statusAdd;
};

const setStatusAdd = (value) => {
    statusAdd = value;
};
export { setDoctorSearch, getDoctorSearch, setDoctorForm, getDoctorForm, deaultDoctorForm, getStatusAdd, setStatusAdd };
