import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, Layout, Upload, message,Checkbox,Select } from "antd";
import { getStatusAdd, setStatusAdd } from "../../pages/Home/DoctorPage/doctor";
import { getDoctorById, updateDoctorToServer, getDoctorFromServer, getDropdownPosition, getDropdownPositionTittle } from "../../reducers/doctorReducer";
import { connect } from "react-redux";
import moment from "moment";
import { getDoctorSearch } from "../../pages/Home/DoctorPage/doctor";
import { handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import './ContentEdit.scss';
import { removeVietnameseTones } from "../../utils";

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const { Content, Footer, Header } = Layout;
const { Option } = Select;
function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
}

function arrayBufferToBase64(buffer) {
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

function getBase64(img, callback) {
    try {
        img.arrayBuffer().then(function (result) {
            callback(arrayBufferToBase64(result));
        });
    } catch {
        callback("");
    }
}

class ContentEdit extends Component {
    constructor(props) {
        super(props);
        this.formRef = null;
    }
    state = {
        imageUrl: "",
        imageUrl2:"",
        loading: false,
    };

    handleChange = (info) => {
        if (info.file.status === "uploading") {
            this.setState({ loading: true });
            getBase64(info.file.originFileObj, (imageUrl) =>
                this.setState({
                    imageUrl,
                    loading: false,
                })
            );
        }
        if (info.file.status === "done") {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl) =>
                this.setState({
                    imageUrl,
                    loading: false,
                })
            );
        }
    };
    handleChange2 = (info) => {
        if (info.file.status === "uploading") {
            this.setState({ loading: true });
            getBase64(info.file.originFileObj, (imageUrl2) =>
                this.setState({
                    imageUrl2,
                    loading: false,
                })
            );
        }
        if (info.file.status === "done") {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (imageUrl2) =>
                this.setState({
                    imageUrl2,
                    loading: false,
                })
            );
        }
    };
    componentDidMount = async () => {
        if(this.props.isvermed == true){
            await this.props.getDropdownPositionTittle();
            await this.props.getDropdownPosition();
        }
        this.props.getDoctorById(this.props.doctorId).then(() => {
            this.formRef.setFieldsValue({ ...this.props.doctorItem,checkpermiss:this.props.doctorItem.permission == 13?true:false,accessStudyByUser:this.props.doctorItem.accessStudyPermission == 1?true:false, doB: moment(this.props.doctorItem.doB) });
            this.setState({ imageUrl: this.props.doctorItem.signatureImage, imageUrl2: this.props.doctorItem.avatar });
        });
    };
    componentDidUpdate = (prevProps) => {
        if (prevProps.doctorId !== this.props.doctorId) {
            this.props.getDoctorById(this.props.doctorId).then(() => {
                this.formRef.setFieldsValue({ ...this.props.doctorItem,checkpermiss:this.props.doctorItem.permission == 13?true:false,accessStudyByUser:this.props.doctorItem.accessStudyPermission == 1?true:false, doB: moment(this.props.doctorItem.doB) });
            });
        }
    };
    onFinish = (values) => {
        if (!values.email) {
            values.email = `${values.userName}@telerad.vn`;
        }   
        if (values.order) {
            var order = values.order;
            values.order = parseInt(order);
        }
        values.signatureImage = this.state.imageUrl;
        values.avatar = this.state.imageUrl2;
        const { doctorId } = this.props;
        if(values.checkpermiss){
            values.permission = 13;
        }else{
            values.permission = null;
        }
        if(values.accessStudyByUser){
            values.AccessStudyPermission = 1;
        }else{
            values.AccessStudyPermission = 0;
        }
        this.props.updateDoctorToServer(doctorId, values).then(() => {
            if (getStatusAdd() === true) {
                this.props.getDoctorFromServer(getDoctorSearch());
                setStatusAdd(false);
                this.props.setModalBasic({ visible: false });
            }
        });
    };
    onRemoveSignatureImage = (e) => {
        this.setState({ imageUrl: "" });
    }
    onRemoveSignatureImage2 = (e) => {
        this.setState({ imageUrl2: "" });
    }
    render() {
        const { loading,dropdownPosition,dropdownPositionTittle,isvermed } = this.props;
        const { imageUrl } = this.state;
        const { imageUrl2 } = this.state;
        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
        const uploadButton2 = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
        return (
            <Layout className="layout-doctor-edit">
                <Header>
                    <p className="modalBasic-title">Sửa thông tin bác sĩ</p>
                    <button
                        className="modalBasic-btn-close"
                        onClick={() => {
                            setStatusAdd(false);
                            this.props.setModalBasic({ visible: false });
                        }}
                    >
                        <i className="fas fa-times icon"></i>
                    </button>
                </Header>
                <Content>
                    <Form
                        {...formItemLayout}
                        className="form-edit-doctor"
                        name="addDoctor"
                        onFinish={this.onFinish}
                        ref={(ref) => (this.formRef = ref)}
                        scrollToFirstError
                    >
                        <Form.Item name="userName" label="Tên đăng nhập" rules={[{ required: true, message:"vui lòng nhập tên đăng nhập!" }]}>
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="name" label="Họ tên" rules={[{ required: true, message:"vui lòng nhập họ tên!" }]}>
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="gender" label="Giới tính">
                            <Radio.Group>
                                <Radio value="Nam">Nam</Radio>
                                <Radio value="Nữ">Nữ</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="address" label="Địa chỉ">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="doB" label="Ngày sinh">
                            <DatePicker format="DD/MM/YYYY" placeholder="" style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item name="email" label="Email">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="phoneNumber" label="Số điện thoại">
                            <Input allowClear type="number" />
                        </Form.Item>
                        {isvermed == true ?
                        <>
                            <Form.Item name="position" label="Đơn vị công tác">
                                {dropdownPosition.length == 0 ?
                                    <Input allowClear />
                                    :
                                    <Select
                                        showSearch
                                        placeholder="Chọn đơn vị công tác"
                                        allowClear
                                        style={{ width: "100%" }}
                                        filterOption={(input, option) =>
                                            {
                                                option.children = removeVietnameseTones(option.children);
                                                input = removeVietnameseTones(input);
                                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        }
                                    >
                                        {dropdownPosition.map((item) => (
                                            <Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                }
                            </Form.Item>
                            <Form.Item name="positionTittle" label="Vị trí">
                            {dropdownPositionTittle.length == 0 ?
                                    <Input allowClear />
                                    :
                                    <Select
                                        showSearch
                                        placeholder="Chọn vị trí"
                                        allowClear
                                        style={{ width: "100%" }}
                                        filterOption={(input, option) =>
                                            {
                                                option.children = removeVietnameseTones(option.children);
                                                input = removeVietnameseTones(input);
                                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        }
                                    >
                                        {dropdownPositionTittle.map((item) => (
                                            <Option key={item.id} value={item.id}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                }
                            </Form.Item>
                            <Form.Item name="NameOutsideMed" label="Tên đơn vị ngoài MED">
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="description" label="Mô tả">
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="personPropose" label="Người đề xuất lập">
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="order" label="Thứ tự ưu tiên">
                                <Select
                                        showSearch
                                        defaultValue={null}
                                        style={{ width: "100%" }}
                                    >
                                        <Option value={null}>Không</Option>
                                        <Option value={2}>Giáo sư</Option>
                                        <Option value={3}>Phó giáo sư</Option>
                                        <Option value={4}>Bác sĩ</Option>
                                </Select>
                            </Form.Item>
                        </>
                        :
                            ""
                        }
                        <Form.Item name="userHis" label="userHis">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="signatureImage" label="Chữ ký:">
                            <div className="uploadSignatureImage" style={{display:"flex"}}>
                                <Upload
                                    name="file"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                                    onChange={this.handleChange}
                                    style={{ background: "#2a274c", color: "#fff",  }}
                                >
                                    {imageUrl ? (
                                        <img
                                            src={"data:image/jpeg;base64," + imageUrl}
                                            alt="avatar"
                                            style={{ width: "100%" }}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload>
                                {imageUrl ? (
                                        <Button style={{ marginTop: "auto",
                                            marginBottom: "auto", }} onClick={this.onRemoveSignatureImage}>
                                           xóa
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                               
                            </div>
                        </Form.Item>
                        <Form.Item name="avatar" label="Ảnh đại diện:">
                            <div className="uploadSignatureImage" style={{display:"flex"}}>
                                <Upload
                                    name="file2"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeUpload}
                                    onChange={this.handleChange2}
                                >
                                    {imageUrl2 ? (
                                        <img
                                            src={"data:image/jpeg;base64," + imageUrl2}
                                            alt="avatar"
                                            style={{ width: "100%" }}
                                        />
                                    ) : (
                                        uploadButton2
                                    )}
                                </Upload>
                                {imageUrl2 ? (
                                    <Button style={{ marginTop: "auto",
                                        marginBottom: "auto", }} onClick={this.onRemoveSignatureImage2}>
                                    xóa
                                    </Button>
                                ) : (
                                    ""
                                )}
                            </div>
                        </Form.Item>
                        <Form.Item name="checkpermiss" valuePropName="checked" label="LAN Only">
                            <Checkbox>Chỉ cho phép trong LAN</Checkbox>
                        </Form.Item>
                        {this.props.AccessStudyByUser && 
                            <Form.Item name="accessStudyByUser" valuePropName="checked" label="Truy cập tất cả ca chụp">
                                <Checkbox>Cho phép</Checkbox>
                            </Form.Item>
                        }
                        <Form.Item name="signatureSerialNumber" label="Signature Serial Number">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="signatureUser" label="Signature User">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="signaturePassword" label="Signature Password">
                            <Input allowClear />
                        </Form.Item>
                        <Button style={{ display: "none" }} id="btn-submit-edit-doctor" htmlType={"submit"}>
                            Lưu lại
                        </Button>
                    </Form>
                </Content>
                <Footer>
                    <Button
                        style={{ color: "#fff", background: "#2687d4" }}
                        onClick={() => document.getElementById("btn-submit-edit-doctor").click()}
                        htmlType={"submit"}
                    >
                        Lưu lại
                    </Button>
                </Footer>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        doctorItem: state.doctor.doctorItem,
        dropdownPosition: state.doctor.dropdownPosition,
        dropdownPositionTittle: state.doctor.dropdownPositionTittle,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getDoctorById: (id) => dispatch(getDoctorById(id)),
        updateDoctorToServer: (id, doctor) => dispatch(updateDoctorToServer(id, doctor)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        getDoctorFromServer: (search) => dispatch(getDoctorFromServer(search)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        getDropdownPositionTittle: () => dispatch(getDropdownPositionTittle()),
        getDropdownPosition: () => dispatch(getDropdownPosition()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentEdit);
