import Axios from "axios";
import { queryString } from "../utils/queryString";
import { message } from "antd";
const ACTION_TYPES = {
    SET_TYPESERVICES: "SET_TYPESERVICES",
    SET_ROLES: "SET_ROLES",
    SET_TYPESERVICE_ITEM: "SET_TYPESERVICE_ITEM",
};

const initialstate = {
    typeservices: [],
    totalCount: 0,
    roles: [],
    typeserviceItem: {},
};

const typeserviceReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_TYPESERVICES:
            state = { ...state };
            state.typeservices = [...action.payload.data];
            state.totalCount = action.payload.dataCount;
            return { ...state };
        case ACTION_TYPES.SET_ROLES:
            state.roles = action.payload;
            return { ...state };
        case ACTION_TYPES.SET_TYPESERVICE_ITEM:
            state.typeserviceItem = action.payload;
            return { ...state };
        default:
            return state;
    }
};
const setTypeServices = (typeservices) => {
    return {
        type: ACTION_TYPES.SET_TYPESERVICES,
        payload: typeservices,
    };
};
const getTypeServiceFromServer = (request) => async (dispatch) => {
    const { Keyword, PageIndex, PageSize } = request;
    let url = "api/TypeServices/paging?";
    url += `Keyword=${Keyword}&`;
    url += `PageIndex=${PageIndex}&`;
    url += `PageSize=${PageSize}`;
    const response = await Axios.get(url);
    if (response.status === 200) {
        // response.data.data.forEach((item, index) => {
        //     item.stt = index + 1;
        // });
        if (response.data.status === 1) {
            dispatch(setTypeServices(response.data));
        }
        
    } else {
        message.error(response.status);
    }
};

const setRoles = (roles) => {
    return {
        type: ACTION_TYPES.SET_ROLES,
        payload: roles,
    };
};
const getListRoleFromServer = (request) => async (dispatch) => {
    const response = await Axios.get(`api/Users/${request}/roles`);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setRoles(response.data.data.listRoles));
        }
        
    }
};
const updateRoleToServer = (typeserviceId, roles) => async (dispatch) => {
    const response = await Axios.put(`api/Users/${typeserviceId}/roles`, { roles: roles });
    if (response.status === 200) {
        message.success("Update Thành công!");
    } else {
        message.error("Update Thất bại!", response.status);
    }
};
const setTypeServiceItem = (typeservice) => {
    return {
        type: ACTION_TYPES.SET_TYPESERVICE_ITEM,
        payload: typeservice,
    };
};
const getTypeServiceById = (id) => async (dispatch) => {
    const response = await Axios.get(`api/TypeServices/${id}`);
    if (response.data.status === 1) {
        dispatch(setTypeServiceItem(response.data.data));
    }
    
};

const updateTypeServiceToServer = (id, typeservice) => async (dispatch) => {
    await Axios.put(`api/TypeServices/${id}`, typeservice)
        .then((response) => {
            if (response.data) {
                message.success(response.data.message);
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let mes = "";
                if (e.Name) {
                    for (let i of e.Name) {
                        mes += i + ", ";
                    }
                }
                message.error(mes, 3);
            }
        });
};

const addTypeServiceToServer = (request) => async (dispatch) => {
    await Axios.post("api/TypeServices", request)
        .then((response) => {
            if (response.status === 200) {
                if (response.data.status !== 1) {
                    message.error(response.data.message);
                } else {
                    message.success(response.data.message);
                }
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let mes = "";
                if (e.Name) {
                    for (let i of e.Name) {
                        mes += i + ", ";
                    }
                }
                message.error(mes, 3);
            }
        });
};

const deleteTypeServiceFromServer = (id) => async (dispatch) => {
    await Axios.delete(`api/TypeServices/${id}`)
        .then((response) => {
            message.success(response.data.message);
        })
        .catch((err) => {
            message.error("Xóa thất bại!");
        });
};
export {
    addTypeServiceToServer,
    deleteTypeServiceFromServer,
    getTypeServiceFromServer,
    typeserviceReducer,
    getListRoleFromServer,
    setTypeServiceItem,
    setRoles,
    updateRoleToServer,
    getTypeServiceById,
    updateTypeServiceToServer,
};
