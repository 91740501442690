import moment from "moment";
let searchStaticStudies = {
    fromDate: moment().format(),
    toDate: moment().format(),
    modality: "",
    pindication: "",
    pbodypart: "",
    pconclusions: "",
    code: "",
    pCode: "",
    pName: "",
    svStatus: null,
    filterBy: 1,
    filterTypeMachine: "",
    filterMachine: "",
    address: "",
    insurance: [],
    placeSending: [],
    cDiagnosis: "",
    areaId: null,
    order: 0,
    existOnHis: null, //true/false/null
    PageIndex: 1,
    PageSize: 30,
};

const initSearchStaticStudies = {
    fromDate: moment(),
    toDate: moment(),
    modality: "",
    pindication: "",
    pbodypart: "",
    pconclusions: "",
    code: "",
    pCode: "",
    pName: "",
    svStatus: null,
    filterBy: 1,
    filterTypeMachine: "",
    filterMachine: "",
    address: "",
    insurance: [],
    placeSending: [],
    cDiagnosis: "",
    areaId: "",
    order: 0,
    existOnHis: null, //true/false/null
    PageIndex: 1,
    PageSize: 30,
};

const setSearchStaticStudies = (data) => {
    searchStaticStudies = data;
};

const getSearchStaticStudies = () => {
    return searchStaticStudies;
};

export { initSearchStaticStudies, setSearchStaticStudies, getSearchStaticStudies };