import { Button, Checkbox, Layout, message, Upload } from "antd";
import Axios from "axios";
import React, { Component } from "react";
import "./ContentConfigTools.scss";
const { Header, Content, Footer } = Layout;
export default class ContentConfigTools extends Component {
  state = {
    viewerTools: null,
    viewerToolsMobile: null,
    listPositions: [],
    listPositionsMobile: [],
    device: "Web",
    active: null,
  };
  componentDidMount = () => {
    this.convertConfigTool();
  };
  convertConfigTool = () => {
    const { configToolsMobile, configTools } = JSON.parse(
      this.props.data.data
    );
    if (!configToolsMobile || !configToolsMobile) {
      return null;
    }
    const listPositions = Object.getOwnPropertyNames(configTools);
    const listPositionsMobile = Object.getOwnPropertyNames(configToolsMobile);
    this.setState({
      viewerTools: configTools,
      viewerToolsMobile: configToolsMobile,
      listPositions,
      active: listPositions[0],
      listPositionsMobile,
    });
  }
  replaceToolViewer = async (request) => {
    const response = await Axios.post(
      `api/Configs/write_config_tool_viewer`, request
      );
      if (response.status === 200) {
          if (response.data.status === 1) {
            message.success(response.data.message);
          }
          else {
            message.error(response.status);
          }
      } else {
          message.error(response.status);
      }
  }

  onChangeStatus = (active, index, hidden) => {
    const { viewerTools,viewerToolsMobile } = this.state;
    const clone = { ...viewerTools };
    clone[active][index].hidden = hidden;
    this.setState({
      viewerTools: clone,
    });
    // let dataViewerTools = {
    //   configTools: clone,
    //   configToolsMobile: viewerToolsMobile,
    // };
    // const dataJSON = JSON.stringify(dataViewerTools);
    // let data = {...this.props.data};
    // data.configName = dataJSON;
    // this.props.setConfigurationItem(data);
  };
  
  onChangeStatusMobile = (active, index, hidden) => {
    const { viewerTools,viewerToolsMobile } = this.state;
    const clone = { ...viewerToolsMobile };
    clone[active][index].hidden = hidden;
    this.setState({
      viewerToolsMobile: clone,
    });
    // let dataViewerTools = {
    //   configTools: viewerTools,
    //   configToolsMobile: clone,
    // };
    // const dataJSON = JSON.stringify(dataViewerTools);
    // let data = {...this.props.data};
    // data.configName = dataJSON;
    
    // this.props.setConfigurationItem(data);
  };

  onSubmit = () => {
    let dataViewerTools = {
        configTools: this.state.viewerTools,
        configToolsMobile: this.state.viewerToolsMobile,
      };
      const dataJSON = JSON.stringify(dataViewerTools);
      let request = {
        config : dataJSON
      }
      this.replaceToolViewer(request);
      this.props.setModalBasic({ visible: false });
  };
  onUploadFileConfigTool = (value) =>{
    try {
   
      const { configToolsMobile, configTools } = JSON.parse(
        value
      );
      let ConfigToolWeb = Object.keys(configTools) 
      let ConfigToolMobile = Object.keys(configToolsMobile)
      if (!configToolsMobile || !configToolsMobile) {
        return null;
      }
      const listPositions = Object.getOwnPropertyNames(configTools);
      const listPositionsMobile = Object.getOwnPropertyNames(configToolsMobile);
      const sameArray1 = ConfigToolWeb.length === listPositions.length && ConfigToolWeb.sort().every(function(value, index) { return value === listPositions.sort()[index]});
      const sameArray2 = ConfigToolMobile.length === listPositionsMobile.length && ConfigToolMobile.sort().every(function(value, index) { return value === listPositionsMobile.sort()[index]});
      if(sameArray1 && sameArray2)
      {
        let dataViewerTools = {
          configTools: configTools,
          configToolsMobile: configToolsMobile,
        };
        const dataJSON = JSON.stringify(dataViewerTools);
        let request = {
          config : dataJSON
        }
        this.replaceToolViewer(request);
        this.setState({
          viewerTools: configTools,
          viewerToolsMobile: configToolsMobile,
          listPositions,
          active: listPositions[0],
          listPositionsMobile,
        });
      }
      else
      {
        message.error("không thể upload file này");
      }
    } catch (error) {
      console.log(error);
      message.error("không thể upload file này ");
    }
  }

  render() {
    const {
      listPositions,
      viewerTools,
      viewerToolsMobile,
      active,
      device,
      listPositionsMobile,
    } = this.state;
    if (!viewerTools || !viewerToolsMobile) {
      return <></>;
    }
    return (
      <>
        <Layout className="layout-indicate-edit">
            <Header>
                <p className="modalBasic-title">Sửa Tool Viewer</p>
                <button
                    className="modalBasic-btn-close"
                    onClick={() => this.props.setModalBasic({ visible: false })}
                >
                    <i className="fas fa-times icon"></i>
                </button>
            </Header>
            <Content>
              <Upload
                accept=".txt, .csv, .json"
                showUploadList={false}
                beforeUpload={file => {
                    const reader = new FileReader();

                    reader.onload = e => {
                        this.onUploadFileConfigTool(e.target.result);
                    };
                    reader.readAsText(file);

                    // Prevent upload
                    return false;
                }}
              >
                <Button 
                style={{ color: "#fff", background: "#2687d4", margin:10 }}
                >
                      Upload config
                </Button>
            </Upload>;
              <div className="wrapper-config-tools">
                <div className="sider" style={{ width: 150 }}>
                  <ul>
                    {["Web", "Mobile"].map((pos) => (
                      <li
                        key={pos}
                        className={device === pos ? "active" : ""}
                        onClick={() => {
                          this.setState({
                            device: pos,
                            active: listPositionsMobile[0],
                          });
                        }}
                      >
                        {pos}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="sider">
                  <ul>
                    {device === "Web" &&
                      listPositions.length > 0 &&
                      listPositions.map((pos) => (
                        <li
                          key={pos}
                          className={active === pos ? "active" : ""}
                          onClick={() => {
                            this.setState({
                              active: pos,
                            });
                          }}
                        >
                          {pos}
                        </li>
                      ))}
                    {device === "Mobile" &&
                      listPositionsMobile.length > 0 &&
                      listPositionsMobile.map((pos) => (
                        <li
                          key={pos}
                          className={active === pos ? "active" : ""}
                          onClick={() => {
                            this.setState({
                              active: pos,
                            });
                          }}
                        >
                          {pos}
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="content">
                  <ul>
                    {device === "Web" &&
                      viewerTools[active].map((item, index) => {
                        return (
                          <li key={item.id} onClick={() => {}}>
                            <Checkbox
                              checked={!item.hidden}
                              onChange={(e) =>
                                this.onChangeStatus(active, index, !e.target.checked)
                              }
                            >
                              {item.name}
                            </Checkbox>
                          </li>
                        );
                      })}
                    {device === "Mobile" &&
                      viewerToolsMobile[active].map((item, index) => {
                        return (
                          <li key={item.id} onClick={() => {}}>
                            <Checkbox
                              checked={!item.hidden}
                              onChange={(e) =>
                                this.onChangeStatusMobile(
                                  active,
                                  index,
                                  !e.target.checked
                                )
                              }
                            >
                              {item.name}
                            </Checkbox>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </Content>
            <Footer>
              <Button
                style={{ color: "#fff", background: "#2687d4", margin:10 }}
                onClick={() => this.onSubmit()}
              >
                Lưu lại
              </Button>
            </Footer>
        </Layout>
      </>
    );
  }
}
