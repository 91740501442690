import { Layout, Form, Input, Radio, DatePicker, Button, message, Select, Row, InputNumber } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getTemplateSearch } from "../../pages/Home/TemplatePage/templateModel";
import {
    getDropdownServices,
    getTemplateById,
    getTemplateFromServer,
    updateTemplateToServer,
} from "../../reducers/templateReducer";
import { setModalBasic } from "../../reducers/uiReducer";
import TextArea from "antd/lib/input/TextArea";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { setModalBasic2 } from "../../reducers/modalBasic2Reducer";
import { getListDiagnose, getListIcdTemp } from "../../reducers/icdTempManagerReducer";
const { Content, Footer, Header } = Layout;

const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20 },
};
const toolbarConfig = {
    fontSize: {
        options: [9, 11, 13, "default", 17, 19, 21],
    },
    toolbar: {
        items: [
            "heading",
            "|",
            "bold",
            "italic",
            "underline",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "alignment",
            "|",
            "insertTable",
            "imageUpload",
            "blockQuote",
            "mediaEmbed",
            "undo",
            "redo",
            "code",
            "codeBlock",
            "fontBackgroundColor",
            "fontColor",
            "fontSize",
            "fontFamily",
            "highlight",
            "horizontalLine",
            "htmlEmbed",
            "imageInsert",
            "MathType",
            "ChemType",
            "pageBreak",
        ],
    },
    language: "en",
    image: {
        toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side", "linkImage"],
    },
    table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableCellProperties", "tableProperties"],
    },
    licenseKey: "",
};
const { Option } = Select;
class EditTemplate extends Component {
    constructor(props) {
        super(props);
        this.formRef = null;
        this.btnSubmitRef = null;
        this.state = {
            data: "",
            result: "",
            proposal: "",
        };
    }
    componentDidMount = () => {
        if (this.props.modal == 2) {
            this.props.getDropdownServices();
        }
        this.props.getTemplateById(this.props.id).then(() => {
            this.formRef.setFieldsValue({
                ...this.props.templateItem,
            });
            this.setState({
                data: this.props.templateItem.text ?? "",
                result: this.props.templateItem.result ?? "",
                proposal: this.props.templateItem.proposal ?? "",
            });
        });
    };

    onChange = (name, value) => {
        if (name === "text") {
            this.setState({ data: value });
        }
        if (name === "result") {
            this.setState({ result: value });
        }
        if (name === "proposal") {
            this.setState({ proposal: value });
        }
    };

    onFinish = (values) => {
        const data = {
            ...values,
            text: this.state.data ?? "",
            result: this.state.result ?? "",
            proposal: this.state.proposal ?? "",
        };
        const { id, serviceId, modal, idIdc } = this.props;

        this.props.updateTemplateToServer(id, data).then(() => {
            if (modal == 2) {
                this.props.setModalBasic2({ visible: false });
                if (serviceId) {
                    this.props.getListDiagnose(serviceId);
                }
                if (idIdc) {
                    this.props.getListIcdTemp(idIdc);
                }
            } else {
                this.props.setModalBasic({ visible: false });
                const search = getTemplateSearch();
                this.props.getTemplateFromServer(search);
            }
        });
    };

    render() {
        const { dropdownServices, modal } = this.props;
        return (
            <Layout className="doctor-add-form-content">
                <Header>
                    <p className="modalBasic-title">Chỉnh sửa mẫu kết quả</p>
                    <button
                        className="modalBasic-btn-close"
                        onClick={() => {
                            if (modal === 2) {
                                this.props.setModalBasic2({ visible: false });
                            } else {
                                this.props.setModalBasic({ visible: false });
                            }
                        }}
                    >
                        <i className="fas fa-times icon"></i>
                    </button>
                </Header>
                <Content>
                    <Form
                        style={{ marginTop: 20 }}
                        {...formItemLayout}
                        name="addTemplateResult"
                        onFinish={this.onFinish}
                        ref={(ref) => (this.formRef = ref)}
                        scrollToFirstError
                    >
                        <Form.Item name="name" label="Tên" rules={[{ required: true, message:"vui lòng nhập tên!" }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="serviceID" label="Dịch vụ" valuePropName="value" rules={[{ required: true, message:"vui lòng nhập dịch vụ!" }]}>
                            <Select disabled={modal == 2}>
                                {dropdownServices.map((item, index) => (
                                    <Option key={index} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="tech" label="Kỹ thuật">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Mô tả" className="ck-style-custom">
                            <CKEditor
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.onChange("text", data);
                                }}
                                config={toolbarConfig}
                                editor={window.ClassicEditor}
                                data={this.state.data}
                            />
                        </Form.Item>
                        <Form.Item name="result" label="Kết quả">
                            <CKEditor
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.onChange("result", data);
                                }}
                                // config={{ toolbar: [] }}
                                config={toolbarConfig}
                                editor={window.ClassicEditor}
                                data={this.state.result}
                            />
                        </Form.Item>
                        <Form.Item name="proposal" label="Khuyến nghị">
                            <CKEditor
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.onChange("proposal", data);
                                }}
                                // config={{ toolbar: [] }}
                                config={toolbarConfig}
                                editor={window.ClassicEditor}
                                data={this.state.proposal}
                            />
                        </Form.Item>
                        <Form.Item name="price" label="Giá" valuePropName="value">
                            <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>
                        <Button style={{ display: "none" }} id="btn-submit-edit-tempalte" htmlType={"submit"}>
                            Lưu lại
                        </Button>
                    </Form>
                </Content>
                <Footer>
                    <Button
                        type="primary"
                        ghost
                        onClick={() => document.getElementById("btn-submit-edit-tempalte").click()}
                    >
                        Lưu lại
                    </Button>
                </Footer>
            </Layout>
        );
    }
}
function mapStateToProps(state) {
    return {
        templateItem: state.template.templateItem,
        dropdownServices: state.template.dropdownServices,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        setModalBasic2: (data) => dispatch(setModalBasic2(data)),
        getTemplateFromServer: (data) => dispatch(getTemplateFromServer(data)),
        getDropdownServices: () => dispatch(getDropdownServices()),
        getTemplateById: (id) => dispatch(getTemplateById(id)),
        updateTemplateToServer: (id, template) => dispatch(updateTemplateToServer(id, template)),
        getListDiagnose: (id) => dispatch(getListDiagnose(id)),
        getListIcdTemp: (id) => dispatch(getListIcdTemp(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTemplate);
