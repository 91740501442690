import React, { Component } from "react";
import { connect } from "react-redux";
import { getPatientFromServer, deletePatientFromServer } from "../../../reducers/patientReducer";
import { getPatientSearch, setPatientSearch } from "./patient";
import { handleOpenModalApp, setModalBasic } from "../../../reducers/uiReducer";
import ContentEdit from "../../../components/Patient/ContentEdit";
import { Form, Input, Select, Button, Pagination } from "antd";
import { formatDate } from "../../../utils";
import moment from "moment";
import FontAwesome from "react-fontawesome";
import "./PatientPage.scss";
const { Option } = Select;
class PatientPage extends Component {
    componentDidMount = () => {
        this.props.getPatientFromServer(getPatientSearch());
    };
    handleUpdateTable = () => {
        this.props.getPatientFromServer(getPatientSearch());
    };
    handleOpenModal = (modalData) => {
        this.props.handleOpenModalApp(modalData);
    };

    onEdit = (patientId, patientName) => {
        const modalData = {
            visible: true,
            width: 900,
            content: <ContentEdit   patientId={patientId} patientName={patientName} />,
            name: "modal--patient-add",
        };
        this.props.setModalBasic(modalData);
    };

    handleDeletePatient = (id) => {
        this.props.deletePatientFromServer(id).then(() => {
            this.props.getPatientFromServer(getPatientSearch());
        });
    };

    onFinish = (values) => {
        let patientSearch = getPatientSearch();
        patientSearch.Keyword = values.Keyword;
        patientSearch.Age = values.Age;
        patientSearch.Sex = values.Sex;
        patientSearch.PageIndex = 1;
        setPatientSearch(patientSearch);
        this.handleUpdateTable(patientSearch);
    };

    onChangePage = (page, pageSize) => {
        let patientSearch = getPatientSearch();
        patientSearch.PageIndex = page;
        patientSearch.PageSize = pageSize;
        setPatientSearch(patientSearch);
        this.handleUpdateTable();
    };

    render() {
        const { patients, totalCount } = this.props;
        return (
            <div className="patient-page-d">
                <div className="patient-search-d">
                    <Form
                        name="horizontal_login"
                        initialValues={{ Keyword: "", Sex: "", Age: "" }}
                        layout="inline"
                        onFinish={this.onFinish}
                    >
                        <Form.Item name="Keyword">
                            <Input placeholder="Email & Họ tên" />
                        </Form.Item>
                        <Form.Item name="Age">
                            <Input placeholder="Tuổi" />
                        </Form.Item>
                        <Form.Item name="Sex">
                            <Select style={{ minWidth: 80 }}>
                                <Option value="">Tất cả</Option>
                                <Option value="M">Nam</Option>
                                <Option value="F">Nữ</Option>
                            </Select>
                        </Form.Item>
                        <Button style={{ background: "#2a274c", color: "#fff" }} htmlType="submit">
                            Tìm kiếm
                        </Button>
                    </Form>
                    {/* <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.onOpenModal}>
                    Add
                </Button> */}
                </div>
                <div className={"patient-table-d"}>
                    <table border="0" className="table-admin">
                        <thead>
                            <tr>
                                <th>Mã Bệnh Nhân</th>
                                <th>Tên Bệnh Nhân</th>
                                <th>Giới Tính</th>
                                <th>Địa chỉ</th>
                                <th>Tuổi</th>
                                <th>Ngày Sinh</th>
                                <th>Email</th>
                                <th>Điện Thoại</th>
                                <th>Mã BHYT</th>
                                <th>BHYT</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {patients.map((patient, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{patient.code}</td>
                                        <td>{patient.name}</td>
                                        <td>
                                            {patient.gender === "M" ||
                                            patient.gender === "Nam" ||
                                            patient.sex === "M" ? (
                                                <p>Nam</p>
                                            ) : patient.gender === "F" ||
                                              patient.gender === "Nữ" ||
                                              patient.sex === "F" ? (
                                                <p>Nữ</p>
                                            ) : (
                                                <p>Khác</p>
                                            )}
                                        </td>
                                        <td>{patient.address}</td>
                                        <td>{patient.age}</td>
                                        <td>{moment(patient.birthDay).format("DD/MM/YYYY")}</td>
                                        <td>{patient.email}</td>
                                        <td>{patient.phoneNo}</td>
                                        <td>{patient.typeCode}</td>
                                        <td>{patient.type}</td>
                                        <td>
                                            <div className="tools">
                                                <Button onClick={() => this.onEdit(patient.id, patient.name)}>
                                                    <FontAwesome name="edit" />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="pagination-d">
                        <Pagination
                            defaultCurrent={3}
                            current={getPatientSearch().PageIndex}
                            total={totalCount}
                            showQuickJumper
                            showTitle={false}
                            defaultPageSize={getPatientSearch().PageSize}
                            onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        patients: state.patient.patients,
        totalCount: state.patient.totalCount,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getPatientFromServer: (search) => dispatch(getPatientFromServer(search)),
        handleOpenModalApp: (modalData) => dispatch(handleOpenModalApp(modalData)),
        deletePatientFromServer: (id) => dispatch(deletePatientFromServer(id)),
        setModalBasic: (data) => dispatch(setModalBasic(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientPage);
