import React, { Component } from "react";
import { Menu, Switch, Divider, Button, Dropdown } from "antd";
import { MailOutlined, CalendarOutlined, AppstoreOutlined, SettingOutlined, LinkOutlined } from "@ant-design/icons";
import "./Navigation.scss";
import { Link } from "react-router-dom";
import Avatar from "antd/lib/avatar/avatar";
import Axios from "axios";
const { SubMenu } = Menu;
var FontAwesome = require("react-fontawesome");
class Navigation extends Component {
    state = {
        visible: false,
        data: [],
    };

    componentDidMount = async () => {
            const response = await Axios.get(`api/RoleAdmin/list-role-admin`);
            if(response.data.status === 1){
                if(response.data.data == null || response.data.data.length == 0){
                    this.props.handleLogout();
                }
                // const dfStorage =localStorage.getItem("router") ? `${localStorage.getItem("router")}` : "login";
                // const checkExist = response.data.data.filter(x=> x.link == dfStorage);
                // let df = checkExist[0]? checkExist[0].link:response.data.data[0].link;
                this.setState({
                    data : response.data.data,
                });
            }
    }
    handleMenuClick = (e) => {
        if (e.key !== "1") {
            this.setState({ visible: false });
        }
    };

    handleVisibleChange = (flag) => {
        this.setState({ visible: flag });
    };
    onLogout = () => {
        this.props.handleLogout();
    };
    onToggleNav = () => {
        this.props.handleToggleNav();
    };
    onChangeRouter = (data) => {
        localStorage.setItem("router", data.key);
    };
    render() {
        const {data} = this.state;
        return (
            <div className="navigation-d">
                <button className="bars" onClick={this.onToggleNav}>
                    <div>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
                <div className="logo">
                    <div className="logo-txt">
                        <p>Vrpacs Admin</p>
                    </div>
                    <Dropdown
                        overlay={
                            <Menu style={{ color: "black", fontFamily: "Roboto" }}>
                                <Menu.Item key="1" style={{ fontWeight: "bold" }}>
                                    <span style={{ color: "rgb(38 53 68)!important" }}>
                                        <i className="fas fa-user" style={{ minWidth: "30px" }}></i>
                                        {localStorage.getItem("username")}
                                    </span>
                                </Menu.Item>
                                <Menu.Item key="1" onClick={this.onLogout}>
                                    <i className="fas fa-key" style={{ minWidth: "30px" }}></i> Đăng xuất
                                </Menu.Item>
                            </Menu>
                        }
                        onVisibleChange={this.handleVisibleChange}
                        visible={this.state.visible}
                        trigger={["click"]}
                    >
                        <Avatar src="image/profile.jpg" size="number"></Avatar>
                    </Dropdown>
                </div>
                <Menu
                    style={{ width: 250 }}
                    className="nav-menu-d"
                    onClick={({ item, key, keyPath, domEvent }) =>
                        this.onChangeRouter({ item, key, keyPath, domEvent })
                    }
                >
                    {data.map(item => item.link !=="backup" && (
                        <Menu.Item
                        key={item.link}
                        icon={<FontAwesome name={item.icon} className="icon-fontawesome" />}
                    >
                        <Link to={item.link}>{item.name}</Link>
                    </Menu.Item>
                    ))}
                    {/* {data[0]?.selected === false ? "" :
                    <Menu.Item
                        key="statistic"
                        icon={<FontAwesome name="tachometer-alt" className="icon-fontawesome" />}
                    >
                        <Link to="/statistic">Thống kê</Link>
                    </Menu.Item>
                    }
                    {data[1]?.selected === false ? "" :
                    <Menu.Item key="static-studies" icon={<FontAwesome name="book" className="icon-fontawesome" />}>
                        <Link to="/static-studies">Sổ báo cáo</Link>
                    </Menu.Item>
                    }
                    {data[2]?.selected === false ? "" :
                    <Menu.Item key="doctor" icon={<FontAwesome name="user-nurse" className="icon-fontawesome" />}>
                        <Link to="/doctor">Bác sĩ</Link>
                    </Menu.Item>
                     }
                     {data[3]?.selected === false ? "" :
                    <Menu.Item key="patient" icon={<FontAwesome name="user-injured" className="icon-fontawesome" />}>
                        <Link to="/patient">Bệnh nhân</Link>
                    </Menu.Item>
                     }
                     {data[4]?.selected === false ? "" :
                    <Menu.Item key="indicate" icon={<FontAwesome name="file-alt" className="icon-fontawesome" />}>
                        <Link to="/indicate">Chỉ định</Link>
                    </Menu.Item>
                     }
                     {data[5]?.selected === false ? "" :
                    <Menu.Item
                        key="technical-services"
                        icon={<FontAwesome name="user-cog" className="icon-fontawesome" />}
                    >
                        <Link to="/technical-services">Dịch vụ kỹ thuật</Link>
                    </Menu.Item>
                     }
                     {data[6]?.selected === false ? "" :
                    <Menu.Item key="type-services" icon={<FontAwesome name="tasks" className="icon-fontawesome" />}>
                        <Link to="/type-services">Loại dịch vụ</Link>
                    </Menu.Item>
                     }
                     {data[7]?.selected === false ? "" :
                    <Menu.Item
                        key="sample-results"
                        icon={<FontAwesome name="file-word" className="icon-fontawesome" />}
                    >
                        <Link to="/sample-results">Mẫu kết quả</Link>
                    </Menu.Item>
                     }
                     {data[8]?.selected === false ? "" :
                    <Menu.Item key="backup" icon={<FontAwesome name="save" className="icon-fontawesome" />}>
                        <Link to="/backup">Backup</Link>
                    </Menu.Item>
                     }
                     {data[9]?.selected === false ? "" :
                    <Menu.Item key="configuration" icon={<FontAwesome name="tools" className="icon-fontawesome" />}>
                        <Link to="/configuration">Cấu hình</Link>
                    </Menu.Item>
                     }
                     {data[10]?.selected === false ? "" :
                    <Menu.Item key="server-pacs" icon={<FontAwesome name="server" className="icon-fontawesome" />}>
                        <Link to="/server-pacs">Máy chủ Pacs</Link>
                    </Menu.Item>
                     }
                     {data[11]?.selected === false ? "" :
                    <Menu.Item key="area" icon={<FontAwesome name="clinic-medical" className="icon-fontawesome" />}>
                        <Link to="/area">Khu vực</Link>
                    </Menu.Item>
                     }
                     {data[12]?.selected === false ? "" :
                    <Menu.Item key="hospital" icon={<FontAwesome name="hospital-alt" className="icon-fontawesome" />}>
                        <Link to="/hospital">Thư mục</Link>
                    </Menu.Item>
                     }
                     {data[13]?.selected === false ? "" :
                    <Menu.Item key="doctor-roles" icon={<FontAwesome name="user-tag" className="icon-fontawesome" />}>
                        <Link to="/doctor-roles">Phân quyền bác sĩ</Link>
                    </Menu.Item>
                     }
                     {data[14]?.selected === false ? "" :
                    <Menu.Item key="print-results" icon={<FontAwesome name="print" className="icon-fontawesome" />}>
                        <Link to="/print-results">Mẫu in kết quả</Link>
                    </Menu.Item>
                     }
                     {data[15]?.selected === false ? "" :
                    <Menu.Item key="icd" icon={<FontAwesome name="list-alt" className="icon-fontawesome" />}>
                        <Link to="/icd">ICD</Link>
                    </Menu.Item>
                     }
                     {data[16]?.selected === false ? "" :
                    <Menu.Item key="log" icon={<FontAwesome name="book" className="icon-fontawesome" />}>
                        <Link to="/log">LOG</Link>
                    </Menu.Item>
                    }
                    {data[17]?.selected === false ? "" :
                    <Menu.Item key="role-admin" icon={<FontAwesome name="book" className="icon-fontawesome" />}>
                        <Link to="/role-admin">phân quyền Admin</Link>
                    </Menu.Item>
                    } */}
                </Menu>
                <div className="contacts">
                    <p>
                        <FontAwesome name="phone" className="icon-fontawesome" /> 0334815049
                    </p>
                </div>
            </div>
        );
    }
}

export default Navigation;
