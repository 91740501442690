import Axios from "axios";
import { message } from "antd";
const ACTION_TYPES = {
    SET_ROLE: "SET_ROLE",
    SET_ROLE_ITEM: "SET_ROLE_ITEM",
};

const initialstate = {
    roles: [],//2-2
    roleItem: {},
};

const roleManagerReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_ROLE:
            state = { ...state };
            state.roles = action.payload.data;
            return { ...state };
        case ACTION_TYPES.SET_ROLE_ITEM:
            state.roleItem = action.payload;
            return { ...state };
        default:
            return state;
    }
};

const setRoles = (roles) => {
    return {
        type: ACTION_TYPES.SET_ROLE,
        payload: roles,
    };
};
const getRoleFromServer = () => async (dispatch) => {
    const response = await Axios.get('api/roles');
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setRoles(response.data));
        }
        
    } else {
        message.error(response.status);
    }
};

const setRoleItem = (role) => {
    return {
        type: ACTION_TYPES.SET_ROLE_ITEM,
        payload: role,
    };
};
const getRoleById = (id) => async (dispatch) => {
    const response = await Axios.get(`api/Roles/${id}`);
    if (response.data.status === 1) {
        dispatch(setRoleItem(response.data.data));
    }
    
};

const updateRoleToServer = (id, role) => async (dispatch) => {
    await Axios.put(`api/Roles/${id}`, role)
        .then((response) => {
            if (response.data) {
                if (response.data.status !== 1) {
                    message.error(response.data.message);
                } else {
                    message.success(response.data.message);
                }
            }
        })
        
};

const addRoleToServer = (request) => async (dispatch) => {
    await Axios.post("api/Roles", request)
        .then((response) => {
            if (response.status === 200) {
                if (response.data.status !== 1) {
                    message.error(response.data.message);
                } else {
                    message.success(response.data.message);
                }
            }
        })  
};
const deleteRoleFromServer = (id) => async (dispatch) => {
    await Axios.delete(`api/Roles/${id}`)
        .then((response) => {
            message.success(response.data.message);
        })
        .catch((err) => {
            message.error("Bạn không có quyền xóa Quyền này!");
        });
};
export {
    addRoleToServer,
    deleteRoleFromServer,
    getRoleFromServer,
    roleManagerReducer,
    getRoleById,
    updateRoleToServer,
};