import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./TechnicalServiceTable.scss";
import { Pagination, Switch, Button, Tooltip, Popconfirm, Checkbox, message } from "antd";
import moment from "moment";
import {
    getTechnicalServiceSearch,
    setTechnicalServiceSearch,
} from "../../pages/Home/TechnicalServicePage/technicalservice";
import Column from "antd/lib/table/Column";
class TechnicalServiceTable extends Component {
    changeSizeTable = () => {
        this.setState({
            tableSize: !this.state.tableSize,
        });
    };
    onChangePage = (page, pageSize) => {
        let technicalserviceSearch = getTechnicalServiceSearch();
        technicalserviceSearch.PageIndex = page;
        technicalserviceSearch.PageSize = pageSize;
        setTechnicalServiceSearch(technicalserviceSearch);
        this.props.handleUpdateTable();
    };
    onShowInfo = (technicalserviceId, technicalserviceName) => {
        this.props.handleShowInfo(technicalserviceId, technicalserviceName);
    };
    onEdit = (data) => {
        this.props.handleEdit(data.id, data.name);
    };
    onEditTime = (technicalserviceId, technicalserviceName) => {
        this.props.handleEditTime(technicalserviceId, technicalserviceName);
    };
    onDelete = (id) => {
        this.props.handleDeleteTechnicalService(id);
    };
    render() {
        const { technicalservices, totalCount } = this.props;
        return (
            <div className={"technicalservice-table-d"}>
                <table border="0" className="table-admin">
                    <thead>
                        <tr>
                            <th>Mã dịch vụ</th>
                            <th>Code</th>
                            <th>Tên dịch vụ</th>
                            <th>StudyID</th>
                            <th>Thời gian tạo</th>
                            <th>Thời gian cập nhật</th>
                            <th style={{minWidth:300}}>Kết quả</th>
                            <th>Trạng thái</th>
                            <th>Kỹ thuật</th>
                            <th style={{minWidth:600}}>Mô tả</th>
                            <th>Thời gian duyệt</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {technicalservices.map((technicalservices, index) => {
                            return (
                                <tr key={index}>
                                    <td>{technicalservices.serviceCode}</td>
                                    <td>{technicalservices.code}</td>
                                    <td>{technicalservices.serviceName}</td>
                                    <td>{technicalservices.studyID}</td>
                                    <td >{moment(technicalservices.createTime).format("DD/MM/YYYY HH:mm:ss")} </td>
                                    {/* <FontAwesomeIcon icon="edit" /> */}
                                    <td >{moment(technicalservices.timeEdit).format("DD/MM/YYYY HH:mm:ss")}</td>
                                    <td>
                                        <div dangerouslySetInnerHTML={{ __html: technicalservices.result }} /> 
                                    </td>
                                    <td>{technicalservices.state}</td>
                                    <td>{technicalservices.tech}</td>
                                    <td>
                                        <div dangerouslySetInnerHTML={{ __html: technicalservices.descTxt }} />
                                    </td>
                                    <td>{moment(technicalservices.aproTime).format("DD/MM/YYYY HH:mm:ss")}</td>
                                    <td>
                                        <div className="tools">
                                            <Button onClick={() =>{technicalservices.aproTime ? this.onEditTime(technicalservices.id, technicalservices.name) : message.error("Bạn không thể thay đổi thời gian khi chưa duyệt!");} }>
                                                <FontAwesomeIcon icon="edit" />
                                            </Button>
                                            {/* <Button onClick={() => this.onEdit(technicalservices)}>
                                                <FontAwesome name="edit" />
                                            </Button> */}
                                            <Popconfirm
                                                placement="topRight"
                                                title={`Xác nhận xóa ${technicalservices.id}`}
                                                onConfirm={() => this.onDelete(technicalservices.id, technicalservices.name)}
                                                okText="Ok"
                                                cancelText="Cancel"
                                            >
                                                <Button>
                                                    <FontAwesomeIcon icon="trash-alt" />
                                                </Button>
                                            </Popconfirm>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <div className="pagination-d">
                    <Pagination
                        defaultCurrent={3}
                        current={getTechnicalServiceSearch().PageIndex}
                        total={totalCount}
                        showQuickJumper
                        showTitle={false}
                        defaultPageSize={getTechnicalServiceSearch().PageSize}
                        onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                    />
                </div>
            </div>
        );
    }
}
export default TechnicalServiceTable;
