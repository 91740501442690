import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, message, Layout, Upload } from "antd";
import {
    getPrintResultById,
    updatePrintResultToServer,
    getPrintResultFromServer,
} from "../../reducers/printresultReducer";
import { connect } from "react-redux";
import moment from "moment";
import { getPrintResultSearch } from "../../pages/Home/PrintResultPage/printresult";
import { handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
import { UploadOutlined } from "@ant-design/icons";
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const { Content, Footer, Header } = Layout;

class ContentEdit extends Component {
    constructor(props) {
        super(props);
        this.formRef = null;
    }

    state = {
        fileList: [],
        uploading: false,
    };

    componentDidMount = () => {
        this.props.getPrintResultById(this.props.printresultId).then(() => {
            this.formRef.setFieldsValue({ ...this.props.printresultItem });
        });
    };
    componentDidUpdate = (prevProps) => {
        if (prevProps.printresultId !== this.props.printresultId) {
            this.props.getPrintResultById(this.props.printresultId).then(() => {
                this.formRef.setFieldsValue({
                    ...this.props.printresultItem,
                });
            });
        }
    };
    onFinish = (values) => {
        const { printresultId } = this.props;
        const { fileList } = this.state;
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('file', file);
        });
        for ( var key in values ) {
            if(!values[key]){
                values[key] = "";
            }
            formData.append(key, values[key]);
        }
        formData.append("status",  this.props.printresultItem.status);
        this.setState({
            uploading: true,
        });
        this.props
            .updatePrintResultToServer(printresultId, formData)
            .then(() => {
                this.props.setModalBasic({ visible: false })
                const search = getPrintResultSearch();
                this.props.getPrintResultFromServer(search);
                this.setState({
                    fileList: [],
                    uploading: false,
                });
                this.props.getPrintResultFromServer(getPrintResultSearch());
            });
    };
    render() {
        const { uploading, fileList } = this.state;
        const propsUpload = {
            name: 'file',
            multiple: false,
            listType:'picture',
            showUploadList: {
                showDownloadIcon: false,
            },
            onRemove: file => {
                this.setState(state => {
                    return {
                        fileList: []
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file]
                }))
                return false;
            },
            fileList
        }
        return (
            <>
                <Layout className="layout-doctor-edit">
                    <Header>
                        <p className="modalBasic-title">Sửa thông tin bác sĩ</p>
                        <button
                            className="modalBasic-btn-close"
                            onClick={() => this.props.setModalBasic({ visible: false })}
                        >
                            <i className="fas fa-times icon"></i>
                        </button>
                    </Header>
                    <Content>
                        <Form
                            className="form-edit-printResult"
                            {...formItemLayout}
                            name="addPrintResult"
                            onFinish={this.onFinish}
                            ref={(ref) => (this.formRef = ref)}
                            scrollToFirstError
                        >
                            <Form.Item name="name" label="Tên">
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="pTitle" label="Tiêu đề 1">
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="pTitle2" label="Tiêu đề 2">
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="pHospital" label="Bệnh viện">
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="pDepartment" label="Khoa phòng">
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="pAddress" label="Địa chỉ">
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="pPhone" label="Điện thoại">
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="pNote" label="Ghi chú">
                                <Input allowClear />
                            </Form.Item>
                            <Form.Item name="template" label="Mẫu Layout">
                                <Input allowClear />
                            </Form.Item>
                            <div className="ant-row ant-form-item">
                                <div className="ant-col ant-col-6 ant-form-item-label">
                                    <label title="Logo">Logo</label>
                                </div>
                                <div className="ant-col ant-col-14 ant-form-item-control">
                                    <Upload className="upload-list-inline" {...propsUpload}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>
                            </div>
                            <Button
                                style={{display: "none" }}
                                id="btn-submit-ediit-printresult"
                                htmlType={"submit"}
                            >
                                Lưu lại
                            </Button>
                        </Form>
                    </Content>
                    <Footer>
                        <Button
                            style={{ color: "#fff", background: "#2687d4" }}
                            htmlType={"submit"}
                            onClick={() => document.getElementById("btn-submit-ediit-printresult").click()}
                        >
                            Lưu lại
                        </Button>
                    </Footer>
                </Layout>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        printresultItem: state.printresult.printresultItem,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getPrintResultById: (id) => dispatch(getPrintResultById(id)),
        updatePrintResultToServer: (id, printresult) => dispatch(updatePrintResultToServer(id, printresult)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        getPrintResultFromServer: (search) => dispatch(getPrintResultFromServer(search)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentEdit);
