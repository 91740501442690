import Axios from "axios";
import { message } from "antd";
const ACTION_TYPES = {
    SET_BACKUP: "SET_BACKUP",
    SET_BACKUP_ITEM: "SET_BACKUP_ITEM",
};

const initialstate = {
    backups: [],
    totalCount: 0,
    backupItem: {},
};

const backupReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_BACKUP:
            state = {...state };
            state.backups = action.payload.data;
            state.totalCount = action.payload.dataCount;
            return {...state };
        case ACTION_TYPES.SET_BACKUP_ITEM:
            state.backupItem = action.payload;
            return {...state };
        default:
            return state;
    }
};
const setBackups = (backups) => {
    return {
        type: ACTION_TYPES.SET_BACKUP,
        payload: backups,
    };
};
const getBackupFromServer = (request) => async(dispatch) => {
    const response = await Axios.get(
        `api/Backups/paging?Keyword=${request.Keyword}&Age=${request.Age}&Sex=${request.Sex}&PageIndex=${request.PageIndex}&PageSize=${request.PageSize}`
    );
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setBackups(response.data));
        }
    } else {
        message.error(response.status);
    }
};

const setBackupItem = (backup) => {
    return {
        type: ACTION_TYPES.SET_BACKUP_ITEM,
        payload: backup,
    };
};
const getBackupById = (id) => async(dispatch) => {
    const response = await Axios.get(`api/Backups/${id}`);
    if (response.data.status === 1) {
        dispatch(setBackupItem(response.data.data));
    }
};

const updateBackupToServer = (id, backup) => async(dispatch) => {
    await Axios.put(`api/Backups/${id}`, backup)
        .then((response) => {
            if (response.data) {
                message.success(response.data.message);
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let messages = "";
                if (e.Name) {
                    for (let i of e.Name) {
                        messages += i + "! ";
                    }
                }
                message.error(messages, 3);
            }
        });
};

const addBackupToServer = (request) => async(dispatch) => {
    await Axios.post("api/Backups", request)
        .then((response) => {
            if (response.status === 200) {
                if (response.data.status === 1)
                    message.success("Successfully!");
                else
                    message.error(response.data.message);
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let mes = "";
                if (e.Name) {
                    for (let i of e.Name) {
                        mes += i + "! ";
                    }
                }

                message.error(mes, 3);
            }
        });
};

const deleteBackupFromServer = (id) => async(dispatch) => {
    await Axios.delete(`api/Backups/${id}`)
        .then((response) => {
            message.success("Successfully!");
        })
        .catch((err) => {
            message.error("Bạn không có quyền xóa Bác sĩ này!");
        });
};
export {
    addBackupToServer,
    deleteBackupFromServer,
    getBackupFromServer,
    backupReducer,
    getBackupById,
    updateBackupToServer,
};