import moment from "moment";
let printresultSeach = {
    Keyword: "",
    PageIndex: 1,
    PageSize: 10,
};
const printresultSeachDefault = {
    Keyword: "",
    PageIndex: 1,
    PageSize: 10,
};

const getPrintResultSearch = () => {
    return printresultSeach;
};

const setPrintResultSearch = (search) => {
    printresultSeach = search;
};

export { printresultSeachDefault, getPrintResultSearch, setPrintResultSearch };
export const printResultDefault = {
    name: "name " + Math.round(Math.random() * 10000000),
    pTitle: "title " + Math.round(Math.random() * 10000000),
    pTitle2: "title2 " + Math.round(Math.random() * 10000000),
    pHospital: "hospital " + Math.round(Math.random() * 10000000),
    pDepartment: "department " + Math.round(Math.random() * 10000000),
    pAddress: "address " + Math.round(Math.random() * 10000000),
    pPhone: "14124122 " + Math.round(Math.random() * 10000000),
    pNote: "Note Note " + Math.round(Math.random() * 10000000),
    pLogo: "Logo.png",
};
