import moment from "moment";
let mediEquipmentSeach = {
    Keyword: "",
    PageIndex: 1,
    PageSize: 10,
};
let TypeEquipMedi = [
    {
        id: 0,
        name:"NonType"
    },
    {
        id: 1,
        name:"Drug"
    },
    {
        id: 2,
        name:"Film"
    },
    {
        id: 3,
        name:"Other"
    }
]

const getMediEquipmentSearch = () => {
    return mediEquipmentSeach;
};
const getTypeEquipMedi = () => {
    return TypeEquipMedi;
};

const setMediEquipmentSearch = (search) => {
    mediEquipmentSeach = search;
};

export { getMediEquipmentSearch, setMediEquipmentSearch, getTypeEquipMedi };