import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, message, Select, Layout } from "antd";
import { connect } from "react-redux";
import { setModalBasic } from "../../reducers/uiReducer";
import Axios from "axios";
import { fetchIndications, getDropdownServices } from "../../reducers/icdReducer";
const { Content, Footer, Header } = Layout;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const { Option } = Select;
class AddIcd extends Component {
    componentDidMount = () => {
        // this.refForm.setFieldsValue(geticdForm());
    };

    refForm = null;

    onFinish = async (values) => {
        const response = await Axios.post("/api/Indications", values);
        if(response.data.status === 1){
            message.success(response.data.message)
            this.props.fetchIndications();
            this.props.setModalBasic({ visible: false })
        }else{
            message.error(response.data.message)
        }
        // this.props.addicdToServer(values).then(() => {
        //     this.props.handleCloseModalApp();
        //     this.props.geticdFromServer(geticdSearch());
        //     if (getStatusAdd() === true) {
        //         this.refForm.setFieldsValue(deaulticdForm);
        //         setStatusAdd(false);
        //     }
        // });
    };

    render() {
        const {dropdownServices } = this.props;
        return (
            <Layout className="icd-add-form-content">
                <Header>
                    <p className="modalBasic-title">Thêm mới ICD</p>
                    <button
                        className="modalBasic-btn-close"
                        onClick={() => this.props.setModalBasic({ visible: false })}
                    >
                        <i className="fas fa-times icon"></i>
                    </button>
                </Header>
                <Content>
                    <Form
                        ref={(ref) => (this.refForm = ref)}
                        style={{ marginTop: 20 }}
                        {...formItemLayout}
                        name="addicd"
                        onFinish={this.onFinish}
                        scrollToFirstError
                    >
                        <Form.Item name="code" label="Mã" rules={[{ required: true, message:"vui lòng nhập mã!" }]}>
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="name" label="Tên" rules={[{ required: true, message:"vui lòng nhập tên!" }]}>
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="serviceID" label="Loại dịch vụ">
                            <Select showSearch placeholder="Chọn dịch vụ"
                                allowClear
                                style={{ width: '100%' }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {dropdownServices.map((item) => (
                                    <Option key={item.id} value={Number(item.id)}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Button style={{ display: "none" }} htmlType={"submit"} id="btn-submit-add-icd">
                            Thêm mới
                        </Button>
                    </Form>
                </Content>
                <Footer>
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            document.getElementById("btn-submit-add-icd").click();
                        }}
                    >
                        Thêm mới
                    </Button>
                </Footer>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        dropdownServices: state.icd.dropdownServices
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        fetchIndications: () => dispatch(fetchIndications()),
        getDropdownServices: () => dispatch(getDropdownServices()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddIcd);
