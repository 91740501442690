import React, { Component } from "react";
import { Button, Select, Checkbox, Layout, message } from "antd";
import { connect } from "react-redux";
import { getDoctorRoleSearch } from "../../pages/Home/DoctorRolePage/doctorrole";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContentEdit from "../DoctorRole/ContentEdit";
import { fetchDoctorRoleById } from "../../reducers/doctorroleReducer";
import { setModalBasic } from "../../reducers/uiReducer";


class RoleItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numberShow:3
        };
    }
    componentDidMount= () => {
    }
    showMore = () => {
        const { doctorrole } = this.props;
        if (doctorrole.listServerpacs.length<3) {
            return;
        }
        if (this.state.numberShow == 3) {
            this.setState({ numberShow: doctorrole.listServerpacs.length });
        } else {
            this.setState({ numberShow: 3 });
        }
    }

    handleEdit = (doctorrole) => {
        //this.props.fetchDoctorRoleById(doctorrole.userId);
        const modalData = {
            visible: true,
            width: window.screen.width -100,
            content: <ContentEdit userId={doctorrole.userId} />,
            name: "doctor-add",
        };
        this.props.setModalBasic(modalData);
    };

    render() {
        const { numberShow } = this.state;
        const { doctorrole, index, numRole} = this.props;
        return (
            doctorrole.listServerpacs.length <3?
            doctorrole.listServerpacs.map((item, index2) => {
                return (
                    <tr key={`${index}-${index2}`}>
                        {index2 === 0 && (
                            <td onClick={() => this.setState({ numberShow: 9999999 })} rowSpan={doctorrole.listServerpacs.length}>
                                {doctorrole.name}
                            </td>
                        )}

                        <td>{item.areaName}</td>
                        <td>{item.hosName}</td>
                        <td >{item.name}</td>
                        {Object.getOwnPropertyNames(item.permissionData).map(
                            (j, idx) => {
                                return (
                                    <td key={idx}>
                                        <Checkbox
                                            key={idx}
                                            checked={item.permissionData[j]}
                                        ></Checkbox>
                                    </td>
                                );
                            }
                        )}
                        {index2 === 0 && (
                            <td rowSpan={doctorrole.listServerpacs.length}>
                                <div className="tools">
                                    <Button onClick={() => this.handleEdit(doctorrole)}>
                                        <FontAwesomeIcon icon="edit" />
                                    </Button>
                                </div>
                            </td>
                        )}
                    </tr>
                )
            }) :
                <>
                    {
                        doctorrole.listServerpacs.slice(0, numberShow).map((item, index2) => {
                            return (

                                <tr key={`${index}-${index2}`}>
                                    {index2 === 0 && (
                                        <td onClick={() => this.setState({ numberShow: 9999999 })} rowSpan={numberShow+1}>
                                            {doctorrole.name}
                                        </td>
                                    )}

                                <td>{item.areaName}</td>
                                <td>{item.hosName}</td>
                                <td >{item.name}</td>
                                    {Object.getOwnPropertyNames(item.permissionData).map(
                                        (j, idx) => {
                                            return (
                                                <td key={idx}>
                                                    <Checkbox
                                                        key={idx}
                                                        checked={item.permissionData[j]}
                                                    ></Checkbox>
                                                </td>
                                            );
                                        }
                                    )}
                                    {index2 === 0 && (
                                        <td rowSpan={numberShow+1}>
                                            <div className="tools" style={{ display: "flex", flexDirection: "column", alignItems: "space-between" }}>
                                                <Button onClick={() => this.handleEdit(doctorrole)}>
                                                    <FontAwesomeIcon icon="edit" />
                                                </Button>
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            )
                        })
                    }
                    <tr>
                        <td colSpan={numRole + 2}><Button onClick={() => this.showMore()} block>
                            {numberShow > 3 ?
                                <>
                                    Ẩn bớt <FontAwesomeIcon icon="chevron-up" />
                                </>
                                :
                                <>
                                    Hiển thị thêm <FontAwesomeIcon icon="chevron-down" />
                                </>
                                }
                            
                        </Button></td>
                    </tr>
                 </>
        )
    }
}
function mapStateToProps(state) {
   return{};
}
function mapDispatchToProps(dispatch) {
    return {
        fetchDoctorRoleById: (id) => dispatch(fetchDoctorRoleById(id)),
        setModalBasic: (data) => dispatch(setModalBasic(data))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleItem);
