import React, { Component } from "react";
import AppBar from "../../components/AppBar";
import Navigation from "../../components/Navigation";
import { connect } from "react-redux";
import { handleToggleNav } from "../../reducers/uiReducer";
import { clearAll } from "../../utils/authenticate";
import { setAuth } from "../../reducers/authReducer";
import { Redirect, BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeRouter from "../../routes/HomeRouter";
import "./HomePage.scss";
import { Spin } from "antd";

class HomePage extends Component {
    handleToggleNav = () => {
        this.props.handleToggleNav();
    };
    handleLogout = () => {
        clearAll();
        this.props.setAuth(false);
    };
    render() {
        const { drawer, isAuthenticate, loading } = this.props;
        return isAuthenticate ? (
            <div className="home-page-d">
                <div className="layout-main-d" style={{ position: "relative", height: "100vh" }}>
                    <div className={drawer ? "navigation-d active" : "navigation-d hidden"}>
                        <Navigation handleToggleNav={this.handleToggleNav} handleLogout={this.handleLogout} />
                    </div>
                    <div className={drawer ? "app-content active" : "app-content fullWidth"}>
                        <HomeRouter />
                    </div>
                    {loading && (
                        <div
                            style={{
                                background: "rgba(0,0,0,.45)",
                                position: "absolute",
                                top: 0,
                                bottom: 0,
                                right: 0,
                                left: 0,
                                zIndex: 99,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Spin size="large" tip="Loading..."></Spin>,
                        </div>
                    )}
                </div>
            </div>
        ) : (
            <Redirect
                push
                to={{
                    pathname: "/",
                }}
            />
        );
    }
}
function mapStateToProps(state) {
    return {
        drawer: state.ui.drawer,
        isAuthenticate: state.isAuthenticate,
        loading: state.loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        handleToggleNav: () => dispatch(handleToggleNav()),
        setAuth: (value) => dispatch(setAuth(value)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
