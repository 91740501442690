import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, message } from "antd";
import { getDoctorById, updateDoctorToServer, getDoctorFromServer } from "../../reducers/doctorReducer";
import { connect } from "react-redux";
import moment from "moment";
import { getDoctorSearch } from "../../pages/Home/DoctorPage/doctor";
import { handleCloseModalApp } from "../../reducers/uiReducer";

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
class ContentEdit extends Component {
    constructor(props) {
        super(props);
        this.formRef = null;
    }

    componentDidMount = () => {
        this.props.getDoctorById(this.props.doctorId).then(() => {
            this.formRef.setFieldsValue({ ...this.props.doctorItem, doB: moment(this.props.doctorItem.doB) });
        });
    };
    componentDidUpdate = (prevProps) => {
        if (prevProps.doctorId !== this.props.doctorId) {
            this.props.getDoctorById(this.props.doctorId).then(() => {
                this.formRef.setFieldsValue({ ...this.props.doctorItem, doB: moment(this.props.doctorItem.doB) });
            });
        }
    };
    onFinish = (values) => {
        const tmp = ["ct", "dx", "eo", "mamo", "mri", "pet", "us"];
        for (let item of tmp) {
            values[item] = Number(values[item]);
        }
        const { doctorId } = this.props;
        this.props.updateDoctorToServer(doctorId, values).then(() => {
            this.props.handleCloseModalApp();
            const search = getDoctorSearch();
            this.props.getDoctorFromServer(search);
        });
    };
    render() {
        return (
            <Form
                style={{ marginTop: 20 }}
                {...formItemLayout}
                name="addDoctor"
                onFinish={this.onFinish}
                ref={(ref) => (this.formRef = ref)}
                scrollToFirstError
            >
                <Form.Item name="name" label="Họ tên">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="gender" label="Giới tính">
                    <Radio.Group>
                        <Radio value="Nam">Nam</Radio>
                        <Radio value="Nữ">Nữ</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="address" label="Địa chỉ">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="doB" label="Ngày sinh">
                    <DatePicker format="DD/MM/YYYY" placeholder="" style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item name="email" label="Email">
                    <Input allowClear />
                </Form.Item>
                <Form.Item name="phoneNumber" label="Số điện thoại">
                    <Input allowClear type="number" />
                </Form.Item>
                <Form.Item name="ct" label="CT">
                    <Input allowClear type="number" />
                </Form.Item>
                <Form.Item name="dx" label="DX">
                    <Input allowClear type="number" />
                </Form.Item>
                <Form.Item name="eo" label="EO">
                    <Input allowClear type="number" />
                </Form.Item>
                <Form.Item name="mamo" label="MAMO">
                    <Input allowClear type="number" />
                </Form.Item>
                <Form.Item name="mri" label="MRI">
                    <Input allowClear type="number" />
                </Form.Item>
                <Form.Item name="pet" label="PET">
                    <Input allowClear type="number" />
                </Form.Item>
                <Form.Item name="us" label="US">
                    <Input allowClear type="number" />
                </Form.Item>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}></div>
                    <div style={{ flex: 3 }}>
                        <Button style={{ color: "#fff", background: "#2687d4" }} htmlType={"submit"}>
                            Lưu lại
                        </Button>
                    </div>
                </div>
            </Form>
        );
    }
}

function mapStateToProps(state) {
    return {
        doctorItem: state.doctor.doctorItem,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getDoctorById: (id) => dispatch(getDoctorById(id)),
        updateDoctorToServer: (id, doctor) => dispatch(updateDoctorToServer(id, doctor)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        getDoctorFromServer: (search) => dispatch(getDoctorFromServer(search)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentEdit);
