import React, { Component } from "react";
import { Form, Input, Button, message, AutoComplete, Layout, Row, Col, Select } from "antd";
import { connect } from "react-redux";
import { setModalBasic } from "../../reducers/uiReducer";
import Axios from "axios";
import {
    deleteIcdTemp,
    fetchIcdTemp,
    getListIcdTemp,
    postIcdTemp,
    getListIcd,
    getListDiagnose,
} from "../../reducers/icdTempManagerReducer";
import Item from "antd/lib/list/Item";
import { getDropdownServices, fetchIndications } from "../../reducers/icdReducer";
import { LoadingOutlined } from "@ant-design/icons";
import { setModalBasic2 } from "../../reducers/modalBasic2Reducer";
import EditTemplate from "../../components/TemplateComponent/EditTemplate";
import "./AddIcdTemp.scss";
import AddTemplate from "../TemplateComponent/AddTemplate";
const { Content, Footer, Header } = Layout;

const { Option } = Select;
class EditTemplateIcd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idIdcChange: "",
            serviceIdSelect: "",
        };
    }
    componentDidMount = () => {
        message.info({
            content: "Loading...",
            duration: 10,
            icon: <LoadingOutlined />,
        });
        this.props.getDropdownServices();
        this.props.getListDiagnose(this.props.ServiceID);
        this.props.getListIcdTemp(this.props.idIdc).then(() => {
            message.destroy();
        });
        const { idIdc, ServiceID } = this.props;
        this.setState({ idIdcChange: idIdc, serviceIdSelect: ServiceID });
    };
    // componentDidUpdate =(prevProps) => {
    //     const {dropdownIcd} = this.props;
    //     if(prevProps.dropdownIcd !== dropdownIcd){
    //         this.idIdc = this.props.idIdc;
    //         this.refForm.setFieldsValue({ idIdc:this.props.idIdc+"" });
    //     }
    // }
    onSelectIcdTemp = async (id) => {
        await this.props.getListIcdTemp(id);
        this.setState({ idIdcChange: id });
    };
    onSelectService = async (id) => {
        if (!id) {
            id = "";
        }
        await this.props.getListDiagnose(id);
        this.setState({ serviceIdSelect: id });
    };
    onDeleteIcdTemp = async (id) => {
        message.info({
            content: "Loading...",
            duration: 10,
            icon: <LoadingOutlined />,
        });
        await this.props.deleteIcdTemp(id);
        await this.props.getListIcdTemp(this.state.idIdcChange).then(() => {
            message.destroy();
        });
        await this.props.fetchIndications();
    };
    onSelectDiagnose = async (id) => {
        message.info({
            content: "Loading...",
            duration: 10,
            icon: <LoadingOutlined />,
        });
        if (this.state.idIdcChange) {
            let data = {
                idIdc: this.state.idIdcChange,
                idDiag: id,
            };
            await this.props.postIcdTemp(data);
            await this.props.getListIcdTemp(this.state.idIdcChange).then(() => {
                message.destroy();
            });
            await this.props.fetchIndications();
        } else {
            message.warning("Vui lòng chọn dịch vụ!");
        }
    };

    render() {
        const { dropdownDiagnose, listIcdTemp, dropdownServices, idcCode, idcName, ServiceID, idIdc } = this.props;
        const { serviceIdSelect, idIdcChange } = this.state;
        return (
            <Layout className="icd-add-form-content">
                <Header>
                    <p className="modalBasic-title">Phân mẫu Icd</p>
                    <button
                        className="modalBasic-btn-close"
                        onClick={() => this.props.setModalBasic({ visible: false })}
                    >
                        <i className="fas fa-times icon"></i>
                    </button>
                </Header>
                <Content>
                    <div className="top-addIcdTemp">
                        <Form.Item style={{ width: "400px", paddingLeft: 5 }} name="serviceId">
                            <Select
                                showSearch
                                placeholder="Chọn dịch vụ"
                                allowClear
                                defaultValue={ServiceID ?? ""}
                                style={{ width: "100%" }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(value) => this.onSelectService(value)}
                            >
                                {dropdownServices.map((item) => (
                                    <Option key={item.id} value={Number(item.id)}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Button
                            onClick={() => {
                                this.props.setModalBasic2({
                                    width: "80%",
                                    height: "90%",
                                    name: "modal-basic-2-edit-template",
                                    content: <AddTemplate modal={2} serviceId={serviceIdSelect} idIdc={idIdcChange} />,
                                    visible: true,
                                });
                            }}
                            type="primary"
                        >
                            Thêm mẫu mới
                        </Button>
                    </div>
                    <div className="main-addIcdTemp">
                        <div className="left" style={{ paddingLeft: 5, color: "#000" }}>
                            <span>
                                {idcCode} - {idcName}
                            </span>
                        </div>
                        <div className="right" style={{ paddingRight: 5 }}>
                            <Select
                                showSearch
                                placeholder="Chọn mẫu"
                                onSelect={(value) => this.onSelectDiagnose(value)}
                                style={{ width: "100%" }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {dropdownDiagnose.map((item) => (
                                    <Option key={item.id} value={Number(item.id)}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                            {listIcdTemp.map((item) => (
                                <Col key={item.idIdcTemp} span={24} style={{ margin: "5px", fontSize: 20 }}>
                                    <span style={{ color: "#000" }}>{item.nameDiag}</span>
                                    <span
                                        onClick={() => {
                                            this.props.setModalBasic2({
                                                width: "80%",
                                                height: "90%",
                                                name: "modal-basic-2-edit-template",
                                                content: (
                                                    <EditTemplate
                                                        id={item.idDiag}
                                                        modal={2}
                                                        serviceId={serviceIdSelect}
                                                        idIdc={idIdcChange}
                                                    />
                                                ),
                                                visible: true,
                                            });
                                        }}
                                        style={{ color: "royalblue", cursor: "pointer", marginLeft: 10 }}
                                    >
                                        <i className="fas fa-edit"></i>
                                    </span>
                                    <span
                                        onClick={() => this.onDeleteIcdTemp(item.idIdcTemp)}
                                        style={{ color: "#f5222d", cursor: "pointer", marginLeft: 10 }}
                                    >
                                        <i className="fas fa-times"></i>
                                    </span>
                                </Col>
                            ))}
                        </div>
                    </div>
                </Content>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        dropdownIcd: state.icdTemp.dropdownIcd,
        dropdownDiagnose: state.icdTemp.dropdownDiagnose,
        listIcdTemp: state.icdTemp.listIcdTemp,
        dropdownServices: state.icd.dropdownServices,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        setModalBasic2: (data) => dispatch(setModalBasic2(data)),
        fetchIndications: () => dispatch(fetchIndications()),
        getListIcdTemp: (id) => dispatch(getListIcdTemp(id)),
        getListIcd: (id, serviceId) => dispatch(getListIcd(id, serviceId)),
        getListDiagnose: (serviceId) => dispatch(getListDiagnose(serviceId)),
        postIcdTemp: (data) => dispatch(postIcdTemp(data)),
        deleteIcdTemp: (id) => dispatch(deleteIcdTemp(id)),
        getDropdownServices: () => dispatch(getDropdownServices()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTemplateIcd);
