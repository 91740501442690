import React, { Component } from "react";
import "./SummaryTable.scss";
import { Pagination, Switch, Button, Tooltip, Popconfirm, Table, Checkbox } from "antd";
import { setStatisticSearch, getStatisticSearch } from "../../pages/Home/StatisticPage/statistic";

class SummaryTable extends Component {
    onChangePage = (page, pageSize) => {
        //Change StatisticSearch
        let statisticSearch = getStatisticSearch();
        statisticSearch.PageIndex = page;
        statisticSearch.PageSize = pageSize;
        setStatisticSearch(statisticSearch);
        this.props.handleUpdateTable();
    };
    render() {
        const { summary, totalCount } = this.props;
        //         name: "MR"
        // readNum: 0
        // readNumHis: 0
        // unReadNum: 0
        // unReadNumHis: 0
        return (
            <div className={"summary-table-d"}>
                <div style={{ width: "100%", height: 30, background: "#2a274c" }}>
                    <p style={{ lineHeight: "30px", color: "white", textAlign: "center", fontSize: 20 }}>
                        <b>THỐNG KÊ CHUNG</b>
                    </p>
                </div>
                {/* <Table
                    bordered
                    pagination={false}
                    dataSource={summary}
                    size="small"
                    scroll={{ y: window.innerHeight - 150 }}
                >
                    <Column title="STT" dataIndex="stt" key="Name" />
                    <Column title="Máy" dataIndex="name" key="FullName" />
                    <ColumnGroup title="Số lượng ca chụp trên HIS" align="center">
                        <Column title="Chưa đọc" dataIndex="unReadNumHis" key="Path" align="center" />
                        <Column title="Đã đọc" dataIndex="readNumHis" key="StartMode" align="center" />
                    </ColumnGroup>
                    <ColumnGroup title="Số lượng ca chụp ngoài HIS" align="center">
                        <Column title="Chưa đọc" dataIndex="unReadNum" key="Path" align="center" />
                        <Column title="Đã đọc" dataIndex="readNum" key="StartMode" align="center" />
                    </ColumnGroup>
                </Table> */}
                <table border="0" className="table-admin">
                    <thead>
                        <tr>
                            <th rowSpan="2">STT</th>
                            <th rowSpan="2">Máy</th>
                            <th colSpan="2">Số lượng ca chụp trên HIS</th>
                            <th colSpan="2">Số lượng ca chụp ngoài HIS</th>

                        </tr>
                        <tr>
                            <th>Chưa đọc</th>
                            <th>Đã đọc</th>
                            <th>Chưa đọc</th>
                            <th>Đã đọc</th>
                        </tr>
                    </thead>
                    <tbody>
                        {summary.map((summary, index) => {
                            return (<tr key={index}>
                                <td>{summary.stt}</td>
                                <td>{summary.name}</td>
                                <td>{summary.unReadNumHis}</td>
                                <td>{summary.readNumHis}</td>
                                <td>{summary.unReadNum}</td>
                                <td>{summary.readNum}</td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}
export default SummaryTable;
