import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getDoctorFromServer,
  deleteDoctorFromServer,
  resetPasswordFromServer,
  lockUser,
  unlockUser,
  getDropdownRoles,
  checkVermed,
} from "../../../reducers/doctorReducer";
import { handleOpenModalApp, setModalBasic } from "../../../reducers/uiReducer";
import RolesContent from "../../../components/Doctor/ContentRoles";
import ContentInfo from "../../../components/Doctor/ContentInfo";
import ContentEdit from "../../../components/Doctor/ContentEdit";
import AddFormContent from "../../../components/Doctor/AddFormContent";
import AddUserWithExcel from "../../../components/Doctor/AddUserWithExcel";
import { Form, Button, Input, Popconfirm, Pagination, Image, message, Upload,Select,Tooltip  } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./DoctorPage.scss";
import { getDoctorSearch, setDoctorSearch } from "./doctor";
import { Link } from "react-router-dom";
import Axios from "axios";
import { removeVietnameseTones } from "../../../utils";
import EditPosition from "../../../components/Doctor/EditPosition";
import moment from "moment";

const { Option } = Select;
class DoctorPage extends Component {
  constructor(props) {
    super(props);
    this.Keyword = null;
    this.state = {
        AccessStudyByUser:false
    };
  }

  componentDidMount = () => {
    this.props.checkVermed();
    this.props.getDoctorFromServer(getDoctorSearch());
    this.props.getDropdownRoles();
    try{
      Axios.get(
          `api/Configs/paging?PageIndex=1&PageSize=1000`
        ).then(response => {
          if (response.status === 200) {
              if (response.data.status === 1) {
                  const list = response.data.data.filter(x=>x.configCode == "AccessStudyByUser");
                  if(list.length > 0){
                    if(list[0].configName == "true")
                      this.setState({AccessStudyByUser:true})
                  }
              }
          }
        });
    }catch{
      
    }
  };

  handleUpdateTable = () => {
    this.props.getDoctorFromServer(getDoctorSearch());
  };

  onChangeRole = (doctorId, doctorName) => {
    const modalData = {
      visible: true,
      width: 900,
      content: <RolesContent doctorId={doctorId} doctorName={doctorName} />,
      name: "modal--change-role-doctor",
    };
    this.props.setModalBasic(modalData);
  };

  onShowInfo = (doctorId, doctorName) => {
    const {isvermed} = this.props;
    const modalData = {
      visible: true,
      width: 900,
      content: <ContentInfo doctorId={doctorId} doctorName={doctorName} isvermed={isvermed} />,
      name: "doctor-add",
    };
    this.props.setModalBasic(modalData);
  };

  onEdit = (doctorId, doctorName) => {
    const {isvermed} = this.props;
    const modalData = {
      visible: true,
      width: 900,
      content: <ContentEdit doctorId={doctorId} doctorName={doctorName} AccessStudyByUser ={this.state.AccessStudyByUser} isvermed={isvermed}/>,
      name: "doctor-edit",
    };
    this.props.setModalBasic(modalData);
  };

  onDelete = (doctorId, doctorName) => {
    this.props.deleteDoctorFromServer(doctorId).then(() => {
      setDoctorSearch({ ...getDoctorSearch(), PageIndex: 1 });
      this.props.getDoctorFromServer(getDoctorSearch());
    });
  };

  onResetPassword = (id, name) => {
    this.props.resetPasswordFromServer(id);
  };

  onLockUser = async (id) => {
    await this.props.lockUser(id);
    await this.props.getDoctorFromServer(getDoctorSearch());
  };

  onUnlockUser = async (id) => {
    await this.props.unlockUser(id);
    await this.props.getDoctorFromServer(getDoctorSearch());
  };

  onChangePage = (page, pageSize) => {
    let doctorSearch = getDoctorSearch();
    doctorSearch.PageIndex = page;
    doctorSearch.PageSize = pageSize;
    setDoctorSearch(doctorSearch);
    this.handleUpdateTable();
  };

  onFinish = (values) => {
    let doctorSearch = getDoctorSearch();
    doctorSearch.Keyword = values.Keyword;
    doctorSearch.PageIndex = 1;
    doctorSearch.RoleName =values.Roles;
    if(!values.Roles)
      doctorSearch.RoleName = "";
    getDoctorSearch(doctorSearch);
    this.handleUpdateTable(doctorSearch);
  };
  // onSearch = () => {
  //     setDoctorSearch({
  //         ...getDoctorSearch(),
  //         PageIndex: 1,
  //         Keyword: this.Keyword.state.value
  //     })
  //     this.handleUpdateTable();
  // };

  onAdd = () => {
    const {isvermed} = this.props;
    const modalData = {
      visible: true,
      width: 900,
      content: <AddFormContent AccessStudyByUser ={this.state.AccessStudyByUser} isvermed={isvermed}/>,
      name: "doctor-add",
    };
    this.props.setModalBasic(modalData);
  };
  onAddUserWithExcel = () => {
    const modalData = {
      visible: true,
      width: 900,
      content: <AddUserWithExcel />,
      name: "doctor-add",
    };
    this.props.setModalBasic(modalData);
  };
  onAddUser = () => {
    const modalData = {
      visible: true,
      width: 900,
      content: <AddFormContent />,
      name: "doctor-add",
    };
    this.props.setModalBasic(modalData);
  };
  onEditPosition = () => {
    const {isvermed} = this.props;
    const modalData = {
      visible: true,
      width: 900,
      content: <EditPosition isvermed={isvermed}/>,
      name: "doctor-add",
    };
    this.props.setModalBasic(modalData);
  };
  

  render() {
    // const AddUser = {
    //     listType: 'text',
    //     onpre(file) {
    //       console.log('Your upload file:', file);
    //       // Your process logic. Here we just mock to the same file
    //       const response = Axios.post(`api/AddUser`, file);
    //         if (response.status === 200) {
    //             message.success(`file uploaded successfully, ${response.message}`);
    //         } else {
    //             message.error(`Update Thất bại!, ${response.message}`, response.status, );
    //         }
    //     },
    //   };
    const { doctors, totalCount,listGroupRoles, isvermed } = this.props;
    return (
      <div className="doctor-page-d">
        <div className="doctor-search-d">
          <Form
            name="horizontal_login"
            initialValues={{ Keyword: "" }}
            layout="inline"
            onFinish={this.onFinish}
          >
            <Form.Item name="Keyword" style={{marginTop: 5 }}>
              <Input placeholder="Keyword" />
            </Form.Item>
            <Form.Item name="Roles" style={{marginTop: 5, width: 250 }}>
               <Select
                   defaultValue=""
                   showSearch
                   style={{ width: "100%" }}
                   filterOption={(input, option) =>
                       {
                           option.children = removeVietnameseTones(option.children);
                           input = removeVietnameseTones(input);
                           return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                       }
                       }
                   >
                       <Option value="">Tất cả</Option>
                       {listGroupRoles.map((item) => {
                           return (
                               <Option key={item.name} value={item.name}>
                                   {item.name}
                               </Option>
                           );
                       })}
               </Select>
            </Form.Item>
            <Button
              style={{ background: "#2a274c", color: "#fff",marginTop: 5,}}
              htmlType="submit"
            >
              Tìm kiếm
            </Button>
          </Form>
          {/* <Input defaultValue="" placeholder="Keyword" ref={r => this.Keyword = r}/>
                    <Button style={{ background: "#2a274c", color: "#fff", marginRight: 10 }} onClick={this.onSearch}>
                        Tìm kiếm
                    </Button> */}
          <Button
            style={{ background: "#2a274c", color: "#fff",marginTop: 5,  }}
            onClick={this.onAdd}
          >
            Thêm mới
          </Button>
          <Link to="/role-manager">
            <Button
              style={{ background: "#2a274c", color: "#fff",marginTop: 5, }}
              onClick={() => localStorage.setItem("router", "role-manager")}
            >
              Quản lý quyền
            </Button>
          </Link>
          <Button
            style={{ background: "#2a274c", color: "#fff",marginTop: 5, }}
            onClick={this.onAddUserWithExcel}
          >
            Thêm mới nhanh
          </Button>
          {isvermed == true ? 
            <Button
              style={{ background: "#2a274c", color: "#fff",marginTop: 5,  }}
              onClick={this.onEditPosition}
            >
              Sửa DMục vị trí
            </Button>
          : 
          ""
          }
          {/* <Upload {...AddUser}>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload> */}
        </div>
        <div className="doctor-table-d ">
          <table border="0" className="table-admin">
            <thead>
              <tr>
                <th>Tên tài khoản</th>
                <th>Tên Đăng Nhập</th>
                <th>Giới Tính</th>
                <th>Địa chỉ</th>
                <th>Ngày Sinh</th>
                <th>Email</th>
                <th>Điện Thoại</th>
                <th>Chữ ký</th>
                <th>Ảnh đại diện</th>
                {isvermed == true ? 
                <>
                  <th>Đơn vị công tác</th>
                  <th>Vị trí</th>
                  <th>Tên đơn vị ngoài MED</th>
                  <th>Mô tả</th>
                  <th>Thứ tự ưu tiên</th>
                  <th>Người đề xuất lập</th>
                  <th>Người lập</th>
                  <th>Thời gian lập</th>
                </>
                : 
                  ""
                }
                
                <th></th>
              </tr>
            </thead>
            <tbody>
              {doctors.map((doctor, index) => {
                return (
                  <tr key={index}>
                    <td>{doctor.name}</td>
                    <td>{doctor.userName}</td>
                    <td>
                      {doctor.gender === "M" || doctor.gender === "Nam" ? (
                        <p>Nam</p>
                      ) : doctor.gender === "F" || doctor.gender === "Nữ" ? (
                        <p>Nữ</p>
                      ) : (
                        <p>Khác</p>
                      )}
                    </td>
                    <td>{doctor.address}</td>
                    <td>{doctor.Dob}</td>
                    <td>{doctor.email}</td>
                    <td>{doctor.phoneNumber}</td>
                    <td>
                      {doctor.signatureImage ? (
                        <Image
                          width={50}
                          src={
                            "data:image/jpeg;base64," + doctor.signatureImage
                          }
                        />
                      ) : (
                        doctor.signatureImage
                      )}
                    </td>
                    <td>
                      {doctor.avatar ? (
                        <Image
                          width={50}
                          src={
                            "data:image/jpeg;base64," + doctor.avatar
                          }
                        />
                      ) : (
                        doctor.avatar
                      )}
                    </td>
                    {isvermed == true ? 
                    <>
                      <td>{doctor.position}</td>
                      <td>{doctor.positionTittle}</td>
                      <td>{doctor.nameOutsideMed}</td>
                      <td>{doctor.description}</td>
                      <td>{doctor.order == 2 ? "Giáo sư" : (doctor.order == 3 ? "Phó giáo sư" : (doctor.order == 4 ? "Bác sĩ" : "Không"))}</td>
                      <td>{doctor.personPropose}</td>
                      <td>{doctor.userNameCreate}</td>
                      <td>{doctor.createDate == null ? "" : moment(doctor.createDate).format("DD/MM/YYYY")}</td>
                    </>
                    : 
                    ""
                    }
                    <td>
                      <div className="tools">
                        <Tooltip title="Thông tin tài khoản">
                          <Button
                            onClick={() =>
                              this.onShowInfo(doctor.id, doctor.name)
                            }
                          >
                            <FontAwesomeIcon icon="info" />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Sửa tài khoản">
                          <Button
                            onClick={() => this.onEdit(doctor.id, doctor.name)}
                          >
                            <FontAwesomeIcon icon="edit" />
                          </Button>
                        </Tooltip>
                        <Popconfirm
                          placement="topRight"
                          title={`Xác nhận xóa ${doctor.name}`}
                          onConfirm={() =>
                            this.onDelete(doctor.id, doctor.name)
                          }
                          okText="Ok"
                          cancelText="Cancel"
                        >
                          <Tooltip title="Xóa tài khoản">
                            <Button>
                              <FontAwesomeIcon icon="trash-alt" />
                            </Button>
                          </Tooltip>
                        </Popconfirm>
                        <Tooltip title="Phân quyền">
                          <Button
                            onClick={() =>
                              this.onChangeRole(doctor.id, doctor.name)
                            }
                          >
                            <FontAwesomeIcon icon="tags" />
                          </Button>
                        </Tooltip>
                        <Popconfirm
                          placement="topRight"
                          title={`Xác nhận ĐẶT LẠI MẬT KHẨU ${doctor.name}`}
                          onConfirm={() =>
                            this.onResetPassword(doctor.id, doctor.name)
                          }
                          okText="Ok"
                          cancelText="Cancel"
                        >
                          <Tooltip title="Đặt lại mật khẩu">
                            <Button>
                              <FontAwesomeIcon icon="undo" />
                            </Button>
                          </Tooltip>
                        </Popconfirm>
                        {
                          //console.log(doctor.name, doctor.lockoutEnabled)
                        }
                        {doctor.lockoutEnabled === true ? (
                          <Popconfirm
                            placement="topRight"
                            title={`Xác nhận MỞ KHÓA TÀI KHOẢN ${doctor.name}`}
                            onConfirm={() => this.onUnlockUser(doctor.id)}
                            okText="Ok"
                            cancelText="Cancel"
                          >
                            <Tooltip title="Mở khóa tài khoản">
                              <Button style={{ color: "gray" }}>
                                <FontAwesomeIcon icon="lock" />
                              </Button>
                            </Tooltip>
                          </Popconfirm>
                        ) : (
                          <Popconfirm
                            placement="topRight"
                            title={`Xác nhận KHÓA TÀI KHOẢN ${doctor.name}`}
                            onConfirm={() => this.onLockUser(doctor.id)}
                            okText="Ok"
                            cancelText="Cancel"
                          >
                            <Tooltip title="Khóa tài khoản">
                              <Button style={{ color: "green" }}>
                                <FontAwesomeIcon icon="unlock-alt" />
                              </Button>
                            </Tooltip>
                          </Popconfirm>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="pagination-d">
            <Pagination
              defaultCurrent={3}
              current={getDoctorSearch().PageIndex}
              total={totalCount}
              showQuickJumper
              hideOnSinglePage
              showTitle={false}
              defaultPageSize={getDoctorSearch().PageSize}
              onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
            />
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    doctors: state.doctor.doctors,
    totalCount: state.doctor.totalCount,
    listGroupRoles: state.doctor.listGroupRoles,
    isvermed: state.doctor.isvermed,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDoctorFromServer: (search) => dispatch(getDoctorFromServer(search)),
    deleteDoctorFromServer: (id) => dispatch(deleteDoctorFromServer(id)),
    getDropdownRoles: () => dispatch(getDropdownRoles()),
    resetPasswordFromServer: (id) => dispatch(resetPasswordFromServer(id)),
    lockUser: (userName) => dispatch(lockUser(userName)),
    unlockUser: (userName) => dispatch(unlockUser(userName)),
    setModalBasic: (data) => dispatch(setModalBasic(data)),
    checkVermed: () => dispatch(checkVermed()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorPage);
