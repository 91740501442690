import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, message, Select, Layout, Upload,Checkbox, Spin  } from "antd";
import {  getDoctorSearch, getStatusAdd, setStatusAdd } from "../../pages/Home/DoctorPage/doctor";
import { connect } from "react-redux";
import { setModalBasic } from "../../reducers/uiReducer";
import Axios from "axios";
import { UploadOutlined } from "@ant-design/icons";
import { addUserWithExcel, getDoctorFromServer } from "../../reducers/doctorReducer";
const { Content, Footer, Header } = Layout;
const { Option } = Select;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

class AddUserWithExcel extends Component {
    state = { 
        file: null,
        uploading: false,
    };
    onFinish = (values)  => {
        const formData = new FormData();
        formData.append('file', this.state.file);
        this.setState({
            uploading: true,
        });
        this.props
            .addUserWithExcel(formData)
            .then(() => {
                this.props.setModalBasic({ visible: false })
                this.props.getDoctorFromServer(getDoctorSearch());
                this.setState({
                    uploading: false,
                });
            });
        // this.props.addUserWithExcel(formData);
        // this.props.getDoctorFromServer(getDoctorSearch());
    };

    render() {
        const { uploading, file } = this.state;
        const propsUpload = {
            name: 'file',
            listType:'text',
            showUploadList: {
                showDownloadIcon: false,
            },
            onRemove: file => {
                this.setState(state => {
                    return {
                        file: null
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    file: file
                }))
                return false;
            },
            file
        }
        return (
            <Layout className="doctor-add-form-content">
                <Header>
                    <p className="modalBasic-title">Thêm mới bác sĩ</p>
                    <button
                        className="modalBasic-btn-close"
                        onClick={() => {
                            setStatusAdd(false);
                            this.props.setModalBasic({ visible: false });
                        }}
                    >
                        <i className="fas fa-times icon"></i>
                    </button>
                </Header>
                <Content>
                    <Form
                        ref={(ref) => (this.refForm = ref)}
                        style={{ marginTop: 20 }}
                        {...formItemLayout}
                        name="addDoctor"
                        onFinish={this.onFinish}
                        scrollToFirstError
                    >
                        <div className="ant-row ant-form-item">
                            <div className="ant-col ant-col-6 ant-form-item-label">
                               <label title="Logo">Upload</label>
                            </div>
                            <div className="ant-col ant-col-14 ant-form-item-control">
                                <Upload className="upload-list-inline" {...propsUpload}>
                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </div>
                        </div>
                        <Button style={{ display: "none" }} htmlType={"submit"} id="submit-add-doctor">
                            Upload
                        </Button>
                    </Form>
                </Content>
                <Footer>
                    {uploading == true ? <div style={{display: "flex",justifyContent: "center",alignItems: "center",width: "100%"}}><Spin  size="large" tip="Loading..."></Spin></div> :
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            document.getElementById("submit-add-doctor").click();
                        }}
                    >
                        Thêm mới
                    </Button>
                    }
                </Footer>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getDoctorFromServer: (search) => dispatch(getDoctorFromServer(search)),
        addUserWithExcel: (request) => dispatch(addUserWithExcel(request)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUserWithExcel);
