import React, { Component } from "react";
import { connect } from "react-redux";
import {
    fetchStaticStudies,
    fetchStaticStudiesCount,
    fetchDropdownDoctors,
    fetchDropdownAssDepartment,
    fetchDropdownInsurance,
    fetchDropdownHospital,
    fetchDropdownModalities,
    fetchDropdownServerpacsByIdHos,
    fetchDropdownAreas,
} from "../../../reducers/staticStudiesReducer";
import { Col, Row, Input, Form, Button, DatePicker, Checkbox, Select, Layout, Pagination } from "antd";
import { getSearchStaticStudies, setSearchStaticStudies } from "../../../models/staticStudies";
import { initSearchStaticStudies } from "../../../models/staticStudies";
import "./StaticStudiesPage.scss";
const { Option } = Select;
const { Header, Footer, Sider, Content } = Layout;
class StaticStudiesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterType: 1,
        };
        this.formSeach = null;
    }

    componentDidMount = () => {
        this.props.fetchDropdownDoctors();
        this.props.fetchStaticStudies();
        this.props.fetchStaticStudiesCount();
        this.props.fetchDropdownInsurance();
        this.props.fetchDropdownAssDepartment();
        this.props.fetchDropdownHospital("");
        this.props.fetchDropdownModalities();
        this.props.fetchDropdownAreas();
    };

    convertGender = (gender) => {
        if (gender == "M" || gender == "1" || gender == "Nam") return "Nam";
        return "Nữ";
    };

    onChangePage = (page, pageSize) => {
        setSearchStaticStudies({
            ...getSearchStaticStudies(),
            PageIndex: page,
            PageSize: pageSize,
        });
        this.props.fetchStaticStudies();
        this.props.fetchStaticStudiesCount();
    };

    onChangeFilterBy = (value) => {
        this.setState({
            filterType: value,
        });
        this.formSeach.setFieldsValue({ filterMachine: "", filterTypeMachine: "" });
    };
    appendLeadingZeroes = (n) => {
        if (n <= 9) {
            return "0" + n;
        }
        return n;
    };

    formatDatetime = (time) => {
        var date = new Date(time);
        var month = this.appendLeadingZeroes(date.getMonth() + 1); //months from 1-12
        var day = this.appendLeadingZeroes(date.getDate());
        var year = date.getFullYear();
        var hours = date.getHours();
        var minutes = date.getMinutes(); // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = day + "/" + month + "/" + year + " " + hours + ":" + minutes;
        return strTime;
    };
    onFinish = (values) => {
        let params = getSearchStaticStudies();
        let lstProps = Object.getOwnPropertyNames(values);
        for (let item of lstProps) {
            //values[item] = Number(values[item]);
            params[item] = values[item];
        }
        params.areaId = Number(params.areaId) == 0 ? null : Number(params.areaId);
        params.fromDate = values.fromDate.format();
        params.toDate = values.toDate.format();
        params.PageIndex = 1;
        setSearchStaticStudies(params);
        this.props.fetchStaticStudies();
        this.props.fetchStaticStudiesCount();
    };
    onChageFilterTypeMachine = (value, filterType) => {
        if (filterType != 1) {
            this.props.fetchDropdownServerpacsByIdHos(value);
        }
    };

    onChangeArea = (value) => {
        this.props.fetchDropdownHospital(value);
    };

    render() {
        const {
            data,
            headerEquips,
            listInsurance,
            listAssDeparttment,
            listDoctors,
            listArea,
            dataCount,
            listHospital,
            listServerpacsByHos,
            listFilterTypeMachine,
        } = this.props;
        const { filterType } = this.state;
        const keyDrugs = [];
        const keyFilms = [];
        return (
            <Layout
                style={{ height: "100%", overflow: "hidden", maxHeight: "100vh", background: "inherit" }}
                className="staticStudies-page-d"
            >
                <Header style={{ background: "#fff", height: "auto" }}>
                    <div className="staticStudies-search-d">
                        <Form
                            name="horizontal_login"
                            ref={(ref) => (this.formSeach = ref)}
                            initialValues={initSearchStaticStudies}
                            layout="inline"
                            onFinish={this.onFinish}
                        >
                            <Row>
                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Từ</Row>
                                    <Form.Item name="fromDate" valuePropName="value">
                                        <DatePicker format="DD/MM/YYYY" placeholder="" />
                                    </Form.Item>
                                </div>
                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Đến</Row>
                                    <Form.Item name="toDate">
                                        <DatePicker format="DD/MM/YYYY" placeholder="" />
                                    </Form.Item>
                                </div>

                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Khu vực</Row>
                                    <Form.Item name="areaId" valuePropName="value">
                                        <Select onChange={(e) => this.onChangeArea(e)}>
                                            <Option value={""}>Tất cả</Option>
                                            {listArea.map((item) => {
                                                return (
                                                    <Option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Lọc theo</Row>
                                    <Form.Item name="filterBy">
                                        <Select placeholder="" onChange={(e) => this.onChangeFilterBy(e)}>
                                            <Option value={1}>Loại thiết bị</Option>
                                            <Option value={2}>Thiết bị</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Loại thiết bị/Thiết bị</Row>
                                    <Form.Item name="filterTypeMachine">
                                        <Select
                                            placeholder=""
                                            onChange={(e) => this.onChageFilterTypeMachine(e, filterType)}
                                        >
                                            <Option value={""}>All</Option>
                                            {filterType === 1 &&
                                                listFilterTypeMachine.map((item) => {
                                                    return (
                                                        <Option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </Option>
                                                    );
                                                })}
                                            {filterType === 2 &&
                                                listHospital.map((item) => {
                                                    return (
                                                        <Option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Thiết bị</Row>
                                    <Form.Item name="filterMachine">
                                        <Select
                                            placeholder=""
                                            disabled={filterType === 1 ? true : false}
                                            onChange={(e) => this.onChageFilterTypeMachine(e)}
                                        >
                                            <Option value={""}>All</Option>
                                            {listServerpacsByHos.map((item) => {
                                                return (
                                                    <Option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                            </Row>
                            <Row>
                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Mã bệnh nhân</Row>
                                    <Form.Item name="pCode">
                                        <Input placeholder="" allowClear />
                                    </Form.Item>
                                </div>
                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Tên bệnh nhân</Row>
                                    <Form.Item name="pName">
                                        <Input placeholder="" allowClear />
                                    </Form.Item>
                                </div>
                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Bảo hiểm</Row>
                                    <Form.Item name="insurance">
                                        <Select mode="multiple" placeholder="" allowClear showArrow>
                                            {listInsurance.map((item, index) => {
                                                return (
                                                    <Option key={index} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Địa chỉ</Row>
                                    <Form.Item name="address">
                                        <Input placeholder="" allowClear />
                                    </Form.Item>
                                </div>
                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Mã chỉ định</Row>
                                    <Form.Item name="code">
                                        <Input placeholder="" allowClear />
                                    </Form.Item>
                                </div>
                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Modality</Row>
                                    <Form.Item name="modality">
                                        <Input placeholder="" allowClear />
                                    </Form.Item>
                                </div>
                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Bác sĩ duyệt</Row>
                                    <Form.Item name="doctorID">
                                        <Select placeholder="" allowClear showArrow>
                                            {listDoctors.map((item, index) => {
                                                return (
                                                    <Option key={index} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Chỉ định</Row>
                                    <Form.Item name="pindication">
                                        <Input placeholder="" allowClear />
                                    </Form.Item>
                                </div>
                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Bộ phận chụp</Row>
                                    <Form.Item name="pbodypart">
                                        <Input placeholder="" allowClear />
                                    </Form.Item>
                                </div>
                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Kết luận</Row>
                                    <Form.Item name="pconclusions">
                                        <Input placeholder="" allowClear />
                                    </Form.Item>
                                </div>
                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Trạng thái</Row>
                                    <Form.Item name="svStatus">
                                        <Select placeholder="">
                                            <Option value={0}>Chưa đọc</Option>
                                            <Option value={1}>Đang đọc</Option>
                                            <Option value={2}>Đã đọc</Option>
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Nơi gửi</Row>
                                    <Form.Item name="placeSending">
                                        <Select mode="multiple" showArrow placeholder="" allowClear>
                                            {listAssDeparttment.map((item, index) => {
                                                return (
                                                    <Option key={index} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Chẩn đoán</Row>
                                    <Form.Item name="cDiagnosis">
                                        <Input placeholder="" allowClear />
                                    </Form.Item>
                                </div>
                                <div className="wraper-form-item">
                                    <Row className="form-item-label">Tồn tại bên His</Row>
                                    <Form.Item name="existOnHis">
                                        <Select placeholder="">
                                            <Option value={null}>All</Option>
                                            <Option value={true}>True</Option>
                                            <Option value={false}>False</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                                <Button
                                    style={{ background: "#2a274c", color: "#fff", marginTop: 2 }}
                                    htmlType="submit"
                                >
                                    Search
                                </Button>
                            </Row>
                        </Form>
                    </div>
                </Header>
                <Content style={{ overflow: "auto" }}>
                    <div className={"staticStudies-table-d"}>
                        <div style={{ fontWeight: "bold", textAlign: "center" }}>
                            <span>Tổng số ca: {dataCount}</span>
                        </div>
                        <div className="tableFixHead">
                            <table className="table-admin" style={{ width: "150%", margin: 0 }}>
                                <thead>
                                    <tr>
                                        <th rowSpan="2">STT</th>
                                        <th rowSpan="2">Mã bệnh nhân</th>
                                        <th rowSpan="2" width="8%">
                                            Họ tên bệnh nhân
                                        </th>
                                        <th colSpan="2">Tuổi</th>
                                        <th rowSpan="2" width="20%">
                                            Địa chỉ
                                        </th>
                                        <th rowSpan="2" width="6%">
                                            Ngày thực hiện
                                        </th>
                                        <th rowSpan="2">BHYT</th>
                                        <th rowSpan="2" width="8%">
                                            Chẩn đoán
                                        </th>
                                        <th rowSpan="2">Nơi gửi</th>
                                        <th rowSpan="2" width="15%">
                                            Yêu cầu
                                        </th>
                                        <th rowSpan="2" width="25%">
                                            Kết quả
                                        </th>
                                        <th rowSpan="2" width="10%">
                                            Người đọc
                                        </th>
                                        {headerEquips.map((item, index) => {
                                            return (
                                                <th key={index} colSpan={item.lstEquipName.length}>
                                                    {item.typeEquip === 1 ? <span>Thuốc</span> : <span>Phim</span>}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                    <tr>
                                        <th>Nam</th>
                                        <th>Nữ</th>
                                        {headerEquips.map((item, index) => {
                                            let rows = [];
                                            for (let key in item.lstEquipName) {
                                                if (item.typeEquip === 1) keyDrugs.push(key);
                                                if (item.typeEquip === 2) keyFilms.push(key);
                                                rows.push(item.lstEquipName[key]);
                                            }
                                            rows.map((eq, idx) => {
                                                return <th key={idx}>{eq}</th>;
                                            });
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => {
                                        let stt =
                                            getSearchStaticStudies().PageSize *
                                                (getSearchStaticStudies().PageIndex - 1) +
                                            index +
                                            1;
                                        return (
                                            <tr key={index}>
                                                <td>{stt}</td>
                                                <td>{item.pCode}</td>
                                                <td>{item.pName}</td>
                                                <td>{this.convertGender(item.sex) == "Nam" ? item.pAge : ""}</td>
                                                <td>{this.convertGender(item.sex) == "Nữ" ? item.pAge : ""}</td>
                                                <td>{item.pAddress}</td>
                                                <td>{this.formatDatetime(item.timeEx)}</td>
                                                <td>{item.pType}</td>
                                                <td>{item.comment}</td>
                                                <td>{item.assDepartment}</td>
                                                <td>{item.serviceName}</td>
                                                <td>{item.result}</td>
                                                <td>{item.doctorApproved}</td>
                                                {keyDrugs.map((d, idx) => {
                                                    return <td key={`${stt}-${idx}`}>{item.drugs[d]}</td>;
                                                })}
                                                {keyFilms.map((f, idx) => {
                                                    return <td key={`${stt}-${stt}-${idx}`}>{item.films[f]}</td>;
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Content>
                <Footer style={{ overflow: "auto", padding: "0 50px", background: "inherit", overflow: "hidden" }}>
                    <div className="pagination-d" style={{ marginTop: 15 }}>
                        <Pagination
                            current={getSearchStaticStudies().PageIndex}
                            total={dataCount}
                            showQuickJumper
                            showTitle={false}
                            defaultPageSize={getSearchStaticStudies().PageSize}
                            onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                            defaultCurrent={3}
                        />
                    </div>
                </Footer>
            </Layout>
        );
    }
}
function mapStateToProps(state) {
    return {
        // staticStudiess: state.staticStudies.staticStudiess,
        // totalCount: state.staticStudies.totalCount,
        data: state.staticStudies.data,
        listServerpacs: state.staticStudies.listServerpacs,
        listInsurance: state.staticStudies.listInsurance,
        listAssDeparttment: state.staticStudies.listAssDeparttment,
        listDoctors: state.staticStudies.listDoctors,
        listArea: state.staticStudies.listArea,
        dataCount: state.staticStudies.dataCount,
        listHospital: state.staticStudies.listHospital,
        listServerpacsByHos: state.staticStudies.listServerpacsByHos,
        headerEquips: state.staticStudies.headerEquips,
        listFilterTypeMachine: state.staticStudies.listFilterTypeMachine,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        // getstaticStudiesFromServer: (search) => dispatch(getstaticStudiesFromServer(search)),
        // handleOpenModalApp: (modalData) => dispatch(handleOpenModalApp(modalData)),
        // deletestaticStudiesFromServer: (id) => dispatch(deletestaticStudiesFromServer(id)),
        fetchStaticStudies: () => dispatch(fetchStaticStudies()),
        fetchStaticStudiesCount: () => dispatch(fetchStaticStudiesCount()),
        fetchDropdownInsurance: () => dispatch(fetchDropdownInsurance()),
        fetchDropdownAssDepartment: () => dispatch(fetchDropdownAssDepartment()),
        fetchDropdownHospital: (areaId) => dispatch(fetchDropdownHospital(areaId)),
        fetchDropdownModalities: () => dispatch(fetchDropdownModalities()),
        fetchDropdownDoctors: () => dispatch(fetchDropdownDoctors()),
        fetchDropdownAreas: () => dispatch(fetchDropdownAreas()),
        fetchDropdownServerpacsByIdHos: (idHos) => dispatch(fetchDropdownServerpacsByIdHos(idHos)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticStudiesPage);
