import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button,Layout } from "antd";
import { connect } from "react-redux";
import { getLogById } from "../../../reducers/logReducer";
import { setModalBasic } from "../../../reducers/uiReducer";
const { Header, Content } = Layout;
class ContentInfo extends Component {
    componentDidMount = () => {
        this.props.getLogById(this.props.id);
    };
    componentDidUpdate = (prevProps) => {
        if (prevProps.id !== this.props.id) {
            this.props.getLogById(this.props.id);
        }
    };
    render() {
        const { logItem } = this.props;
        return (
            <Layout>
                <Header style={{ display: "flex", justifyContent: "space-between" }}>
                    <p className="modalBasic-title">Nhật ký</p>
                    <button className="modalBasic-btn-close"onClick={() => this.props.setModalBasic({visible:false})}>
                        <i className="fas fa-times icon" ></i>
                    </button>
                </Header>
                <Content>
                    <div className="content-info" style={{ padding: 10, color: "black",userSelect: "text"}}>
                        <div dangerouslySetInnerHTML={{__html: logItem.inf}} />
                    </div>
                </Content>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        logItem: state.log.logItem,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getLogById: (id) => dispatch(getLogById(id)),
        setModalBasic: (data) => dispatch(setModalBasic(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentInfo);
