import { store } from "../store/store";
import { setAuth } from "../reducers/authReducer";

const checkAuth = (key = "token") => {
    const token = localStorage.getItem(key);
    if (token) {
        store.dispatch(setAuth(true));
        return true;
    } else {
        store.dispatch(setAuth(false));
        return false;
    }
};

const saveToken = (token, key = "token") => {
    localStorage.setItem(key, token);
};

const getToken = (key = "token") => {
    const token = localStorage.getItem(key);
    return token;
};

const clearToken = (key = "token") => {
    localStorage.removeItem(key);
};

const setTokenTimeOut = (timeout, key = "token") => {
    setTimeout(() => {
        localStorage.removeItem(key);
    }, timeout);
};

const saveTokenRefresh = (tokenRefresh, key = "tokenRefresh") => {
    localStorage.setItem(key, tokenRefresh);
};

const getTokenRefresh = (key = "tokenRefresh") => {
    return localStorage.getItem(key);
};

const setUsername = (username, key = "username") => {
    localStorage.setItem(key, username);
};

const setUserID = (userId, key = "userId") => {
    localStorage.setItem(key, userId);
};

const getUserName = (key = "username") => {
    return localStorage.getItem(key);
};

const getUserId = (key = "userId") => {
    return localStorage.getItem(key);
};

const clearUsername = (key = "username") => {
    localStorage.removeItem(key);
};

const clearUserId = (key = "userId") => {
    localStorage.removeItem(key);
};

const clearRefreshtoken = (key = "tokenRefresh") => {
    localStorage.removeItem(key);
};

const clearAll = () => {
    clearUsername("username");
    clearUserId("userId");
    clearToken("token");
    clearRefreshtoken("tokenRefresh");
};

export {
    checkAuth,
    saveToken,
    getToken,
    clearToken,
    setTokenTimeOut,
    setUsername,
    setUserID,
    getUserName,
    getUserId,
    clearAll,
    saveTokenRefresh,
    clearRefreshtoken,
    getTokenRefresh,
};
