import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { errorBoundryReducer } from "./errorReducer";
import { uiReducer } from "./uiReducer";
import { doctorReducer } from "./doctorReducer";
import { patientReducer } from "./patientReducer";
import { indicateReducer } from "./indicateReducer";
import { technicalserviceReducer } from "./technicalServiceReducer";
import { typeserviceReducer } from "./typeserviceReducer";
import { sampleresultReducer } from "./sampleresultReducer";
import { backupReducer } from "./backupReducer";
import { configurationReducer } from "./configurationReducer";
import { serverpacsReducer } from "./serverpacsReducer";
import { serverpacsTemplateReducer } from "./serverpacsTemplateReducer";
import { hospitalReducer } from "./hospitalReducer";
import { printresultReducer } from "./printresultReducer";
import { doctorroleReducer } from "./doctorroleReducer";
import { statisticReducer } from "./statisticReducer";
import { reportReducer } from "./reportReducer";
import { icdReducer } from "./icdReducer";
import { icdTempManagerReducer } from "./icdTempManagerReducer";
import { staticStudiesReducer } from "./staticStudiesReducer";
import { templateReducer } from "./templateReducer";
import { modalBasic2Reducer } from "./modalBasic2Reducer";
import { loadingReducer } from "./loadingHomeReducer";
import { areaReducer } from "./areaReducer";
import { welcomeReducer } from "./welcomeReducer";
import { roleManagerReducer } from "./roleManagerReducer";
import { logReducer } from "./logReducer";
import { mediEquipmentReducer } from "./mediEquipmentReducer";
import { roleAdminReducer } from "./roleAdminReducer";

const rootReducer = combineReducers({
    isAuthenticate: authReducer,
    errorBoundryReducer,
    doctor: doctorReducer,
    ui: uiReducer,
    patient: patientReducer,
    indicate: indicateReducer,
    technicalservice: technicalserviceReducer,
    typeservice: typeserviceReducer,
    sampleresult: sampleresultReducer,
    backup: backupReducer,
    configuration: configurationReducer,
    serverpacs: serverpacsReducer,
    serverpacsTempManager: serverpacsTemplateReducer,
    hospital: hospitalReducer,
    area: areaReducer,
    printresult: printresultReducer,
    doctorrole: doctorroleReducer,
    statistic: statisticReducer,
    report: reportReducer,
    icd: icdReducer,
    icdTemp: icdTempManagerReducer,
    staticStudies: staticStudiesReducer,
    template: templateReducer,
    modalBasic2: modalBasic2Reducer,
    loading: loadingReducer,
    worklist: welcomeReducer,
    role: roleManagerReducer,
    log : logReducer,
    mediEquipment: mediEquipmentReducer,
    roleadmin : roleAdminReducer
});
export { rootReducer };