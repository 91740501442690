import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import HomePage from "../pages/Home/HomePage";
import LoginPage from "../pages/Login/LoginPage";
import { checkAuth } from "../utils/authenticate";
import WelcomePage from "../pages/Welcome/WelcomePage";
function PrivateRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) =>
                checkAuth() ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}
export default class RouterPage extends Component {
    render() {
        return (
            <Router>
                
                <Switch>
                    <Route exact path="/login">
                        <LoginPage />
                    </Route>
                    <Route  path="/welcome">
                        <WelcomePage />
                    </Route>
                    <PrivateRoute path="/">
                        <HomePage />
                    </PrivateRoute>
                </Switch>
            </Router>
        );
    }
}
