import moment from "moment";

let RoleAdminSeach = {
    userName: "",
    PageIndex: 1,
    PageSize: 10,
};
const initialRoleAdminSeach = {
    userName: "",
    PageIndex: 1,
    PageSize: 10,
};
const getRoleAdminSearch = () => {
    return RoleAdminSeach;
};

const setRoleAdminSearch = (search) => {
    RoleAdminSeach = search;
};

export { initialRoleAdminSeach, getRoleAdminSearch, setRoleAdminSearch };
