import React, { Component } from "react";
import LoginForm from "../../components/LoginPage/LoginForm";
import "./LoginPage.scss";
import { connect } from "react-redux";
import { loginApi, setAuth } from "../../reducers/authReducer";
import { getToken, checkAuth } from "../../utils/authenticate";
import { Redirect } from "react-router-dom";
class LoginPage extends Component {
    handleLogin = (user) => {
        this.props.handleLoginApi(user);
    };
    render() {
        const isAuthenticate = this.props.isAuthenticate;
        return isAuthenticate ? (
            <Redirect
                push
                to={{
                    pathname: "/",
                }}
            />
        ) : (
            <div className="login-page-d">
                <LoginForm handleLogin={this.handleLogin} />
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        isAuthenticate: state.isAuthenticate,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        handleLoginApi: (user) => dispatch(loginApi(user)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
