
const ACTION_TYPES = {
    ERROR_BOUDRY: "ERROR_BOUDRY",
};

const initialstate = { show: false, error: "" };

const errorBoundryReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.ERROR_BOUDRY:
            state = action.payload;
            return state;
        default:
            return state;
    }
};

const setErrorBoundry = (error) => {
    return ({
        type: ACTION_TYPES.ERROR_BOUDRY,
        payload: error,
    });
};

export { errorBoundryReducer, setErrorBoundry };
