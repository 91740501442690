import React, { Component } from "react";
import { connect } from "react-redux";
// import { getHospitalFromServer, deleteHospitalFromServer } from "../../../reducers/hospitalReducer";
// import { getHospitalSearch, setHospitalSearch } from "./hospital";
// import { handleOpenModalApp, setModalBasic } from "../../../reducers/uiReducer";
// import ContentEdit from "../../../components/Hospital/ContentEdit";
// import AddFormContent from "../../../components/Hospital/AddFormContent";
import { Form, Input, Pagination, Button, Select, message } from "antd";

import { getIcdSearch, setIcdSearch } from "../../../models/icdModel";
import { fetchIcdTemp, getListDiagnose, getListIcd, getListIcdTemp, getListIcdNew } from "../../../reducers/icdTempManagerReducer";
import FontAwesome from "react-fontawesome";
import "./ICDPage.scss";
import { setModalBasic } from "../../../reducers/uiReducer";
import { getDropdownServices } from "../../../reducers/icdReducer";
import EditTemplateIcd from "../../../components/IcdTemplate/EditTemplateIcd";
import AddTemplateIcd from "../../../components/IcdTemplate/AddTemplateIcd";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
const { Option } = Select;
class ICDTemplateManager extends Component {
    componentDidMount = () => {
        this.props.fetchIcdTemp();
        this.props.getDropdownServices();
    };


    onAdd = async (id, ServiceID) => {
        message.info({
            content: "Loading...", duration: 10, icon:<LoadingOutlined />
        });
        let type = "";
            await this.props.getListIcdNew("");
            await this.props.getListIcdTemp(-1);
            type = "add";
            await this.props.getListDiagnose("");
        const modalData = {
            visible: true,
            width: window.innerWidth*0.7,
            content: <AddTemplateIcd idIdc={id} ServiceID={ServiceID} type={type} />,
            name: "modal-icd-add"
        };
        message.destroy();
        this.props.setModalBasic(modalData);
    };

    onEdit = async (id, ServiceID) => {
        message.info({
            content: "Loading...", duration: 10, icon: <LoadingOutlined />
        });
        let type = "edit";
        await this.props.getListIcd(id,"");
        await this.props.getListIcdTemp(id);
        await this.props.getListDiagnose(ServiceID);
        const modalData = {
            visible: true,
            width: window.innerWidth * 0.7,
            content: <EditTemplateIcd idIdc={id} ServiceID={ServiceID} type={type} />,
            name: "modal-icd-add"
        };
        message.destroy();
        this.props.setModalBasic(modalData);
    };

    onChangePage = (page, pageSize) => {
        setIcdSearch({ ...getIcdSearch(), PageIndex: page, PageSize: pageSize });
        this.props.fetchIcdTemp();
    };

    onFinish = (values) => {
        setIcdSearch({ ...getIcdSearch(), Keyword: values.Keyword, code: values.code??"", serviceId: values.serviceId ?? "", PageIndex: 1 });
        this.props.fetchIcdTemp();
    };

    render() {
        const { data, total, dropdownServices } = this.props;
        return (
            <div className="icd-page-d">
                <div className="icd-search-d">
                    <Link to="/icd">
                        <Button
                        style={{ background: "#2a274c", color: "#fff",marginRight:10 }}
                        onClick={() => localStorage.setItem("router", "icd")}
                        icon = {<FontAwesome name="arrow-left" className="icon-fontawesome" />}
                        >
                        </Button>
                    </Link>
                    <Form
                        name="horizontal_login"
                        initialValues={{ Keyword: "", code: "" }}
                        layout="inline"
                        onFinish={this.onFinish}
                    >
                        <Form.Item name="Keyword">
                            <Input placeholder="Từ khóa" />
                        </Form.Item>
                        <Form.Item style={{ width: "300px" }} name="serviceId">
                            <Select showSearch placeholder="Chọn dịch vụ"
                                allowClear
                                style={{ width: '100%' }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {dropdownServices.map((item) => (
                                    <Option key={item.id} value={Number(item.id)}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Button style={{ background: "#2a274c", color: "#fff" }} htmlType="submit">
                            Tìm kiếm
                        </Button>
                    </Form>
                    <Button style={{ background: "#2a274c", color: "#fff" }} onClick={() => this.onAdd()}>
                        Phân mẫu mới
                    </Button>
                </div>
                <div className={"icd-table-d"}>
                    <table border="0" className="table-admin">
                        <thead>
                            <tr>
                                <th>Mã</th>
                                <th>Tên</th>
                                <th>Mẫu kết quả</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((icd, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{icd.codeIdc}</td>
                                        <td>{icd.nameIdc}</td>
                                        <td>{icd.diagNames.map((diagnose, i) => {
                                            return (
                                                <span style={{ marginRight: 5 }} key={i}>{diagnose}, </span>
                                            )
                                        })

                                        }</td>
                                        <td>
                                            <div className="tools">
                                                <Button onClick={() => this.onEdit(icd.idIdc, icd.serviceID)}>
                                                    <FontAwesome name="edit" />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="pagination-d">
                        <Pagination
                            defaultCurrent={3}
                            current={getIcdSearch().PageIndex}
                            total={total}
                            showQuickJumper
                            showTitle={false}
                            defaultPageSize={getIcdSearch().PageSize}
                            onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        data: state.icdTemp.data,
        total: state.icdTemp.total,
        dropdownServices: state.icd.dropdownServices,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        fetchIcdTemp: () => dispatch(fetchIcdTemp()),
        getListIcd: (id, serviceId) => dispatch(getListIcd(id, serviceId)),
        getListDiagnose: (serviceId) => dispatch(getListDiagnose(serviceId)),
        getListIcdTemp: (id) => dispatch(getListIcdTemp(id)),
        getListIcdNew: (serviceId) => dispatch(getListIcdNew(serviceId)),
        getDropdownServices: () => dispatch(getDropdownServices()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ICDTemplateManager);
