import { getWorklistSearch, initialWorklistSearch, setWorklistSearch } from "./Model/worklistModel";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Input, Button, Row, notification, Spin, Popconfirm, Layout, Tabs, Form, DatePicker, Checkbox, Select, Pagination } from "antd";
import "./WelcomePage.scss"
import { setLoadingWelcomePage } from "../../reducers/uiReducer";
import { delService, fetchDropdownRoom, getListOders, getWorklist, fetchDropdownExecutor, updateRoom, updateExecutor } from "../../reducers/welcomeReducer";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import { removeVietnameseTones } from "../../utils";
import OrderItem from './OrderItem';
import { PreviewPrintTemplate } from "./PreviewPrintTemplate";
const { TabPane } = Tabs;

const { Option } = Select;
class WelcomePage extends Component {
    state = {
        code: "",
        isOdersActive: false,
        dataHtml: null

    };

    codeRequest = "";
    onChangeCode = (e) => {
        this.setState({
            code: e.target.value,
        });
    };
    componentDidMount = () => {
        this.props.fetchDropdownExecutor();
        this.props.fetchDropdownRoom();
    };

    onSubmit = async () => {
        const { code } = this.state;
        this.props.setLoadingWelcomePage(true);
        if (!code) {
            notification["warning"]({
                message: 'Cảnh báo!',
                description: "Vui lòng nhập các trường bắt buộc!"
            })
            this.props.setLoadingWelcomePage(false);
            return false;
        } else {
            this.codeRequest = code;
            var res = await this.props.getWorklist(code);
            if (res) {
                this.setState({ code: "" });
            }
        }
        this.props.setLoadingWelcomePage(false);
    }

    onDelete = async (id, key) => {
        this.props.setLoadingWelcomePage(true);
        const result = await this.props.delService(id);
        if (result) {
            if (key == 1) {
                await this.props.getWorklist(this.codeRequest);
            } else {
                await this.props.getListOders(getWorklistSearch());
            }
            notification["success"]({
                message: 'Thành công!',
                description: "Hủy chỉ định thành công!"
            })
        }
        this.props.setLoadingWelcomePage(false);
    };
    onFinishSearch = async (values) => {
        this.props.setLoadingWelcomePage(true);
        let params = getWorklistSearch();
        params.fromDate = values.fromDate.format().substring(0, 10);
        params.toDate = values.toDate.format().substring(0, 10);
        params.pName = values.pName;
        params.pCode = values.pCode;
        params.Modality = values.Modality;
        params.executor = values.executor;
        params.room = values.room;
        params.PageIndex = 1;
        setWorklistSearch(params);
        await this.props.getListOders(getWorklistSearch());
        this.props.setLoadingWelcomePage(false);
    }
    tabClick = async (key) => {
        if (key == "1") {
            this.setState({ isOdersActive: false })
        }
        if (key == "2" && !this.state.isOdersActive) {
            this.setState({ isOdersActive: true });
            this.props.setLoadingWelcomePage(true);
            await this.props.getListOders(getWorklistSearch());
            this.props.setLoadingWelcomePage(false);
        }
    }
    onChangeRoom = (room, wlid) => {
        let data = {
            room: room,
            wlid: wlid,
        }
        this.props.updateRoom(data);

    };
    onChangeExecutor = (executor, wlid, room) => {
        let data = {
            executor: executor,
            wlid: wlid,
        }
        this.props.updateExecutor(data);
    };
    onChangePage = async (page, pageSize) => {
        //Change ServerPacsSearch
        this.props.setLoadingWelcomePage(true);
        let worklistSearch = getWorklistSearch();
        worklistSearch.PageIndex = page;
        worklistSearch.PageSize = pageSize;
        setWorklistSearch(worklistSearch);
        await this.props.getListOders(getWorklistSearch());
        this.props.setLoadingWelcomePage(false);
    };
    setTemplate=(template)=>{
        this.setState({dataHtml:template})
    }


    render() {
        const { code } = this.state;
        let worklistSearch = getWorklistSearch();
        const { loadingWelcomePage, listServices, listOders, listExecutor, listRoom, totalCount } = this.props;
        return (
            <>
                {
                    loadingWelcomePage &&
                    <div className="loading">
                        <Spin size="large" tip="Loading..."></Spin>
                    </div>
                }
                {/* <Tabs defaultActiveKey="1" style={{ background: "#fff", padding: 10 }} onTabClick={(key)=> {this.tabClick(key)}}>
            <TabPane tab="Quét mã" key="1"> */}
                {/* <div className="welcome-page">
                <div className="container welcome-page-container">
                    <div className="wrapper">
                        <p className="title">Xác Nhận Dịch Vụ Yêu Cầu </p>
                        <Row>
                            <Input
                                size="large"
                                placeholder="Quét mã lần khám"
                                value={code}
                                onChange ={this.onChangeCode}
                                onPressEnter={this.onSubmit}
                            ></Input>
                        </Row>
                        <Row>
                            <Button type="primary" onClick={this.onSubmit}>
                                Gửi xác nhận
                            </Button>
                            <Button type="danger" onClick={()=>{this.setState({code:""})}} style={{marginLeft:5}}>
                                Reset
                            </Button>
                        </Row>

                        {
                    (listServices && listServices.length > 0)?
                    <table className="table-wl" style={{ margin: 0 }}>
                    <thead>
                        <tr>
                            <th>STT</th>
                            <th>Tên bệnh nhân</th>
                            <th>Mã bệnh nhân</th>
                            <th>Ngày chỉ định</th>
                            <th>Tên dịch vụ</th>
                            <th>Thao tác</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listServices.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{++index}</td>
                                    <td>{item.pName}</td>
                                    <td>{item.pCode}</td>
                                    <td>{item.createTime}</td>
                                    <td>{item.serviceName}</td>
                                    <td>
                                    <Popconfirm
                                                    placement="topRight"
                                                    title={`Xác nhận hủy chỉ định ${item.serviceName}`}
                                                    onConfirm={() => this.onDelete(item.sCode,1)}
                                                    okText="Ok"
                                                    cancelText="Cancel"
                                                >
                                                    <Button>
                                                        <FontAwesome name="undo-alt" style={{color:"red"}} />
                                                    </Button>
                                                </Popconfirm>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                    :""
                }
                    </div>
                </div>
            </div> */}
                {/* </TabPane>
            <TabPane tab="Danh sách chờ" key="2"> */}

                <div className="welcome-page" style={{ margin: 10 }}>
                    <div className="container welcome-page-container" style={{ marginBottom: "20px" }}>

                        <div style={{ display: "flex", }}>
                            <div style={{ flex: 2, paddingRight: 10, paddingBottom: 30, "borderWidth": "2px", "borderLeft": "0px", "borderTop": "0px", 'borderColor': "#cfc0c0", 'borderStyle': 'dashed', }}>
                                <Row style={{ fontWeight: "bold", paddingTop: 5 }}>
                                    <p className="title" >Xác Nhận Dịch Vụ Yêu Cầu </p>
                                </Row>
                                <Row >
                                    <Input
                                        style={{ minWidth: 150, maxWidth: 270, marginTop: 5, marginRight: 5 }}
                                        placeholder="Quét mã lần khám"
                                        value={code}
                                        onChange={this.onChangeCode}
                                        onPressEnter={this.onSubmit}
                                    ></Input>
                                    <Row >
                                        <Button type="primary" onClick={this.onSubmit} style={{ marginRight: 5, marginTop: 5 }}>
                                            Gửi xác nhận
                                        </Button>
                                        <Button type="danger" onClick={() => { this.setState({ code: "" }) }} style={{ marginTop: 5 }} >
                                            Reset
                                        </Button>
                                    </Row>
                                </Row>
                            </div>
                            <div style={{ flex: 5, paddingLeft: 10, "borderWidth": "2px", "borderLeft": "0px", "borderTop": "0px", "borderRight": "0px", 'borderColor': "#cfc0c0", 'borderStyle': 'dashed' }}>
                                <Row style={{ fontWeight: "bold", paddingTop: 5 }}>
                                    <p className="title">Tìm kiếm danh sách chờ </p>
                                </Row>
                                <Row >
                                    <Form
                                        name="horizontal_login"
                                        initialValues={initialWorklistSearch}
                                        layout="inline"
                                        onFinish={this.onFinishSearch}
                                        style={{ marginBottom: 15 }}
                                    >
                                        <Form.Item name="fromDate">
                                            <DatePicker format="DD/MM/YYYY" style={{ marginTop: 5 }} placeholder="" />
                                        </Form.Item>
                                        <Form.Item name="toDate">
                                            <DatePicker format="DD/MM/YYYY" style={{ marginTop: 5 }} placeholder="" />
                                        </Form.Item>
                                        <Form.Item name="pCode">
                                            <Input placeholder="Mã bệnh nhân" style={{ marginTop: 5 }} allowClear />
                                        </Form.Item>
                                        <Form.Item name="pName">
                                            <Input placeholder="Tên bệnh nhân" style={{ marginTop: 5 }} allowClear />
                                        </Form.Item>
                                        <Form.Item name="room">
                                            <Select defaultValue="Phòng"
                                                showSearch
                                                style={{ width: 100, marginTop: 5 }}
                                                filterOption={(input, option) => {
                                                    option.children = removeVietnameseTones(option.children);
                                                    input = removeVietnameseTones(input);
                                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                }
                                            >
                                                <Option value="">Phòng</Option>
                                                {listRoom.map((it) => (
                                                    <Option key={it} >{it}</Option>
                                                ))}

                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="executor">
                                            <Select defaultValue="Kỹ thuật viên"
                                                showSearch
                                                style={{ width: 200, marginTop: 5 }}
                                                filterOption={(input, option) => {
                                                    option.children = removeVietnameseTones(option.children);
                                                    input = removeVietnameseTones(input);
                                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                }
                                            >
                                                <Option value="">Kỹ thuật viên</Option>
                                                {listExecutor.map((item) => (

                                                    <Option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name="Modality" style={{ width: 150, marginTop: 5 }} placeholder="Dịch vụ">
                                            <Select>
                                                <Option value="">Tất cả</Option>
                                                <Option value="XQ">X-Quang</Option>
                                                <Option value="CT">CT</Option>
                                                <Option value="MR">MRI</Option>
                                                <Option value="MG">Mamo</Option>
                                                <Option value="US">Siêu âm</Option>
                                                <Option value="EO">Nội soi</Option>
                                            </Select>
                                        </Form.Item>

                                        <Button style={{ background: "#2a274c", color: "#fff", marginTop: 5 }} htmlType="submit">
                                            Tìm kiếm
                                        </Button>
                                    </Form>
                                </Row>
                            </div>
                        </div>
                        <table className="table-wl" style={{ margin: 0, marginTop: 30 }}>
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Mã Lần Khám</th>
                                    <th>Tên bệnh nhân</th>
                                    <th>Mã bệnh nhân</th>
                                    <th>Ngày chỉ định</th>
                                    <th>Tên dịch vụ</th>
                                    <th>Phòng</th>
                                    <th>Kỹ thuật viên</th>
                                    <th>Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listOders.map((orderItem, index) => {
                                    return (
                                        <OrderItem key={`r-${index}`} worklistSearch={worklistSearch} orderItem={orderItem} index={index} setTemplate={this.setTemplate}/>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                        <div className="pagination-d">
                            <Pagination
                                defaultCurrent={5}
                                current={getWorklistSearch().PageIndex}
                                total={totalCount}
                                showQuickJumper
                                showSizeChanger
                                pageSizeOptions={[10, 20, 50, 100, 200]}
                                showTitle={false}
                                defaultPageSize={getWorklistSearch().PageSize}
                                onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ display: "none" }} id="wrapper-preview-template">
                        <PreviewPrintTemplate dataHtml={this.state.dataHtml} />
                </div>
                {/* </TabPane>
        </Tabs> */}
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        loadingWelcomePage: state.ui.loadingWelcomePage,
        listServices: state.worklist.listServices,
        listOders: state.worklist.listOders,
        listExecutor: state.worklist.listExecutor,
        listRoom: state.worklist.listRoom,
        totalCount: state.worklist.totalCount,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setLoadingWelcomePage: val => dispatch(setLoadingWelcomePage(val)),
        getWorklist: (code) => dispatch(getWorklist(code)),
        getListOders: (code) => dispatch(getListOders(code)),
        delService: (code) => dispatch(delService(code)),
        fetchDropdownExecutor: () => dispatch(fetchDropdownExecutor()),
        fetchDropdownRoom: () => dispatch(fetchDropdownRoom()),
        updateExecutor: (data) => dispatch(updateExecutor(data)),
        updateRoom: (data) => dispatch(updateRoom(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
