import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Input, Select, Popconfirm, Pagination, Button, message, Checkbox } from "antd";

import { getIcdSearch, setIcdSearch } from "../../../models/icdModel";
import { fetchIndications, getDropdownServices, inactiveIcd, activeIcd} from "../../../reducers/icdReducer";
import FontAwesome from "react-fontawesome";
import "./ICDPage.scss";
import AddIcd from "../../../components/ICD/AddIcd";
import { setModalBasic } from "../../../reducers/uiReducer";
import EditIcd from "../../../components/ICD/EditIcd";
import Axios from "axios";
import { Link } from "react-router-dom";
import AddIcdTemp from "../../../components/ICD/AddIcdTemp";
// import {useHistory} from 'react-router-dom';
// let history = useHistory();
const { Option } = Select;
class ICDPage extends Component {
    componentDidMount = () => {
        this.props.fetchIndications();
        this.props.getDropdownServices();
    };

    onEdit = (id, name, serviceId) => {
        const modalData = {
            visible: true,
            width: 900,
            content: <EditIcd id={id} name={name} serviceID={serviceId} />,
            name: "modal-icd-edit",
        };
        this.props.setModalBasic(modalData);
    };

    onAdd = () => {
        const modalData = {
            visible: true,
            width: 900,
            content: <AddIcd />,
            name: "modal-icd-add",
        };
        this.props.setModalBasic(modalData);
    };

    onAssignTemp = (idIcd,serviceId,code,name) => {
        const modalData = {
            visible: true,
            width: 0.7 * window.innerWidth,
            content: <AddIcdTemp idIdc={idIcd} ServiceID={serviceId} idcCode={code} idcName= {name} />,
            name: "modal-icd-asign-temp",
        };
        this.props.setModalBasic(modalData);
    };


    // onDelete = (id) => {
    //     this.props.deleteHospitalFromServer(id).then(() => {
    //         this.props.getHospitalFromServer(getHospitalSearch());
    //     });
    // };

    onChangePage = (page, pageSize) => {
        setIcdSearch({ ...getIcdSearch(), PageIndex: page, PageSize: pageSize });
        this.props.fetchIndications();
    };

    onFinish = (values) => {
        setIcdSearch({ ...getIcdSearch(), Keyword: values.Keyword, code: values.code, serviceId: values.serviceId??"", PageIndex: 1 });
        this.props.fetchIndications();

        // let hospitalSearch = getHospitalSearch();
        // hospitalSearch.Keyword = values.Keyword;
        // hospitalSearch.HosType = values.HosType;
        // hospitalSearch.PageIndex = 1;
        // setHospitalSearch(hospitalSearch);
        // this.handleUpdateTable(hospitalSearch);
    };

    onInactive = (id) => {
        this.props.inactiveIcd(id).then(() => {
            this.props.fetchIndications();
        })
    }

    onActive = (id) => {
        this.props.activeIcd(id).then(() => {
            this.props.fetchIndications();
        })
    }

    onChangeRoute = () => {
        // history.push('/icd/template-manager');
    }

    render() {
        const { data, total, dropdownServices } = this.props;
        return (
            <div className="icd-page-d">
                <div className="icd-search-d">
                    <Form
                        name="horizontal_login"
                        initialValues={{ Keyword: "", code: "" }}
                        layout="inline"
                        onFinish={this.onFinish}
                    >
                        <Form.Item name="Keyword">
                            <Input allowClear placeholder="Từ khóa" />
                        </Form.Item>
                        <Form.Item style={{ width: "300px" }} name="serviceId">
                            <Select showSearch placeholder="Chọn dịch vụ"
                                allowClear
                                style={{ width: '100%' }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {dropdownServices.map((item) => (
                                    <Option key={item.id} value={Number(item.id)}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Button style={{ background: "#2a274c", color: "#fff" }} htmlType="submit">
                            Tìm kiếm
                        </Button>
                    </Form>
                    <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.onAdd}>
                        Thêm mới
                    </Button>
                    <Link to="/icd-template-manager">
                        <Button style={{ background: "#2a274c", color: "#fff" }} onClick={() => localStorage.setItem("router", 'icd-template-manager')}>
                            Quản lý mẫu ICD
                        </Button>
                    </Link>
                </div>
                <div className={"icd-table-d"}>
                    <table border="0" className="table-admin">
                        <thead>
                            <tr>
                                <th>Mã</th>
                                <th>Tên</th>
                                <th>Loại dịch vụ</th>
                                <th>Mẫu</th>
                                <th>Trạng thái</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((icd, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{icd.code}</td>
                                        <td>{icd.name}</td>
                                        <td>{icd.serviceName}</td>
                                        <td>{icd.diagnoises.map((diag, ind) => {
                                            return (
                                                <span key={ind}>{diag.nameDiag ? diag.nameDiag+", ":""}</span>
                                                )
                                        })}</td>
                                        <td>{icd.status === 1 ?
                                                <Popconfirm
                                                placement="topRight"
                                                title={`Xác nhận vô hiệu hóa ${icd.name}`}
                                                onConfirm={() => this.onInactive(icd.id)}
                                                okText="Ok"
                                                cancelText="Cancel"
                                            >
                                                <Checkbox checked={true}></Checkbox>
                                            </Popconfirm>
                                            :
                                                <Popconfirm
                                                placement="topRight"
                                                title={`Xác nhận kích hoạt ${icd.name}`}
                                                onConfirm={() => this.onActive(icd.id)}
                                                okText="Ok"
                                                cancelText="Cancel"
                                            >
                                                <Checkbox checked={false}></Checkbox>
                                            </Popconfirm>
                                        }</td>
                                        <td>
                                            <div className="tools">
                                                <Button style={{ width:50 }} onClick={() => this.onAssignTemp(icd.id, icd.serviceID, icd.code, icd.name)}>
                                                    <FontAwesome name="tags" />
                                                </Button>
                                                <Button onClick={() => this.onEdit(icd.id, icd.name, icd.serviceID)}>
                                                    <FontAwesome name="edit" />
                                                </Button>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={`Ngừng hoạt động ${icd.code}?`}
                                                    onConfirm={() => this.onInActive(icd.id)}
                                                    okText="Ok"
                                                    cancelText="Cancel"
                                                >
                                                    <Button>
                                                        <FontAwesome name="lock" />
                                                    </Button>
                                                </Popconfirm>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="pagination-d">
                        <Pagination
                            defaultCurrent={3}
                            current={getIcdSearch().PageIndex}
                            total={total}
                            showQuickJumper
                            showTitle={false}
                            defaultPageSize={getIcdSearch().PageSize}
                            onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        // hospitals: state.hospital.hospitals,
        // totalCount: state.hospital.totalCount,
        data: state.icd.data,
        dropdownServices: state.icd.dropdownServices,
        total: state.icd.total
    };
}

function mapDispatchToProps(dispatch) {
    return {
        // getHospitalFromServer: (search) => dispatch(getHospitalFromServer(search)),
        // handleOpenModalApp: (modalData) => dispatch(handleOpenModalApp(modalData)),
        // deleteHospitalFromServer: (id) => dispatch(deleteHospitalFromServer(id)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        fetchIndications: () => dispatch(fetchIndications()),
        getDropdownServices: () => dispatch(getDropdownServices()),
        activeIcd: (id) => dispatch(activeIcd(id)),
        inactiveIcd: (id) => dispatch(inactiveIcd(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ICDPage);
