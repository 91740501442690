let icdSearch = {
    Keyword: "",
    code: "",
    serviceId: "",
    PageIndex: 1,
    PageSize: 10,
}

const getIcdSearch = () => {
    return icdSearch;
}

const setIcdSearch = (data) => { 
    icdSearch = data
}

export { getIcdSearch, setIcdSearch }