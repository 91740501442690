import Axios from "axios";
import { message } from "antd";
import { store } from "../store/store";
const ACTION_TYPES = {
    SET_MEDI_EQUIPMENT: "SET_MEDI_EQUIPMENT",
    SET_MEDI_EQUIPMENT_ITEM: "SET_MEDI_EQUIPMENT_ITEM",
};

const initialstate = {
    mediEquipments: [],
    totalCount: 0,
    mediEquipmentItem: {},
};

const mediEquipmentReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_MEDI_EQUIPMENT:
            state = {...state };
            state.mediEquipments = action.payload.data;
            state.totalCount = action.payload.dataCount;
            return {...state };
        case ACTION_TYPES.SET_MEDI_EQUIPMENT_ITEM:
            state.mediEquipmentItem = action.payload;
            return {...state };
        default:
            return state;
    }
};
const setMediEquipments = (mediEquipment) => {
    return {
        type: ACTION_TYPES.SET_MEDI_EQUIPMENT,
        payload: mediEquipment,
    };
};
const getMediEquipmentFromServer = (request) => async(dispatch) => {
    const response = await Axios.get(
        `api/Medicals/paging?Keyword=${request.Keyword}&PageIndex=${request.PageIndex}&PageSize=${request.PageSize}`
    );
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setMediEquipments(response.data));
        }
    } else {
        message.error(response.status);
    }
};

const setMediEquipmentItem = (mediEquipment) => {
    return {
        type: ACTION_TYPES.SET_MEDI_EQUIPMENT_ITEM,
        payload: mediEquipment,
    };
};

const getMediEquipmentById = (id) => async(dispatch) => {
    const response = await Axios.get(`api/Medicals/${id}`);
    if (response.data.status === 1) {
        dispatch(setMediEquipmentItem(response.data.data));
    }
    
};
const updateMediEquipmentToServer = (id, mediEquipment) => async(dispatch) => {
    await Axios.put(`api/Medicals/${id}`, mediEquipment)
        .then((response) => {
            if (response.data) {
                if (response.data.status === 1) {
                    message.success(response.data.message);
                }
                
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let messages = "";

                message.error(messages, 5);
            }
        });
};

const addMediEquipmentToServer = (request) => async(dispatch) => {
    await Axios.post("api/Medicals/create", request)
        .then((response) => {
            if (response.status === 200) {
                if (response.data.status === 1) {
                    message.success("Successfully! ");
                }
            }
        })
        .catch((err) => {
            if (err.response) {
                message.error("Có lỗi xảy ra");
            }
        });
};

const deleteMediEquipmentFromServer = (id) => async(dispatch) => {
    await Axios.delete(`api/Medicals/${id}`)
        .then((response) => {
            if (response.data.status === 1) {
                message.success(response.data.message);
            } else {
                message.error(response.data.message);
            }
        })
        .catch((err) => {
            message.error("Error!");
        });
};

export {
    deleteMediEquipmentFromServer,
    addMediEquipmentToServer,
    updateMediEquipmentToServer,
    getMediEquipmentById,
    mediEquipmentReducer,
    setMediEquipmentItem,
    getMediEquipmentFromServer
};