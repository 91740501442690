import React, { Component } from "react";
import { connect } from "react-redux";
import { getConfigurationFromServer, deleteConfigurationFromServer } from "../../../reducers/configurationReducer";
import { getConfigurationSearch, setConfigurationSearch } from "./configuration";
import { handleOpenModalApp, setModalBasic } from "../../../reducers/uiReducer";
import ContentEdit from "../../../components/Configuration/ContentEdit";
import AddFormContent from "../../../components/Configuration/AddFormContent";
import { Form, Input, Button, Pagination,Popconfirm, message } from "antd";

import "./ConfigurationPage.scss";
import FontAwesome from "react-fontawesome";
import ContentConfigTools from './../../../components/Configuration/ContentConfigTools';
import Axios from "axios";
class ConfigurationPage extends Component {
    state = {
        SuperAdmin: false,
    };
    componentDidMount = async () => {
        this.props.getConfigurationFromServer(getConfigurationSearch());
        await Axios.get("api/Users/info")
            .then((response) => {
                if (response.status === 200) {
                    if(response.data.data.roles.includes("SuperAdmin")){
                        this.setState({SuperAdmin: true});
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    handleUpdateTable = () => {
        this.props.getConfigurationFromServer(getConfigurationSearch());
    };


    onEdit = (configurationId, configurationName) => {
        const modalData = {
            visible: true,
            width: 900,
            content: <ContentEdit configurationId={configurationId} configurationName={configurationName} />,
            name: "doctor-add",
        };
        this.props.setModalBasic(modalData);
    };
    onAdd = () => {
        const modalData = {
            visible: true,
            width: 900,
            content: <AddFormContent />,
            name: "doctor-add",
        };
        this.props.setModalBasic(modalData);
    };
    onEditToolViewer = async () => {
        let data = {};
        const response = await Axios.get(
            `api/Configs/read_config_tool_viewer`
        );
        if (response.status === 200) {
            if (response.data.status === 1) {
            data = response.data;
            const modalData = {
                visible: true,
                width: 900,
                content: <ContentConfigTools  data={data} setModalBasic={this.props.setModalBasic}/>,
                name: "doctor-add",
            };
            this.props.setModalBasic(modalData);
            }
        } else {
            message.error(response.status);
        }
    };
    handleDeleteConfiguration = (id) => {
        this.props.deleteConfigurationFromServer(id).then(() => {
            this.props.getConfigurationFromServer(getConfigurationSearch());
        });
    };

    onFinish = (values) => {
        let configurationSearch = getConfigurationSearch();
        configurationSearch.Keyword = values.Keyword;
        configurationSearch.PageIndex = 1;
        setConfigurationSearch(configurationSearch);
        this.handleUpdateTable();
    };

    onChangePage = (page, pageSize) => {
        let configurationSearch = getConfigurationSearch();
        configurationSearch.PageIndex = page;
        configurationSearch.PageSize = pageSize;
        setConfigurationSearch(configurationSearch);
        this.handleUpdateTable();
    };

    render() {
        const { configurations, totalCount } = this.props;
        return (
            <div className="configuration-page-d">
                <div className="configuration-search-d">
                    <Form
                        name="horizontal_login"
                        initialValues={{ Keyword: "", Sex: "", Age: "" }}
                        layout="inline"
                        onFinish={this.onFinish}
                    >
                        <Form.Item name="Keyword">
                            <Input placeholder="Từ khóa" />
                        </Form.Item>
                        <Button style={{ background: "#2a274c", color: "#fff" }} htmlType="submit">
                            Tìm kiếm
                        </Button>
                        <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.onAdd}>
                            Thêm mới
                        </Button>
                        {this.state.SuperAdmin == true ? 
                            <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.onEditToolViewer}>
                                Sửa tool viewer
                            </Button>
                        : 
                            ""
                        }
                        
                    </Form>
                </div>
                <div className={"configuration-table-d"}>
                    <table border="0" className="table-admin" style={{userSelect: "text"}}>
                        <thead>
                            <tr>
                                <th>Mã</th>
                                <th>Tên</th>
                                <th>Giá trị</th>
                                <th>Trạng thái</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {configurations.map((configurations, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{configurations.configCode}</td>
                                        {(configurations.configCode === "Logo"|| configurations.configCode === "Ico")?<td><img style={{width:100}} src={`${(window.location.protocol == "https:" ? (window.HTTPS_API_SERVER_RIS_URL ?? window.API_SERVER_RIS_URL) : window.API_SERVER_RIS_URL)}/${configurations.configName}`} alt="Logo"/></td>:<td>{configurations.configName}</td>}
                                        <td>{configurations.configValue}</td>
                                        <td>{configurations.status}</td>
                                        <td>
                                            <div className="tools">
                                                <Button onClick={() => this.onEdit(configurations.configID)}>
                                                    <FontAwesome name="edit" />
                                                </Button>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={`Xác nhận xóa ${configurations.configCode}`}
                                                    onConfirm={() => this.handleDeleteConfiguration(configurations.configID)}
                                                    okText="Ok"
                                                    cancelText="Cancel"
                                                >
                                                    <Button>
                                                        <FontAwesome name="trash-alt" />
                                                    </Button>
                                                </Popconfirm>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="pagination-d">
                        <Pagination
                            defaultCurrent={3}
                            current={getConfigurationSearch().PageIndex}
                            total={totalCount}
                            showQuickJumper
                            showSizeChanger
                            showTitle={false}
                            defaultPageSize={getConfigurationSearch().PageSize}
                            pageSizeOptions={[10,20,50,100]}
                            onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        configurations: state.configuration.configurations,
        totalCount: state.configuration.totalCount,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getConfigurationFromServer: (search) => dispatch(getConfigurationFromServer(search)),
        handleOpenModalApp: (modalData) => dispatch(handleOpenModalApp(modalData)),
        deleteConfigurationFromServer: (id) => dispatch(deleteConfigurationFromServer(id)),
        setModalBasic: (data) => dispatch(setModalBasic(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationPage);
