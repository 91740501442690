import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, Layout } from "antd";
import { getDoctorById } from "../../reducers/doctorReducer";
import { connect } from "react-redux";

import { formatDate } from "../../utils";
import { setModalBasic } from "../../reducers/uiReducer";
import moment from "moment";
const { Header, Content } = Layout;
class ContentInfo extends Component {
    componentDidMount = () => {
        this.props.getDoctorById(this.props.doctorId);
    };
    componentDidUpdate = (prevProps) => {
        if (prevProps.doctorId !== this.props.doctorId) {
            this.props.getDoctorById(this.props.doctorId);
        }
    };
    render() {
        const { doctorItem,isvermed } = this.props;
        return (
            <Layout>
                <Header style={{ display: "flex", justifyContent: "space-between" }}>
                    <p className="modalBasic-title">Thông tin bác sĩ</p>
                    <button className="modalBasic-btn-close"onClick={() => this.props.setModalBasic({visible:false})}>
                        <i className="fas fa-times icon" ></i>
                    </button>
                </Header>
                <Content>
                    <div className="content-info-doctor-d" style={{ padding: 10 }}>
                        <table className="table-admin--info" border="0">
                            <tbody>
                                <tr>
                                    <td>Name:</td>
                                    <td>{doctorItem.name}</td>
                                </tr>
                                <tr>
                                    <td>UserName:</td>
                                    <td>{doctorItem.userName}</td>
                                </tr>
                                <tr>
                                    <td>Giới tính:</td>
                                    <td>{doctorItem.gender}</td>
                                </tr>
                                <tr>
                                    <td>Địa chỉ:</td>
                                    <td>{doctorItem.address}</td>
                                </tr>
                                <tr>
                                    <td>Ngày sinh:</td>
                                    <td>{doctorItem.doB}</td>
                                </tr>
                                <tr>
                                    <td>Email:</td>
                                    <td>{doctorItem.email}</td>
                                </tr>
                                <tr>
                                    <td>Số điện thoại:</td>
                                    <td>{doctorItem.phoneNumber}</td>
                                </tr>
                                {isvermed == true ?
                                <>
                                    <tr>
                                        <td>Đơn vị công tác:</td>
                                        <td>{doctorItem.position}</td>
                                    </tr>
                                    <tr>
                                        <td>Vị trí:</td>
                                        <td>{doctorItem.positionTittle}</td>
                                    </tr>
                                    <tr>
                                        <td>Tên đơn vị ngoài MED:</td>
                                        <td>{doctorItem.nameOutsideMed}</td>
                                    </tr>
                                    <tr>
                                        <td>Mô tả:</td>
                                        <td>{doctorItem.description}</td>
                                    </tr>
                                    <tr>
                                        <td>Thứ tự ưu tiên:</td>
                                        <td>{doctorItem.order}</td>
                                    </tr>
                                    <tr>
                                        <td>Người đề xuất lập:</td>
                                        <td>{doctorItem.personPropose}</td>
                                    </tr>
                                    <tr>
                                        <td>Người lập:</td>
                                        <td>{doctorItem.userNameCreate}</td>
                                    </tr>
                                    <tr>
                                        <td>Thời gian lập:</td>
                                        <td>{doctorItem.createDate == null ? "" : moment(doctorItem.createDate).format("DD/MM/YYYY")}</td>
                                    </tr>
                                </>
                                :
                                ""
                                }
                                
                                <tr>
                                    <td>CT:</td>
                                    <td>{doctorItem.ct}</td>
                                </tr>
                                <tr>
                                    <td>DX:</td>
                                    <td>{doctorItem.dx}</td>
                                </tr>
                                <tr>
                                    <td>EO:</td>
                                    <td>{doctorItem.eo}</td>
                                </tr>
                                <tr>
                                    <td>MAMO:</td>
                                    <td>{doctorItem.mamo}</td>
                                </tr>
                                <tr>
                                    <td>MRI:</td>
                                    <td>{doctorItem.mri}</td>
                                </tr>
                                <tr>
                                    <td>PET:</td>
                                    <td>{doctorItem.pet}</td>
                                </tr>
                                <tr>
                                    <td>US:</td>
                                    <td>{doctorItem.us}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Content>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        doctorItem: state.doctor.doctorItem,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getDoctorById: (id) => dispatch(getDoctorById(id)),
        setModalBasic: (data) => dispatch(setModalBasic(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentInfo);
