import React, { Component } from "react";
import { connect } from "react-redux";
import { handleOpenModalApp, setModalBasic } from "../../../reducers/uiReducer";
import { Form, Button, DatePicker, Input, Pagination } from "antd";
import FontAwesome from "react-fontawesome";
import moment from "moment";
import { setRoleAdminSearch, getRoleAdminSearch, initialRoleAdminSeach } from "./RoleAdmin";
import { getRoleAdminFromServer } from "../../../reducers/roleAdminReducer";
import Checkbox from "antd/lib/checkbox/Checkbox";
import ContentInfo from "./ContentInfo";

class RoleAdminPage extends Component {
    componentDidMount = () => {
        this.props.getRoleAdminFromServer(getRoleAdminSearch());
    };
    handleOpenModal = (modalData) => {
        this.props.handleOpenModalApp(modalData);
    };
    onFinish = (values) => {
        let RoleAdminSearch = getRoleAdminSearch();
        RoleAdminSearch.userName = values.userName;
        RoleAdminSearch.PageIndex = 1;
        setRoleAdminSearch(RoleAdminSearch);
        this.props.getRoleAdminFromServer(RoleAdminSearch);
    };
    onChangePage = (page, pageSize) => {
        let RoleAdminSearch = getRoleAdminSearch();
        RoleAdminSearch.PageIndex = page;
        RoleAdminSearch.PageSize = pageSize;
        setRoleAdminSearch(RoleAdminSearch);
        this.props.getRoleAdminFromServer(RoleAdminSearch);
      };
      onEdit = (id,name) => {
        const modalData = {
            title: [<p>Thông tin Quyền admin</p>],
            visible: true,
            width:  500,
            height:  650,
            content: <ContentInfo id={id} name = {name}/>,
        };
        this.props.setModalBasic(modalData);
    };
    render() {
        const { roleadmins, totalCount } = this.props;
        var obj = Object.getOwnPropertyNames(roleadmins);
        return (
            <div className="doctor-page-d">
                <div className="doctor-search-d">
                <Form
                        name="horizontal_login"
                        initialValues={initialRoleAdminSeach}
                        layout="inline"
                        onFinish={this.onFinish}
                    >
                        <Form.Item name="userName" style={{ marginTop: 5 }}>
                            <Input allowClear />
                        </Form.Item>
                        <Button style={{ background: "#2a274c", color: "#fff",marginTop: 5 }} htmlType="submit">
                            Tìm kiếm
                        </Button>
                    </Form>
                </div>
                <div className={"doctor-table-d"}>
                    <table border="0" className="table-admin">
                        <thead>
                            <tr>
                                <th>Tên</th>
                                <th>Quyền</th>
                                <th>Quyền sử dụng admin</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {roleadmins.map((roleadmin, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                        <strong>{roleadmin.name}</strong>
                                        </td>
                                        <td>{roleadmin.role}</td>
                                        <td>{roleadmin.lstRoleAdmin}</td>
                                            {/* {roleadmin.role.map((rolead, id) => {
                                                return (
                                                //item[index]
                                                    <td>
                                                        <Checkbox
                                                            key={parseInt(rolead.id) + index}
                                                            checked={rolead.selected}
                                                        >
                                                        </Checkbox>
                                                    </td>
                                                );
                                            })} */}
                                        
                                        
                                        <td>
                                            <div className="tools">
                                                <Button onClick={() => this.onEdit(roleadmin.id,roleadmin.name)}>
                                                    <FontAwesome name="edit" />
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="pagination-d">
                        <Pagination
                        defaultCurrent={3}
                        current={getRoleAdminSearch().PageIndex}
                        total={totalCount}
                        showQuickJumper
                        hideOnSinglePage
                        showTitle={false}
                        defaultPageSize={getRoleAdminSearch().PageSize}
                        onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        roleadmins: state.roleadmin.roleadmins,//2-2
        totalCount: state.roleadmin.totalCount,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getRoleAdminFromServer: (request) => dispatch(getRoleAdminFromServer(request)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        handleOpenModalApp: (modalData) =>dispatch(handleOpenModalApp (modalData)),
        setModalBasic: (data) => dispatch(setModalBasic(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleAdminPage);
