import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, Link } from "react-router-dom";
import DoctorPage from "../pages/Home/DoctorPage/DoctorPage";
import PatientPage from "../pages/Home/PatientPage/PatientPage";
import IndicatePage from "../pages/Home/IndicatePage/IndicatePage";
import TechnicalServicePage from "../pages/Home/TechnicalServicePage/TechnicalServicePage";
import TypeServicePage from "../pages/Home/TypeServicePage/TypeServicePage";
// import SampleResultPage from "../pages/Home/SampleResultPage/SampleResultPage";
import ConfigurationPage from "../pages/Home/ConfigurationPage/ConfigurationPage";
import ServerPacsPage from "../pages/Home/ServerPacsPage/ServerPacsPage";
import HospitalPage from "../pages/Home/HospitalPage/HospitalPage";
import PrintResultPage from "../pages/Home/PrintResultPage/PrintResultPage";
import DoctorRolePage from "../pages/Home/DoctorRolePage/DoctorRolePage";
import StatisticPage from "../pages/Home/StatisticPage/StatisticPage";
import ICDPage from "../pages/Home/ICDPage/ICDPage";
import ICDTemplateManager from "../pages/Home/ICDPage/ICDTemplateManager";
import StaticStudiesPage from "../pages/Home/StaticStudiesPage/StaticStudiesPage";
import TemplatePage from "../pages/Home/TemplatePage/TemplatePage";
import ServerpacsTemplateManager from "../pages/Home/ServerPacsPage/ServerpacsTemplateManager";
import AreaPage from "../pages/Home/AreaPage/AreaPage";
//import BackupPage from "../pages/Home/BackupPage/BackupPage";
import RoleManager from "../pages/Home/DoctorPage/RoleManager";

import MediEquipmentPage from "../pages/Home/MediEquipmentPage/MediEquipmentPage";
import LogPage from "../pages/Home/LogPage/LogPage";    
import RoleAdminPage from "../pages/Home/RoleAdminPage/RoleAdminPage";
import ServiceEquipPage from "../pages/Home/IndicatePage/ServiceEquipPage";

class HomeRouter extends Component {
    
    render() {
        return (
            <Switch>
                <Redirect
                    exact
                    from="/"
                    to={"/"}
                    //to={localStorage.getItem("router") ? `/${localStorage.getItem("router")}` : "/statistic"}
                />
                <Route exact path="/statistic">
                    <StatisticPage />
                </Route> 
                <Route exact path="/static-studies">
                    <StaticStudiesPage />
                </Route>    
                <Route exact path="/doctor">
                    <DoctorPage />
                </Route>
                <Route exact path="/role-manager">
                    <RoleManager />
                </Route>
                <Route exact path="/patient">
                    <PatientPage />
                </Route>
                <Route exact path="/indicate">
                    <IndicatePage />
                </Route>
                <Route exact path="/technical-services">
                    <TechnicalServicePage />
                </Route>
                <Route exact path="/type-services">
                    <TypeServicePage />
                </Route>
                <Route exact path="/sample-results">
                    <TemplatePage />
                </Route>
                {/* <Route exact path="/backup">
                    <BackupPage />
                </Route> */}
                <Route exact path="/configuration">
                    <ConfigurationPage />
                </Route>
                <Route exact path="/server-pacs">
                    <ServerPacsPage />
                </Route>
                <Route exact path="/area">
                    <AreaPage />
                </Route>
                <Route exact path="/hospital">
                    <HospitalPage />
                </Route>
                <Route exact path="/doctor-roles">
                    <DoctorRolePage />
                </Route>
                <Route exact path="/print-results">
                    <PrintResultPage />
                </Route>
                <Route exact path="/icd">
                    <ICDPage />
                </Route>
                <Route exact path="/icd-template-manager">
                    <ICDTemplateManager />
                </Route>
                <Route exact path="/server-template-manager">
                    <ServerpacsTemplateManager />
                </Route>
                <Route exact path="/Log">
                    <LogPage />
                </Route>
                <Route exact path="/role-admin">
                    <RoleAdminPage />
                </Route>
                <Route exact path="/medi-equipment">
                    <MediEquipmentPage />
                </Route>
                <Route exact path="/service-equipment">
                    <ServiceEquipPage />
                </Route>
            </Switch>
        );
        }
    }

export default HomeRouter;
