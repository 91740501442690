let serverpacsSearch = {
    Keyword: "",
    PageIndex: 1,
    PageSize: 10,
}

let serverpacsTempSearch = {
    Keyword: "",
    PageIndex: 1,
    PageSize: 10,
}


const getServerpacsSearch = () => {
    return serverpacsSearch;
}

const setServerpacsSearch = (data) => {
    serverpacsSearch = data
}

const getServerpacsTempSearch = () => {
    return serverpacsTempSearch;
}

const setServerpacsTempSearch = (data) => {
    serverpacsTempSearch = data
}

export { getServerpacsSearch, setServerpacsSearch,getServerpacsTempSearch,setServerpacsTempSearch}