import moment from "moment";
let hospitalSeach = {
    Keyword: "",
    HosType: "",
    AreaId: "",
    PageIndex: 1,
    PageSize: 10,
};

const getHospitalSearch = () => {
    return hospitalSeach;
};

const setHospitalSearch = (search) => {
    hospitalSeach = search;
};

export { getHospitalSearch, setHospitalSearch };