import React, { Component } from "react";
import { connect } from "react-redux";
import {
    getTechnicalServiceFromServer,
    deleteTechnicalServiceFromServer,
} from "../../../reducers/technicalServiceReducer";
import { getTechnicalServiceSearch } from "./technicalservice";
import { handleOpenModalApp,setModalBasic } from "../../../reducers/uiReducer";
import ContentInfo from "../../../components/TechnicalService/ContentInfo";
import ContentEdit from "../../../components/TechnicalService/ContentEdit";
import ContentEditTime from "../../../components/TechnicalService/ContentEditTime";
import TechnicalServiceSearch from "../../../components/TechnicalService/TechnicalServiceSearch";
import TechnicalServiceTable from "../../../components/TechnicalService/TechnicalServiceTable";

class TechnicalServicePage extends Component {
    componentDidMount = () => {
        this.props.getTechnicalServiceFromServer(getTechnicalServiceSearch());
    };
    handleUpdateTable = () => {
        this.props.getTechnicalServiceFromServer(getTechnicalServiceSearch());
    };
    handleOpenModal = (modalData) => {
        this.props.handleOpenModalApp(modalData);
    };
    handleShowInfo = (technicalserviceId, technicalserviceName) => {
        const modalData = {
            title: [<p>Thông tin bác sĩ</p>],
            visible: true,
            width: 900,
            height: 500,
            content: (
                <ContentInfo technicalserviceId={technicalserviceId} technicalserviceName={technicalserviceName} />
            ),
        };
        this.props.handleOpenModalApp(modalData);
    };
    handleEdit = (technicalserviceId, technicalserviceName) => {
        const modalData = {
            header: <p key="Sửa thông tin dịch vụ kỹ thuật">Sửa thông tin dịch vụ kỹ thuật</p>,
            visible: true,
            footer: null,
            content: (
                <ContentEdit technicalserviceId={technicalserviceId} technicalserviceName={technicalserviceName} />
            ),
            name: "modal-edit-technical-service",
        };
        this.props.handleOpenModalApp(modalData);
    };
    handleEditTime = (technicalserviceId, technicalserviceName) => {
        const modalData = {
            title: [<p key="Thay đổi Thời gian duyệt">Thay đổi Thời gian duyệt</p>],
            visible: true,
            width: 900,
            height: 200,
            content: <ContentEditTime technicalserviceId={technicalserviceId} technicalserviceName={technicalserviceName} />,
            name: "modal--indication-add",
        };
        this.props.setModalBasic(modalData);
    };
    handleAdd = (values) => {};
    handleDeleteTechnicalService = (id) => {
        this.props.deleteTechnicalServiceFromServer(id).then(() => {
            this.props.getTechnicalServiceFromServer(getTechnicalServiceSearch());
        });
    };
    render() {
        const { technicalservices, totalCount } = this.props;
        return (
            <div className="technicalservice-page-d" style={{height:"100vh"}}>
                <TechnicalServiceSearch
                    handleUpdateTable={this.handleUpdateTable}
                    handleOpenModal={this.handleOpenModal}
                    handleAdd={this.handleAdd}
                />
                <TechnicalServiceTable
                    handleShowInfo={this.handleShowInfo}
                    handleEdit={this.handleEdit}
                    handleEditTime={this.handleEditTime}
                    technicalservices={technicalservices}
                    totalCount={totalCount}
                    handleUpdateTable={this.handleUpdateTable}
                    handleDeleteTechnicalService={this.handleDeleteTechnicalService}
                />
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        technicalservices: state.technicalservice.technicalservices,
        totalCount: state.technicalservice.totalCount,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getTechnicalServiceFromServer: (search) => dispatch(getTechnicalServiceFromServer(search)),
        handleOpenModalApp: (modalData) => dispatch(handleOpenModalApp(modalData)),
        deleteTechnicalServiceFromServer: (id) => dispatch(deleteTechnicalServiceFromServer(id)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TechnicalServicePage);
