import React, { Component } from "react";
import { connect } from "react-redux";
import { getIndicateFromServer, deleteIndicateFromServer,fetchDropdownServerPacs, handleRecoveryIndicate } from "../../../reducers/indicateReducer";
import { getIndicateSearch } from "./indicate";
import { handleOpenModalApp, setModalBasic } from "../../../reducers/uiReducer";
import ContentInfo from "../../../components/Indicate/ContentInfo";
import ContentEdit from "../../../components/Indicate/ContentEdit";
import IndicateSearch from "../../../components/Indicate/IndicateSearch";
import IndicateTable from "../../../components/Indicate/IndicateTable";
import ContentEditTime from "../../../components/Indicate/ContentEditTime";
import ChangeServerPacs from "../../../components/Indicate/ChangeServerPacs";
import ChangePath from "../../../components/Indicate/ChangePath";

class IndicatePage extends Component {
    componentDidMount = () => {
        this.props.getIndicateFromServer(getIndicateSearch());
        this.props.fetchDropdownServerPacs();
    };
    handleUpdateTable = () => {
        this.props.getIndicateFromServer(getIndicateSearch());
    };
    handleOpenModal = (modalData) => {
        this.props.handleOpenModalApp(modalData);
    };
    handleShowInfo = (indicateId, indicateName) => {
        const modalData = {
            title: [<p>Thông tin bác sĩ</p>],
            visible: true,
            width: 900,
            height: 500,
            content: <ContentInfo indicateId={indicateId} indicateName={indicateName} />,
        };
        this.props.handleOpenModalApp(modalData);
    };
    handleEdit = (indicateId, indicateName) => {
        const modalData = {
            title: [<p key="Thay đổi thông tin bác sĩ">Thay đổi thông tin bác sĩ</p>],
            visible: true,
            width: 900,
            height: 500,
            content: <ContentEdit indicateId={indicateId} indicateName={indicateName} />,
        };
        this.props.handleOpenModalApp(modalData);
    };
    handleEditTime = (indicateId, indicateName) => {
        const { listServerPacs } = this.props;
        const modalData = {
            title: [<p key="Thay đổi Thời gian thực hiện">Thay đổi Thời gian thực hiện</p>],
            visible: true,
            width: 900,
            height: 400,
            content: <ContentEditTime indicateId={indicateId} indicateName={indicateName} listServerPacs={listServerPacs}/>,
            name: "modal--indication-add",
        };
        this.props.setModalBasic(modalData);
    };
    handleAdd = (values) => {
    };
    handleChangeServerPacs = () => {
        const { listServerPacs } = this.props;
        const modalData = {
            title: [<p key="Đổi máy chụp">Đổi máy chụp</p>],
            visible: true,
            width: 900,
            height: 300,
            content: <ChangeServerPacs listServerPacs={listServerPacs}/>,
            name: "modal--indication-change-serverPacs",
        };
        this.props.setModalBasic(modalData);
    };
    handleChangePath = () => {
        const { listServerPacs } = this.props;
        const modalData = {
            title: [<p key="Đổi đường dẫn">Đổi đường dẫn</p>],
            visible: true,
            width: 900,
            height: 300,
            content: <ChangePath listServerPacs={listServerPacs}/>,
            name: "modal--indication-change-path",
        };
        this.props.setModalBasic(modalData);
    };
    handleDeleteIndicate = (id) => {
        this.props.deleteIndicateFromServer(id).then(() => {
            this.props.getIndicateFromServer(getIndicateSearch());
        });
    };
    handleRecoveryIndicate = (id) => {
        this.props.handleRecoveryIndicate(id).then(() => {
            this.props.getIndicateFromServer(getIndicateSearch());
        });
    };
    render() {
        const { indicates, totalCount, listServerPacs } = this.props;
        return (
            <div className="indicate-page-d" style={{height:"100vh"}}>
                <IndicateSearch
                    handleUpdateTable={this.handleUpdateTable}
                    handleOpenModal={this.handleOpenModal}
                    listServerPacs={listServerPacs}
                    handleAdd={this.handleAdd}
                    handleChangeServerPacs={this.handleChangeServerPacs}
                    handleChangePath={this.handleChangePath}
                />
                <IndicateTable
                    handleShowInfo={this.handleShowInfo}
                    handleEdit={this.handleEdit}
                    indicates={indicates}
                    totalCount={totalCount}
                    handleUpdateTable={this.handleUpdateTable}
                    handleRecoveryIndicate ={this.handleRecoveryIndicate}
                    handleEditTime={this.handleEditTime}
                    handleDeleteIndicate={this.handleDeleteIndicate}
                />
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        indicates: state.indicate.indicates,
        totalCount: state.indicate.totalCount,
        listServerPacs: state.indicate.listServerPacs,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getIndicateFromServer: (search) => dispatch(getIndicateFromServer(search)),
        handleOpenModalApp: (modalData) => dispatch(handleOpenModalApp(modalData)),
        deleteIndicateFromServer: (id) => dispatch(deleteIndicateFromServer(id)),
        handleRecoveryIndicate: (id) => dispatch(handleRecoveryIndicate(id)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        fetchDropdownServerPacs: () => dispatch(fetchDropdownServerPacs()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IndicatePage);
