import React, { Component } from "react";
import { Form, Input, Radio, Layout, Button, Select, InputNumber } from "antd";
import { connect } from "react-redux";
import { handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
import { getMediEquipmentSearch, getTypeEquipMedi } from "../../pages/Home/MediEquipmentPage/MediEquipment";
import { addMediEquipmentToServer, getMediEquipmentFromServer } from "../../reducers/mediEquipmentReducer";
const { Option } = Select;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const { Content, Footer, Header } = Layout;

class AddFormContentMedi extends Component {
    onFinish = (values) => {
        values.price = Number(values.price);
        values.typeEquip = Number(values.typeEquip);
        this.props.addMediEquipmentToServer(values).then(() => {
            this.props.setModalBasic({ visible: false });
            this.props.getMediEquipmentFromServer(getMediEquipmentSearch());
        });
    };

    render() {
        const TypeEquipMedi = getTypeEquipMedi();
        return (
            <Layout className="doctor-add-form-content">
                <Header>
                    <p className="modalBasic-title">Thêm mới vật tư</p>
                    <button
                        className="modalBasic-btn-close"
                        onClick={() => this.props.setModalBasic({ visible: false })}
                    >
                        <i className="fas fa-times icon"></i>
                    </button>
                </Header>
                <Content>
                    <Form
                        style={{ marginTop: 20 }}
                        {...formItemLayout}
                        name="addArea"
                        onFinish={this.onFinish}
                        initialValues={{ name: "" }}
                        scrollToFirstError
                    >
                        <Form.Item name="name" label="Tên">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="typeEquip" label="Loại">
                                <Select>
                                    {TypeEquipMedi.map((item) => {
                                        return (
                                            <Option key={item.id} value={Number(item.id)}>
                                                {item.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        <Form.Item name="price" label="Giá" >
                            <InputNumber />
                        </Form.Item>
                        <Button
                            style={{ color: "#fff", background: "#2687d4", display: "none" }}
                            id="btn-submit-add-mediEquipment"
                            htmlType={"submit"}
                        >
                            Thêm mới
                        </Button>
                    </Form>
                </Content>
                <Footer>
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            document.getElementById("btn-submit-add-mediEquipment").click();
                        }}
                    >
                        Thêm mới
                    </Button>
                </Footer>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addMediEquipmentToServer: (mediEquipment) => dispatch(addMediEquipmentToServer(mediEquipment)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        getMediEquipmentFromServer: (search) => dispatch(getMediEquipmentFromServer(search)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFormContentMedi);
