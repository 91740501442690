import { message } from "antd";
import Axios from "axios";
import { getServerpacsTempSearch } from "../models/serverpacsModel";
import { store } from "../store/store";

const ACTION_TYPES = {
    SET_SERVERPACS_TEMP_DATA: "SET_SERVERPACS_TEMP_DATA",
};

const initialstate = {
    data: [],
    total: 0,
    severpacsDropdown: [],
    templateDropdown: []
};

const serverpacsTemplateReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_SERVERPACS_TEMP_DATA:
            state = action.payload;
            return {...state };
        default:
            return state;
    }
};

const getServerpacsDropdown = (id) => async dispatch => {
    const response = await Axios.get(`/api/ServerPacs/${id}/serverpacs-temp`)
    if (response.data.status === 1) {
        dispatch(setDataServerpacsTemp({...store.getState().serverpacsTempManager, severpacsDropdown: response.data.data }));
    }
}

const getTemplateDropdown = (id) => async dispatch => {
    const response = await Axios.get(`/api/ServerPacs/${id}/serverpacs-temp/service`)
    if (response.data.status === 1) {
        dispatch(setDataServerpacsTemp({...store.getState().serverpacsTempManager, templateDropdown: response.data.data }));
    }
}

const assign = (data) => async dispatch => {
    const response = await Axios.post(`/api/ServerPacs/serverpacs-temp/assign`, data)
    if (response.data.status === 1) {
        message.success(response.data.message);
    }
}

const fetchServerpacsTemp = () => async dispatch => {
    const params = getServerpacsTempSearch();
    const response = await Axios.get(`/api/ServerPacs/serverpacs-temp/paging?Keyword=${params.Keyword}&PageIndex=${params.PageIndex}&PageSize=${params.PageSize}`)
    if (response.data.status === 1) {
        dispatch(setDataServerpacsTemp({...store.getState().serverpacsTempManager, data: response.data.data, total: response.data.dataCount }));
    }
}

const setDataServerpacsTemp = (data) => {
    return {
        type: ACTION_TYPES.SET_SERVERPACS_TEMP_DATA,
        payload: data,
    };
};

export { serverpacsTemplateReducer, fetchServerpacsTemp, getServerpacsDropdown, getTemplateDropdown, assign }