import { store } from "../store/store";
import { saveToken, saveTokenRefresh, clearAll, getToken, getTokenRefresh } from "./authenticate";
import { setAuth } from "../reducers/authReducer";
import { setErrorBoundry } from "../reducers/errorReducer";
import Axios from "axios";

const setupAxiosInterceptors = (onUnauthenticated) => {
    const TIMEOUT = 1 * 60 * 100000;
    Axios.defaults.timeout = TIMEOUT;
    Axios.defaults.baseURL = window.API_SERVER_URL;
    let requestConfig;
    const onRequestSuccess = (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        requestConfig = config;
        return config;
    };
    const onResponseSuccess = (response) => response;
    const onResponseError = (err) => {
        const status = err.status || (err.response ? err.response.status : 0);
        if (status === 403 || status === 401) {
            onUnauthenticated();
            store.dispatch(setAuth(false));
        } else {
            store.dispatch(
                setErrorBoundry({
                    show: true,
                    error: `From: ${requestConfig.url}\n` + err,
                })
            );
        }
        return Promise.reject(err);
    };
    Axios.interceptors.request.use(onRequestSuccess);
    //Axios.interceptors.response.use(onResponseSuccess, onResponseError);
    Axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (
                error.response &&
                error.response.status === 401 &&
                error.config &&
                !error.config.__isRetryRequest &&
                refreshToken
            ) {
                return new Promise((resolve) => {
                    const originalRequest = error.config;
                    const refreshToken = localStorage.getItem("tokenRefresh");
                    const jwtToken = getToken();
                    originalRequest._retry = true;

                    const response = fetch(`${window.API_SERVER_URL}api/Users/refresh`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                refreshToken: refreshToken,
                                jwtToken: jwtToken,
                            }),
                        })
                        .then((res) => res.json())
                        .then((res) => {
                            const newToken = res;
                            if (res.status === 1) {
                                if (res.data) {
                                    saveToken(newToken.data.jwtToken);
                                    saveTokenRefresh(newToken.data.refreshToken);
                                    return Axios(originalRequest);
                                } else {
                                    localStorage.clear();
                                    store.dispatch(setAuth(false));
                                }
                            } else {
                                localStorage.clear();
                                store.dispatch(setAuth(false));
                            }
                        });
                    resolve(response);
                    return Promise.reject(error);
                });
            } else {
                store.dispatch(
                    setErrorBoundry({
                        show: true,
                        error: `From: ${requestConfig.url}\n` + error,
                    })
                );
            }
            return Promise.reject(error);
        }
    );
};

const refreshToken = () => {
    const refreshToken = getTokenRefresh();
    const jwtToken = getToken();
    const instance = Axios.create({
        baseURL: window.API_SERVER_URL,
        timeout: 1000,
    });
    instance
        .post("/api/Users/refresh", {
            refreshToken: refreshToken,
            jwtToken: jwtToken,
        })
        .then(
            (response) => {
                const newToken = response.data.data;
                if (newToken) {
                    saveToken(newToken.jwtToken);
                    saveTokenRefresh(newToken.refreshToken);
                } else {
                    localStorage.clear();
                    store.dispatch(setAuth(false));
                }
            },
            (err) => {
                clearAll();
                store.dispatch(setAuth(false));
            }
        );
};

export { setupAxiosInterceptors, refreshToken };