import React, { Component } from "react";
import { Column } from "@ant-design/charts";
export default class ChartsByYear extends Component {
    render() {
       
        // const data = [
        //     { name: "Tuần trước", date: "Monday", value: 7 },
        //     { name: "Tuần trước", date: "Tuesday", value: 15 },
        //     { name: "Tuần trước", date: "Wednesday", value: 11 },
        //     { name: "Tuần trước", date: "Thursday", value: 12 },
        //     { name: "Tuần trước", date: "Friday", value: 2 },
        //     { name: "Tuần trước", date: "Saturday", value: 3 },
        //     { name: "Tuần trước", date: "Sunday", value: 2 },
        //     { name: "Tuần này", date: "Monday", value: 5 },
        //     { name: "Tuần này", date: "Tuesday", value: 6 },
        //     { name: "Tuần này", date: "Wednesday", value: 4 },
        //     { name: "Tuần này", date: "Thursday", value: 10 },
        //     { name: "Tuần này", date: "Friday", value: 8 },
        //     { name: "Tuần này", date: "Saturday", value: 0 },
        //     { name: "Tuần này", date: "Sunday", value: 0 },
        // ];
        const {data} = this.props;
        var config = {
            data: data,
            isGroup: true,
            xField: "date",
            yField: "value",
            seriesField: "name",
            color: function color(_ref) {
                var type = _ref.name;
                if (type === 'Năm trước') {
                    return '#3f51b5';
                }
                return '#ff9800';
            },
            label: {
                position: "middle",
                layout: [
                    { type: "interval-adjust-position" },
                    { type: "interval-hide-overlap" },
                    { type: "adjust-color" },
                ],
            },
        };
        return (
            <div>
                <Column {...config} />
            </div>
        );
    }
}
