import moment from "moment";

let worklistSearch = {
    fromDate: moment().format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    pName: "",
    pCode: "",
    Modality: "",
    room:"",
    executor:"",
    PageIndex: 1,
    PageSize: 20,
};
const initialWorklistSearch = {
	fromDate: moment(),
    toDate: moment(),
    pName: "",
    pCode: "",
    Modality: "",
    room:"",
    executor:"",
    PageIndex: 1,
    PageSize: 20,
};
const getWorklistSearch = () => {
    return {...worklistSearch};
};

const setWorklistSearch = (search) => {
    worklistSearch = search;
};

export { initialWorklistSearch, getWorklistSearch,setWorklistSearch };
