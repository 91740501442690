let templateSeach = {
    Keyword: "",
    ServiceID: "",
    PageIndex: 1,
    PageSize: 3,
};
const templateSeachDefault = {
    Keyword: "",
    ServiceID: "",
    PageIndex: 1,
    PageSize: 3,
};

const getTemplateSearch = () => {
    return templateSeach;
};

const setTemplateSearch = (search) => {
    templateSeach = search;
};

export { templateSeachDefault, getTemplateSearch, setTemplateSearch };

export const templateResultDefault = {
    name: "",
    serviceID: 1,
    data: "",
    tech: "",
    result: "",
    price: 0,
    text: "",
};