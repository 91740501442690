import { rootReducer } from "../reducers/rootReducer";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";

// const logger = (store) => (next) => (action) => {
//     console.log(action, store, result);
//     let result = next(action);
//     return result;
// };

const middleWare = [thunk];

const store = createStore(rootReducer, applyMiddleware(...middleWare));

export { store };