import React, { Component } from "react";
import { connect } from "react-redux";
import {
    getAreaFromServer,
    deleteAreaFromServer,
    activeArea,
    inactiveArea,
    fetchDropdownAreas,
} from "../../../reducers/areaReducer";
import { getAreaSearch, setAreaSearch } from "./area";
import { handleOpenModalApp, setModalBasic } from "../../../reducers/uiReducer";
import ContentEdit from "../../../components/Area/ContentEdit";
import AddFormContent from "../../../components/Area/AddFormContent";
import { Form, Input, Select, Popconfirm, Pagination, Button, Checkbox } from "antd";
import FontAwesome from "react-fontawesome";

import "./AreaPage.scss";
const { Option } = Select;

class AreaPage extends Component {
    componentDidMount = () => {
        this.props.getAreaFromServer(getAreaSearch());
    };

    handleUpdateTable = () => {
        this.props.getAreaFromServer(getAreaSearch());
    };

    onEdit = (areaId, areaName) => {
        this.props.fetchDropdownAreas();
        const modalData = {
            visible: true,
            width: 900,
            content: <ContentEdit areaId={areaId} areaName={areaName} />,
            name: "area-edit",
        };
        this.props.setModalBasic(modalData);
    };

    onAdd = () => {
        this.props.fetchDropdownAreas();
        const modalData = {
            visible: true,
            width: 900,
            content: <AddFormContent />,
            name: "area-add",
        };
        this.props.setModalBasic(modalData);
    };

    onDelete = (id) => {
        this.props.deleteAreaFromServer(id).then(() => {
            this.props.getAreaFromServer(getAreaSearch());
        });
    };

    onInactive = (id) => {
        this.props.inactiveArea(id).then(() => {
            this.props.getAreaFromServer(getAreaSearch());
        });
    };

    onActive = (id) => {
        this.props.activeArea(id).then(() => {
            this.props.getAreaFromServer(getAreaSearch());
        });
    };

    onChangePage = (page, pageSize) => {
        let areaSearch = getAreaSearch();
        areaSearch.PageIndex = page;
        areaSearch.PageSize = pageSize;
        setAreaSearch(areaSearch);
        this.handleUpdateTable();
    };

    onFinish = (values) => {
        let areaSearch = getAreaSearch();
        areaSearch.Keyword = values.Keyword;
        areaSearch.PageIndex = 1;
        setAreaSearch(areaSearch);
        this.handleUpdateTable(areaSearch);
    };

    render() {
        const { areas, totalCount } = this.props;
        return (
            <div className="area-page-d">
                <div className="area-search-d">
                    <Form
                        name="horizontal_login"
                        initialValues={{ Keyword: "", HosType: "", AreaId: "" }}
                        layout="inline"
                        onFinish={this.onFinish}
                    >
                        <Form.Item name="Keyword">
                            <Input placeholder="Keyword" />
                        </Form.Item>
                        <Button style={{ background: "#2a274c", color: "#fff" }} htmlType="submit">
                            Tìm kiếm
                        </Button>
                    </Form>
                    <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.onAdd}>
                        Thêm mới
                    </Button>
                </div>
                <div className={"area-table-d"}>
                    <table border="0" className="table-admin">
                        <thead>
                            <tr>
                                <th>Cơ sở</th>
                                <th>Mô tả</th>
                                <th>Thứ tự</th>
                                <th>Trạng thái</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {areas?.map((area, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{area.name}</td>
                                        <td>{area.description}</td>
                                        <td>{area.order}</td>
                                        <td>
                                            {area.status === 1 ? (
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={`Xác nhận vô hiệu hóa ${area.name}`}
                                                    onConfirm={() => this.onInactive(area.id)}
                                                    okText="Ok"
                                                    cancelText="Cancel"
                                                >
                                                    <Checkbox checked={true}></Checkbox>
                                                </Popconfirm>
                                            ) : (
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={`Xác nhận kích hoạt ${area.name}`}
                                                    onConfirm={() => this.onActive(area.id)}
                                                    okText="Ok"
                                                    cancelText="Cancel"
                                                >
                                                    <Checkbox checked={false}></Checkbox>
                                                </Popconfirm>
                                            )}
                                        </td>
                                        <td>
                                            {" "}
                                            <div className="tools">
                                                <Button onClick={() => this.onEdit(area.id, area.name)}>
                                                    <FontAwesome name="edit" />
                                                </Button>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={`Xác nhận xóa ${area.name}`}
                                                    onConfirm={() => this.onDelete(area.id)}
                                                    okText="Ok"
                                                    cancelText="Cancel"
                                                >
                                                    <Button>
                                                        <FontAwesome name="trash-alt" />
                                                    </Button>
                                                </Popconfirm>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="pagination-d">
                        <Pagination
                            defaultCurrent={3}
                            current={getAreaSearch().PageIndex}
                            total={totalCount}
                            showQuickJumper
                            showTitle={false}
                            defaultPageSize={getAreaSearch().PageSize}
                            onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        areas: state.area.areas,
        totalCount: state.area.totalCount,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAreaFromServer: (search) => dispatch(getAreaFromServer(search)),
        handleOpenModalApp: (modalData) => dispatch(handleOpenModalApp(modalData)),
        deleteAreaFromServer: (id) => dispatch(deleteAreaFromServer(id)),
        fetchDropdownAreas: () => dispatch(fetchDropdownAreas()),
        activeArea: (id) => dispatch(activeArea(id)),
        inactiveArea: (id) => dispatch(inactiveArea(id)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AreaPage);
