import React, { Component } from "react";
import { getListRoleFromServer, updateRoleToServer } from "../../reducers/doctorReducer";
import { connect } from "react-redux";
import { setRoles } from "../../reducers/doctorReducer";
import { setModalBasic } from "../../reducers/uiReducer";
import { Layout,Radio,Button, Popconfirm, Checkbox } from "antd";
const { Content, Footer, Header } = Layout;
class RolesContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //flagdisable: false,
            stateRole:[],// role thay đổi
            flagChangeRole: false,// cờ check thay đổi role
            roleOld:{},// role trước khi thay đổi
            roleNew:{},// role sau khi thay đổi
        };
      }
    componentDidMount = async () => {
        const { doctorId } = this.props;
        await this.props.getListRoleFromServer(doctorId);
        const { roles } = this.props;
        let roleold = {};
        if(roles.length > 0){
            roleold = roles.find(element => element.selected == true && element.name != "DoctorOnlyRead" &&element.name != "DoctorApproved" && element.name != "Admin" && element.name != "DoctorsConsultation" && element.name != "Technician" && element.name != "SuperAdmin");
        }
        this.setState({stateRole : roles,roleOld : roleold});
        // roles.forEach((item) => {
        //     if(item.name != "DoctorOnlyRead" &&item.name != "DoctorApproved" && item.name != "Admin" && item.name != "DoctorsConsultation" && item.name != "Technician")
        //     {
        //         if (item.selected === true) {
        //             this.setState({flagdisable : true});
        //         }
        //     }
        // });
    };
    componentDidUpdate = (prevProps) => {
        if (prevProps.doctorId !== this.props.doctorId) {
            const { doctorId } = this.props;
            this.props.getListRoleFromServer(doctorId);
        }
    };
    onChange = (id, name) => {
        let {stateRole,roleOld} = this.state;
        let roleNew = stateRole;
        //let checkDis = false;
        if(name == "DoctorOnlyRead" || name == "DoctorApproved" || name == "Admin" || name == "DoctorsConsultation" || name == "Technician" || name == "SuperAdmin")
        {
            roleNew.forEach((item) => {
                if (item.id === id) {
                    item.selected = !item.selected;
                }
            });
        }
        else{
            roleNew.forEach((item) => {
                if(item.name != "DoctorOnlyRead" && item.name != "DoctorApproved" && item.name != "Admin" && item.name != "DoctorsConsultation" && item.name != "Technician" && item.name != "SuperAdmin")
                {
                    if (item.id === id) {
                        item.selected = !item.selected;
                    }
                    else{
                        item.selected = false
                    }
                }
            });
            let count = 0;
            if(roleOld){
                if(roleOld.name !== name)
                {
                    count++;
                }
                if(count > 0)
                {
                    this.setState({flagChangeRole : true, roleNew:name});
                }
                else
                {
                    this.setState({flagChangeRole : false});
                }
            }
            else
            {
                this.setState({flagChangeRole : true,roleNew:name});
            }
        }
        
        
        
        this.setState({stateRole : roleNew});
        // console.log(roleNew, "roleNew")
        // this.props.setRoles(roleNew);
        // if(checkDis === false){
        //     this.setState({flagdisable : false});
        // }
        // else{
        //     this.setState({flagdisable : true});
        // }
    };
    onSubmit = () => {
        const { doctorId } = this.props;
        let {stateRole} = this.state;
        this.props.updateRoleToServer(doctorId, stateRole).then(() => {
            this.props.getListRoleFromServer(doctorId);
        });
        this.props.setModalBasic({ visible: false });
    };
    render() {
        const { doctorName } = this.props;
        let { flagChangeRole } = this.state;
        let {stateRole, roleOld, roleNew} = this.state;
        let nameRoleOld = ""; 
        if(roleOld){
            nameRoleOld = roleOld.name;
        }
        return (
            <Layout className="doctor-add-form-content">
                <Header>
                    <p className="modalBasic-title">Phân quyền bác sĩ</p>
                    <button
                        className="modalBasic-btn-close"
                        onClick={() => this.props.setModalBasic({ visible: false })}
                    >
                        <i className="fas fa-times icon"></i>
                    </button>
                </Header>
                <Content>
                    <div className="roles-content-d doctor-change-roles">
                        <h2>{doctorName}</h2>
                        <h1>Quyền hệ thống</h1>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {stateRole?.map((role) => (
                                (role.name == "DoctorOnlyRead" ||role.name == "DoctorApproved" || role.name == "Admin" || role.name == "DoctorsConsultation" || role.name == "Technician" || role.name == "SuperAdmin") &&
                                    <div className="role" key={role.id}>
                                    <Checkbox 
                                        style={{padding: 15,fontSize:16}}
                                        key={role.id}
                                        checked={role.selected}
                                        onChange={() => this.onChange(role.id,role.name)}
                                    >
                                        {role.name}
                                    </Checkbox>
                                </div>
                            ))}
                        </div>
                        <h1>Quyền tùy chỉnh</h1>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {stateRole?.map((role) => (
                                (role.name != "DoctorOnlyRead" &&role.name != "DoctorApproved" && role.name != "Admin" && role.name != "DoctorsConsultation" && role.name != "Technician" && role.name != "SuperAdmin" ) &&
                                    <div className="role" key={role.id}>
                                    <Checkbox
                                        style={{padding: 15,fontSize:16}}
                                        key={role.id}
                                        checked={role.selected}
                                        onChange={() => this.onChange(role.id, role.name)}
                                        // disabled={flagdisable === true && role.selected === false}
                                    >
                                        {role.name}
                                    </Checkbox>
                                </div>
                            ))}
                        </div>
                    </div>
                </Content>
                <Footer>
                    {flagChangeRole === true ?
                        <Popconfirm
                            placement="top"
                            title={<div>Xác nhận thay đổi từ quyền <b>{nameRoleOld}</b> sang <b>{roleNew}</b></div>}
                            onConfirm={() => this.onSubmit()}
                            okText="Ok"
                            cancelText="Cancel"
                        >
                            <Button
                                style={{ color: "#fff", background: "#2687d4"  }}
                                type="primary"
                            >
                                Thay đổi
                            </Button>
                        </Popconfirm>
                    :
                        <Button
                            style={{ color: "#fff", background: "#2687d4"  }}
                            type="primary"
                            onClick={() =>  this.onSubmit()}
                        >
                            Thay đổi
                        </Button>
                    }
                </Footer>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        roles: state.doctor.roles,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getListRoleFromServer: (doctorId) => dispatch(getListRoleFromServer(doctorId)),
        setRoles: (roles) => dispatch(setRoles(roles)),
        updateRoleToServer: (doctorId, roles) => dispatch(updateRoleToServer(doctorId, roles)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RolesContent);
