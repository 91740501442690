import React, { Component } from "react";
import { UploadOutlined } from '@ant-design/icons';
import { Form, Input, Radio, DatePicker, Button, Layout,Upload, Col, Row } from "antd";
import {
    deaultPrintResultForm,
    getPrintResultSearch,
    printResultDefault,
} from "../../pages/Home/PrintResultPage/printresult";
import { addPrintResultToServer, getPrintResultFromServer } from "../../reducers/printresultReducer";
import { connect } from "react-redux";
import { handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
const { Content, Footer, Header } = Layout;

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};





class AddFormContent extends Component {

    state = {
        fileList: [],
        uploading: false,
    };

    onFinish = (values) => {
        const { fileList } = this.state;
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('file', file);
        });
        for ( var key in values ) {
            if(!values[key]){
                values[key] = "";
            }
            formData.append(key, values[key]);
        }
        this.setState({
            uploading: true,
        });
        this.props.addPrintResultToServer(formData).then(() => {
            this.props.setModalBasic({ visible: false });
            this.setState({
                fileList: [],
                uploading: false,
            });
            this.props.getPrintResultFromServer(getPrintResultSearch());
        });
    };
    
    handleUpload = () => {
        
    };

    render() {
        const { uploading, fileList } = this.state;
        const propsUpload = {
            name: 'file',
            multiple: false,
            listType:'picture',
            showUploadList: {
                showDownloadIcon: false,
            },
            onRemove: file => {
                this.setState(state => {
                    return {
                        fileList: []
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [file]
                }))
                return false;
            },
            fileList
        }
        return (
            <Layout className="doctor-add-form-content">
                <Header>
                    <p className="modalBasic-title">Thêm mới mẫu in</p>
                    <button
                        className="modalBasic-btn-close"
                        onClick={() => this.props.setModalBasic({ visible: false })}
                    >
                        <i className="fas fa-times icon"></i>
                    </button>
                </Header>
                <Content>
                    <Form
                        style={{ marginTop: 20 }}
                        {...formItemLayout}
                        name="addPrintResult"
                        onFinish={this.onFinish}
                        //initialValues={printResultDefault}
                        scrollToFirstError
                    >
                        <Form.Item name="name" label="Tên" rules={[{ required: true, message:"vui lòng nhập tên!" }]}>
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="pTitle" label="Tiêu đề 1">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="pTitle2" label="Tiêu đề 2">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="pHospital" label="Bệnh viện">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="pDepartment" label="Khoa phòng">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="pAddress" label="Địa chỉ">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="pPhone" label="Điện thoại">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="pNote" label="Ghi chú">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="template" label="Mẫu Layout">
                                <Input allowClear />
                        </Form.Item>
                        <div className="ant-row ant-form-item">
                            <div className="ant-col ant-col-6 ant-form-item-label">
                               <label title="Logo">Logo</label>
                            </div>
                            <div className="ant-col ant-col-14 ant-form-item-control">
                                <Upload className="upload-list-inline" {...propsUpload}>
                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </div>
                        </div>
                        <Button style={{ display: 'none' }} id="btn-submit-add-printresult" htmlType={"submit"}>
                            Thêm mới
                        </Button>
                    </Form>
                </Content>
                <Footer>
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            document.getElementById("btn-submit-add-printresult").click();
                        }}
                    >
                        Thêm mới
                    </Button>
                </Footer>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        addPrintResultToServer: (printresult) => dispatch(addPrintResultToServer(printresult)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        getPrintResultFromServer: (search) => dispatch(getPrintResultFromServer(search)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFormContent);
