import React, { Component } from "react";
import "./AppBar.scss";
import { Button, Dropdown, Menu } from "antd";
var FontAwesome = require("react-fontawesome");
class AppBar extends Component {
    render() {
        return (
            <div className="app-bar-d">
                <div className="left-d"></div>
                <div className="center-d"></div>
                <div className="right-d">
                    <Dropdown
                        overlay={
                            <Menu style={{ color: "black", fontFamily: "Roboto" }}>
                                <Menu.Item onClick={this.onLogout}>
                                    <i className="fas fa-key" style={{ minWidth: "30px" }}></i> Đăng xuất
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={["click"]}
                    >
                        <Button shape="circle" className="btn-acc-d">
                            <FontAwesome name="user-circle" style={{ fontSize: 34, color: "orangered" }} />
                        </Button>
                    </Dropdown>
                </div>
            </div>
        );
    }
}

export default AppBar;
