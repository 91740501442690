import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, message, Checkbox, Select, Layout, InputNumber } from "antd";
import { deaultServerPacsForm, getServerPacsSearch } from "../../pages/Home/ServerPacsPage/serverpacs";
import { addServerPacsToServer, getDropdownModalityTypeFromServer, getServerPacsFromServer } from "../../reducers/serverpacsReducer";
import { connect } from "react-redux";
import { handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
import { removeVietnameseTones } from "../../utils";

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

const { Content, Footer, Header } = Layout;

const { Option } = Select;

class AddFormContent extends Component {
    componentDidMount = async () => {
        this.props.getDropdownModalityTypeFromServer();
    };
    onFinish = (values) => {
        values.startMode = parseInt(values.startMode);
        values.state = parseInt(values.state);
        this.props.addServerPacsToServer(values).then(() => {
            this.props.getServerPacsFromServer(getServerPacsSearch());
            this.props.setModalBasic({ visible: false });
        });
    };
    
    render() {
        const { dropdownHospital, dropdownPrintHeader,dropdownModalityType } = this.props;

        return (
            <Layout className="">
                <Header>
                    <p className="modalBasic-title">Thêm mới máy chụp</p>
                    <button
                        className="modalBasic-btn-close"
                        onClick={() => this.props.setModalBasic({ visible: false })}
                    >
                        <i className="fas fa-times icon"></i>
                    </button>
                </Header>
                <Content>
                    <Form
                        style={{ marginTop: 20 }}
                        {...formItemLayout}
                        name="addServerPacs"
                        onFinish={this.onFinish}
                        initialValues={{
                            hospitalID: dropdownHospital[0].id,
                            headerID: dropdownPrintHeader[0].id,
                        }}
                        scrollToFirstError
                    >
                        <Form.Item name="name" label="Tên" rules={[{ required: true, message:"vui lòng nhập tên!" }]}>
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="fullName" label="Tên đầy đủ" rules={[{ required: true, message:"vui lòng nhập tên đầy đủ!" }]}>
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="room" label="Phòng">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="loginName" label="LoginName">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="password" label="Password">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="startMode" label="StartMode">
                            <InputNumber allowClear/>
                        </Form.Item>
                        <Form.Item name="state" label="State">
                            <InputNumber allowClear/>
                        </Form.Item>
                        <Form.Item name="errorCode" label="ErrorCode">
                            <InputNumber allowClear/>
                        </Form.Item>
                        <Form.Item name="requestFromIP" label="RequestFromIP">
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="modality" label="Modality" rules={[{ required: true, message:"vui lòng nhập modality!" }]}>
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="modalityType" label="ModalityType" rules={[{ required: true, message:"vui lòng nhập modalityType!" }]}>
                            <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    filterOption={(input, option) =>
                                        {
                                            option.children = removeVietnameseTones(option.children);
                                            input = removeVietnameseTones(input);
                                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    }
                                >
                                    {dropdownModalityType.map((item) => (
                                    <Option value={item} key={item}>{item}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="mapModality" label="Map Modality" rules={[{ required: true, message:"vui lòng nhập mapModality!" }]}>
                            <Input allowClear />
                        </Form.Item>
                        <Form.Item name="machineCode" label="MachineCode">
                            <Input allowClear />
                        </Form.Item>
                        {/* <Form.Item name="requestFor" label="RequestFor" rules={[{ required: true }]}>
                            <InputNumber min={0} max={3} />
                        </Form.Item>
                        <Form.Item name="applyFor" label="ApplyFor" rules={[{ required: true }]}>
                            <InputNumber min={0} max={3} />
                        </Form.Item>
                        <Form.Item name="isStore" label="IsStore" valuePropName="checked">
                            <Checkbox />
                        </Form.Item>
                        <Form.Item name="wlResultAll" label="WLResultAll" valuePropName="checked">
                            <Checkbox />
                        </Form.Item> */}
                        <Form.Item name="hospitalID" label="Chọn Bệnh viện" valuePropName="value">
                            <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    filterOption={(input, option) =>
                                        {
                                            option.children = removeVietnameseTones(option.children);
                                            input = removeVietnameseTones(input);
                                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    }
                                >
                                    {dropdownHospital.map((item) => (
                                    <Option value={item.id} key={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="headerID" label="Chọn Mẫu in" valuePropName="value">
                            <Select>
                                {dropdownPrintHeader.map((item) => (
                                    <Option value={item.id} key={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <div style={{ display: "none" }}>
                            <div style={{ flex: 1 }}></div>
                            <div style={{ flex: 3 }}>
                                <Button style={{ color: "#fff", background: "#2687d4" }} id="btn-submit-edit-serverpacs" htmlType={"submit"}>
                                    Thêm mới
                        </Button>
                            </div>
                        </div>
                    </Form>
                </Content>
                <Footer>
                    <Button
                        style={{ color: "#fff", background: "#2687d4" }}
                        onClick={() => document.getElementById("btn-submit-edit-serverpacs").click()}
                        htmlType={"submit"}
                    >
                        Lưu lại
                    </Button>
                </Footer>
            </Layout>

        );
    }
}

function mapStateToProps(state) {
    return {
        dropdownHospital: state.serverpacs.dropdownHospital,
        dropdownPrintHeader: state.serverpacs.dropdownPrintHeader,
        dropdownModalityType:state.serverpacs.dropdownModalityType,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addServerPacsToServer: (serverpacs) => dispatch(addServerPacsToServer(serverpacs)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        getServerPacsFromServer: (search) => dispatch(getServerPacsFromServer(search)),
        getDropdownModalityTypeFromServer: () => dispatch(getDropdownModalityTypeFromServer()),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFormContent);
