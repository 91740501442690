import moment from "moment";
let areaSeach = {
    Keyword: "",
    PageIndex: 1,
    PageSize: 10,
};

const getAreaSearch = () => {
    return areaSeach;
};

const setAreaSearch = (search) => {
    areaSeach = search;
};

export { setAreaSearch, getAreaSearch };