import React, { Component } from "react";
import { connect } from "react-redux";
import "./ModalBasic.scss";

class ModalApp extends Component {
    closeModal = (e) => {
        this.props.handleCloseModalApp();
    };

    render() {
        const { visible, width, content, height, name } = this.props;
        return (
            <div className={`modalBasic modalBasic-2 ${visible ? "modalBasic--visible" : "modalBasic--hidden"}`}>
                <div className="modalBasic__overlay" ></div>
                <div className={`modalBasic__main ${name}`} style={{width, height}}>{content}</div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        visible: state.modalBasic2.visible,
        content: state.modalBasic2.content,
        name: state.modalBasic2.name,
        width: state.modalBasic2.width,
        height: state.modalBasic2.height,
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalApp);
