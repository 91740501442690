import Axios from "axios";
import { message } from "antd";
const ACTION_TYPES = {
    SET_PATIENT: "SET_PATIENT",
    SET_PATIENT_ITEM: "SET_PATIENT_ITEM",
};

const initialstate = {
    patients: [],
    totalCount: 0,
    patientItem: {},
};

const patientReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_PATIENT:
            state = { ...state };
            state.patients = action.payload.data;
            state.totalCount = action.payload.dataCount;
            return { ...state };
        case ACTION_TYPES.SET_PATIENT_ITEM:
            state.patientItem = action.payload;
            return { ...state };
        default:
            return state;
    }
};
const setPatients = (patients) => {
    return {
        type: ACTION_TYPES.SET_PATIENT,
        payload: patients,
    };
};
const getPatientFromServer = (request) => async (dispatch) => {
    const response = await Axios.get(
        `api/Patients/paging?Keyword=${request.Keyword}&Age=${request.Age}&Sex=${request.Sex}&PageIndex=${request.PageIndex}&PageSize=${request.PageSize}`
    );
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setPatients(response.data));
        }
        
    } else {
        message.error(response.status);
    }
};

const setPatientItem = (patient) => {
    return {
        type: ACTION_TYPES.SET_PATIENT_ITEM,
        payload: patient,
    };
};
const getPatientById = (id) => async (dispatch) => {
    const response = await Axios.get(`api/Patients/${id}`);
    if (response.data.status === 1) {
        dispatch(setPatientItem(response.data.data));
    }
    
};

const updatePatientToServer = (id, patient) => async (dispatch) => {
    await Axios.put(`api/Patients/${id}`, patient)
        .then((response) => {
            if (response.data) {
                if (response.data.status !== 1) {
                    message.error(response.data.message);
                } else {
                    message.success(response.data.message);
                }
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let messages = "";
                if (e.Email) {
                    for (let i of e.Email) {
                        messages += i + "! ";
                    }
                }
                if (e.PhoneNo) {
                    for (let i of e.PhoneNo) {
                        messages += i + "! ";
                    }
                }
                if (e.Name) {
                    for (let i of e.Name) {
                        messages += i + "! ";
                    }
                }
                message.error(messages, 5);
            }
        });
};

const addPatientToServer = (request) => async (dispatch) => {
    await Axios.post("api/Patients/register", request)
        .then((response) => {
            if (response.status === 200) {
                if (response.data.status !== 1) {
                    message.error(response.data.message);
                } else {
                    message.success(response.data.message);
                }
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let mes = "";
                if (e.Email) {
                    for (let i of e.Email) {
                        mes += i + ", ";
                    }
                }
                if (e.Password) {
                    for (let i of e.Password) {
                        mes += i + ", \n";
                    }
                }
                if (e.UserName) {
                    for (let i of e.UserName) {
                        mes += i + ", \n";
                    }
                }
                if (e.ConfirmPassword) {
                    for (let i of e.ConfirmPassword) {
                        mes += i + ", \n";
                    }
                }
                message.error(mes, 5);
            }
        });
};

const deletePatientFromServer = (id) => async (dispatch) => {
    await Axios.delete(`api/Patients/${id}`)
        .then((response) => {
            message.success(response.data.message);
        })
        .catch((err) => {
            message.error("Bạn không có quyền xóa Bác sĩ này!");
        });
};
export {
    addPatientToServer,
    deletePatientFromServer,
    getPatientFromServer,
    patientReducer,
    getPatientById,
    updatePatientToServer,
};
