import moment from "moment";

let logSeach = {
    fromDate: new Date().toISOString().substr(0, 10),//new Date("01-01-2015").toISOString().substr(0, 10),
    toDate: new Date().toISOString().substr(0, 10),
    
};
const initialLogSeach = {
    fromDate: moment(),//moment("01-01-2015"),
    toDate: moment(),
};
const getLogSearch = () => {
    return logSeach;
};

const setLogSearch = (search) => {
    logSeach = search;
};

export { initialLogSeach, getLogSearch, setLogSearch };
