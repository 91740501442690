import moment from "moment";

let serverpacsSeach = {
    fromDate: new Date("01-01-2015").toISOString().substr(0, 10),
    toDate: new Date().toISOString().substr(0, 10),
    Keyword: "",
    Code: "",
    pCode: "",
    Modality: "",
    ExistsOnHis: "",
    RequestFor:"",
    RequestFromIp:"",
    PageIndex: 1,
    PageSize: 10,
};
const initialServerPacsSeach = {
    fromDate: moment("01-01-2015"),
    toDate: moment(),
    Keyword: "",
    Code: "",
    pCode: "",
    Modality: "",
    ExistsOnHis: "",
    PageIndex: 1,
    PageSize: 10,
};

const getServerPacsSearch = () => {
    return serverpacsSeach;
};

const setServerPacsSearch = (search) => {
    serverpacsSeach = search;
};

export { initialServerPacsSeach, getServerPacsSearch, setServerPacsSearch };
