import Axios from "axios";
import { queryString } from "../utils/queryString";
import { message } from "antd";
const ACTION_TYPES = {
    SET_REPORTS: "SET_REPORTS",
    SET_ROLES: "SET_ROLES",
    SET_REPORT_ITEM: "SET_REPORT_ITEM",
};

const initialstate = {
    reports: [],
    totalCount: 0,
    roles: [],
    reportItem: {},
};

const reportReducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_REPORTS:
            state = { ...state };
            state.reports = [...action.payload.data];
            state.totalCount = action.payload.dataCount;
            return { ...state };
        case ACTION_TYPES.SET_ROLES:
            state.roles = action.payload;
            return { ...state };
        case ACTION_TYPES.SET_REPORT_ITEM:
            state.reportItem = action.payload;
            return { ...state };
        default:
            return state;
    }
};
const setReports = (reports) => {
    return {
        type: ACTION_TYPES.SET_REPORTS,
        payload: reports,
    };
};
const getReportFromServer = (request) => async (dispatch) => {
    const { fromDate, toDate, Keyword, Code, pCode, Modality, ExistsOnHis, PageIndex, PageSize } = request;
    let url = "api/Studies/paging?";
    url += `fromDate=${fromDate}&`;
    url += `toDate=${toDate}&`;
    url += `Keyword=${Keyword}&`;
    url += `Code=${Code}&`;
    url += `pCode=${pCode}&`;
    url += `Modality=${Modality}&`;
    url += `ExistsOnHis=${ExistsOnHis}&`;
    url += `PageIndex=${PageIndex}&`;
    url += `PageSize=${PageSize}`;
    const response = await Axios.get(url);
    if (response.status === 200) {
        // response.data.data.forEach((item, index) => {
        //     item.stt = index + 1;
        // });
        if (response.data.status === 1) {
            dispatch(setReports(response.data));
        }
        
    } else {
        message.error(response.status);
    }
};

const setRoles = (roles) => {
    return {
        type: ACTION_TYPES.SET_ROLES,
        payload: roles,
    };
};
const getListRoleFromServer = (request) => async (dispatch) => {
    const response = await Axios.get(`api/Users/${request}/roles`);
    if (response.status === 200) {
        if (response.data.status === 1) {
            dispatch(setRoles(response.data.data.listRoles));
        }
        
    }
};
const updateRoleToServer = (reportId, roles) => async (dispatch) => {
    const response = await Axios.put(`api/Users/${reportId}/roles`, { roles: roles });
    if (response.status === 200) {
        message.success("Update Thành công!");
    } else {
        message.error("Update Thất bại!", response.status);
    }
};
const setReportItem = (report) => {
    return {
        type: ACTION_TYPES.SET_REPORT_ITEM,
        payload: report,
    };
};
const getReportById = (id) => async (dispatch) => {
    const response = await Axios.get(`api/Users/${id}`);
    if (response.data.status === 1) {
        dispatch(setReportItem(response.data.data));
    }
    
};

const updateReportToServer = (id, report) => async (dispatch) => {
    await Axios.put(`api/Users/${id}`, report)
        .then((response) => {
            if (response.data) {
                message.success(response.data.message);
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let messages = "";
                if (e.Email) {
                    for (let i of e.Email) {
                        messages += i + "\n";
                    }
                }
                message.error(messages, 3);
            }
        });
};

const addReportToServer = (request) => async (dispatch) => {
    await Axios.post("api/Users/register", request)
        .then((response) => {
            if (response.status === 200) {
                if (response.data.status !== 1) {
                    message.error(response.data.message);
                } else {
                    message.success(response.data.message);
                }
            }
        })
        .catch((err) => {
            if (err.response) {
                const e = err.response.data.errors;
                let mes = "";
                if (e.Email) {
                    for (let i of e.Email) {
                        mes += i + ", ";
                    }
                }
                message.error(mes, 3);
            }
        });
};

const deleteReportFromServer = (id) => async (dispatch) => {
    await Axios.delete(`api/Users/${id}`)
        .then((response) => {
            message.success(response.data.message);
        })
        .catch((err) => {
            message.error("Bạn không có quyền xóa Bác sĩ này!");
        });
};
export {
    addReportToServer,
    deleteReportFromServer,
    getReportFromServer,
    reportReducer,
    getListRoleFromServer,
    setRoles,
    updateRoleToServer,
    getReportById,
    updateReportToServer,
};
