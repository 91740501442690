import Axios from "axios";
import { queryString } from "../utils/queryString";
import { message } from "antd";
import {
  ADD_DOCTOR_SUCCESS,
  setStatusAdd,
} from "../pages/Home/DoctorPage/doctor";
import { store } from "../store/store";
import { setModalBasic } from "./uiReducer";
const ACTION_TYPES = {
  SET_DOCTORS: "SET_DOCTORS",
  SET_ROLES: "SET_ROLES",
  SET_DOCTOR_ITEM: "SET_DOCTOR_ITEM",
  SET_DROPDOWN_ROLES: "SET_DROPDOWN_ROLES",
  SET_DROPS_ROLES:"SET_DROPS_ROLES",
  SET_DROPDOWN_POSITION:"SET_DROPDOWN_POSITION",
  SET_DROPDOWN_POSITION_TITTLE:"SET_DROPDOWN_POSITION_TITTLE",
  SET_ISVERMED:"SET_ISVERMED"
};

const initialstate = {
  doctors: [],
  totalCount: 0,
  roles: [],
  doctorItem: {},
  dropdownRoles: [],
  listGroupRoles:[],
  dropdownPosition:[],
  dropdownPositionTittle:[],
  isvermed:false,
};

const doctorReducer = (state = initialstate, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_DOCTORS:
      state = { ...state };
      state.doctors = action.payload.data;
      state.totalCount = action.payload.dataCount;
      return { ...state };
    case ACTION_TYPES.SET_ROLES:
      state.roles = action.payload;
      return { ...state };
    case ACTION_TYPES.SET_DROPS_ROLES:
      state.listGroupRoles = action.payload;
      return { ...state };
    case ACTION_TYPES.SET_DOCTOR_ITEM:
      state.doctorItem = action.payload;
      return { ...state };
    case ACTION_TYPES.SET_DROPDOWN_ROLES:
      state.dropdownRoles = action.payload;
      return { ...state };
    case ACTION_TYPES.SET_DROPDOWN_POSITION:
      state.dropdownPosition = action.payload;
      return { ...state };
    case ACTION_TYPES.SET_DROPDOWN_POSITION_TITTLE:
      state.dropdownPositionTittle = action.payload;
      return { ...state };
    case ACTION_TYPES.SET_ISVERMED:
      state.isvermed = action.payload;
      return { ...state };
    default:
      return state;
  }
};
const setDoctors = (doctors) => {
  return {
    type: ACTION_TYPES.SET_DOCTORS,
    payload: doctors,
  };
};
const getDoctorFromServer = (request) => async (dispatch) => {
  const response = await Axios.get(
    `api/Users/paging?Keyword=${request.Keyword}&RoleName=${request.RoleName}&PageIndex=${request.PageIndex}&PageSize=${request.PageSize}`
  );
  if (response.status === 200) {
    // response.data.data.forEach((item, index) => {
    //     item.stt = index + 1;
    // });
    if (response.data.status === 1) {
      dispatch(setDoctors(response.data));
    }
  } else {
    message.error(response.status);
  }
};

const setRoles = (roles) => {
  return {
    type: ACTION_TYPES.SET_ROLES,
    payload: roles,
  };
};

const setDropRoles = (roles) => {
  return {
    type: ACTION_TYPES.SET_DROPS_ROLES,
    payload: roles,
  };
};


const setDropdownRoles = (roles) => {
  return {
    type: ACTION_TYPES.SET_DROPDOWN_ROLES,
    payload: roles,
  };
};

const setDropdownPosition = (position) => {
  return {
    type: ACTION_TYPES.SET_DROPDOWN_POSITION,
    payload: position,
  };
};

const setDropdownPositionTittle = (positionTittle) => {
  return {
    type: ACTION_TYPES.SET_DROPDOWN_POSITION_TITTLE,
    payload: positionTittle,
  };
};
const setIsvermed = (isvermed) => {
  return {
    type: ACTION_TYPES.SET_ISVERMED,
    payload: isvermed,
  };
};

const getListRoleFromServer = (request) => async (dispatch) => {
  const response = await Axios.get(`api/Users/${request}/roles`);
  if (response.status === 200) {
    if (response.data.status === 1) {
      dispatch(setRoles(response.data.data.listRoles));
    }
  }
};

const getDropdownRoles = (request) => async (dispatch) => {
  const response = await Axios.get(`api/Users/dropdown-roles`);
  if (response.status === 200) {
    if (response.data.status === 1) {
      dispatch(setDropRoles(response.data.data));
    }
  }
};
const getDropdownPosition = () => async (dispatch) => {
  const response = await Axios.get(`api/Users/dropdown-position`);
  if (response.status === 200) {
    if (response.data.status === 1) {
      dispatch(setDropdownPosition(response.data.data));
    }
  }
};

const getDropdownPositionTittle = () => async (dispatch) => {
  const response = await Axios.get(`api/Users/dropdown-position-tittle`);
  if (response.status === 200) {
    if (response.data.status === 1) {
      dispatch(setDropdownPositionTittle(response.data.data));
    }
  }
};
const checkVermed = () => async (dispatch) => {
  const response = await Axios.get(`api/Users/check-vermed`);
  if (response.status === 200) {
    if (response.data.status === 1) {
      dispatch(setIsvermed(response.data.data));
    }
  }
};
const WritePositionMed = (data) => async(dispatch) => {
  await Axios.post(`/api/Users/write-position-med`, data).then((response) => {
      if (response.data.status === 1) {
          message.success(response.data.message);
      } else {
          message.error(response.data.message);
      }
  });
};
const WritePositionTittleMed = (data) => async(dispatch) => {
  await Axios.post(`/api/Users/write-position-tittle-med`, data).then((response) => {
      if (response.data.status === 1) {
          message.success(response.data.message);
      } else {
          message.error(response.data.message);
      }
  });
};

const addUserWithExcel = (request) => async (dispatch) => {
  const response = await Axios.post(`api/AddUser`, request);
  if (response.status === 200) {
    if (response.data.status === 1) {
      message.success("Successfully! ");
    }
    else{
      message.error(response.data.message);
    }
  } else {
    message.error(`Update Thất bại!, ${response.message}`, response.status);
  }
  store.dispatch(setModalBasic({ visible: false }));
};

const getRoles = () => async (dispatch) => {
  const response = await Axios.get(`api/Users/roles`);
  if (response.status === 200) {
    if (response.data.status === 1) {
      dispatch(setDropdownRoles(response.data.data));
    }
  }
};

const updateRoleToServer = (doctorId, roles) => async (dispatch) => {
  const response = await Axios.put(`api/Users/${doctorId}/roles`, {
    roles: roles,
  });
  if (response.status === 200) {
    message.success("Update Thành công!");
  } else {
    message.error("Update Thất bại!", response.status);
  }
};
const setDoctorItem = (doctor) => {
  return {
    type: ACTION_TYPES.SET_DOCTOR_ITEM,
    payload: doctor,
  };
};
const getDoctorById = (id) => async (dispatch) => {
  const response = await Axios.get(`api/Users/${id}`);
  if (response.data.status === 1) {
    dispatch(setDoctorItem(response.data.data));
  }
};

const updateDoctorToServer = (id, doctor) => async (dispatch) => {
  await Axios.put(`api/Users/${id}`, doctor)
    .then((response) => {
      if (response.data) {
        if (response.data.status === 1) {
          message.success(response.data.message);
          setStatusAdd(true);
        } else {
          message.error(response.data.message);
          setStatusAdd(false);
        }
      }
    })
    .catch((err) => {
      setStatusAdd(false);
      if (err.response) {
        let messages = "";
        if (err.response.data.errors.Email) {
          for (let i of err.response.data.errors.Email) {
            messages += i + "\n";
          }
        }
        message.error(messages, 5);
      }
    });
};

const addDoctorToServer = (request) => async (dispatch) => {
  await Axios.post("api/Users/register", request)
    .then((response) => {
      if (response.status === 200) {
        if (response.data.status !== 1) {
          message.error(response.data.message);
          setStatusAdd(false);
        } else {
          setStatusAdd(true);
          message.success(response.data.message);
        }
      }
    })
    .catch((err) => {
      setStatusAdd(false);
      if (err.response) {
        const e = err.response.data.errors;
        let mes = "";
        if (e.Email) {
          for (let i of e.Email) {
            mes += i + ", ";
          }
        }
        if (e.Password) {
          for (let i of e.Password) {
            mes += i + ", \n";
          }
        }
        if (e.UserName) {
          for (let i of e.UserName) {
            mes += i + ", \n";
          }
        }
        if (e.ConfirmPassword) {
          for (let i of e.ConfirmPassword) {
            mes += i + ", \n";
          }
        }
        message.error(mes, 5);
      }
    });
};

const deleteDoctorFromServer = (id) => async (dispatch) => {
  await Axios.delete(`api/Users/${id}`)
    .then((response) => {
      message.success(response.data.message);
    })
    .catch((err) => {
      message.error("Bạn không có quyền xóa Bác sĩ này!");
    });
};

const resetPasswordFromServer = (id) => async (dispatch) => {
  await Axios.put(`api/Users/${id}/reset-password`)
    .then((response) => {
      if (response.data.status === 1) message.success(response.data.message);
      else message.error(response.data.message);
    })
    .catch((err) => {
      message.error("Bạn không có quyền ĐẶT LẠI MẬT KHÂỦ cho tài khoản này!");
    });
};

const lockUser = (id) => async (dispatch) => {
  await Axios.put(`/api/Users/${id}/lock-user`)
    .then((response) => {
      if (response.data.status === 1) message.success(response.data.message);
      else message.error(response.data.message);
    })
    .catch((err) => {
      message.error("Bạn không có quyền khóa tài khoản này!");
    });
};

const unlockUser = (id) => async (dispatch) => {
  await Axios.put(`/api/Users/${id}/unlock-user`)
    .then((response) => {
      if (response.data.status === 1) message.success(response.data.message);
      else message.error(response.data.message);
    })
    .catch((err) => {
      message.error("Bạn không có quyền mở khóa tài khoản này!");
    });
};


export {
  addDoctorToServer,
  deleteDoctorFromServer,
  getDoctorFromServer,
  doctorReducer,
  getListRoleFromServer,
  setRoles,
  updateRoleToServer,
  getDoctorById,
  updateDoctorToServer,
  resetPasswordFromServer,
  lockUser,
  unlockUser,
  getRoles,
  addUserWithExcel,
  getDropdownRoles,
  getDropdownPositionTittle,
  getDropdownPosition,
  checkVermed,
  WritePositionMed,
  WritePositionTittleMed,
};
