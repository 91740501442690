const ACTION_TYPES = {
    SET_MODAL_BASIC_2: "SET_MODAL_BASIC_2",
};

const initialstate = {
    visible: false,
    content: null,
    width: 400,
    height: 300,
    name: "",
};

const modalBasic2Reducer = (state = initialstate, action) => {
    switch (action.type) {
        case ACTION_TYPES.SET_MODAL_BASIC_2:
            state = action.payload;
            return { ...state };
        default:
            return state;
    }
};

const setModalBasic2 = (data) => {
    return {
        type: ACTION_TYPES.SET_MODAL_BASIC_2,
        payload: data,
    };
};

export { modalBasic2Reducer, setModalBasic2 };
