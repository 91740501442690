import React, { Component } from "react";
import {
  Form,
  Input,
  Radio,
  DatePicker,
  Button,
  Layout,
  InputNumber,
  Select,
} from "antd";
import {
  deaultConfigurationForm,
  getConfigurationSearch,
} from "../../pages/Home/ConfigurationPage/configuration";
import {
  addConfigurationToServer,
  getConfigurationFromServer,
} from "../../reducers/configurationReducer";
import { connect } from "react-redux";
import { handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
import "./AddFormContent.scss";
const { Content, Footer, Header } = Layout;
const { Option } = Select;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};
class AddFormContent extends Component {
  state = {
    type: "text",
  };
  onFinish = (values) => {
    this.props.addConfigurationToServer({ ...values, status: 1 }).then(() => {
      this.props.handleCloseModalApp();
      this.props.getConfigurationFromServer(getConfigurationSearch());
    });
  };
  onChangeType = (e) => {
    this.setState({
      type: e.target.value,
    });
  };
  onFormatJson = (e) => {
    try {
      var ugly = document.getElementById("myTextArea").value;
      var obj = JSON.parse(ugly);
      var pretty = JSON.stringify(obj, undefined, 4);
      document.getElementById("myTextArea").value = pretty;
    } catch (error) {
      console.log(error);
    }
  };
  onRemoveWhiteSpace = (e) => {
    try {
      var ugly = document.getElementById("myTextArea").value;
      var pretty = ugly.replace(/\s+/g, " ").trim();
      document.getElementById("myTextArea").value = pretty;
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    let { type } = this.state;
    return (
      <>
        <Layout className="doctor-add-form-content">
          <Header>
            <p className="modalBasic-title">Thêm mới cấu hình</p>
            <button
              className="modalBasic-btn-close"
              onClick={() => this.props.setModalBasic({ visible: false })}
            >
              <i className="fas fa-times icon"></i>
            </button>
          </Header>
          <Content>
            <Form
              style={{ marginTop: 20 }}
              {...formItemLayout}
              name="addConfig"
              onFinish={this.onFinish}
              scrollToFirstError
            >
              <Form.Item name="configCode" label="Mã" rules={[{ required: true, message:"vui lòng nhập mã!" }]}>
                <Input allowClear />
              </Form.Item>
              <Form.Item className="contentNoMargin" label="Kiểu">
                <Radio.Group onChange={this.onChangeType} value={type}>
                  <Radio value="boolean">boolean</Radio>
                  <Radio value="text">text</Radio>
                </Radio.Group>
              </Form.Item>
              {type == "text" ? (
                <Form.Item className="contentNone" label=" ">
                  <div display="flex">
                    <Button
                      style={{ marginRight: 10 }}
                      type="primary"
                      shape="round"
                      size="normal"
                      onClick={() => this.onFormatJson()}
                    >
                      format
                    </Button>
                    <Button
                      type="primary"
                      shape="round"
                      size="normal"
                      onClick={() => this.onRemoveWhiteSpace()}
                    >
                      Remove white space
                    </Button>
                  </div>
                </Form.Item>
              ) : (
                ""
              )}
              <Form.Item name="configName" label="Nội dung" rules={[{ required: true, message:"vui lòng nhập nội dung!" }]}>
                {type == "text" ? (
                  <TextArea id="myTextArea" rows={8} />
                ) : (
                  <Select placeholder="">
                    <Option value="true">true</Option>
                    <Option value="false">false</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item name="configValue" label="Giá trị" rules={[{ required: true, message:"vui lòng nhập giá trị!" }]}>
                <InputNumber allowClear />
              </Form.Item>

              <Button
                style={{
                  color: "#fff",
                  background: "#2687d4",
                  display: "none",
                }}
                id="btn-submit-add-config"
                htmlType={"submit"}
              >
                Thêm mới
              </Button>
            </Form>
          </Content>
          <Footer>
            <Button
              style={{ color: "#fff", background: "#2687d4" }}
              onClick={() =>
                document.getElementById("btn-submit-add-config").click()
              }
            >
              Thêm mới
            </Button>
          </Footer>
        </Layout>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    addConfigurationToServer: (configuration) =>
      dispatch(addConfigurationToServer(configuration)),
    handleCloseModalApp: () => dispatch(handleCloseModalApp()),
    getConfigurationFromServer: (search) =>
      dispatch(getConfigurationFromServer(search)),
    setModalBasic: (data) => dispatch(setModalBasic(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFormContent);
