import React, { Component } from "react";
import { connect } from "react-redux";
import {setModalBasic } from "../../../reducers/uiReducer";
import ContentEdit from "../../../components/Area/ContentEdit";
import { Form, Input, Select, Popconfirm, Pagination, Button, Checkbox } from "antd";
import FontAwesome from "react-fontawesome";

import "./MediEquipmentPage.scss";
import { getMediEquipmentSearch, getTypeEquipMedi, setMediEquipmentSearch } from "./MediEquipment";
import { deleteMediEquipmentFromServer, getMediEquipmentFromServer } from "../../../reducers/mediEquipmentReducer";
import AddFormContentMedi from "../../../components/MediEquipment/AddFormContentMedi";
import ContentEditMedi from "../../../components/MediEquipment/ContentEditMedi";
const { Option } = Select;

class MediEquipmentPage extends Component {
    componentDidMount = () => {
        this.props.getMediEquipmentFromServer(getMediEquipmentSearch());
    };

    handleUpdateTable = () => {
        this.props.getMediEquipmentFromServer(getMediEquipmentSearch());
    };

    onEdit = (mediEquipmentId, Name) => {
        const modalData = {
            visible: true,
            width: 900,
            content: <ContentEditMedi mediEquipmentId={mediEquipmentId} Name={Name} />,
            name: "area-edit",
        };
        this.props.setModalBasic(modalData);
    };

    onAdd = () => {
        const modalData = {
            visible: true,
            width: 900,
            content: <AddFormContentMedi />,
            name: "area-add",
        };
        this.props.setModalBasic(modalData);
    };

    onDelete = (id) => {
        this.props.deleteMediEquipmentFromServer(id).then(() => {
            this.props.getMediEquipmentFromServer(getMediEquipmentSearch());
        });
    };

    onChangePage = (page, pageSize) => {
        let mediEquipment = getMediEquipmentSearch();
        mediEquipment.PageIndex = page;
        mediEquipment.PageSize = pageSize;
        setMediEquipmentSearch(mediEquipment);
        this.handleUpdateTable();
    };

    onFinish = (values) => {
        let mediEquipmentSearch = getMediEquipmentSearch();
        mediEquipmentSearch.Keyword = values.Keyword;
        mediEquipmentSearch.PageIndex = 1;
        setMediEquipmentSearch(mediEquipmentSearch);
        this.handleUpdateTable(mediEquipmentSearch);
    };

    render() {
        const { mediEquipments, totalCount } = this.props;
        const TypeEquipMedi = getTypeEquipMedi();
        return (
            <div className="area-page-d">
                <div className="area-search-d">
                    <Form
                        name="horizontal_login"
                        initialValues={{ Keyword: ""}}
                        layout="inline"
                        onFinish={this.onFinish}
                    >
                        <Form.Item name="Keyword">
                            <Input placeholder="Keyword" />
                        </Form.Item>
                        <Button style={{ background: "#2a274c", color: "#fff" }} htmlType="submit">
                            Tìm kiếm
                        </Button>
                    </Form>
                    <Button style={{ background: "#2a274c", color: "#fff" }} onClick={this.onAdd}>
                        Thêm mới
                    </Button>
                </div>
                <div className={"area-table-d"}>
                    <table border="0" className="table-admin">
                        <thead>
                            <tr>
                                <th>Tên</th>
                                <th>Loại</th>
                                <th>giá</th>
                                <th>Trạng thái</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {mediEquipments?.map((mediEquipment, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{mediEquipment.name}</td>
                                        <td>{TypeEquipMedi[mediEquipment.typeEquip].name}</td>
                                        <td>{mediEquipment.price}</td>
                                        <td>
                                            <input type="checkbox" checked={mediEquipment.status}></input>
                                        </td>
                                        <td>
                                            {" "}
                                            <div className="tools">
                                                <Button onClick={() => this.onEdit(mediEquipment.id, mediEquipment.name)}>
                                                    <FontAwesome name="edit" />
                                                </Button>
                                                <Popconfirm
                                                    placement="topRight"
                                                    title={`Xác nhận xóa ${mediEquipment.name}`}
                                                    onConfirm={() => this.onDelete(mediEquipment.id)}
                                                    okText="Ok"
                                                    cancelText="Cancel"
                                                >
                                                    <Button>
                                                        <FontAwesome name="trash-alt" />
                                                    </Button>
                                                </Popconfirm>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="pagination-d">
                        <Pagination
                            defaultCurrent={3}
                            current={getMediEquipmentSearch().PageIndex}
                            total={totalCount}
                            showQuickJumper
                            showTitle={false}
                            defaultPageSize={getMediEquipmentSearch().PageSize}
                            onChange={(page, pageSize) => this.onChangePage(page, pageSize)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        mediEquipments: state.mediEquipment.mediEquipments,
        totalCount: state.mediEquipment.totalCount,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getMediEquipmentFromServer: (search) => dispatch(getMediEquipmentFromServer(search)),
        deleteMediEquipmentFromServer: (id) => dispatch(deleteMediEquipmentFromServer(id)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MediEquipmentPage);
