import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, message, Select, Layout } from "antd";
import { connect } from "react-redux";
import { setModalBasic } from "../../reducers/uiReducer";
import Axios from "axios";
import { fetchIndications, getDropdownServices } from "../../reducers/icdReducer";
import TextArea from "antd/lib/input/TextArea";
const { Content, Footer, Header } = Layout;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const { Option } = Select;
class EditIcd extends Component {
    componentDidMount = () => {
        this.refForm.setFieldsValue({ name: this.props.name });
    };

    refForm = null;

    onFinish = async (values) => {
        const response = await Axios.put(`/api/Indications/${this.props.id}`, values);
        if (response.data.status === 1) {
            message.success(response.data.message);
            this.props.fetchIndications();
            this.props.setModalBasic({ visible: false });
        } else {
            message.error(response.data.message);
        }
    };

    render() {
        const { name, id, dropdownServices, serviceID } = this.props;
        return (
            <Layout className="icd-edit-form-content">
                <Header>
                    <p className="modalBasic-title">Cập nhật ICD</p>
                    <button
                        className="modalBasic-btn-close"
                        onClick={() => this.props.setModalBasic({ visible: false })}
                    >
                        <i className="fas fa-times icon"></i>
                    </button>
                </Header>
                <Content>
                    <Form
                        ref={(ref) => (this.refForm = ref)}
                        style={{ marginTop: 20 }}
                        {...formItemLayout}
                        name="editicd"
                        onFinish={this.onFinish}
                        scrollToFirstError
                    >
                        <Form.Item name="name" label="Tên" rules={[{ required: true, message:"vui lòng nhập tên!" }]}>
                            <TextArea allowClear />
                        </Form.Item>
                        <Form.Item name="serviceID" label="Loại dịch vụ" rules={[{ required: true, message:"vui lòng nhập tên!" }]}>
                            <Select
                                showSearch
                                placeholder="Chọn dịch vụ"
                                allowClear
                                defaultValue={serviceID ?? ""}
                                style={{ width: "100%" }}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {dropdownServices.map((item) => (
                                    <Option key={item.id} value={Number(item.id)}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Button style={{ display: "none" }} htmlType={"submit"} id="btn-submit-edit-icd">
                            Cập nhật
                        </Button>
                    </Form>
                </Content>
                <Footer>
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            document.getElementById("btn-submit-edit-icd").click();
                        }}
                    >
                        Cập nhật
                    </Button>
                </Footer>
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        dropdownServices: state.icd.dropdownServices,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        fetchIndications: () => dispatch(fetchIndications()),
        getDropdownServices: () => dispatch(getDropdownServices()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditIcd);
