import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button, message, Layout } from "antd";
import { EditApproTimeTechnicalServiceFromServer, getTechnicalServiceFromServer, getTechnicalServiceById } from "../../reducers/technicalServiceReducer";
import { connect } from "react-redux";
import moment from "moment";
import { handleOpenModalApp, handleCloseModalApp, setModalBasic } from "../../reducers/uiReducer";
import { getTechnicalServiceSearch } from "../../pages/Home/TechnicalServicePage/technicalservice";

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const { Header, Content, Footer } = Layout;
class ContentEditTime extends Component {
    constructor(props) {
        super(props);
        this.formRef = null;
    }

    componentDidMount = () => {
        this.props.getTechnicalServiceById(this.props.technicalserviceId).then(() => {
            if (this.props.technicalserviceItem.aproTime) {
                this.formRef.setFieldsValue({ ...this.props.technicalserviceItem, aproTime: moment(this.props.technicalserviceItem.aproTime) });
            }
        });
    };
    componentDidUpdate = (prevProps) => {
        if (prevProps.technicalserviceId !== this.props.technicalserviceId) {
            this.props.getTechnicalServiceById(this.props.technicalserviceId).then(() => {
                if (this.props.technicalserviceItem.aproTime) {
                    this.formRef.setFieldsValue({ ...this.props.technicalserviceItem, aproTime: moment(this.props.technicalserviceItem.aproTime) });
                }
            });
        }
    };
    onFinish = (values) => {
        const { technicalserviceId } = this.props;
        const obj = {
            aproTime: values.aproTime.format()
        }
        this.props.EditApproTimeTechnicalServiceFromServer(technicalserviceId, obj).then(() => {
            this.props.handleCloseModalApp();
            const search = getTechnicalServiceSearch();
            this.props.getTechnicalServiceFromServer(search);
        });
    };
    render() {
        return (
            <>
                <Layout className="layout-technicalservice-edit">
                    <Header>
                        <p className="modalBasic-title">Sửa Thời gian duyệt</p>
                        <button
                            className="modalBasic-btn-close"
                            onClick={() => this.props.setModalBasic({ visible: false })}
                        >
                            <i className="fas fa-times icon"></i>
                        </button>
                    </Header>
                    <Content>
                        <Form
                            //style={{ marginTop: 20 }}
                            {...formItemLayout}
                            name="EditApproTime"
                            className="form-edit-technicalservice"
                            onFinish={this.onFinish}
                            ref={(ref) => (this.formRef = ref)}
                            scrollToFirstError
                        >
                            <Form.Item name="aproTime" label="Thời gian duyệt">
                                    <DatePicker showTime format="DD/MM/YYYY HH:mm:ss" placeholder="" style={{ width: "100%" }} />
                            </Form.Item>
                            {/* <div style={{ display: "flex" }}>
                                <div style={{ flex: 1 }}></div>
                                <div style={{ flex: 3 }}>
                                    <Button style={{ display: "none" }} id="btn-submit-edit-indication" htmlType={"submit"}>
                                        Lưu lại
                                    </Button>
                                </div>
                            </div> */}
                            <Button style={{ display: "none" }} id="btn-submit-edit-technicalservice-time" htmlType={"submit"}>
                                Lưu lại
                            </Button>
                        </Form>
                    </Content>
                    <Footer>
                        <Button
                            style={{ color: "#fff", background: "#2687d4" }}
                            onClick={() => document.getElementById("btn-submit-edit-technicalservice-time").click()}
                        >
                            Lưu lại
                        </Button>
                    </Footer>
                </Layout>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        technicalserviceItem: state.technicalservice.technicalserviceItem,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getTechnicalServiceById: (id) => dispatch(getTechnicalServiceById(id)),
        EditApproTimeTechnicalServiceFromServer: (id, technicalservice) => dispatch(EditApproTimeTechnicalServiceFromServer(id, technicalservice)),
        handleOpenModalApp: (modalData) => dispatch(handleOpenModalApp(modalData)),
        handleCloseModalApp: () => dispatch(handleCloseModalApp()),
        getTechnicalServiceFromServer: (search) => dispatch(getTechnicalServiceFromServer(search)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentEditTime);
