import React, { Component } from "react";
import { Form, Input, Radio, DatePicker, Button,Layout } from "antd";
import { connect } from "react-redux";
import { setModalBasic } from "../../../reducers/uiReducer";
import { getRoleAdminById, getRoleAdminFromServer, setRoleAdminItem, updateRoleAdminToServer} from "../../../reducers/roleAdminReducer";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { initialRoleAdminSeach } from "./RoleAdmin";
const { Header, Content,Footer } = Layout;
class ContentInfo extends Component {
    componentDidMount = () => {
        this.props.getRoleAdminById(this.props.id);
    };
    // componentDidUpdate = (prevProps) => {
    //     if (prevProps.id !== this.props.id) {
    //         this.props.getLogById(this.props.id);
    //     }
    // };
    onChange = (id) => {
        const { roleadminitem } = this.props;
        const newroleadminitem = [...roleadminitem] ;
        for (var key in newroleadminitem) {
          if (newroleadminitem[key].id == id) {
            newroleadminitem[key].selected =
              !newroleadminitem[key].selected;
          }
        }
        this.props.setRoleAdminItem(newroleadminitem);
      };
      onFinish = async () =>{
        const { roleadminitem } = this.props;
        let obj = {
            idUserrole : this.props.id,
            role : roleadminitem,
        }
        await this.props.updateRoleAdminToServer(obj);
        this.props.setModalBasic({visible:false});
        this.props.getRoleAdminFromServer(initialRoleAdminSeach);
      }
    render() {
        const { roleadminitem } = this.props;
        return (
            <Layout>
                <Header style={{ display: "flex", justifyContent: "space-between" }}>
                    <p className="modalBasic-title">{this.props.name}</p>
                    <button className="modalBasic-btn-close"onClick={() => this.props.setModalBasic({visible:false})}>
                        <i className="fas fa-times icon" ></i>
                    </button>
                </Header>
                <Content >
                    {roleadminitem.map((rolead, id) => {
                        return (
                            <tr style={{display: "flex", marginLeft:180}}>
                                <Checkbox
                                    key={rolead.id}
                                    checked={rolead.selected}
                                    onChange={() => this.onChange(rolead.id)}
                                >
                                    <span style={{fontSize:18}}>{rolead.name}</span>
                                </Checkbox>
                            </tr>
                        );
                    })}
                </Content>
                <Footer>
                    <Button
                        onClick={this.onFinish}
                        style={{ float: "right", marginBottom: 20, marginTop: 20 }}
                        type="primary"
                    >
                        Thay Đổi
                    </Button>
                </Footer>
                
            </Layout>
        );
    }
}

function mapStateToProps(state) {
    return {
        roleadminitem: state.roleadmin.roleadminitem,//2-2
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getRoleAdminById: (id) => dispatch(getRoleAdminById(id)),
        setRoleAdminItem:(data) =>dispatch(setRoleAdminItem(data)),
        setModalBasic: (data) => dispatch(setModalBasic(data)),
        updateRoleAdminToServer: (data) => dispatch(updateRoleAdminToServer(data)),
        getRoleAdminFromServer : (data) => dispatch(getRoleAdminFromServer(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentInfo);
